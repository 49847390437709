import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Layout,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuFooter from './MenuFooter';
import MenuHeader from './MenuHeader';

import MainMenuDynamicBody from './auxiliary/MainMenuDynamicBody';
import MainMenuTopWidget from './auxiliary/MainMenuTopWidget';
import MenuInputItem from './auxiliary/MenuInputItem';
import MenuStaticItem from './auxiliary/MenuStaticItem';

import InformationCard from '../general/InformationCard';
import ScrollableContainer from '../general/ScrollableContainer';

import config from '../utils/config';

import './MainMenu.less';

const MainMenu = (
  {
    handleAlternateMenuClick,
    handleMenuClick,
    menu,
    openDrawer,
    ...props
  }
) => {

  const {
    hasDivider,
    hasFooter,
    hasTitle,
    innerConfig,
    menuShown
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleJustify = () =>
    JSON.stringify(menu) !== JSON.stringify(localConfig.menu) ?
      props.classieContentBadgeText.length ?
        localConfig.grid.menuJustifyAlternate +
        localConfig.text.activityResultSeparatorText +
        props.carouselContent
        :
        localConfig.grid.menuJustifyAlternate
      :
      localConfig.grid.menuJustifyAlternate
    ;

  const handleScrollFrame = (values) => {

  }

  return (
    <div>
      <Row
        justify={handleJustify()}
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline",
              onClick: handleMenuClick
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={['right', 'left']}
          >
            <Menu.Item>
              <MenuHeader
                innerConfig={localConfig}
                openDrawer={openDrawer}
                rightBadgeText={localConfig.text.topLeftSlugAlternateText}
              />
            </Menu.Item>
            {
              hasTitle &&
              menu.hasInputItem ?
                <Menu.Item>
                  <MenuInputItem
                    handleMenuClick={handleMenuClick}
                    innerConfig={localConfig}
                    menu={menu}
                  />
                </Menu.Item>
                :
                <Menu.Item
                >
                  <MainMenuTopWidget
                    handleMenuClick={handleMenuClick}
                    innerConfig={localConfig}
                    menu={menu}
                    staticData={props.staticData}
                    subject={props.subject}
                  />
                </Menu.Item>
            }
            {
              hasDivider && <Menu.Divider/>
            }
            {
              menuShown &&
              (
                props.staticData &&
                props.staticData.length
              ) ?
                menu.items ?
                  menu.items.map(
                    (item, index) =>
                      !item.hidden &&
                      <Menu.Item
                      >
                        <MenuStaticItem
                          handleMenuClick={handleMenuClick}
                          innerConfig={localConfig}
                          item={item}
                          itemIndex={index}
                        />
                      </Menu.Item>
                  )
                  :
                  <MainMenuDynamicBody
                    handleMenuClick={handleMenuClick}
                    innerConfig={localConfig}
                    menu={menu}
                    staticData={props.staticData}
                  />
                :
                <Menu.Item>
                </Menu.Item>
            }
            {
              hasFooter &&
              <Menu.Item>
                <MenuFooter
                  handleAlternateMenuClick={handleAlternateMenuClick}
                  innerConfig={localConfig}
                  justify={handleJustify()}
                  staticData={props.staticData}
                />
              </Menu.Item>
            }
          </QueueAnim>
        </Col>
      </Row>
    </div>
  );
}

export default MainMenu;
