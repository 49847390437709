import React from 'react';

import PropTypes from 'prop-types';

import DashboardBaseView from './DashboardBaseView';

const DashboardClassicView = (
  props
) => {

  return (
    <div>
      <DashboardBaseView
        {...props}
      >
      </DashboardBaseView>
    </div>
  );
}

DashboardBaseView.propTypes = {

}

export default DashboardClassicView;
