import config from '../utils/config';

import {
  getRequest,
  postRequest
} from '../aws/aws_rest_client';

const { api } = config;
const { methods } = api;

const createGroup = async (parameters) =>
  postRequest(
    methods.groups.create,
    {
      body: parameters
    }
  );

const groupInfo = async (parameters) =>
  getRequest(
    methods.groups.info,
    {
      queryStringParameters: parameters
    }
  );

const listGroups = async (
  parameters,
  reducer
) =>
  getRequest(
    methods.groups.list,
    {
      queryStringParameters: parameters
    },
    reducer
  );

const updateGroup = async (parameters) =>
  postRequest(
    methods.groups.update,
    {
      body: parameters
    }
  );

export {
  createGroup,
  groupInfo,
  listGroups,
  updateGroup
};
