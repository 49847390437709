import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Badge,
  Checkbox,
  Col,
  Icon,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuActionWidget  from './MenuActionWidget';

import config from '../utils/config';

const { Link } = Anchor;

const MenuStaticItem = (
  {
    handleActionClick,
    handleMenuClick,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props)

  return (
    props.item.hasActivity ?
      <Row
        justify="start"
        type="flex"
      >
        <Col>
          <Anchor>
            <Link
              title={props.item.title}
            >
            </Link>
          </Anchor>
        </Col>
        <Col>
          <Badge
            count={props.item.activityCount}
          />
        </Col>
      </Row>
      :
      props.item.hasAction ?
        <Row
          justify="space-between"
          type="flex"
        >
          <Col>
            <Anchor>
              <Link
                title={props.item.title}
              >
              </Link>
            </Anchor>
          </Col>
          <Col>
            <MenuActionWidget
              handleActionClick={handleActionClick}
              handleMenuClick={handleActionClick}
              innerConfig={localConfig}
              item={props.item}
            />
          </Col>
        </Row>
        :
        <Anchor>
          <Link
            title={props.item.title}
          >
          </Link>
        </Anchor>
  );
}

export default MenuStaticItem;
