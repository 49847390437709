import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row,
  Skeleton
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import InformationCard from '../../general/InformationCard';

import config from '../../utils/config';

import './CardInnerWidgetBlockCard.less';

const CardInnerWidgetBlockCard = (
  {
    handleCardInnerWidgetInnerClick,
    handleCardInnerWidgetTopClick,
    handleDatatransformation,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInnerItemArraySpan = (itemSpan) =>
    Array.apply(null, Array(itemSpan));


  const handleColumns = () =>
    props.grid ?
      props.grid.columns ?
        props.grid.columns
        :
        props.subject[props.item.field].columns
      :
      props.subject[props.item.field].columns


  const handleData = () =>
    props.data.length &&
    props.datatransfomation &&
    (
      props.data.slice().shift()[
        props.datatransfomation.find(
          element => element.extraMarkerField
        ).field
      ]
      ||
      props.data.slice().shift()[
        props.datatransfomation.find(
          element => element.extraMarkerField
        ).defaultField
      ]
    )

  console.log(
    handleColumns()
  )
  console.log(
    handleData()
  )

  const handleInnerItemSpan = (item) =>
    Math.ceil(
      (
        props.data &&
        props.data.length ?
          props.data.length
          :
          props.subject[item.field].count
      )
      /
      handleColumns()
    );

  const handleItemFilter = (innerIndex, innerInnerIndex, item) =>
    item ?
      item.parent ?
        props.carouselContent && props.carouselContent !== localConfig.defaultThirdPartyParameters[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
          item.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
          :
          !item.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
        :
        item
      :
      props.item.innerWidgetInnerWidgetReverse ?
        props.item.alternateTopic ?
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent &&
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryField] &&
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryField] == props.staticData[localConfig.defaultThirdPartyThirdPartySecondaryField]
          :
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent &&
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryField] &&
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryField] != props.staticData[localConfig.defaultThirdPartyThirdPartySecondaryField]
        :
        props.carouselContent &&
        props.carouselContent !== localConfig.defaultThirdPartyParameters[localConfig.defaultThirdPartyThirdPartySecondaryField] &&
        props.alternateCardInnerWidgetInnerWidgetShown ?
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent &&
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
          :
          props.data[innerIndex * handleColumns() + innerInnerIndex].parent

  const handleInnerItemCount = (item) =>
    (
      props.data.length ?
        props.data.filter(
          item => handleItemFilter(item, item, item)
        ).length
        :
        props.subject[item.field].count
    )

  const handleItemResultContent = (item, topic, separatorText) =>
    props.subject[props.item.field].title ?
      separatorText || topic ?
        localConfig.text.activityResultSeparatorText
        :
        props.subject[props.item.field].title
      :
      separatorText ?
        (
          !props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKeyThirdParty] &&
          separatorText
        ) +
        handleInnerItemCount(item) +
        localConfig.text.activityResultSeparatorText +
        (
          topic ?
            topic
            :
            item[localConfig.text.innerWidgetInnerWidgetFormValueKey]
        )
        :
        handleInnerItemCount(item) +
        localConfig.text.activityResultSeparatorText +
        (
          topic ?
            topic
            :
            item[localConfig.text.innerWidgetInnerWidgetFormValueKey]
        )
  ;

  const handleItemResultContentAlternate = () =>
    props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKey] ?
      props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKey]
      :
      props.text.activityResultAlternateSlugText
    +
    localConfig.text.activityResultAlternateSeparatorText +
    localConfig.text.activityResultFilterText

  const handleJustify = (
    innerItem = (
      props.data &&
      props.data.length &&
      props.data.slice().shift()
    )
  ) =>
    props.item.justify ?
      props.item.justify
      :
      innerItem ?
        innerItem.parent ?
          innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
            localConfig.grid.cardInnerWidgetBlockCardJustifyAlternate
            :
            localConfig.grid.cardInnerWidgetBlockCardJustify
          :
          localConfig.grid.cardInnerWidgetBlockCardJustify
        :
        localConfig.grid.cardInnerWidgetBlockCardJustify

  console.log(props)
  console.log(
    props.data &&
    props.data.length &&
    props.data.slice().shift()
  )

  return (
    [

      props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKeyAlternate] ?
        <Row
          justify="left"
          type="flex"
        >
          <Col
          >
            {props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKeyAlternate]}
          </Col>
        </Row>
        :
        (
          props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKey] ||
          props.text.activityResultAlternateSlugText == props.text.activityResultSlugText
        ) ?
          <Row
            justify="left"
            type="flex"
          >
            <Col
            >
              {
                handleData() &&
                props.data.filter(
                  item => handleItemFilter(item, item, item)
                ).length
              }
            </Col>
            <Col
            >
              {handleItemResultContentAlternate()}
            </Col>
          </Row>
          :
          <Row
            justify="left"
            type="flex"
          >
            {
              !props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKeyThirdParty] &&
              <Col
                onClick={handleCardInnerWidgetTopClick}
              >
                {handleItemResultContent(props.item)}
              </Col>
            }
            ,
            {
              <Col
                onClick={handleCardInnerWidgetTopClick}
              >
                {handleItemResultContent(props.item, props.text.activityResultAlternateSlugText, localConfig.text.activityResultAlternateSeparatorText)}
              </Col>
            }
            ,
            {
              !props.item[localConfig.text.alternateInnerWidgetInnerWidgetFormValueKeyThirdParty] &&
              <Col
                onClick={handleCardInnerWidgetTopClick}
              >
                {handleItemResultContent(props.item, props.text.activityResultSlugText)}
              </Col>
            }
          </Row>
      ,
      (
        props.alternateCardInnerWidgetInnerWidgetShown &&
        !props.item.innerWidgetInnerWidgetReverse
        // &&
        // !props.carouselContent
      ) ?
        handleData() ?
          props.data.map(
            (innerItem, innerIndex) =>
              // handleItemFilter(innerItem, innerItem, innerItem) &&
              <Row
                justify="initial"
                type="flex"
              >
                <Col
                  onClick={event => handleCardInnerWidgetInnerClick(event, innerItem)}
                  span={localConfig.grid.gridSpan}
                >
                  <InformationCard
                    hasExtendedAvatar
                    hasExtendedContent
                    hasMediumAvatar
                    justify={props.justify}
                    secondaryJustify={props.secondaryJustify}
                    subject={innerItem}
                  />
                </Col>
              </Row>
          )
          :
          handleInnerItemArraySpan(props.subject[props.item.field].columns).map(
            (innerItem, innerIndex) =>
              <Row
                justify="initial"
                type="flex"
              >
                <Col
                  span={localConfig.grid.gridSpan}
                >
                  <Skeleton
                    active
                    avatar
                    loading={!props.data.length}
                  >
                  </Skeleton>
                </Col>
              </Row>
          )
        :
        handleInnerItemArraySpan(handleInnerItemSpan(props.item)).map(
          (innerItem, innerIndex) =>
            <Row
              justify={handleJustify()}
              type="flex"
            >
              {
                props.data &&
                props.data.length ?
                  handleInnerItemArraySpan(handleColumns()).map(
                    (innerInnerItem, innerInnerIndex) =>
                      props.data[innerIndex * handleColumns() + innerInnerIndex] &&
                      // handleItemFilter(innerIndex, innerInnerIndex) &&
                      <Col
                        onClick={event => handleCardInnerWidgetInnerClick(event, props.data[innerIndex * handleColumns() + innerInnerIndex])}
                      >
                        <InformationCard
                          hasExtendedContent
                          informationReverse={
                            props.data[innerIndex * handleColumns() + innerInnerIndex].parent &&
                            !props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryField] &&
                            props.data[innerIndex * handleColumns() + innerInnerIndex].parent[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty]
                          }
                          innerInformationReverse
                          subject={props.data[innerIndex * handleColumns() + innerInnerIndex]}
                        />
                      </Col>
                  )
                  :
                  handleInnerItemArraySpan(handleColumns()).map(
                    (innerInnerItem, innerInnerIndex) =>
                      <Col
                      >
                        <Skeleton
                          active
                          avatar
                          loading={!props.data.length}
                        >

                        </Skeleton>
                      </Col>
                  )
              }
            </Row>
        )
    ]
  );
}

export default CardInnerWidgetBlockCard;
