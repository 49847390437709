import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Anchor,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import CardHeaderWidgetBlockCard from './auxiliary/CardHeaderWidgetBlockCard';
import CardHeaderWidgetForm from './auxiliary/CardHeaderWidgetForm';
import CardHeaderWidgetInlineInformation from './auxiliary/CardHeaderWidgetInlineInformation';

import config from '../utils/config';

import './CardHeaderWidget.less';

const { Link } = Anchor;

const CardHeaderWidget = (
  {
    handleCardHeaderWidgetClick,
    onChangeSubject,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleCardWidgetsGrid = (item) =>
    props.formProps ?
      props.formProps.grid ?
        props.formProps.grid.gridSpan ?
          props.formProps.grid
          :
          props.grid ?
            props.grid.gridSpan ?
              props.grid
              :
              localConfig.grid
            :
            localConfig.grid
        :
        props.grid ?
          props.grid.gridSpan ?
            props.grid
            :
            localConfig.grid
          :
          localConfig.grid
      :
      props.grid ?
        props.grid.gridSpan ?
          props.grid
          :
          localConfig.grid
        :
        localConfig.grid
    ;

  return (
    props.form ?
      <CardHeaderWidgetForm
        form={props.form}
        formProps={props.formProps}
        grid={handleCardWidgetsGrid()}
        handleCardHeaderWidgetClick={handleCardHeaderWidgetClick}
        innerConfig={localConfig}
      />
      :
      (
        props.inlineInformation &&
        Array.isArray(props.inlineInformation)
      ) ?
        props.inlineInformation.map(
          item =>
            item.card &&
            props.subject[item.field].items &&
            <CardHeaderWidgetBlockCard
              button={props.button}
              formValues={props.formValues}
              handleCardHeaderWidgetClick={handleCardHeaderWidgetClick}
              inlineInformation={props.inlineInformation}
              innerConfig={localConfig}
              item={item}
              navigate={navigate}
              onChangeSubject={onChangeSubject}
              subject={props.subject}
            />
        )
        :
        <Row
          justify="unsafe"
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            {
              props.inlineInformation &&
              <CardHeaderWidgetInlineInformation
                formValues={props.formValues}
                handleCardHeaderWidgetClick={handleCardHeaderWidgetClick}
                inlineInformation={props.inlineInformation}
                innerConfig={localConfig}
                subject={props.subject}
              />
            }
          </Col>
        </Row>
  )
}

export default CardHeaderWidget;
