module.exports = {
  defaultField: "listing",
  drawerWidth: 405,
  rcQueueAnim: {
    delay: [0, 1000],
    duration: [500, 5000],
    interval: [500, 1000]
  },
  text: {
    extraMarkerFieldSlugText: "",
    linkTarget: "_blank",
    topLeftSlugAlternateText: "",
    topMainAlternateMenuDynamicBodySlugText: ""
  }
}
