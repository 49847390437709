import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import InformationCard from '../../general/InformationCard';

import config from '../../utils/config';

import './MainThirdPartyMenuTopWidget.less';

const MainThirdPartyMenuTopWidget = (
  {
    handleThirdPartyMenuClick,
    ...props
  }
) => {

  const {
    handleStep,
    innerConfig,
    step
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleContent = () =>
    props.subject[localConfig.defaultFifthPartyField] ?
      localConfig.text.topCenterSlugThirdPartyAlternateText
      :
      localConfig.text.topCenterSlugThirdPartyText
    ;

  const handleFilter = () =>
    props.subject[localConfig.defaultFifthPartyField] &&
    (
      props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
      props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
    )
  ;

  console.log(props)

  return (
    <Row
      justify="inherit"
      type="flex"
    >
      <Col>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col>
            <MenuButton
              action={handleThirdPartyMenuClick}
            />
          </Col>
          <Col>
            {handleContent()}
          </Col>
          <Col>
            <MenuButton
              action={handleThirdPartyMenuClick}
            />
          </Col>
        </Row>
        <Row
          justify="initial"
          type="flex"
        >
          <Col>

          </Col>
          <Col>
            <InformationCard
              informationReverse
              subject={props.subject}
            />
          </Col>
        </Row>
        {
          handleFilter() &&
          <Row
            justify="center"
            type="flex"
          >
            <Col>
              {
                step == step - step ?
                  props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ?
                    [
                      <MenuButton
                        action={event => handleStep(event, event)}
                        title={localConfig.text.topMainThirdPartyMenuLeftButtonSlugText}
                      />,
                      <MenuButton
                        title={localConfig.text.topMainThirdPartyMenuRightButtonSlugText}
                      />
                    ]
                    :
                    [
                      <MenuButton
                        action={event => handleStep(event, event)}
                        title={localConfig.text.topMainThirdPartyMenuLeftButtonSlugAlternateText}
                      />,
                      <MenuButton
                        title={localConfig.text.topMainThirdPartyMenuRightButtonSlugAlternateText}
                      />
                    ]
                  :
                  <MenuButton
                    title={localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyAlternateText}
                  />
              }
            </Col>
          </Row>
        }
      </Col>
    </Row>
  );
}

export default MainThirdPartyMenuTopWidget;
