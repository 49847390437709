import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

import MenuStaticItem from './auxiliary/MenuStaticItem';
import MainMenuTopWidget from './auxiliary/MainMenuTopWidget';

import config from '../utils/config';

import './MainFourthPartyMenu.less';

const MainFourthPartyMenu = (
  {
    handleFourthPartyMenuClick,
    openDrawer,
    ...props
  }
) => {

  const {
    fourthPartyMenuShown,
    handleMenuClick,
    hasFooter,
    hasTitle,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <div>
      <Row
        justify="baseline"
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline",
              onClick: handleMenuClick
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={['right', 'left']}
          >
            <Menu.Item>
              <MenuHeader
                innerConfig={localConfig}
                isFourthPartyMenu
                openDrawer={openDrawer}
                rightBadgeText={localConfig.text.topLeftSlugFourthPartyText}
              />
            </Menu.Item>
            <Menu.Item>
              <MainMenuTopWidget
                handleAlternateMenuClick={handleFourthPartyMenuClick}
                handleMenuClick={handleFourthPartyMenuClick}
                innerConfig={localConfig}
                menu={localConfig.menu.fourthPartyItem}
                subject={props.subject}
              />
            </Menu.Item>
            {
              localConfig.menu.fourthPartyItem.items.map(
                (item, index) =>
                  !item.hidden &&
                  <Menu.Item
                  >
                    <MenuStaticItem
                      handleMenuClick={handleMenuClick}
                      innerConfig={localConfig}
                      item={item}
                      itemIndex={index}
                    />
                  </Menu.Item>
              )
            }
            <Menu.Item>
              <Row
                justify="baseline"
                type="flex"
              >
                <Col>
                  <MenuButton
                    title={localConfig.text.topLeftSlugFourthPartyTextAlternate}
                  />                  
                </Col>
                <Col>
                  <MenuButton
                    title={localConfig.text.topLeftSlugFourthPartyTextThirdParty}
                  />
                </Col>
              </Row>
            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
          </QueueAnim>
        </Col>
      </Row>
    </div>
  )
}

export default MainFourthPartyMenu;
