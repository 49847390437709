import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Checkbox,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

const MenuActionWidget = (
  {
    handleActionClick,
    handleMenuClick,
    ...props
  }
) => {

  const handleContentClick = (event) => {
    event.stopPropagation();
  };

  return (
    [
      <Avatar
        onClick={event => handleActionClick(event, props.item, props.item.hasAction)}
      />
      ,
      <Checkbox
        onChange={event => handleActionClick(event, props.item)}
      >
      </Checkbox>
    ]
  );
}

export default MenuActionWidget;
