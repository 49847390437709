import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import QueueAnim from 'rc-queue-anim';

import PhoneInput from 'react-phone-input-2';

import config from '../utils/config';

import 'react-phone-input-2/lib/style.css'

const PhoneNumberInputField = (
  {
    fieldValues,
    onChangeField,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  const handleChange = (value, extravalue) =>
    onChangeField(
      Object.fromEntries(
        [
          [props.field, value],
          [props.extraField, extravalue[props.extraFieldKey]]
        ]
      )
    )

  const handleSearch = () => {
  }

  return (
    <div>
      <PhoneInput
        country={props.phone.defaultValue}
        onChange={(value, extravalue) => handleChange(value, extravalue)}
        placeholder={props.placeholder}
        value={
          fieldValues &&
          fieldValues[props.field] &&
          fieldValues[props.field]
        }
      />
    </div>
  )
}


export default PhoneNumberInputField;
