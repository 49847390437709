import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Card,
  Col,
  Icon,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuBadge from '../menu/MenuBadge';

import config from '../utils/config';

import './InformationCard.less';

const { Meta } = Card;

const InformationCard = (
  {
    ...props
  }
) => {

  const {
    hasBadge,
    hasExtendedAvatar,
    hasExtendedContent,
    hasHiddenAvatar,
    hasHiddenContent,
    hasInnerContent,
    hasMediumAvatar,
    hasPartialContent,
    hasPartialContentAlternate,
    informationReverse,
    innerInformationReverse,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleAvatar = () =>
    hasMediumAvatar ?
      props.subject.avatarMedium ?
        props.subject.avatarMedium
        :
        props.subject.avatar
      :
      props.subject.avatar

  const  handleExtendedAvatar = () =>
    props.subject.extendedAvatar

  const  handleExtendedAvatarReplacementDescription = (item, secondaryItem) =>
    secondaryItem ?
      <Row
        justify={props.secondaryJustify}
        type="flex"
      >
        <Col
        >
          <Avatar
            src={handleExtendedAvatar()}
          />
          {item}
        </Col>
        <Col
        >
          {secondaryItem}
        </Col>
      </Row>
      :
      <Row
        justify={props.justify}
        type="flex"
      >
        <Col
        >
          <Avatar
            src={handleExtendedAvatar()}
          />
        </Col>
        <Col
        >
          {item}
        </Col>
      </Row>

  const  handleExtendedAvatarReplacementTitle = (item, secondaryItem) =>
    hasBadge ?
      <Row
        justify={props.secondaryJustify}
        type="flex"
      >
        <Col
        >
          <MenuBadge
            rightText={item}
          />
        </Col>
      </Row>
      :
      <Row
        justify={props.secondaryJustify}
        type="flex"
      >
        <Col
        >
          <Avatar
            src={handleExtendedAvatar()}
          />
          {item}
        </Col>
        <Col
        >
          {secondaryItem}
        </Col>
      </Row>

  const handleInnerContent = () =>
    localConfig.text.activitySeparatorText +
    props.subject.activityCount
  ;

  const handleReplacementDescriptionText = (hasExtendedContent) =>
    hasExtendedContent ?
      !hasHiddenContent &&
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementDescription(props.subject.subDescription)
        :
        props.subject.subDescription
      :
      hasPartialContentAlternate ?
        props.subject.subTitle
        :
        hasPartialContent ?
          props.subject.subDescription
          :
          props.replacementDescriptionText ?
            props.replacementDescriptionText
            :
            hasExtendedAvatar ?
              handleExtendedAvatarReplacementDescription(
                innerInformationReverse ?
                  props.subject.subDescription == props.subject.subTitle ?
                    props.subject.subTitle
                    :
                    props.subject.description
                  :
                  props.subject.description
                ,
                props.subject.subSubDescription
              )
              :
              props.subject.description
    ;

  const handleReplacementTitleText = (hasExtendedContent) =>
    hasExtendedContent ?
      !hasHiddenContent &&
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementTitle(
          innerInformationReverse ?
            props.subject.subDescription == props.subject.subTitle ?
              props.subject.description
              :
              props.subject.subTitle
            :
            props.subject.subTitle
        )
        :
        props.subject.subTitle
      :
      hasBadge ?
        handleExtendedAvatarReplacementTitle(props.subject.title)
        :
        props.replacementTitleText ?
          props.replacementTitleText
          :
          props.subject.title
    ;

  console.log(props)
  console.log(handleAvatar())

  return (
    props.subject &&
    <div>
      <Card
      >
        {
          hasExtendedContent ?
            <Meta
              avatar={
                <Avatar
                  src={handleAvatar()}
                />
              }
              description={
                <Card
                >
                  {
                    informationReverse ?
                      <Meta
                        description={handleReplacementDescriptionText(hasExtendedContent)}
                        title={handleReplacementTitleText(hasExtendedContent)}
                      />
                      :
                      <Meta
                        description={handleReplacementTitleText(hasExtendedContent)}
                        title={handleReplacementDescriptionText(hasExtendedContent)}
                      />
                  }
                </Card>
              }
              title={
                <Card
                >
                  {
                    informationReverse ?
                      <Meta
                        description={handleReplacementDescriptionText()}
                        title={handleReplacementTitleText()}
                      />
                      :
                      <Meta
                        description={handleReplacementTitleText()}
                        title={handleReplacementDescriptionText()}
                      />
                  }
                </Card>
              }
            />
            :
            informationReverse ?
              <Meta
                avatar={
                  !hasHiddenAvatar &&
                  <Avatar
                    src={!hasInnerContent && handleAvatar()}
                  >
                    {
                      hasInnerContent &&
                      handleInnerContent()
                    }
                  </Avatar>
                }
                description={handleReplacementDescriptionText()}
                title={handleReplacementTitleText()}
              />
              :
              <Meta
                avatar={
                  !hasHiddenAvatar &&
                  <Avatar
                    src={!hasInnerContent && handleAvatar()}
                  >
                    {
                      hasInnerContent &&
                      handleInnerContent()
                    }
                  </Avatar>
                }
                description={handleReplacementTitleText()}
                title={handleReplacementDescriptionText()}
              />
        }
      </Card>
    </div>
  );
}

export default InformationCard;
