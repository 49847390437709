import React from 'react';

import { connect } from "react-redux";

import ReactPlayer from 'react-player';

import config from '../../utils/config';

const ClassieContentVideo = (
  {
    ...props
  }
) => {
  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  return (
    <ReactPlayer
      controls={props.item.video.controls}
      height={props.item.video.height}
      forceVideo
      muted={props.item.video.muted}
      playing={props.item.video.playing}
      url={props.item.video.src}
      width={props.item.video.width}
    />
  )
}

export default ClassieContentVideo;
