/* eslint-disable no-unused-expressions */
const classReg = (className) =>
  new RegExp("(^|\\s+)" + className + "(\\s+|$)");

var addClass, hasClass, removeClass;

(typeof window !== `undefined` &&
document.documentElement.classList ) ?
  (
    addClass = (element, classString) =>
      element.classList.add(classString)
    ,
    hasClass = (element, classString) =>
      element.classList.contains(classString)
    ,
    removeClass = (element, classString) =>
      element.classList.remove(classString)
  )
  :
  (
    hasClass = (element, classString) =>
      classReg(classString).test(element.className)
    ,
    addClass = (element, classString) => {
      element.className =
        !hasClass(element, classString) ?
          element.className + ' ' + classString
          :
          element.className
    }
    ,
    removeClass = (element, classString) => {
      element.className = element.className.replace(classReg(classString), ' ')
    }
  )


const toggleClass = (element, classString) => {
  var method = hasClass(element, classString) ? removeClass : addClass;
  method(element, classString);
}

var classie = {
  add: addClass,
  addClass: addClass,
  has: hasClass,
  hasClass: hasClass,
  remove: removeClass,
  removeClass: removeClass,
  toggle: toggleClass,
  toggleClass: toggleClass
};

module.exports = classie;
