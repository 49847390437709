import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row,
  Skeleton
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import InformationCard from '../../general/InformationCard';
import CardButton from './CardButton';

import config from '../../utils/config';

import './CardHeaderWidgetBlockCard.less';

const CardHeaderWidgetBlockCard = (
  {
    handleCardInnerWidgetTopClick,
    handleCardInnerWidgetInnerClick,
    onChangeSubject,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  const handleCardHeaderWidgetBlockCardClick = (event, item) => {
    event.stopPropagation();
    item.subject &&
    onChangeSubject(!item.subject);
    item.href &&
    navigate(item.href);
  }

  const handleJustify = () =>
    props.item.justify
  ;

  return (
    [
      <Row
        justify={handleJustify()}
        type="flex"
      >
        <Col
        >
          {props.item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
        </Col>
      </Row>
      ,
      <Row
        justify={handleJustify()}
        type="flex"
      >
        {
          props.subject[props.item.field].items.map(
            (item, index) =>
              <Col
                onMouseDown={event => handleCardHeaderWidgetBlockCardClick(event, item)}
              >
                <InformationCard
                  informationReverse
                  innerInformationReverse
                  subject={item}
                />
              </Col>
          )
        }
      </Row>
      ,
      props.button &&
      <Row
        justify={handleJustify()}
        type="flex"
      >
        <Col
        >
          <CardButton
            addonBefore={props.button.addonBefore}
            title={props.button.label}
          >
          </CardButton>
        </Col>
      </Row>
    ]
  )
}

export default CardHeaderWidgetBlockCard;
