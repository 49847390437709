/* eslint-disable no-unused-expressions */
import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Col,
  Form,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import FormButton from './FormButton';

import config from '../utils/config';

import './FormFooter.less';

const FormItem = Form.Item;

const FormFooter = (
  {
    handleChangeStep,
    handleFormFields,
    handleFormFooterAction,
    handleFormStep,
    handleFormStepValidate,
    handleSubject,
    handleSubmit,
    onChangeField,
    onChangeStep,
    openFormDrawer,
    resetData,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInnerInnerItemClick = (event, href) => {
    event.preventDefault();
    event.stopPropagation();
    href && console.log(href);
    href && (
      openFormDrawer &&
      openFormDrawer(),
      navigate(href),
      onChangeStep(props.step / props.step)
    );
    // href && window.location.assign(href);
  }

  const handleInnerInnerFormAction = (form) =>
    Object.assign({}, form, {
      handleSubmit: handleSubmit
    })

  const handleInnerInnerItemActionHandler = (event, action) => {
    event.stopPropagation();
    console.log(action)
    action.link ?
      window.open(
        props.fieldValues ?
          props.fieldValues[action.field] ?
            props.fieldValues[action.field]
            :
            props.formValues ?
              props.formValues[action.field] ?
                props.formValues[action.field]
                :
                props.subjectAlternate ?
                  props.subjectAlternate[action.field]
                  :
                  props.subjectAlternate[action.field]
              :
              props.subjectAlternate ?
                props.subjectAlternate[action.field]
                :
                props.subjectAlternate[action.field]
            :
            props.subjectAlternate ?
              props.subjectAlternate[action.field]
              :
              props.subjectAlternate[action.field]
        ,
        localConfig.text.linkTarget
      )
      :
      action.payloadExtended ?
        (
          handleChangeStep(event, action.payload, action.payload, action.payload, action.payloadExtended)
        )
        :
        (
          handleFormFooterAction(
            action.payload ?
              Object.assign(
                {},
                ...action,
                {
                  payload:
                  (
                    (
                      action.payload &&
                      Math.abs(action.payload.indexOf(localConfig.text.topLeftSlugSeparatorText)) ==
                        action.payload.indexOf(localConfig.text.topLeftSlugSeparatorText)
                    ) ?
                      props.innerPages.filter(
                        item =>
                          item.node.title ===
                            action.payload.split(
                              localConfig.text.topLeftSlugSeparatorText
                            ).slice().shift()
                      ).length ?
                        [
                          props.innerPages.filter(
                            item =>
                              item.node.title ===
                                action.payload.split(
                                  localConfig.text.topLeftSlugSeparatorText
                                ).slice().shift()
                          ).slice().shift().node
                          ,
                          props.formField ?
                            (
                              Math.abs(props.formField.indexOf(localConfig.text.alternateActivityResultThirdPartySeparatorText)) ==
                                props.formField.indexOf(localConfig.text.alternateActivityResultThirdPartySeparatorText)
                            ) ?
                              action.payload.split(
                                localConfig.text.topLeftSlugSeparatorText
                              ).reverse().slice().shift()
                              +
                              props.formField .split(
                                localConfig.text.alternateActivityResultThirdPartySeparatorText
                              ).reverse().slice().shift()
                              :
                              action.payload.split(
                                localConfig.text.topLeftSlugSeparatorText
                              ).reverse().slice().shift()
                            :
                            action.payload.split(
                              localConfig.text.topLeftSlugSeparatorText
                            ).reverse().slice().shift()
                        ]
                        :
                        props.subject ?
                          props.subject[action.payload] ?
                            props.subject[action.payload]
                            :
                            action.payload
                          :
                          action.payload
                      :
                      props.innerPages.filter(
                        item => item.node.title === action.payload
                      ).length ?
                        props.innerPages.filter(
                          item => item.node.title === action.payload
                        ).slice().shift().node
                      :
                      props.subject ?
                        props.subject[action.payload] ?
                          props.subject[action.payload]
                          :
                          action.payload
                        :
                        action.payload

                  )
                }
              )
              :
              action
          )
        )
    ;
    action.href &&
    (
      action.step && (
        (
          props.subjectAlternate[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ==
            localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
          action.alternatestep
        ) ?
          handleChangeStep(event, action.payload, action.payload, action.alternatestep)
          :
          handleChangeStep(event, action.payload, action.payload, action.step)
        ,
        action.dynamicItems ?
          navigate(action.href + props.subjectAlternate[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].toLowerCase())
          :
          navigate(action.href)
      )
    )
  }

  const handleActionAlternateButtons = () =>
    props.actions &&
    props.actions.buttons &&
    props.actions.buttons.filter(
      item => item.alternateButtonShown
    ).slice().shift()
  ;

  const handleActionButtons = () =>
    props.actions &&
    props.actions.buttons &&
    props.actions.buttons.filter(
      item =>
        !item.hidden &&
        (
          handleSubject() ?
            handleActionAlternateButtons() ?
              item.alternateButtonShown
              :
              !item.alternateButtonShown
            :
            !item.alternateButtonShown
      )
    )
  ;

  const handleeActionButtonsValidate = (item) =>
    (
      openFormDrawer
      ||
      (
        item.ascendant
        // &&handleFormStepValidate()
      )
      ||
      item.descendant
      ||
      (
        !item.ascendant &&
        !item.descendant &&
        (
          item.actionHandler &&
          item.actionHandler.condition ?
            (
              (
                props.fieldValues &&
                props.fieldValues[item.actionHandler.condition.leftHandSide] &&
                (
                  item.actionHandler.condition.value ?
                    props.fieldValues[item.actionHandler.condition.leftHandSide] == item.actionHandler.condition.rightHandSide
                    :
                    props.fieldValues[item.actionHandler.condition.leftHandSide] !== item.actionHandler.condition.rightHandSide
                )
              )
              ||
              (
                props.formValues &&
                props.formValues[item.actionHandler.condition.leftHandSide] &&
                (
                  item.actionHandler.condition.value ?
                    props.formValues[item.actionHandler.condition.leftHandSide] == item.actionHandler.condition.rightHandSide
                    :
                    props.formValues[item.actionHandler.condition.leftHandSide] !== item.actionHandler.condition.rightHandSide
                )
              )
              ||
              (
                props.subjectAlternate &&
                props.subjectAlternate[item.actionHandler.condition.leftHandSide] &&
                (
                  item.actionHandler.condition.value ?
                    props.subjectAlternate[item.actionHandler.condition.leftHandSide] == item.actionHandler.condition.rightHandSide
                    :
                    props.subjectAlternate[item.actionHandler.condition.leftHandSide] !== item.actionHandler.condition.rightHandSide
                )
              )
            )
            :
            (
              !item.ascendant &&
              !item.descendant
            )
        )
      )
    )
  ;

  console.log(props);

  const handleJustify = () =>
    props.actions ?
      props.actions.justify ?
        props.actions.justify
        :
        handleSubject() ?
          localConfig.grid.formFooterJustifyAlternate
          :
          localConfig.grid.formFooterJustify
      :
      localConfig.grid.formFooterJustify
  ;

   const handleSpan = () =>
     props.actions &&
     props.actions.justify &&
     localConfig.grid.gridSpan
   ;

  return (
    <Row
      justify={handleJustify()}
      type="flex"
    >
      <Col
        span={handleSpan()}
      >
        {
          props.text &&
          props.text.actionsTopCenterSlugText &&
          <Row
            justify="normal"
            type="flex"
          >
            <Col>
              {props.text.actionsTopCenterSlugText}
            </Col>
          </Row>
        }
        <Row
          justify={handleJustify()}
          type="flex"
        >
          <Col
            span={handleSpan()}
          >
            <FormItem >
              {
                props.actions ?
                  props.actions.buttons &&
                  handleActionButtons().map(
                    (item, index) =>
                      item.step ?
                        item.step === props.step &&
                        handleeActionButtonsValidate(item) &&
                        <FormButton
                          action={
                            item.actionHandler ?
                              item.descendant ?
                                event => handleChangeStep(event, item.ascendant, item.descendant, item.skipStep)
                                :
                                event => handleInnerInnerItemActionHandler(event, item.actionHandler)
                              :
                              item.href ?
                                event => handleInnerInnerItemClick(event, item.href)
                                :
                                event => handleChangeStep(event, item.ascendant, item.descendant, item.skipStep)
                          }
                          addonAfter={item.addonAfter}
                          addonBefore={item.addonBefore}
                          handleFormStep={handleFormStep}
                          handleFormStepValidate={handleFormStepValidate}
                          staticData={props.staticData}
                          step={props.step}
                          title={item.title}
                        />
                        :
                        <FormButton
                          action={
                            item.actionHandler ?
                              event => handleInnerInnerItemActionHandler(event, item.actionHandler)
                              :
                              item.href ?
                                event => handleInnerInnerItemClick(event, item.href)
                                :
                                event => handleChangeStep(event, item.ascendant, item.dascendant)
                          }
                          addonAfter={item.addonAfter}
                          addonBefore={item.addonBefore}
                          handleFormStep={handleFormStep}
                          handleFormStepValidate={handleFormStepValidate}
                          staticData={props.staticData}
                          step={props.step}
                          title={item.title}
                        />
                  )
                  :
                  <Button
                    htmlType="submit"
                    type="primary"
                  >
                    {props.submitLabel}
                  </Button>
              }
            </FormItem>
          </Col>
        </Row>
        {
          props.actions &&
          props.actions.menu &&
          <Row
            justify="unset"
            type="flex"
          >
            {
              props.actions.menu.map(
                (item, index) =>
                  <Col
                  >
                    {
                      item.avatar &&
                      <Avatar
                        src={item.avatar}
                      />
                    }
                    {item.title}
                  </Col>
              )
            }
          </Row>
        }
      </Col>
    </Row>
  );
}

export default FormFooter;
