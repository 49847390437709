import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuBadge from './MenuBadge';
import MenuButton from './MenuButton';

import MainAlternateMenuFooterWidget from './auxiliary/MainAlternateMenuFooterWidget';

import InformationCard from '../general/InformationCard';

import config from '../utils/config';

import './MenuFooter.less';

const MenuFooter = (
  {
    handleAlternateMenuClick,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeClassieContentBadgeText,
    onChangeLoader,
    onChangeSubject,
    onChangeSubjectThirdParty,
    resetData,
    ...props
  }
) => {

  const {
    innerConfig,
    isAlternateMenu
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleData = () =>
    props.staticData &&
    props.staticData.length ?
      props.staticData.slice().shift()
      :
      localConfig.entity.user
    ;

  return (
    <div>
      {
        (
          localConfig.formMenu ||
          !(
            props.staticData &&
            props.staticData.length
          ) &&
          !isAlternateMenu
        ) ?
          <Row
            justify="normal"
            type="flex"
          >
              {
                localConfig.menu.buttons &&
                localConfig.menu.buttons.length &&
                localConfig.menu.buttons.map(
                  (item, index) =>
                    <Col>
                      <MenuButton
                        title={item.title}
                      />
                    </Col>
                )
              }
          </Row>
          :
          !isAlternateMenu ?
            <Row
              justify="space-between"
              type="flex"
            >
              <Col>
                <Row
                  justify="space-between"
                  type="flex"
                >
                  <Col>
                    <InformationCard
                      informationReverse
                      subject={handleData()}
                    />
                  </Col>
                  <Col>

                  </Col>
                </Row>
              </Col>
              <Col>
                <MenuButton
                  action={event => handleAlternateMenuClick(event, localConfig.hrefAlternate)}
                  justify={props.justify}
                  tooltipPlacement="leftBottom"
                  tooltipText={localConfig.tooltipText.bottomRightTooltipText}
                />
              </Col>
            </Row>
            :
            <MainAlternateMenuFooterWidget
              innerConfig={localConfig}
              onChangeCarouselContent={onChangeCarouselContent}
              onChangeCarouselContentLevel={onChangeCarouselContentLevel}
              onChangeClassieContentBadgeText={onChangeClassieContentBadgeText}
              onChangeLoader={onChangeLoader}
              onChangeSubject={onChangeSubject}
              onChangeSubjectThirdParty={onChangeSubjectThirdParty}
              resetData={resetData}
            />
      }
    </div>
  );
}

export default MenuFooter;
