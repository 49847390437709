import React from 'react';

import { Tree } from 'antd';

import config from '../utils/config';

const { TreeNode } = Tree;

const FormTree = props => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props)

  const onLoadData = treeNode =>
    new Promise(
      resolve => {
        props.service[props.method](localConfig.defaultParameters, props.receiveData);
        console.log(treeNode)
        console.log(props.data)
        console.log(props.subject)
        console.log(props.subject.data)
        Object.is(props.data, props.subject.data) &&
        resolve();
      }
    )

  const renderTreeNodes = data =>
    data.map(
      item =>
        item.children ?
          <TreeNode
            dataRef={item}
            title={item.title}
           >
            {renderTreeNodes(item.children)}
          </TreeNode>
          :
          item.items ?
            <TreeNode
              dataRef={item}
              title={item.title}
             >
              {renderTreeNodes(item.items)}
            </TreeNode>
            :
            <TreeNode {...item} />
    );

  return (
    <Tree
      defaultExpandAll={props.defaultExpandAll}
      loadData={!props.defaultExpandAll && onLoadData}
    >
      {renderTreeNodes(props.subject[props.dataField])}
    </Tree>
  );
}

export default FormTree;
