import React from 'react';

import { connect } from "react-redux";

import TweenOne from 'rc-tween-one';

import {
  Avatar,
  Button,
  Card,
  Col,
  Row
} from 'antd';

import config from '../../utils/config';

import './ClassieContentTitle.less';

const { Meta } = Card;

const ClassieContentTitle = (
  {
    carouselContent,
    carouselContentLevel,
    classieContentBadgeText,
    onAnimationComplete,
    onAnimationStart,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeLoader,
    onChangeSubject,
    onChangeSubjectAlternate,
    resetData,
    subject,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleCarouselProgressPlayerAnimation = () =>
    localConfig.carousel.animation.map(
      item =>
        Object.assign(
          {},
          item,
          item.onAnimationComplete &&
            {
              onComplete: onAnimationComplete
            }
          ,
          item.onAnimationStart &&
            {
              onStart: onAnimationStart
            }
        )
    )

  const handleContent = (innerItem, innerInnerItem) =>
    innerItem.count ?
      innerItem.count +
      localConfig.text.activityResultSeparatorText +
      innerItem.title
      :
      innerInnerItem  ?
        localConfig.text.activityResultSeparatorText +
        innerItem.description
        :
        localConfig.text.activityResultSeparatorText +
        innerItem.title
  ;

  const handleContentAlternate = (event, innerItem) =>

    innerItem ?
      (
        // window.location.assign(innerItem.href)
        innerItem.href &&
        window.open(innerItem.href, localConfig.text.linkTarget)
      )
      :
      // props.item[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdPartyAlternate] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateThirdParty &&
      props.itemExtended[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty] != localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary &&
      (
        onChangeLoader(handleContentThirdParty(props.item)),
        onChangeSubjectAlternate(subject),
        onChangeSubject(handleSubject(props.item)),
        onChangeCarouselContentLevel(props.items.length / props.items.length),
        resetData(
          {
            data: {}
          }
        ),
        onChangeCarouselContent(props.itemExtended[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]),
        onChangeCarouselInitialContent(props.items.length / props.items.length)
      )

  const handleContentThirdParty = (innerItem) =>
    localConfig.text.centerSlugText +
    localConfig.text.activityResultSeparatorText +
    innerItem[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdPartyAlternate]


  const handleJustify = () =>
    carouselContent && carouselContent !== localConfig.defaultThirdPartyParametersAlternate[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
      classieContentBadgeText && classieContentBadgeText.length ?
        localConfig.grid.classieContentTitleJustify +
        localConfig.text.activityResultSeparatorText +
        carouselContent
        :
        props.items.length > props.items.length / props.items.length ?
          localConfig.grid.classieContentTitleJustify +
          localConfig.text.activityResultSeparatorText +
          carouselContent
          :
          props.item.subDescription && props.item.subDescription.items && props.item.subDescription.items.length > props.item.subDescription.items.length / props.item.subDescription.items.length ?
            localConfig.grid.classieContentTitleJustify +
            localConfig.text.activityResultSeparatorText +
            carouselContent
            :
            localConfig.grid.classieContentTitleJustify
      :
      localConfig.grid.classieContentTitleJustify
    ;

  console.log(props);
  console.log(carouselContent);
  console.log(localConfig.defaultThirdPartyParametersAlternate[localConfig.defaultThirdPartyThirdPartySecondaryField]);
  console.log(handleJustify());

  const handleSubject = (item) =>
    Object.assign(
      {},
      ...Object.keys(item).map(
        innerItem =>
          Object.assign(
            {},
            {

              [innerItem]
              :
              item[innerItem] ?
                item[innerItem].constructor === Object ?
                  item[innerItem][Object.keys(item[innerItem]).slice().shift()].constructor === Object ?
                    item[innerItem][Object.keys(item[innerItem]).slice().shift()]
                    :
                    Array.isArray(item[innerItem][Object.keys(item[innerItem]).slice().shift()]) ?
                      item[innerItem][Object.keys(item[innerItem]).slice().shift()].slice().shift().constructor === Object ?
                        item[innerItem][Object.keys(item[innerItem]).slice().shift()].slice().shift()[Object.keys(item[innerItem][Object.keys(item[innerItem]).slice().shift()]).slice().shift()]
                        :
                        item[innerItem][Object.keys(item[innerItem]).slice().shift()]
                      :
                      item[innerItem][Object.keys(item[innerItem]).slice().shift()]
                  :
                  item[innerItem]
                :
                item[innerItem]
          }
        )
      )
    )

  console.log(handleSubject(props.item))

  return (
    <TweenOne
      animation={handleCarouselProgressPlayerAnimation()}
      moment={props.animationMoment}
      paused={props.animationPaused}
      repeat={props.repeat}
    >
      <Row
        justify={handleJustify()}
        type="flex"
      >
        <TweenOne
          animation={localConfig.carousel.animationThirdParty}
          moment={props.animationMoment}
          paused={!localConfig.carousel.animationThirdParty}
          repeat={props.repeat}
        >
          <Col
          >
            <Card
              hoverable
            >
             <Meta
               avatar=
               {
                 props.item.avatar &&
                 props.item.avatar.src &&
                 !carouselContentLevel &&
                 <Avatar
                   src={
                     props.item.avatar &&
                     props.item.avatar.src
                   }
                 />
               }
               description={
                 <Card
                 >
                   <Meta
                     description={
                       props.item.subDescription &&
                       props.item.subDescription.items &&
                       props.item.subDescription.items.map(
                         innerItem =>
                          props.item.subDescription.items.slice().shift().avatar ?
                            <Button
                              className="trigger"
                              onClick={event =>  handleContentAlternate(event, innerItem)}
                            >
                              <Row
                                justify="left"
                                type="flex"
                              >
                                <Col
                                  span={localConfig.grid.gridSpan}
                                >
                                  {
                                    innerItem.avatar &&
                                    <Avatar
                                      src={innerItem.avatar}
                                    />
                                  }
                                  {handleContent(innerItem)}
                                </Col>
                                <Col
                                  span={localConfig.grid.gridSpan}
                                >
                                  {handleContent(innerItem, innerItem.avatar)}
                                </Col>
                              </Row>
                            </Button>
                              :
                             <button
                               className="trigger"
                               onClick={event =>  handleContentAlternate(event)}
                             >
                               {handleContent(innerItem)}
                             </button>
                       )
                     }
                     title={
                       <Row
                         justify="safe"
                         type="flex"
                       >
                         {
                           props.item.description &&
                           props.item.description.items &&
                           props.item.description.items.map(
                             innerItem =>
                               <Col
                               >
                                 {
                                   innerItem.avatar &&
                                   <Avatar
                                     src={innerItem.avatar}
                                   />
                                 }
                                 {handleContent(innerItem)}
                               </Col>
                           )
                         }
                       </Row>
                     }
                   />
                 </Card>
               }
               title={
                 <Card
                 >
                   <Meta
                     description={props.item.description}
                     title={
                       <Card
                       >
                         <Meta
                           description={props.item.title}
                           title={props.item.superTitle}
                         />
                       </Card>
                     }
                   />
                 </Card>
               }
             />
            </Card>
          </Col>
        </TweenOne>
      </Row>
    </TweenOne>
  );
}

export default ClassieContentTitle;
