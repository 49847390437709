import Amplify, { API } from 'aws-amplify';

import './aws_config';

const getRequest = (
  method,
  initializer,
  callback,
  callbackParameters
) =>
  API.get(
    method.apiName,
    method.path,
    initializer,
    callback
  )
  .then(
    response =>
      callback(response)
  )
  .catch(
    error => {

    }
  );


const postRequest = (
  method,
  initializer
) => {
  API.post(
    method.apiName,
    method.path,
    initializer
  )
  .then(
    response => {

    }
  )
  .catch(
    error => {

    }
  );
}

const parseJSON = (response) =>
  response.json();

export {
  getRequest,
  postRequest
};
