import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

const CardSideWidgetAvatarInformation = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="center"
      type="flex"
    >
      {
        props.avatarInformation.map(
          item =>
            <Col
            >
              <Avatar
                src={item.avatar}
              />
            </Col>
        )
      }
    </Row>
  );
}

export default CardSideWidgetAvatarInformation;
