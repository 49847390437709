import React from 'react';

import {
	Avatar,
	Col,
	Radio,
	Row
} from 'antd';

import config from '../utils/config';

import './RadioField.less';

const RadioField = (
  {
    onChangeField,
    ...props
  }
) => {
	const {
    innerConfig
  } = props;

	const localConfig = innerConfig ? innerConfig : config;

	const handleChange = (event) =>
    onChangeField(
      {[props.field]: event.target.value}
    )


	return (
		<Radio.Group>
	    {
	      props.radioButton &&
	      props.radioButton.map(
	        item =>
	          <Radio.Button
	            value={item.title}
	          >
	            {item.title}
	          </Radio.Button>
	      )
	    }
	    {
	      props.radio &&
	      props.radio.map(
	        item =>
	          <Radio
							onChange={event => handleChange(event)}
	            value={item.title}
	          >
	            <Row
	              justify="normal"
	              type="flex"
	            >
	              <Col>
	                <Avatar
	                  src={item.avatar}
	                />
	              </Col>
	              <Col>
	                <Row
	                  justify="start"
	                  type="flex"
	                >
	                  <Col
	                    span={localConfig.grid.gridSpan}
	                  >
	                    {item.title}
	                  </Col>
	                  <Col
	                    span={localConfig.grid.gridSpan}
	                  >
	                    {item.text}
	                  </Col>
	                </Row>
	              </Col>
	            </Row>
	          </Radio>
	      )
	    }
	  </Radio.Group>
	)
}

export default RadioField;
