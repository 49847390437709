/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

// import Drawer from 'react-motion-drawer';

import {
  Avatar,
  Button,
  Col,
  Drawer,
  Progress,
  Row
} from 'antd';

import ConnectedTimelineCarousel from './TimelineCarousel';
import InformationCard from './InformationCard';

import config from '../utils/config';

import './TimelineDrawer.less';


const TimelineDrawer = (
  {
    carousel,
    carouselAutoplay,
    carouselContent,
    carouselContentLevel,
    carouselInitialContent,
    carouselProgress,
    data,
    onChangeCarouselAutoplay,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeLoader,
    onChangeSubject,
    onChangeTimelineCarousel,
    openDrawerSixthPartyMenu,
    openTimelineDrawer,
    openTimelineDrawerAlternate,
    openTimelineDrawerFourthParty,
    openTimelineDrawerThirdParty,
    staticData,
    subject,
    subjectAlternate,
    timelineCarousel,
    timelineDrawerOpened,
    timelineDrawerOpenedAlternate,
    timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const [carouselIndex, setCarouselIndex] = useState();

  const localConfig = innerConfig ? innerConfig : config;

  console.log(timelineDrawerOpenedAlternate)
  console.log(timelineDrawerOpenedFourthParty)
  console.log(timelineDrawerOpenedThirdParty)
  console.log(carouselAutoplay)
  console.log(subject)
  console.log(data[carouselInitialContent])
  data[carouselInitialContent] &&
  console.log(data[carouselInitialContent][localConfig.defaultSixthPartyFieldAlternate])

  const handleCarouselContent = () =>
    !carouselContent ||
    carouselContent != localConfig.menu.items.find(
      element => element.timelineMenuShown
    ).carouselContent
  ;

  const handleCarouselInitialContent = (event, localCarouselInitialContent) =>
    onChangeCarouselInitialContent(localCarouselInitialContent)
  ;

  const handleCarouselProgressAvatarImage = () =>
    carouselAutoplay ?
      localConfig.timelineCarousel.image.play
      :
      localConfig.timelineCarousel.image.pause
  ;

  const handleCarouselProgressAvatarPercent = () =>
    !carouselAutoplay && carouselProgress
  ;

  const handleCarouselText = () =>
    carouselInitialContent +
    localConfig.text.activityResultSeparatorText +
    localConfig.text.urlSeparatorText +
    localConfig.text.activityResultSeparatorText +
    data.length
  ;

  const handleCarouselTextAlternate = () =>
    data[carouselInitialContent] &&
    data[carouselInitialContent][localConfig.defaultSixthPartyFieldAlternate]
  ;

  const handleClassName = (event) =>
    handleCarouselContent() ?
      timelineDrawerOpenedFourthParty ?
        localConfig.text.menuDrawerClassNameFourthParty
        :
        timelineDrawerOpenedThirdParty ?
          localConfig.text.menuDrawerClassNameThirdParty
          :
          timelineDrawerOpenedAlternate ?
            localConfig.text.menuDrawerClassNameAlternate
            :
            localConfig.text.menuDrawerClassName
      :
      timelineDrawerOpenedThirdParty ?
        localConfig.text.menuDrawerClassNameThirdParty
        :
        timelineDrawerOpenedAlternate ?
          localConfig.text.menuDrawerClassNameAlternate
          :
          localConfig.text.menuDrawerClassName
  ;

  const handleClassNameAlternate = (event) =>
    handleCarouselContent() ?
      localConfig.text.menuDrawerClassNameSixthParty
      :
      localConfig.text.menuDrawerClassNameSeventhParty
  ;

  const handleClassNameThirdParty = (event) =>
    handleCarouselContent() ?
      (
        localConfig.grid.mainHeaderJustify +
        localConfig.text.activityResultSeparatorText +
        localConfig.text.menuDrawerClassNameSixthParty
      )
      :
      (
        localConfig.grid.mainHeaderJustify +
        localConfig.text.activityResultSeparatorText +
        localConfig.text.menuDrawerClassNameSeventhParty
      )
  ;

  const handleDrawerClose = (visible) => {
    (
      handleCarouselContent()
    ) ?
      // (
      //   timelineDrawerOpenedThirdParty &&
      //   openTimelineDrawerFourthParty(!visible)
      // )
      (
        // onChangeCarouselAutoplay(),
        timelineDrawerOpenedThirdParty ?
          (
            openTimelineDrawerThirdParty(!visible),
            openTimelineDrawerAlternate(!visible)
          )
          :
          openTimelineDrawerAlternate(!visible)
      )
      :
      (
        // !carouselAutoplay &&
        // onChangeCarouselAutoplay(),
        timelineDrawerOpenedThirdParty ?
          (
            openTimelineDrawerThirdParty(!visible),
            openTimelineDrawerAlternate(!visible)
          )
          :
          openTimelineDrawerAlternate(!visible)
      )
    ;
  }

  const handleDatatransformation = (
    item,
    itemItem,
    itemItemItem,
    itemItemItemItem,
    localDatatransfomation =
      props.pages &&
      props.pages.filter(
        innerinnerItem =>
          innerinnerItem.node.title == localConfig.timelineCarousel.datatransfomationNode
      ).slice().shift().node.datatransfomation,
    localDatatransfomationAlternate = localConfig.timelineCarousel.datatransfomation
  ) =>
    (
      console.log(localDatatransfomationAlternate.filter(
        innerinnerItem =>
          itemItemItem ?
            innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate
            :
            itemItem ?
              innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateThirdParty
              :
              innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
              innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary
      )),
      console.log(localDatatransfomation),
      item &&
      Array.isArray(item) &&
      item.length &&
      localDatatransfomation &&
      item.map(
        innerItem =>
          Object.assign(
            {},
            ...localDatatransfomation.filter(
              innerinnerItem =>
                itemItemItem ?
                  innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate
                  :
                  itemItem ?
                    innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary
                    :
                    innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
                    innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary
            ).map(
              innerinnerItem =>
                Object.assign(
                  {},
                  Object.assign(
                    {},
                    (
                      innerItem[
                        localDatatransfomation.find(
                          element => element.markerField
                        ).field
                      ]
                      &&
                      innerinnerItem.extraMarkerField
                    ) ?
                      {
                        [innerinnerItem.defaultField]
                        :
                        props.text && props.text.extraMarkerFieldSlugText
                      }
                      :
                      {
                        [innerinnerItem.defaultField]
                        :
                        Math.abs(innerinnerItem.field.indexOf(localConfig.datatransfomation.separator)) == innerinnerItem.field.indexOf(localConfig.datatransfomation.separator) ?
                          innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()] ?
                            innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()][innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                            :
                            innerItem[innerinnerItem.field]
                          :
                          innerItem[innerinnerItem.field]
                    }
                  )
                )
            )
          )
        ).map(
          innerItem =>
            !itemItemItemItem ?
              Object.assign(
                {},
                ...localDatatransfomationAlternate.filter(
                  innerinnerItem =>
                    itemItemItem ?
                      innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate
                      :
                      itemItem ?
                        innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateThirdParty
                        :
                        innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
                        innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary
                ).map(
                  innerinnerItem =>
                    Object.assign(
                      {},
                      Object.assign(
                        {},
                        {
                          [innerinnerItem.defaultField]
                          :
                          innerItem[innerinnerItem.field]
                        }
                      )
                    )
                )
              )
              :
              innerItem
          )
    )

  console.log(handleDatatransformation(data, data))

  const handleCarouselContentAlternate = (
    localData = handleDatatransformation(data, data)
  ) =>
    !carouselAutoplay &&
    (
      console.log(handleDatatransformation(data, data)),
      console.log(carouselInitialContent),
      handleCarouselContent() ?
        !timelineDrawerOpenedAlternate &&
        localConfig.text.timelineMenuTopLeftSlugText
        :
        carouselIndex ?
          localData[carouselIndex] &&
          localData[carouselIndex][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[carouselIndex][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength)
          :
          localData[carouselInitialContent] &&
          localData[carouselInitialContent][localConfig.defaultSixthPartyFieldThirdPartyAlternate] &&
          localData[carouselInitialContent][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[carouselInitialContent][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength)
    )
  ;

  const handleDrawerPlacement = (event) =>
    localConfig.text.menuDrawerPlacementAlternate
  ;

  const handleSubject = () =>
    handleCarouselContent() ?
      subject && subject[localConfig.defaultSixthPartyFieldAlternate].length != subject[localConfig.defaultSixthPartyFieldAlternate].length - subject[localConfig.defaultSixthPartyFieldAlternate].length ?
        Object.assign(
          {},
          subject,
          {
            [localConfig.defaultSixthPartyFieldThirdParty]
            :
            localConfig.defaultFifthPartyParameters[localConfig.defaultSixthPartyFieldThirdParty]
          }
        )
        :
        localConfig.defaultFifthPartyParameters
      :
      subject && subject[localConfig.defaultSixthPartyFieldAlternate].length != subject[localConfig.defaultSixthPartyFieldAlternate].length - subject[localConfig.defaultSixthPartyFieldAlternate].length ?
        Object.assign(
          {},
          subject,
          {
            [localConfig.defaultSixthPartyFieldThirdParty]
            :
            localConfig.defaultSixthPartyParameters[localConfig.defaultSixthPartyFieldThirdParty]
          }
        )
        :
        localConfig.defaultSixthPartyParameters
  ;

  const handleTimelineDrawer = () => {
    !timelineDrawerOpened &&
    openTimelineDrawer();
    timelineDrawerOpenedFourthParty &&
    openTimelineDrawerFourthParty();
    !timelineDrawerOpenedThirdParty &&
    openTimelineDrawerThirdParty();
  }

  const next = (event) => {
    event.stopPropagation();
    console.log(timelineCarousel);
    timelineCarousel.slick && timelineCarousel.slick.slickNext();
  };

  const nextAlternate = (event) => {
    event.stopPropagation();
    console.log(carousel);
    carousel.slick && carousel.slick.slickNext();
  };

  const previous = (event) => {
    event.stopPropagation();
    console.log(timelineCarousel);
    timelineCarousel.slick && timelineCarousel.slick.slickPrev();
  };

  const previousAlternate = (event) => {
    event.stopPropagation();
    console.log(carousel);
    carousel.slick && carousel.slick.slickPrev();
  };

  console.log(localConfig);
  console.log(localConfig.defaultSixthPartyFieldThirdParty);
  console.log(localConfig.defaultSixthPartyParameters[localConfig.defaultSixthPartyFieldThirdParty]);

  return (
    !carouselAutoplay &&
    data.length &&
    <div
      className={handleClassNameAlternate()}
    >
      <Drawer
        className={handleClassName()}
        getContainer={!innerConfig}
        onClose={visible => handleDrawerClose(!visible)}
        placement={handleDrawerPlacement()}
        visible={timelineDrawerOpened}
        width={localConfig.drawerWidth}
      >
        <Row
          justify="space-between"
          type="flex"
        >
          <Col
          >
            <Row
              justify="space-between"
              type="flex"
            >
              <Col
              >
                {
                  carouselContentLevel ?
                    <Row
                      justify="space-between start"
                      type="flex"
                    >
                      <Col
                      >
                        {handleCarouselTextAlternate()}
                      </Col>
                    </Row>
                    :
                    staticData &&
                    <InformationCard
                      subject={handleSubject()}
                    />
                }
              </Col>
              <Col
              >
                {
                  !carouselContentLevel &&
                  staticData &&
                  <Avatar
                    onMouseDown={openDrawerSixthPartyMenu}
                  />
                }
              </Col>
            </Row>
          </Col>
          <Col
          >
            {
              !timelineDrawerOpenedAlternate &&
              !handleCarouselContent() &&
              <Button>
                {localConfig.text.timelineMenuTopRightSlugText}
              </Button>
            }
          </Col>
        </Row>
        <Row
          justify="start"
          type="flex"
        >
          <Col
          >
            <ConnectedTimelineCarousel
              carouselContentLevel={carouselContentLevel}
              handleCarouselContent={handleCarouselContent}
              handleDatatransformation={handleDatatransformation}
              infinite={!props.pages}
              onChangeCarouselAutoplay={onChangeCarouselAutoplay}
              onChangeCarouselContent={onChangeCarouselContent}
              onChangeCarouselContentLevel={onChangeCarouselContentLevel}
              onChangeLoader={onChangeLoader}
              onChangeSubject={onChangeSubject}
              onChangeTimelineCarousel={onChangeTimelineCarousel}
              openTimelineDrawer={openTimelineDrawer}
              openTimelineDrawerAlternate={openTimelineDrawerAlternate}
              openTimelineDrawerFourthParty={openTimelineDrawerFourthParty}
              openTimelineDrawerThirdParty={openTimelineDrawerThirdParty}
              pages={props.pages}
              setCarouselIndex={setCarouselIndex}
              timelineCarousel={timelineCarousel}
              timelineDrawerOpened={timelineDrawerOpened}
              timelineDrawerOpenedAlternate={timelineDrawerOpenedAlternate}
              timelineDrawerOpenedFourthParty={timelineDrawerOpenedFourthParty}
              timelineDrawerOpenedThirdParty={timelineDrawerOpenedThirdParty}
            />
          </Col>
        </Row>
        <Row
          justify={handleClassNameThirdParty()}
          type="flex"
        >
          <Col
          >
            <Row
              justify={handleClassNameAlternate()}
              type="flex"
            >
              <Col
              >
                {
                  handleCarouselContentAlternate()
                }
              </Col>
            </Row>
          </Col>
          <Col
          >
            {
              !carouselAutoplay &&
              (
                !timelineDrawerOpenedAlternate ?
                [
                  <Avatar
                    onMouseDown={event => previous(event)}
                  />
                  ,
                  !handleCarouselContent() ?
                    <Avatar
                      onMouseDown={visible => handleDrawerClose(!visible)}
                    />
                    :
                    <Row
                      justify="center"
                      type="flex"
                    >
                      <Col
                      >
                        {handleCarouselText()}
                      </Col>
                    </Row>
                  ,
                  <Avatar
                    onMouseDown={event => next(event)}
                  />
                ]
                :
                [
                  <Avatar
                    onMouseDown={event => previousAlternate(event)}
                  />
                  ,
                  !handleCarouselContent() ?
                    <Avatar
                      onMouseDown={visible => handleDrawerClose(!visible)}
                    />
                    :
                    <Row
                      justify="center"
                      type="flex"
                    >
                      <Col
                        onMouseDown={visible => handleDrawerClose(!visible)}
                      >
                        {handleCarouselText()}
                      </Col>
                    </Row>
                  ,
                  <Avatar
                    onMouseDown={event => nextAlternate(event)}
                  />
                ]
              )
            }
          </Col>
        </Row>
      </Drawer>

    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeCarouselAutoplay: () => dispatch (
      {
        type: `onChangeCarouselAutoplay`
      }
    ),
    onChangeCarouselContent: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselContent`
      }
    ),
    onChangeCarouselContentLevel: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselContentLevel`
      }
    ),
    onChangeCarouselInitialContent: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselInitialContent`
      }
    ),
    onChangeLoader: (item) => dispatch (
      {
        payload: item,
        type: `onChangeLoader`
      }
    ),
    onChangeSubject: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubject`
      }
    ),
    openDrawerSixthPartyMenu: () => dispatch (
      {
        type: `openDrawerSixthPartyMenu`
      }
    ),
    onChangeTimelineCarousel: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTimelineCarousel`
      }
    ),
    openTimelineDrawer: (visible) => dispatch (
      {
        payload: visible,
        type: `openTimelineDrawer`
      }
    ),
    openTimelineDrawerAlternate: (visible) => dispatch (
      {
        payload: visible,
        type: `openTimelineDrawerAlternate`
      }
    ),
    openTimelineDrawerFourthParty: (visible) => dispatch (
      {
        payload: visible,
        type: `openTimelineDrawerFourthParty`
      }
    ),
    openTimelineDrawerThirdParty: (visible) => dispatch (
      {
        payload: visible,
        type: `openTimelineDrawerThirdParty`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    carousel: state.carousel,
    carouselAutoplay: state.carouselAutoplay,
    carouselContent: state.carouselContent,
    carouselContentLevel: state.carouselContentLevel,
    carouselInitialContent: state.carouselInitialContent,
    carouselProgress: state.carouselProgress,
    data: state.data,
    staticData: state.staticData,
    subject: state.subject,
    subjectAlternate: state.subjectAlternate,
    timelineCarousel: state.timelineCarousel,
    timelineDrawerOpened: state.timelineDrawerOpened,
    timelineDrawerOpenedAlternate: state.timelineDrawerOpenedAlternate,
    timelineDrawerOpenedFourthParty: state.timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty: state.timelineDrawerOpenedThirdParty
  }
);

const ConnectedTimelineDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineDrawer);

export default ConnectedTimelineDrawer;
