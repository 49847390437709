import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import InformationCard from '../../general/InformationCard';

import config from '../../utils/config';

import './MainMenuTopWidget.less';

const { Link } = Anchor;

const MainMenuTopWidget = (
  {
    handleAlternateMenuClick,
    handleMenuClick,
    ...props
  }
) => {

  const {
    alternateThirdPartyAlternateMenuAlternateShown,
    innerConfig,
    step
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handlehandleContentData = (innerItem) =>
    innerItem.split(localConfig.regularExpressions.regularExpressionsTagsFieldAlternate).join(localConfig.text.activityResultSeparatorText)
  ;

  const handleContent = () =>
    alternateThirdPartyAlternateMenuAlternateShown ?
      props.menuFilter ?
        handlehandleContentData(props.menuFilter)
        :
        localConfig.text.bottomRightThirdPartySlugText
      :
      props.menuFilter ?
        handlehandleContentData(props.menuFilter)
        :
        props.menu.title ?
          props.menu.title
          :
          localConfig.text.bottomRightThirdPartySlugText
        ;

  const handleData = () =>
    props.subject ?
      props.subject
      :
      props.staticData &&
      props.staticData.length ?
        props.staticData.slice().shift()
        :
        localConfig.entity.user
    ;

  props.menuFilter &&
  console.log(props.menuFilter.split(localConfig.regularExpressions.regularExpressionsTagsFieldAlternate))

  const handleSubject = () =>
    Object.keys(handleData()).length

  return (
    props.menu.hasAvatar ?
      <Row
        justify="inherit"
        type="flex"
      >
        <Col>
          {
            handleSubject() ?
              handleData().parent ?
                <Row
                  justify="initial"
                  type="flex"
                >
                  <Col>
                    <MenuButton/>
                  </Col>
                  <Col>
                    <InformationCard
                      replacementDescriptionText={props.menu.title}
                      subject={handleData()}
                    />
                  </Col>
                </Row>
                :
                <Row
                  justify="unset"
                  type="flex"
                >
                  <Col>
                    <MenuButton/>
                  </Col>
                  <Col>
                    <InformationCard
                      replacementDescriptionText={props.menu.title}
                      subject={handleData()}
                    />
                  </Col>
                </Row>
              :
              <Row
                justify="unset"
                type="flex"
              >
                <Col>
                  <MenuButton/>
                </Col>
                <Col>

                </Col>
              </Row>
          }
        </Col>
      </Row>
      :
      <Row
        justify="start"
        type="flex"
      >
        {
          props.menu.level ?
            <Col>
              <MenuButton
                action={handleMenuClick}
              />
            </Col>
            :
            <Col>
            </Col>
        }
        <Col>
          <Anchor>
            <Link
              title={handleContent()}
            >
            </Link>
          </Anchor>
        </Col>
        {
          props.menu.hasAction ?
            <Col>
              {
                !props.menuFilter &&
                [
                  <MenuButton
                  />,
                  <MenuButton
                    action={handleAlternateMenuClick}
                  />
                ]
              }
            </Col>
            :
            <Col>
            </Col>
        }
      </Row>
  );
}

export default MainMenuTopWidget;
