import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

import MainFifthPartyMenuTopWidget from './auxiliary/MainFifthPartyMenuTopWidget';
import MenuInputItem from './auxiliary/MenuInputItem';
import MenuSelectItem from './auxiliary/MenuSelectItem';
import MenuStaticItem from './auxiliary/MenuStaticItem';
import MainMenuTopWidget from './auxiliary/MainMenuTopWidget';

import config from '../utils/config';

import './MainFifthPartyMenu.less';

const MainFifthPartyMenu = (
  {
    handleFourthPartyMenuClick,
    openDrawer,
    menu,
    ...props
  }
) => {

  const {
    fourthPartyMenuShown,
    handleMenuClick,
    hasFooter,
    hasTitle,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <div>
      <Row
        justify="stretch"
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline"
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={['right', 'left']}
          >
            <Menu.Item>
              <MenuHeader
                innerConfig={localConfig}
                isFifthPartyMenu
                openDrawer={openDrawer}
                rightBadgeText={localConfig.text.topLeftSlugFifthPartyText}
              />
            </Menu.Item>
            <Menu.Item>
              <MainMenuTopWidget
                handleAlternateMenuClick={handleFourthPartyMenuClick}
                handleMenuClick={handleFourthPartyMenuClick}
                innerConfig={localConfig}
                menu={localConfig.menu.fifthPartyItem}
                subject={props.subject}
              />
            </Menu.Item>
            <Menu.Item>
              <MainFifthPartyMenuTopWidget
                handleMenuClick={handleMenuClick}
                innerConfig={localConfig}
                menu={menu}
                navigate={navigate}
              />
            </Menu.Item>
            <Menu.Item>
              <MenuButton
                title={localConfig.text.topMainFifthPartyMenuSelectFieldPlaceholder}
              />
              <MenuButton
                title={localConfig.menu.fifthPartyItem.buttonTitle}
              />
            </Menu.Item>
            <Menu.Item>
            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
          </QueueAnim>
        </Col>
      </Row>
    </div>
  )
}

export default MainFifthPartyMenu;
