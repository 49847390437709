import React from 'react';

import {
  Anchor,
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Row,
  Statistic
} from 'antd';

import GenericPanel from './GenericPanel';

import GenerecicChart from '../chart/GenerecicChart';
import GenericForm from '../form/GenericForm';
import MapField from '../form/MapField';
import TimeLinePlot from '../chart/TimeLinePlot';

import config from '../utils/config';

const { Link } = Anchor;
const { Meta } = Card;

const GenericPanelCollectionContent =
(
	props
) => {

	const {
		innerItem,
		innerConfig,
		showAlternateContent
	} = props;

	const localConfig = innerConfig ? innerConfig : config;

  const handleTextItemListSpan = (innerItem) =>
    innerItem.hasSeparator ?
      localConfig.grid.mediumSmallSpan
      :
      localConfig.grid.mediumSpan

	return (
		<GenericPanel
      alternateContent={innerItem.alternateContent}
      edit={innerItem.edit}
      form={innerItem.form}
      innerConfig={localConfig}
      justify={props.justify}
      linkText={innerItem.linkText}
      showAlternateContent={showAlternateContent}
      showLink={innerItem.showLink}
      showTitle
      title={innerItem.title}
    >
      {
        !innerItem.hidden &&
        (
          innerItem.badgeItemList ?
            <Row
              justify="start"
              type="flex"
            >
              {
                props.subject[innerItem.content].map(
                  innerInnerItem =>
                  <Col
                    span={localConfig.grid.gridSpan}
                  >
                     <Badge
                      status={innerInnerItem.status}
                      text={innerInnerItem.text}
                    />
                  </Col>
                )
              }
            </Row>
            :
            ( innerItem.barChart ||
              innerItem.textChart ||
              innerItem.textLineChart ) ?
              <Row
                justify="left"
                type="flex"
              >
                <Col
                  span={localConfig.grid.gridSpan}
                >
                  <GenerecicChart
                    barChart={innerItem.barChart}
                    data={props.subject[innerItem.content]}
                    innerConfig={localConfig}
                    textChart={innerItem.textChart}
                    textLineChart={innerItem.textLineChart}
                  />
                </Col>
              </Row>
              :
              innerItem.card ?
                <Row
                  justify="start"
                  type="flex"
                >
                  <Col>
                    <Card>
                      <Meta
                        avatar={
                          <Badge
                            status={props.subject.status}>
                            <Avatar
                              src={props.subject.avatar}
                            />
                          </Badge>
                        }
                        description={
                          innerItem.description.icons.map(
                            innerInnerItem =>
                              <Avatar
                                src={innerInnerItem.avatar}
                              />
                          )
                        }
                        title={props.subject.title}
                      />
                      <Row
                        justify="left"
                        type="flex"
                      >
                        <Meta
                          description={
                            innerItem.description.text.map(
                              innerInnerItem =>
                              <Col span={localConfig.grid.gridSpan}>
                                {innerInnerItem.item}
                              </Col>
                            )
                          }
                        />
                      </Row>
                    </Card>
                  </Col>
                </Row>
                :
                innerItem.form ?
                  <Row
                    justify="start"
                    type="flex"
                  >
                    <Col
                      span={localConfig.grid.gridSpan}
                    >
                      <GenericForm
                        actions={props.forms[innerItem.form].actions}
                        formFields={props.forms[innerItem.form].formFields}
                        grid={props.forms[innerItem.form].grid}
                        hasFooter
                        innerConfig={localConfig}
                        method={props.forms[innerItem.form].method}
                        mockup={props.forms[innerItem.form].mockup}
                        service={props.forms[innerItem.form].service}
                        services={props.services}
                        subject={props.subject}
                        submitLabel={props.forms[innerItem.form].labelSubmit}
                        title={props.forms[innerItem.form].title}
                      />
                    </Col>
                  </Row>
                  :
                  innerItem.linkItemList ?
                    <Row
                      justify="left"
                      type="flex"
                    >
                      <Col>
                        {
                          props.subject[innerItem.content] &&
                          props.subject[innerItem.content].length &&
                          props.subject[innerItem.content].map(
                            innerInnerItem =>
                            <Row
                              justify="start"
                              type="flex"
                            >
                              <Col>
                                <Avatar
                                  src={innerInnerItem.icon}
                                />
                              </Col>
                              <Col>
                                <Anchor
                                  affix={localConfig.grid.anchorAffix}
                                >
                                  {
                                    innerInnerItem.disabled ?
                                      innerInnerItem.title
                                      :
                                      <Link
                                        href={innerInnerItem.href}
                                        title={innerInnerItem.title}
                                      />
                                  }
                                </Anchor>
                              </Col>
                            </Row>
                          )
                        }
                      </Col>
                    </Row>
                    :
                    innerItem.textItem ?
                      innerItem.content.map(
                        innerInnerItem =>
                          <Row
                            justify="safe"
                            type="flex"
                          >
                            <Col>
                              <Avatar
                                src={innerInnerItem.addonBefore.avatar}
                              />
                            </Col>
                            <Col>
                              <Row
                                justify="space-around"
                                type="flex"
                              >
                                <Col
                                  span={localConfig.grid.gridSpan}
                                >
                                  {innerInnerItem.title}
                                </Col>
                                <Col
                                  span={localConfig.grid.gridSpan}
                                >
                                  {innerInnerItem.text}
                                </Col>
                              </Row>
                            </Col>
                            {
                              innerInnerItem.addonAfter &&
                              <Col>
                                <Button
                                  onClick={() => showAlternateContent(innerInnerItem.alternateContent, innerItem.title)}
                                >
                                  <Avatar
                                    src={innerInnerItem.addonAfter.avatar}
                                  />
                                </Button>
                              </Col>
                            }
                          </Row>
                       )
                      :
                      innerItem.textItemList ?
                        <Row
                          justify="normal"
                          type="flex"
                        >
                          <Col
                            span={localConfig.grid.gridSpan}
                          >
                            {
                              props.subject[innerItem.content] &&
                              props.subject[innerItem.content].length &&
                              props.subject[innerItem.content].map(
                                innerInnerItem =>
                                  <Row
                                    justify="start"
                                    type="flex"
                                  >
                                    <Col
                                      span={handleTextItemListSpan(innerItem)}
                                    >
                                      {innerInnerItem.name}
                                    </Col>
                                    {
                                      innerItem.hasSeparator &&
                                      [
                                        <Col
                                          span={handleTextItemListSpan(innerItem)}
                                        >
                                        </Col>
                                        ,
                                        <Col
                                          span={handleTextItemListSpan(innerItem)}
                                        >
                                          {innerInnerItem.value}
                                        </Col>
                                      ]
                                    }
                                  </Row>
                              )
                            }
                          </Col>
                        </Row>
                        :
                        innerItem.timeline ?
                          <TimeLinePlot
                            data={props.subject.timelines[innerItem.timeline]}
                            innerConfig={localConfig}
                          />
                          :
                          <Row
                            justify="left"
                            type="flex"
                          >
                            {
                              props.subject[innerItem.content] &&
                              props.subject[innerItem.content].length &&
                              props.subject[innerItem.content].map(
                                innerInnerItem =>
                                  <Col
                                    span={localConfig.grid.gridSpan}
                                  >
                                    {innerInnerItem.text}
                                  </Col>
                              )
                            }
                          </Row>
        )
      }
    </GenericPanel>
	);
}

export default GenericPanelCollectionContent;
