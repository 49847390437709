import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';
import MenuSelectItem from './MenuSelectItem';

import CircularProgress from '../../general/CircularProgress';
import InformationCard from '../../general/InformationCard';
import ScrollableContainer from '../../general/ScrollableContainer';

import config from '../../utils/config';

import './MainThirdPartyMenuDynamicBody.less';

const { Link } = Anchor;

const MainThirdPartyMenuDynamicBody = (
  {
    handleMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleFilterCount = (innerItem) =>
    props.staticData.slice().shift()[localConfig.defaultSecondaryField] &&
    props.staticData.slice().shift()[localConfig.defaultSecondaryField].filter(
      innerInnerItem =>
        innerInnerItem[localConfig.defaultFifthPartyField] &&
        innerInnerItem[localConfig.defaultFifthPartyField][innerItem]
    ).length

  const handleFilter = (innerItem) =>
    props.menuFilter ?
      innerItem.parent &&
      innerItem[localConfig.defaultFifthPartyField] &&
      (
        props.menuFilter.constructor === Object ?
          innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
          innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
          :
          innerItem[localConfig.defaultFifthPartyField][props.menuFilter]
      )
      :
      innerItem.parent &&
      !innerItem[localConfig.defaultFifthPartyField] ||
      innerItem[localConfig.defaultFifthPartyField] &&
      (
        !innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] &&
        !innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
      )
    ;

  const handleItem = (innerItem) =>
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ||
    (
      innerItem.parent &&
      innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
    )

  console.log(props)

  return (
    props.menu.dynamicItems ?
      [
        localConfig.menu.thirdPartyItem.secondaryOptions.map(
          (item, index) =>
            <Row
              justify="unset end"
              type="flex"
            >
              <Col
                onMouseDown={event => handleMenuClick(event, item.value)}
              >
                <Row
                  justify="space-between end"
                  type="flex"
                >
                  <Col>
                    {
                      item.avatar &&
                      <Avatar
                        src={item.avatar}
                      />
                    }
                  </Col>
                  <Col>
                    {item.title}
                  </Col>
                  <Col>
                    {handleFilterCount(item.value)}
                  </Col>
                </Row>
              </Col>
            </Row>
        )
      ]
      :
      <Menu.Item>
        <Anchor>
          <Link
            href={localConfig.menu.items[localConfig.menu.items.length - localConfig.menu.items.length / localConfig.menu.items.length].href}
            title={localConfig.menu.items[localConfig.menu.items.length - localConfig.menu.items.length / localConfig.menu.items.length].title}
          >
          </Link>
        </Anchor>
      </Menu.Item>
  );
}

export default MainThirdPartyMenuDynamicBody;
