/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Progress,
  Row
} from 'antd';

import InformationCard from '../general/InformationCard';

import  MainThirdPartyMenuInnerWidgetInlineInformation from '../menu/auxiliary/MainThirdPartyMenuInnerWidgetInlineInformation';

import config from '../utils/config';

import './FormCover.less';

const { Meta } = Card;

const FormCover = (
  {
    handleChangeStep,
    handleFormCoverAction,
    handleHiddenContent,
    onChangeStep,
    openFormDrawer,
    navigate,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const [progress, setProgress] = useState(localConfig.progress.progressPercent);

  const handleBadge = () =>
    props.subject &&
    props.subject[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] &&
    props.subject.parent &&
    !props.subject.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
  ;

  const handleChange = (event) =>
    handleChangeStep(event, event.target);
  ;

  const handleInnerHiddenContent = () =>
    (
      props.subject &&
      props.subject[props.cover[localConfig.text.innerWidgetInnerWidgetFormValueKey]] &&
      Array.isArray(props.subject[props.cover[localConfig.text.innerWidgetInnerWidgetFormValueKey]])
    )
    ||
    (
      !props.subject[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty]
    );

  const handleInnerInnerItemActionHandler = (event, action) => {
    // event.stopPropagation();
    handleFormCoverAction(
      Object.assign({}, action, {
        payload: props.subject
      }));
  }

  const handleImage = () =>
    props.cover.steps ?
      (
        props.fieldValues &&
        props.fieldValues[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField]
      ) ?
        props.fieldValues[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField]
        :
        (
          props.fieldValues &&
          props.fieldValues[localConfig.defaultFormField]
        ) ?
          props.fieldValues[localConfig.defaultFormField]
          :
          (
            props.subjectAlternate &&
            props.subjectAlternate[localConfig.defaultFormField]
          ) ?
            props.subjectAlternate[localConfig.defaultFormField]
            :
            props.cover.steps.find(
              item => item.step == props.step
            ).avatar
      :
      props.cover.image.sizes.src
  ;

  const handleInformationReverse = () =>
    props.cover.steps.find(
      item => item.informationReverse
    )
  ;

  const handleInnerItem = (innerItem) =>
    localConfig.text.formDrawerSlugText.find(
      element => element == innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty]
    )
  ;

  const handleItem = (innerItem, innerInnerItem) =>
    innerInnerItem ?
      innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ||
      (
        innerItem.parent &&
        innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
      )
      :
      (
        (
          innerItem.parent &&
          innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
        )
      )
    ;

  const handleItemAlternate = (innerItem) =>
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty]
  ;


  const handleJustify = () =>
    props.subject ?
      openFormDrawer ?
        props.subject[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ?
          handleInnerItem(props.subject) ?
            localConfig.grid.formCoverJustify
            :
            localConfig.grid.formCoverJustifyAlternate
          :
          props.carouselContentLevel ?
            localConfig.grid.formCoverJustifyAlternate
            :
            localConfig.grid.formCoverJustify
        :
        localConfig.grid.formCoverJustify
      :
      localConfig.grid.formCoverJustify
    ;

  const handleMeta = () =>
    (
      props.fieldValues &&
      (
        props.fieldValues[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField] ||
        props.fieldValues[localConfig.defaultFormField]
      )
    ) ?
      props.fieldValues
      :
      (
        props.subjectAlternate &&
        (
          props.subjectAlternate[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField] ||
          props.subjectAlternate[localConfig.defaultFormField]
        )
      ) ?
        props.subject
        :
        props.cover.steps &&
        props.cover.steps.find(
          item => item.step == props.step
        ).meta
  ;

  const handleMetaAlternate = () =>
    props.cover.steps &&
    props.cover.steps.find(
      item => item.step == props.step
    ).meta
  ;

  const handleMetaThirdParty = () =>
    props.cover.steps &&
    props.cover.steps.find(
      item => item.step == props.step
    ).secondaryMeta
  ;

  const handleMetaThirdPartyItem = (item) =>
    props.fieldValues &&
    (
      props.fieldValues[item.field]
    ) ?
      Array.isArray(props.fieldValues[item.field]) ?
        props.fieldValues[item.field].join(localConfig.text.activityResultSecondarySeparatorText)
        :
        props.fieldValues[item.field]
      :
      (
        props.subjectAlternate &&
        (
          props.subjectAlternate[item.field]
        )
      ) ?
        Array.isArray(props.subjectAlternate[item.field]) ?
          props.subjectAlternate[item.field].join(localConfig.text.activityResultSecondarySeparatorText)
          :
          props.subjectAlternate[item.field]
        :
        (
          props.subject &&
          (
            props.subject[item.field]
          )
        ) ?
          Array.isArray(props.subject[item.field]) ?
            props.subject[item.field].join(localConfig.text.activityResultSecondarySeparatorText)
            :
            props.subject[item.field]
          :
          props.defaultFields &&
          (
            props.defaultFields.find(
              element => element.field == item.field
            )
          ) ?
            props.defaultFields.find(
              element => element.field == item.field
            ).defaultValue
            :
            localConfig.text.activityResultSeparatorText
  ;

  const handleMetaThirdPartyItemClick = (item) =>
    onChangeStep(props.step + props.step / props.step)
  ;

  useEffect(
    (  ) => {
      setTimeout(
        () => {
          props.cover.progress &&
          !handleMeta().replacementDescription &&
          progress <= localConfig.progress.progressMaximumPercent && (
            progress == localConfig.progress.progressMaximumPercent &&
            // handleChangeStep(progress, progress),
            setProgress(
              (
                progress == localConfig.progress.progressMaximumPercent ?
                  (
                    // navigate(localConfig.text.linkText),
                    // onChangeStep(progress / progress),
                    progress - localConfig.progress.progressMaximumPercent
                  )
                  :
                  progress
              )
              +
              localConfig.progress.progressPercent
            )
          );
        }, localConfig.progress.progressInterval)
      ;
    }
  )

  console.log(progress)
  console.log(props)
  console.log(openFormDrawer)

  return (
    <Row
      justify={handleJustify()}
      type="flex"
    >
      <Col>
        {
          (
            props.subject &&
            openFormDrawer
          )
          ?
            props.alternateCover ?
              handleHiddenContent() &&
              handleInnerHiddenContent() &&
              <MainThirdPartyMenuInnerWidgetInlineInformation
                handleThirdPartyThirdPartyAlternateMenuClick={event => handleInnerInnerItemActionHandler(event, props.cover.actionHandler)}
                innerConfig={localConfig}
                subject={props.subject}
                topic={props.cover[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
              />
              :
              <Row
                justify={props.cover.justify}
                type="flex"
              >
                <Col
                >
                  <Row
                    justify={props.cover.justify}
                    type="flex"
                  >
                    <Col
                    >
                      <Avatar
                        onMouseDown={event => openFormDrawer(event)}
                      />
                    </Col>
                    <Col
                    >
                      <Avatar
                        onMouseDown={event => openFormDrawer(event)}
                      />
                    </Col>
                  </Row>
                  <Row
                    justify={props.cover.justify}
                    type="flex"
                  >
                    <Col
                    >
                      <InformationCard
                        hasBadge={handleBadge()}
                        hasExtendedAvatar={
                          (
                            !handleItem(props.subject) ||
                            !handleHiddenContent()
                          )
                        }
                        hasExtendedContent={
                          (
                            handleItem(props.subject) ||
                            handleHiddenContent()
                          )
                          ||
                          (
                            !handleItem(props.subject) ||
                            !handleHiddenContent()
                          )
                        }
                        hasHiddenContent={
                          handleItem(props.subject) ||
                          handleHiddenContent()
                        }
                        hasMediumAvatar
                        informationReverse={
                          !handleItem(props.subject)
                          // ||
                          // |!handleHiddenContent()
                          // ||
                          // handleInnerItem(props.subject)
                        }
                        innerInformationReverse={
                          !handleItemAlternate(props.subject)
                        }
                        subject={props.subject}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            :
            [
              handleMetaAlternate() &&
              handleMetaAlternate().superTitle &&
              <Row
                justify={props.cover.justify}
                type="flex"
              >
                <Col
                >
                  {handleMetaAlternate().superTitle}
                </Col>
              </Row>
              ,
              <Card
                cover=
                {
                  <img
                    alt=""
                    src={handleImage()}
                  />
                }
                hoverable
              >
                {
                  handleMeta() &&
                    <Meta
                      description={
                        handleMeta().replacementDescription ?
                          <Row
                            justify={props.cover.justify}
                            type="flex"
                          >
                            {
                              handleMeta().replacementDescription.map(
             	                  item =>
                                  item.checkbox ?
                                    <Col
                                    >
                                      <Checkbox
                                        onChange={event => handleChange(event)}
                                      >
                                        {item.text}
                                      </Checkbox>
                                    </Col>
                                    :
                                    <Col
                                    >
                                      {item.text}
                                    </Col>
                              )
                            }
                          </Row>
                          :
                          handleInformationReverse() ?
                            handleMeta().title
                            :
                            handleMeta().description

                    }
                    title={
                      handleInformationReverse() ?
                        handleMeta().description
                        :
                        handleMeta().title
                    }
                  />
                }
              </Card>
              ,
              handleMetaThirdParty() &&
              <Row
                justify={props.cover.justify}
                type="flex"
              >
                <Col
                  span={localConfig.grid.gridSpan}
                >
                  <Row
                    justify={props.cover.justify}
                    type="flex"
                  >
                    <Col
                    >
                      {handleMetaThirdParty().title}
                    </Col>
                  </Row>
                  {
                    handleMetaThirdParty().replacementDescription.map(
                      item =>
                      <Row
                        justify={props.cover.justify}
                        type="flex"
                      >
                        <Col
                        >
                            {item.title + localConfig.text.activityResultSecondarySeparatorTextAlternate}
                        </Col>
                        <Col
                        >
                          {handleMetaThirdPartyItem(item)}
                        </Col>
                      </Row>
                    )
                  }
                  <Row
                    justify={props.cover.justify}
                    type="flex"
                  >
                    <Col
                      onMouseDown={event => handleMetaThirdPartyItemClick(event)}
                    >
                      {handleMetaThirdParty().description}
                    </Col>
                  </Row>
                </Col>
              </Row>
              ,
              props.cover.progress &&
              handleMeta() &&
              !handleMeta().replacementDescription &&
              <Progress
                percent={progress}
                showInfo={props.cover.progress.showInfo}
                status={props.cover.progress.status}
                strokeWidth={props.cover.progress.strokeWidth}
              />

            ]
        }
      </Col>
    </Row>
  );
}

export default FormCover;
