module.exports = {
  datatransfomation: {
    separator: "."
  },
  defaultSecondaryField: "connections",
  defaultSecondaryFieldAlternate: "user",
  defaultSixthPartyFieldAlternate: "title",
  defaultSixthPartyFieldThirdParty: "description",
  defaultSixthPartyFieldThirdPartyAlternate: "subDescription",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty: "link",
  defaultThirdPartyThirdPartySecondaryFieldThirdParty: "className",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate: "Post",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary: "Article",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateThirdParty: "Gallery",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty: "class_name",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternate: "subTitle",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary: "id",
  defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdPartyAlternate: "subTitle",
  entity: {
    // user: {
    //   avatar: "https://cdn.filestackcontent.com/V7YJCieTuOmxwmBl7JwM",
    //   avatarExtended: "https://cdn.filestackcontent.com/V7YJCieTuOmxwmBl7JwM",
    //   avatarMedium: "https://cdn.filestackcontent.com/V7YJCieTuOmxwmBl7JwM",
    //   description: "Vowtch",
    //   subTitle: "Vowtch",
    //   title: "Vowtch"
    // }
    user: {
      avatar: "https://cdn.filestackcontent.com/V7YJCieTuOmxwmBl7JwM",
      avatarExtended: "https://cdn.filestackcontent.com/V7YJCieTuOmxwmBl7JwM",
      avatarMedium: "https://cdn.filestackcontent.com/uvTSWhRPRfyx79BWabQ9",
      description: "VIDEO",
      subTitle: "Bayern Powers Past Salzburg",
      title: "MAR 8, 2022",
      useStateWithLocalStorageKey: "keycloakUser"
    }
  },
  grid: {
    gridSpan: 24
  },
  innerWidgetInnerWidgetFormValueKey: "topic",
  menu: {
    items: [
      {
        carouselContent: "5d135ff8fb6fc00e79b09c97",
        href: "/",
        timelineMenuShown: true,
        title: "Timeline"
      }
    ]
  },
  text: {
    activityResultSeparatorText: " ",
    centerSlugText: "Loading",
    classieContentBadgeText: [],
    classieContentThirdPartySlugText: "Comments",
    widgetText: "Vowtch"
  },
  timelineCarousel: {
    centerMode: true,
    data: {
      parent: "2022",
      itemPrefixLength: 3,
      itemSuffixLength: 4,
      items: ["JAN", "FEB", "MAR"]
    },
    datatransfomation: [
      {
        defaultField: "avatar",
        field: "avatar"
      },
      {
        className: "Post",
        defaultField: "description",
        field: "title"
      },
      {
        className: "Post",
        defaultField: "subDescription",
        field: "description"
      },
      {
        className: "Post",
        defaultField: "title",
        field: "superTitle"
      },
      {
        className: "Gallery",
        defaultField: "description",
        field: "superTitle"
      },
      {
        className: "Gallery",
        defaultField: "subTitle",
        field: "title"
      },
      {
        className: "Gallery",
        defaultField: "title",
        field: "description"
      }
    ],
    datatransfomationNode: "Dashboard",
    dots: true,
    draggable: true,
    image: {
      pause: "https://cdn.filepicker.io/api/file/0TK0FHXuSuMJobYQa1vx",
      play: "https://cdn.filepicker.io/api/file/R2gVClJPQyeeoY6cJM46"
    },
    slidesSeparator: 14,
    slidesToFill: 39,
    slidesToShow: 3
  }
}
