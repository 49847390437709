import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Icon,
  Row,
  Spin
} from 'antd';

import config from '../utils/config';

import './CircularProgress.less';

const CircularProgress = (
  props
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Spin
      indicator={
        <Icon
          spin
          type="loading"
        />
      }
    />
  );
}

export default CircularProgress;
