import React, { useEffect, useState } from 'react';

import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

import { Layout } from 'antd';

import { ThemeProvider } from 'styled-components';

import { navigate } from "gatsby";

import Tagsonomy from 'tagsonomy-library';

import Vowtch from 'vowtch-library-branch';

import DashBoardSplashView from '../dashboard/DashBoardSplashView';

import Footer from '../container/Footer';

import config from '../../utils/config';

import theme from '../../styles/theme';

import './index.less';

const {
  ConnectedTagDrawer
} = Tagsonomy.Components;

const {
  CircularProgress,
  ConnectedFormDrawer,
  ConnectedMenuDrawer
} = Vowtch.Components;

const { Content } = Layout;

const VowtchLayout = (
  {
    children,
    data,
    ...props
  }
) => {

  const [localChildren, setLocalCchildren] = useState()

  useEffect(() => {
    setTimeout(() => {
      setLocalCchildren(children);
    }, config.defaultDuration);
    setLocalCchildren(localChildren);
  }, []);

  return (

    <div className="siteRoot">
      <Helmet>
        <title>
          {config.siteTitle}
        </title>
        <meta
          charSet="utf-8"
        />
        <meta
          content={config.siteDescription}
          name="description"
        />
        <meta
          content="width=device-width, initial-scale=1"
          name="viewport"
        />
        <meta
          content="en_US"
          property="og:locale"
        />
        <meta
          content={config.siteTitle}
          property="og:site_name"
        />
        <meta
          content={config.siteTitle}
          property="og:title"
        />
        <meta
          content="website"
          property="og:type"
        />
        <meta
          content={config.siteUrl}
          property="og:url"
        />
        <link
          href={config.favicon}
          rel="icon"
          type="image/png"
        />
      </Helmet>

      <ThemeProvider theme={theme}>
        {
          <Layout
          >
            <ConnectedMenuDrawer
              hasClassieContentBadge
              hasFooter
              hasTitle
              innerConfig={config}
              navigate={navigate}
              pages={props.pages}
            />
            <ConnectedFormDrawer
              hasBadge
              innerConfig={config}
              navigate={navigate}
            />
            <ConnectedTagDrawer
              hasDrawer
              hasTitle
              innerConfig={config}
              navigate={navigate}
            />
            <Content>
              {children}
            </Content>
          </Layout>
        }
      </ThemeProvider>

      <ThemeProvider theme={theme}>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

VowtchLayout.propTypes = {
  children: PropTypes.object,
}

export default VowtchLayout
