import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Button,
  Col,
  Icon,
  Row,
  Upload
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import FormButton from './FormButton';

import config from '../utils/config';

import * as  filestack from '../../libs/filestack/filestack_client' ;

import './ImageDraggerField.less';

const Dragger = Upload.Dragger;

const { Link } = Anchor;

const ImageDraggerField = (
  {
    fieldValues,
    formField,
    formValues,
    handleSubject,
    handleInnerInnerItemSubmitHandler,
    onChangeField,
    receiveFormValues,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleAvatarSource = () =>
    props.subject ?
      fieldValues ?
        fieldValues[props.field] ?
          fieldValues[props.field]
          :
          props.field == localConfig.defaultFormField ?
            props.subject[props.field] ?
              props.subject[props.field]
              :
              props.staticData[localConfig.defaultFormField]
            :
            props.subject[props.field]
        :
        props.subject[props.field]
      :
      fieldValues ?
        props.drawer ?
          fieldValues[props.field] ?
            fieldValues[props.field]
            :
            props.field == localConfig.defaultFormField ?
              props.staticData[localConfig.defaultFormField]
              :
              fieldValues[props.field]
          :
          formField ?
            fieldValues[formField]
            :
            fieldValues[props.field] ?
              fieldValues[props.field]
              :
              props.field == localConfig.defaultFormField ?
                props.staticData[localConfig.defaultFormField]
                :
                fieldValues[props.field]
        :
        formValues ?
          formValues.url ?
            formValues.url
            :
            props.icon
          :
          props.field == localConfig.defaultFormField ?
            props.staticData[localConfig.defaultFormField]
            :
            props.icon
    ;

  const handleJustify = () =>
    props.justify ?
      props.justify
      :
      localConfig.grid.cardInnerWidgetBlockCardJustifyAlternate
  ;

  const handleSpan = () =>
    !props.display && localConfig.grid.gridSpan

  const handleButtonTitle = () =>
    (
      (
        props.hasAvatar &&
        handleSubject()
      )
      ||
      (
        fieldValues &&
        fieldValues[props.field] &&
        handleSubject()
      )
    ) ?
      props.buttonLabel
      :
      props.extraButtonLabel
    ;

  const onUpload = (info) =>
    info.file.status !== localConfig.upload.status &&
    filestack.createFilestackFile(
      receiveFormValues,
      info,
      onChangeField,
      (
        formField ?
          formField
          :
          props.field
      )
    )
  ;

  console.log(props)
  console.log(formField)

  return (
    <div>
      {
        props.buttonLabel ?
          <Row
            justify={handleJustify()}
            type="flex"
          >
            <Col
              span={handleSpan()}
             >
              <Avatar
                src={handleAvatarSource()}
                shape="square"
              />
            </Col>
            <Col>
              {
                props.extraButtonLabel ?
                  <Row
                    justify={handleJustify()}
                    type="flex"
                  >
                    <Col>

                    </Col>
                    <Col>
                      <Upload
                        onChange={onUpload}
                      >
                        {
                          props.drawer ?
                            <FormButton
                              action={event => handleInnerInnerItemSubmitHandler(event, props.button.action, props.field)}
                              addonAfter={props.button.addonAfter}
                              addonBefore={props.button.addonBefore}
                              title={handleButtonTitle()}
                            >
                            </FormButton>
                            :
                            <Button>
                              <Icon
                                type="upload"
                              />
                              {props.buttonLabel}
                            </Button>

                        }
                      </Upload>
                    </Col>
                  </Row>
                  :
                  <Upload
                    onChange={onUpload}
                  >
                   <Button>
                     <Icon
                       type="upload"
                     />
                     {props.buttonLabel}
                   </Button>
                  </Upload>
              }
            </Col>
          </Row>
          :
          <Dragger>
            <Row
              justify="center"
              type="flex"
            >
              <Col>
                {localConfig.text.topDraggerSlugText}
              </Col>
            </Row>
            <Row
              justify="center"
              type="flex"
            >
              <Col>
                {
                  props.icon &&
                  <i className={props.icon}/>
                }
              </Col>
            </Row>
            <Row
              justify="center"
              type="flex"
            >
              <Col>
                {localConfig.text.bottomLeftDraggerSlugText}
              </Col>
              <Col>
                <Anchor>
                  <Link
                    title={localConfig.text.bottomCenterDraggerSlugText}
                  >
                  </Link>
                </Anchor>
              </Col>
              <Col>
                {localConfig.text.bottomRightDraggerSlugText}
              </Col>
            </Row>
          </Dragger>
      }
    </div>
  );
}

export default ImageDraggerField;
