import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericForm from '../../form/GenericForm';

import config from '../../utils/config';

const CardHeaderWidgetForm = (
  {
    handleCardHeaderWidgetClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props)

  const handleInnerItem = (item) =>
    item.replace(
      localConfig.text.alternateActivityResultThirdPartySeparatorText,
      localConfig.text.activityResultThirdPartySeparatorText
    );

  const handleInnerItemContent = (innerItem) =>
    localConfig.text.activityResultSeparatorText +
    localConfig.text.quotesText +
    (
      Array.isArray(innerItem) ?
        handleInnerItem(innerItem.join())
        :
        handleInnerItem(innerItem)
    ) +
    localConfig.text.quotesText;
  ;

  const handleInnerItemInlineInformation = (item) =>
    props.formValues?
      Array.isArray(props.formValues) ?
        props.formValues.map(
          innerItem =>
            handleInnerItemContent(innerItem[localConfig.text.innerWidgetInnerWidgetFormValueKey])
        )
        :
        handleInnerItemContent(props.formValues[localConfig.text.innerWidgetInnerWidgetFormValueKey])
      :
      Array.isArray(props.subject[item.field]) &&
      props.subject[item.field].map(
        innerItem =>
          handleInnerItemContent(innerItem.title)
      )
  ;

  return (
    props.inlineInformation.map(
      item =>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col
            onClick={handleCardHeaderWidgetClick}
          >
            <Row
              justify="start"
              type="flex"
            >
              {
                item.addonBefore && item.addonBefore.avatar &&
                <Col
                >
                  <Avatar
                    src={item.addonBefore.avatar}
                  />
                </Col>
              }
              <Col
              >
                {item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
              </Col>
              <Col
              >
                {handleInnerItemInlineInformation(item)}
              </Col>
              {
                item.addonAfter && item.addonAfter.avatar &&
                <Col
                >
                  <Avatar
                    src={item.addonAfter.avatar}
                  />
                </Col>
              }
            </Row>
          </Col>
          <Col
          >
          </Col>
        </Row>
    )
  );
}

export default CardHeaderWidgetForm;
