/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import {
  Avatar,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  TimePicker
} from 'antd';

import { connect } from "react-redux";

import FormButton from './FormButton';
import FormCover from './FormCover';
import FormFooter from './FormFooter';
import FormHeader from './FormHeader';
import FormInstruction from './FormInstruction';
import FormMenu from './FormMenu';
import FormPreview from './FormPreview';
import FormTree from './FormTree';

import ScrollableContainer from '../general/ScrollableContainer';

import AutoCompleteField from './AutoCompleteField';
import CustomCompositeField from './CustomCompositeField';
import DatePickerField  from './DatePickerField';
import Helper from './Helper';
import ImageDraggerField from './ImageDraggerField';
import MapField from './MapField';
import Mockup from './Mockup';
import PhoneNumberInputField from './PhoneNumberInputField';
import RadioField from './RadioField';
import SelectField from './SelectField';
import SwitchField from './SwitchField';
import TagsField from './TagsField';

import config from '../utils/config';

import './GenericForm.less';

const FormItem = Form.Item;

const { TextArea } = Input;

const GenericForm = (
  {
    form: {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
      validateFieldsAndScroll
    },
    alternateFormItemShown,
    carouselContentLevel,
    data,
    fieldValues,
    formField,
    formValues,
    onAction,
    onChangeField,
    onChangeStep,
    openFormDrawer,
    receiveData,
    receiveFormValues,
    resetData,
    showAlternateFormItem,
    step,
    ...props
  }
) => {

  const {
    hasAlternateCover,
    hasBadge,
    hasBaseButton,
    hasButton,
    hasCover,
    hasHeader,
    hasFooter,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const [formFields, setFormFields] = useState();

  console.log(props);
  console.log(fieldValues);
  console.log(formValues);

  const handleDatatransformation = (item, innerinnerItemReverse) =>
    item.map(
      innerItem =>
        Object.assign(
          {},
          ...props.datatransfomation.map(
            innerinnerItem =>
              Object.assign(
                {},
                Object.assign(
                  {},
                  innerinnerItemReverse ?
                    {
                      [innerinnerItem.defaultField]
                      :
                      innerItem[innerinnerItem.field]
                    }
                    :
                    {
                      [innerinnerItem.field]
                      :
                      innerItem[innerinnerItem.defaultField]
                    }
                )
              )
          )
        )
    )
  ;

  const handleFormStep = () =>
    props.actions &&
    props.actions.buttons &&
    Math.max.apply(
      Math, props.actions.buttons.map(
        item => item.step && !item.alternateButtonShown
      )
    )
  ;

  const handleSubject = () =>
    props.subject &&
    props.datatransfomation &&
    handleDatatransformation([props.subject]).slice().shift()[
      props.datatransfomation.find(
        element => element.markerField
      ).field
    ]
  ;

  const handleSubjectAlternate = () =>
    props.subject &&
    props.datatransfomation &&
    handleDatatransformation([props.subject]).slice().shift()
  ;

  props.subject &&
  props.datatransfomation &&
  console.log(handleDatatransformation([props.subject]).slice().shift());
  console.log(handleSubject());

  const handlePreview = () =>
    props.preview &&
    props.preview.steps.includes(step)
  ;

  const handleFormMenuStep = () =>
    props.menu.steps ?
      props.menu.steps.includes(step)
      :
      props.menu
  ;

  const handleFormMenu = (
    localFormFields = handleFormFields(),
    selectFieldsSections =
      Array.isArray(localFormFields) &&
      localFormFields.find(
        element => element.rows && element.rows.find(
          innerElement => innerElement.columns.find(
            innerInnerElement => innerInnerElement.select
          )
        )
      )
    ,
    selectFieldsRows =
      selectFieldsSections &&
      selectFieldsSections.rows.find(
        element => element.columns.find(
          innerElement => innerElement.select
        )
      )
    ,
    selectFields =
      selectFieldsRows &&
      selectFieldsRows.columns.find(
        element => element.select
      )
  ) =>
    props.menu &&
    handleFormMenuStep() &&
    (
      !fieldValues ||
      !selectFieldsSections ||
      handlePreview()
      ||
      (
        fieldValues &&
        selectFieldsSections &&
        selectFields &&
        (
          Array.isArray(selectFieldsSections) ?
            selectFieldsSections.find(
              element => element.step == step
            )
            :
            selectFieldsSections.step == step
        )
        &&
        (
          Array.isArray(selectFields) ?
            !selectFields.find(
              element => fieldValues[element.field]
            )
            :
            !fieldValues[selectFields.field]
        )
      )
    )
  ;

  const handleFormCover = () =>
    (
      (
        hasCover &&
        handleSubject()
      )
      ||
      (
        hasCover &&
        props.cover &&
        props.cover.image
      )
    )
    &&
    (
      props.cover ?
        props.cover.steps ?
          props.cover.steps.find(
            item => item.step == step
          )
          :
          props.cover.step ?
            props.cover.step == step
            :
            hasCover
        :
        hasCover
    )
  ;

  console.log(handleFormCover());
  console.log(formFields);

  console.log(
    formFields ?
      formFields
      :
      props.formFields
  );

  const handleFormField = (

  ) =>
    formField &&
    formField.replace(
      localConfig.text.alternateActivityResultThirdPartySeparatorText,
      localConfig.text.activityResultThirdPartySeparatorText
    )
  ;

  const handleFormFields = (
    innerLocalStep = step,
    localStep =
      !handleSubject() &&
      props.formFieldsReverse &&
      handleFormStep() !== innerLocalStep / innerLocalStep ?
        ++innerLocalStep > handleFormStep() ?
          innerLocalStep / innerLocalStep
          :
          innerLocalStep
        :
        innerLocalStep
    ,
    localFormFields =
      formFields ?
        formFields
        :
        props.formFields
  ) =>
    localFormFields.filter(
      item =>
        Number.isInteger(props.innerStep) ?
          item.step === props.innerStep
          :
          typeof item.alternateFormItem !== `undefined` ?
            item.alternateFormItem === alternateFormItemShown
            :
            item.step ?
              item.step === localStep
              :
              item
    )
  ;

  const handleFormFieldsDynamicRow = (
    item
  ) =>
    Object.assign(
      {},
      ...item,
      {
        [
          Object.keys(item).find(
            innerItem => Array.isArray(item[innerItem])
          )
        ]
        :
        [
          ...item.columns.map(
            innerItem =>
              Object.assign(
                {},
                ...innerItem,
                {
                  [
                    Object.keys(innerItem)[
                      Object.keys(innerItem).indexOf(
                        Object.keys(innerItem).find(
                          innerInnerItem =>
                            innerItem[innerInnerItem] &&
                            innerItem[innerInnerItem].constructor === Object
                        )
                      ) - Object.keys(innerItem).length / Object.keys(innerItem).length
                    ]
                  ]
                  :
                  innerItem.field + innerItem.field
                }
              )
          )
        ]
      }
    )
  ;

  const handleFormFieldsDynamic = (
    event,
    localFormFields = handleFormFields()
  ) => {
    console.log(formFields);
    console.log(localFormFields);

    setFormFields(
      localFormFields &&
      localFormFields.map(
        item  =>
          item.rows.find(
            element => element.columns.find(
              innerElement => innerElement.select
            )
          ) ?
            item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length == item.rows.length / item.rows.length ?
              Object.assign(
                {},
                ...item,
                {
                  [
                    Object.keys(item).find(
                      innerItem => Array.isArray(item[innerItem])
                    )
                  ]
                  :
                  [
                    ...item.rows.slice(
                      item.rows.length - item.rows.length,
                      item.rows.length - item.rows.length / item.rows.length
                    )
                    ,
                    handleFormFieldsDynamicRow(item.rows[item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length])
                    ,
                    handleFormFieldsDynamicRow(handleFormFieldsDynamicRow(item.rows[item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length]))
                    ,
                    item.rows[item.rows.length - item.rows.length / item.rows.length]
                  ]
                }
              )
              :
              Object.assign(
                {},
                ...item,
                {
                  [
                    Object.keys(item).find(
                      innerItem => Array.isArray(item[innerItem])
                    )
                  ]
                  :
                  [
                    ...item.rows.slice(
                      item.rows.length - item.rows.length,
                      item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length
                    )
                    ,
                    handleFormFieldsDynamicRow(item.rows[item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length  - item.rows.length / item.rows.length])
                    ,
                    handleFormFieldsDynamicRow(handleFormFieldsDynamicRow(item.rows[item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length]))
                    ,
                    item.rows[item.rows.length - item.rows.length / item.rows.length]
                  ]
                }
              )
            :
            item
      )
    );
  }

  const handleFormFieldItem = (
    innerInnerItem,
    conditionLeftHandSideSplit =
      innerInnerItem.condition &&
      Math.abs(innerInnerItem.condition.leftHandSide.indexOf(localConfig.text.menuSeparatorText)) ==
        innerInnerItem.condition.leftHandSide.indexOf(localConfig.text.menuSeparatorText) &&
      innerInnerItem.condition.leftHandSide.split(
        localConfig.text.menuSeparatorText
      ).slice().shift(),
    conditionRightHandSideSplit =
      innerInnerItem.condition &&
      Math.abs(innerInnerItem.condition.rightHandSide.indexOf(localConfig.text.menuSeparatorText)) ==
        innerInnerItem.condition.rightHandSide.indexOf(localConfig.text.menuSeparatorText) &&
      innerInnerItem.condition.rightHandSide.split(
        localConfig.text.menuSeparatorText
      ).slice().shift(),
    conditionRightHandSideSplitSplit =
      innerInnerItem.condition &&
      Math.abs(innerInnerItem.condition.rightHandSide.indexOf(localConfig.text.textSeparatorText)) ==
        innerInnerItem.condition.rightHandSide.indexOf(localConfig.text.textSeparatorText) &&
      innerInnerItem.condition.rightHandSide.split(
        localConfig.text.textSeparatorText
      ),
    conditionLeftHandSideSplitReverse =
      innerInnerItem.condition &&
      Math.abs(innerInnerItem.condition.leftHandSide.indexOf(localConfig.text.menuSeparatorText)) ==
        innerInnerItem.condition.leftHandSide.indexOf(localConfig.text.menuSeparatorText) &&
      innerInnerItem.condition.leftHandSide.split(
        localConfig.text.menuSeparatorText
      ).reverse().slice().shift(),
    conditionRightHandSideSplitReverse =
      innerInnerItem.condition &&
      Math.abs(innerInnerItem.condition.rightHandSide.indexOf(localConfig.text.menuSeparatorText)) ==
        innerInnerItem.condition.rightHandSide.indexOf(localConfig.text.menuSeparatorText) &&
      innerInnerItem.condition.rightHandSide.split(
        localConfig.text.menuSeparatorText
      ).reverse().slice().shift(),
    conditionRightHandSideSplitReverseSplit =
      conditionRightHandSideSplitReverse &&
      Math.abs(conditionRightHandSideSplitReverse.indexOf(localConfig.text.textSeparatorText)) ==
        conditionRightHandSideSplitReverse.indexOf(localConfig.text.textSeparatorText) &&
      conditionRightHandSideSplitReverse.split(
        localConfig.text.textSeparatorText
      )

  ) =>
    (
      (
        innerInnerItem.condition ?
          fieldValues &&
          (
            Math.abs(innerInnerItem.condition.leftHandSide.indexOf(localConfig.text.menuSeparatorText)) ==
              innerInnerItem.condition.leftHandSide.indexOf(localConfig.text.menuSeparatorText) ?
                innerInnerItem.condition.value ?
                  (
                    (
                      fieldValues[conditionLeftHandSideSplit] &&
                      Math.abs(fieldValues[conditionLeftHandSideSplit].indexOf(conditionRightHandSideSplit)) ==
                        fieldValues[conditionLeftHandSideSplit].indexOf(conditionRightHandSideSplit)
                    )
                    ||
                    (
                      fieldValues[conditionLeftHandSideSplitReverse] &&
                      Math.abs(fieldValues[conditionLeftHandSideSplitReverse].indexOf(conditionRightHandSideSplitReverse)) ==
                        fieldValues[conditionLeftHandSideSplitReverse].indexOf(conditionRightHandSideSplitReverse)
                    )
                  )
                  :
                  (
                    (
                      (
                        !fieldValues[conditionLeftHandSideSplit] &&
                        fieldValues[conditionLeftHandSideSplitReverse] &&
                        conditionRightHandSideSplitReverseSplit.reverse().slice().shift() == fieldValues[conditionLeftHandSideSplitReverse]
                      )
                      ||
                      (
                        fieldValues[conditionLeftHandSideSplit] &&
                        Math.abs(fieldValues[conditionLeftHandSideSplit].indexOf(conditionRightHandSideSplit)) !==
                          fieldValues[conditionLeftHandSideSplit].indexOf(conditionRightHandSideSplit)
                      )
                    )
                    ||
                    (
                      fieldValues[conditionLeftHandSideSplitReverse] &&
                      !conditionRightHandSideSplitReverseSplit.includes(fieldValues[conditionLeftHandSideSplitReverse])
                    )
                  )
                :
                conditionRightHandSideSplitSplit ?
                  (
                    fieldValues[innerInnerItem.condition.leftHandSide] &&
                    !innerInnerItem.condition.value &&
                    !conditionRightHandSideSplitSplit.includes(fieldValues[innerInnerItem.condition.leftHandSide])
                  )
                  :
                  innerInnerItem.condition.value ?
                    fieldValues[innerInnerItem.condition.leftHandSide] &&
                    Math.abs(fieldValues[innerInnerItem.condition.leftHandSide].indexOf(innerInnerItem.condition.rightHandSide)) ==
                      fieldValues[innerInnerItem.condition.leftHandSide].indexOf(innerInnerItem.condition.rightHandSide)
                    :
                    fieldValues[innerInnerItem.condition.leftHandSide] &&
                    Math.abs(fieldValues[innerInnerItem.condition.leftHandSide].indexOf(innerInnerItem.condition.rightHandSide)) !==
                      fieldValues[innerInnerItem.condition.leftHandSide].indexOf(innerInnerItem.condition.rightHandSide)
          )
          :
          !innerInnerItem.hidden
      )
      ||
      (
        innerInnerItem.hidden &&
        handleFormMenuStep() &&
        !handleFormMenu()
      )
    )
  ;

  console.log(handleFormMenu());

  const handleFormStepValidate = (
      formFieldsFilter =
        handleFormFields().filter(
          innerElement => innerElement.rows && innerElement.rows.find(
            innerInnerElement => innerInnerElement.columns.find(
              innerInnerInnerElement => innerInnerInnerElement.label
            )
          )
        )
      ,
      fieldValuesFilter =
        fieldValues &&
        formFieldsFilter &&
        Object.keys(fieldValues).filter(
          element => formFieldsFilter.find(
            innerElement => innerElement.rows && innerElement.rows.find(
              innerInnerElement => innerInnerElement.columns.find(
                innerInnerInnerElement => innerInnerInnerElement.field == element
              )
            )
          )
        )
        ,
  ) =>
    (
      fieldValuesFilter &&
      (
        (
          fieldValuesFilter &&
          !(
            formFieldsFilter.find(
              element => element.rows && element.rows.find(
                innerElement => innerElement.columns.find(
                  innerInnerElement =>
                    handleFormFieldItem(innerInnerElement) &&
                    innerInnerElement.label &&
                    !innerInnerElement.customCompositeField &&
                    !fieldValuesFilter.includes(innerInnerElement.field)
                )
              )
            )
          )
        )
        ||
        (
          !fieldValuesFilter.length &&
          handleFormCover()
        )
        ||
        (
          !fieldValuesFilter.length &&
          !formFieldsFilter.length
        )
      )
    )
  ;

  console.log(
    fieldValues &&
    Object.keys(fieldValues).filter(
      element => handleFormFields().find(
        innerElement => innerElement.rows && innerElement.rows.find(
          innerInnerElement => innerInnerElement.columns.find(
            innerInnerInnerElement => innerInnerInnerElement.field == element
          )
        )
      )
    )
  );

  const handleFormTitle = (
    localFormTitle =
      props.replacementTitle &&
      (
        props.replacementTitle.find(
          item => item.steps
        ) ?
          props.replacementTitle.find(
            item =>
              props.subject ?
                item.steps.includes(step) &&
                item.subject
                :
                item.steps.includes(step) &&
                !item.subject
          ) ?
            props.replacementTitle.find(
              item =>
                props.subject ?
                  item.steps.includes(step) &&
                  item.subject
                  :
                  item.steps.includes(step) &&
                  !item.subject
            )
            :
            props
          :
          props
      )
  ) =>
    localFormTitle ?
      localFormTitle.title
      :
      props.title
  ;

  console.log(handleFormFields( ));

  console.log(handleFormStepValidate( ));

  const handleChangeStep = (
    event,
    ascendant,
    descendant,
    skipStep,
    values
  ) => {
    console.log(handleFormStep())
    console.log(step)
    console.log(descendant)
    console.log(event)

    event &&
    event.preventDefault &&
    !descendant &&
    handleSubmit(event, values);

    onChangeStep(
      skipStep ?
        skipStep
        :
        ascendant ?
          (
            handleFormStep() &&
            handleFormStep() !== step / step
          ) ?
            ++step > handleFormStep() ?
              step / step
              :
              step
            :
            ++step
          :
          descendant ?
            --step
            :
            step

    );
  }

  const handleDefaultFields = (
    localDefaultFields =
      props.defaultFields &&
      props.defaultFields.filter(
        item =>
          (
            props.subject ?
              props.datatransfomation &&
              typeof handleDatatransformation([props.subject]).slice().shift()[item.field] == `undefined`
              :
              fieldValues &&
              typeof fieldValues[item.field] == `undefined`
          )
      )
    ,
    localItem =
      localDefaultFields &&
      localDefaultFields.slice().shift()

  ) =>
    localDefaultFields &&
    localItem &&
    localItem.field &&
    localDefaultFields.reduce(
      (
        items,
        item
      ) => (
        console.log(item),
        console.log(items),
        Object.assign(
          {},
          ...items,
          {
            [item.field]
            :
            item.defaultValue ?
              item.label ?
                [item.defaultValue]
                :
                item.defaultValue
              :
              props.staticData[item.field]
          }
        )
      ),
      {
        [localItem.field]
        :
        localItem.defaultValue ?
          localItem.label ?
            [localItem.defaultValue]
            :
            localItem.defaultValue
          :
          props.staticData[localItem.field]
      }
    );

  const handleDefaultValues = (values) =>
    (
      {
        [values.field]
        :
        values.value
      }
    )
  ;

  const handleDefaultValuesAlternate = () =>
    (
      fieldValues &&
      props.staticData &&
      props.staticData[localConfig.defaultThirdPartyThirdPartyThirdPartyField] &&
      fieldValues[localConfig.defaultFormFieldAlternate]
    )
    ?
      fieldValues[localConfig.defaultFormFieldAlternate].map(
        innerItem =>
          Object.assign(
            {},
            ...props.staticData[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types.map(
              innerinnerItem =>
                innerinnerItem.items.find(
                  element =>
                    element.classieContentBadgeText &&
                    element.classieContentBadgeText.slice().shift().text.toUpperCase() == innerItem.toUpperCase()
                ) &&
                Object.assign(
                  {},
                  {
                    [
                      localConfig.text.innerWidgetInnerWidgetFormValueKey
                    ]
                    :
                    innerinnerItem.items.find(
                      element =>
                        element.classieContentBadgeText.slice().shift().text.toUpperCase() == innerItem.toUpperCase()
                    ).carouselContent[localConfig.text.innerWidgetInnerWidgetFormValueKey]
                  }
                )
            )
          )
      )
    :
    localConfig.defaultThirdPartyParameters &&
    [localConfig.defaultThirdPartyParameters[localConfig.defaultThirdPartyThirdPartySecondaryField]]
  ;

  console.log(props.staticData);
  console.log(handleDefaultValuesAlternate());

  const handleMeta = (
    localSubjectAlternate = handleSubjectAlternate()
  ) =>
    (
      fieldValues &&
      (
        fieldValues[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField] ||
        fieldValues[localConfig.defaultFormField]
      )
    ) ?
      fieldValues
      :
      (
        localSubjectAlternate &&
        (
          localSubjectAlternate[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField] ||
          localSubjectAlternate[localConfig.defaultFormField]
        )
      ) ?
        props.subject
        :
        props.cover.steps &&
        props.cover.steps.find(
          item => item.step == step
        ).meta
  ;

  // console.log(handleMeta());

  const handleSubmit = (
    event,
    valuesExtended,
    localStep = step
  ) => {
    event.preventDefault();
    console.log(fieldValues);
    console.log(
      handleDefaultFields()
    );
    console.log(formValues);
    setFieldsValue(
      fieldValues ?
        props.defaultFields ?
          {
            ...(
              valuesExtended &&
              handleDefaultValues(valuesExtended)
            )
            ,
            ...fieldValues,
            ...handleDefaultFields()
          }
          :
          fieldValues
        :
        props.defaultFields ?
          {
            ...(
              valuesExtended &&
              handleDefaultValues(valuesExtended)
            )
            ,
            ...formValues,
            ...handleDefaultFields()
          }
          :
          formValues
    );
    validateFieldsAndScroll((errors, values) =>
      errors ?
        (
          console.log(errors),
          receiveFormValues(values)
        )
        :
        (
          receiveFormValues(values),
          console.log(
            (
              props.submitHandler.submitStep &&
              localStep == props.submitHandler.submitStep
            )
            ||
            (
              ++localStep == handleFormStep() &&
              (
                props.submitHandler.submitStep ?
                  localStep > props.submitHandler.submitStep
                  :
                  localStep
              )
            )
          ),
          props.submitHandler ?
            (
              (
                props.submitHandler.submitStep &&
                localStep == props.submitHandler.submitStep
              )
              ||
              (
                ++localStep == handleFormStep() &&
                (
                  props.submitHandler.submitStep ?
                    localStep > props.submitHandler.submitStep
                    :
                    localStep
                )
              )
            ) &&
            (
              (
                props.submitHandler.submitStep &&
                localStep == props.submitHandler.submitStep
              ) &&
              resetData(
                {
                  staticData: {}
                }
              ),
              props.navigation &&
              props.navigation.hrefFormHeaderClick &&
              navigate(props.navigation.hrefFormHeaderClick),
              fieldValues ?
                (
                  formValues &&
                  formValues[localConfig.entity.user.useStateWithLocalStorageKey]
                ) ?
                  props.handleSubmit(
                    props.submitHandler,
                    {
                      ...fieldValues,
                      ...formValues,
                      ...values
                      ,
                      ...{
                        [localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                        :
                        props.subject &&
                        props.datatransfomation &&
                        handleDatatransformation([props.subject]).slice().shift()[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                      }
                    }
                  )
                  :
                  (
                    // console.log(values),
                    props.handleSubmit(
                      props.submitHandler,
                      {
                        ...fieldValues,
                        ...formValues,
                        ...values,
                        ...{
                          [localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty]
                          :
                          (
                            handleMeta() &&
                            handleMeta()[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty]
                          ) ?
                            handleMeta()[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty]
                            :
                            handleFormTitle().split(localConfig.text.activityResultSeparatorText).at(localStep / localStep)
                        }
                        ,
                        ...{
                          [localConfig.entity.user.useStateWithLocalStorageKey]
                          :
                          props.staticData ?
                            props.staticData[localConfig.entity.user.useStateWithLocalStorageKey]
                            :
                            localConfig.entity.user.useStateWithLocalStorageKey
                        }
                        ,
                        ...{
                          [localConfig.defaultThirdPartyThirdPartySecondaryField]
                          :
                          props.staticData &&
                          props.staticData[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
                            [
                              props.staticData[localConfig.defaultThirdPartyThirdPartySecondaryField],
                              localConfig.defaultThirdPartyParameters[localConfig.defaultThirdPartyThirdPartySecondaryField],
                              ...handleDefaultValuesAlternate().map(
                                element =>
                                  element[localConfig.text.innerWidgetInnerWidgetFormValueKey]
                              )
                            ]
                            :
                            localConfig.defaultThirdPartyThirdPartySecondaryField
                        }
                        ,
                        ...{
                          [localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                          :
                          props.subject &&
                          props.datatransfomation &&
                          handleDatatransformation([props.subject]).slice().shift()[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                        }
                      }
                    ),
                    console.log(values)
                  )
                :
                (
                  formValues &&
                  formValues[localConfig.entity.user.useStateWithLocalStorageKey]
                ) ?
                  props.handleSubmit(
                    props.submitHandler,
                    {
                      ...formValues,
                      ...values
                      ,
                      ...{
                        [localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                        :
                        props.subject &&
                        props.datatransfomation &&
                        handleDatatransformation([props.subject]).slice().shift()[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                      }
                    }
                  )
                  :
                  props.handleSubmit(
                    props.submitHandler,
                    {
                      ...formValues,
                      ...values,
                      ...{
                        [localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty]
                        :
                        (
                          handleMeta() &&
                          handleMeta()[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty]
                        ) ?
                          handleMeta()[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty]
                          :
                          handleFormTitle().split(localConfig.text.activityResultSeparatorText).reverse().slice().shift()
                      }
                      ,
                      ...{
                        [localConfig.entity.user.useStateWithLocalStorageKey]
                        :
                        props.staticdata ?
                          props.staticdata[localConfig.entity.user.useStateWithLocalStorageKey]
                          :
                          localConfig.entity.user.useStateWithLocalStorageKey
                      }
                      ,
                      ...{
                        [localConfig.defaultThirdPartyThirdPartySecondaryField]
                        :
                        props.staticData &&
                        props.staticData[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
                          [
                            props.staticData[localConfig.defaultThirdPartyThirdPartySecondaryField],
                            localConfig.defaultThirdPartyParameters[localConfig.defaultThirdPartyThirdPartySecondaryField],
                            ...handleDefaultValuesAlternate().map(
                              element =>
                                element[localConfig.text.innerWidgetInnerWidgetFormValueKey]
                            )
                          ]
                          :
                          localConfig.defaultThirdPartyThirdPartySecondaryField
                      }
                      ,
                      ...{
                        [localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                        :
                        props.subject &&
                        props.datatransfomation &&
                        handleDatatransformation([props.subject]).slice().shift()[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]
                      }
                    }
                  )
            )
            :
            (
              props.handleSubmit(values)
            )
        )
    );
    // props.subject &&
    // props.navigation.hrefFormHeaderClick &&
    // navigate(props.navigation.hrefFormHeaderClick);
  }

  const handleAddonAfterClick = (
    event,
    innerItem,
    localFormFields = handleFormFields()
  ) => {
    event.stopPropagation();
    console.log(formFields);
    console.log(localFormFields);

    setFormFields(
      localFormFields &&
      localFormFields.map(
        item  =>
          item.rows.find(
            element => element.columns.find(
              innerElement => innerElement.select
            )
          ) ?
            Object.assign(
              {},
              ...item,
              {
                [
                  Object.keys(item).find(
                    innerItem => Array.isArray(item[innerItem])
                  )
                ]
                :
                [
                  ...item.rows.slice(
                    item.rows.length / item.rows.length - item.rows.length / item.rows.length,
                    item.rows.length - item.rows.length / item.rows.length - item.rows.length / item.rows.length
                  )
                  ,
                  item.rows[item.rows.length - item.rows.length / item.rows.length]
                ]
              }
            )
            :
            item
      )
    );
  }


  const handleAddonAfterSpan = () =>
    props.grid ?
      props.grid.gridAddonAfterTreshold ?
        props.grid.gridAddonAfterTreshold
        :
        localConfig.grid.gridAddonAfterTreshold
      :
      localConfig.grid.gridAddonAfterTreshold
    ;

  console.log(handleAddonAfterSpan( ));

  const handleChangeField = (event, innerItem) =>
    onChangeField(
      {[innerItem.field]: event.target.value}
    )

  const handleFormAvatar = (
    localFormTitle =
      props.replacementTitle &&
      props.replacementTitle.find(
        item => item.steps
      ) &&
      props.replacementTitle.find(
        item => item.steps.includes(step)
      )
    ) =>
      localFormTitle ?
        localFormTitle.avatar
        :
        props.avatar
    ;

  const handleFormCoverProgress = () =>
    handleFormCover() ?
      props.cover.progress ?
        props.cover.progress
        :
        !hasCover
      :
      hasCover ?
        !hasCover
        :
        hasCover
    ;

  console.log(handleFormCover( ));
  console.log(handleFormCoverProgress( ));

  const handleFormCoverSubject = () =>
    props.subject &&
    openFormDrawer ?
      handleDatatransformation([props.subject], props.subject).slice().shift()
      :
      props.subject
    ;

  const handleFormFooterAction = action => onAction(action);

  const handleFormHeaderExtraButton = () =>
    hasHeader &&
    props.subject &&
    props.datatransfomation &&
    typeof handleDatatransformation([props.subject]).slice().shift()[
      props.datatransfomation.find(
        element => element.extraMarkerField
      ).field
    ] !== `undefined`
  ;

  const handleFormHeaderClick = () =>
  {
    openFormDrawer &&
    openFormDrawer();
    props.navigation &&
    (
      (
        hasCover &&
        handleSubject()
      ) ?
        props.navigation.hrefFormHeaderClick &&
        navigate(props.navigation.hrefFormHeaderClick)
        :
        props.navigation.hrefFormHeaderClickAlternate &&
        navigate(props.navigation.hrefFormHeaderClickAlternate)
    )
    ;
    // onChangeStep(step / step);
  }

  const handleFormJustify = (
    localFormTitle =
      props.replacementTitle &&
      props.replacementTitle.find(
        item => item.steps
      ) &&
      props.replacementTitle.find(
        item => item.steps.includes(step)
      )
    ) =>
      localFormTitle &&
      localFormTitle.justify
    ;

  const handleFormJustifyAlternate = () =>
    props.justify
  ;

  const handleFormMenuClick = () =>
  {
  }

  const handleFormMenuSpan = () =>
    localConfig.grid.gridSpan -
    (
      handleFormMenu() ?
        props.grid ?
          props.grid.gridSpan ?
            props.grid.gridSpan
            :
            localConfig.grid.gridSpan
          :
          localConfig.grid.gridSpan
        :
        localConfig.grid.gridSpan
    );


  const handleHiddenContent = () =>
    props.formFields.filter(
      item =>
        Number.isInteger(props.innerStep) ?
          item.step === props.innerStep
          :
          item.step ?
            item.step === step
            :
            item
    ).slice().shift()
  ;

  const handleHiddenInnerInnerItemSpan = (
    innerItem,
    innerInnerItemHidden = innerItem.columns.find(
      element => element.hidden
    )
  ) =>
    (
      innerInnerItemHidden &&
      handleFormMenu()
    ) ?
      Array.isArray(innerInnerItemHidden) ?
        innerItem.columns.length - innerInnerItemHidden.length
        :
        innerItem.columns.length - innerItem.columns.length / innerItem.columns.length
      :
      innerItem.columns.length
  ;

  const handleImageDraggerFieldSubject = () =>
    props.subject &&
    Object.keys(props.subject).length &&
    handleDatatransformation([props.subject]).slice().shift()[
      props.datatransfomation.find(
        element => element.markerField
      ).field
    ] &&
    handleDatatransformation([props.subject]).slice().shift()
    ;

  const handleInnerInnerItemSpan =
    (
      innerItem,
      innerInnerIndex,
      innerInnerItem,
      innerInnerItemFilterHiddenSpan = handleHiddenInnerInnerItemSpan(innerItem),
      innerInnerItemGridSpan = (
        innerInnerItem.grid ?
          innerInnerItem.grid.gridSpan ?
            innerInnerItem.grid.gridSpan
            :
            innerItem.addonAfter ?
              innerItem.addonBefore ?
                (localConfig.grid.gridSpan - handleAddonAfterSpan() - localConfig.grid.gridAddonBeforeTreshold) / innerInnerItemFilterHiddenSpan
                :
                (localConfig.grid.gridSpan - handleAddonAfterSpan() - localConfig.grid.gridSpanTreshold) / innerInnerItemFilterHiddenSpan
              :
              (localConfig.grid.gridSpan - localConfig.grid.gridAddonBeforeTreshold) / innerInnerItemFilterHiddenSpan
          :
          innerItem.addonAfter ?
            innerItem.addonBefore ?
              (localConfig.grid.gridSpan - handleAddonAfterSpan() - localConfig.grid.gridAddonBeforeTreshold) / innerInnerItemFilterHiddenSpan
              :
              (localConfig.grid.gridSpan - handleAddonAfterSpan() - localConfig.grid.gridSpanTreshold) / innerInnerItemFilterHiddenSpan
            :
            (localConfig.grid.gridSpan - localConfig.grid.gridAddonBeforeTreshold) / innerInnerItemFilterHiddenSpan
      )
    ) =>
      innerItem.addonBefore || innerItem.addonAfter || innerInnerItem.grid ?
        Number.isInteger(innerInnerItemGridSpan) ?
          innerInnerItemGridSpan
          :
            (innerInnerItemFilterHiddenSpan - innerInnerIndex) == innerInnerItemFilterHiddenSpan ?
            Math.ceil(innerInnerItemGridSpan)
            :
            Math.floor(innerInnerItemGridSpan)
        :
        localConfig.grid.gridSpan / innerInnerItemFilterHiddenSpan
    ;

  const handleInnerInnerItemSubmitHandler = (
    event,
    action,
    payload
  ) => {
    console.log(action)
    console.log(formField)
    event.stopPropagation();
    (
      action.payload ||
      (
        payload &&
        action.type
      )
    ) ?
      handleFormFooterAction(
        {
          ...action,
          payload:
          (
            action.payload &&
            Math.abs(action.payload.indexOf(localConfig.text.topLeftSlugSeparatorText)) ==
              action.payload.indexOf(localConfig.text.topLeftSlugSeparatorText)
          ) ?
            props.innerPages.filter(
              item =>
                item.node.title ===
                  action.payload.split(
                    localConfig.text.topLeftSlugSeparatorText
                  ).slice().shift()
            ).length ?
              [
                props.innerPages.filter(
                  item =>
                    item.node.title ===
                      action.payload.split(
                        localConfig.text.topLeftSlugSeparatorText
                      ).slice().shift()
                ).slice().shift().node
                ,
                formField ?
                  (
                    Math.abs(action.payload.indexOf(formField)) ==
                      action.payload.indexOf(formField)
                    &&
                    Math.abs(formField.indexOf(localConfig.text.alternateActivityResultThirdPartySeparatorText)) ==
                      formField.indexOf(localConfig.text.alternateActivityResultThirdPartySeparatorText)
                  ) ?
                    handleFormField()
                    :
                    action.payload.split(
                      localConfig.text.topLeftSlugSeparatorText
                    ).reverse().slice().shift()
                  :
                  action.payload.split(
                    localConfig.text.topLeftSlugSeparatorText
                  ).reverse().slice().shift()
              ]
              :
              payload
            :
            props.innerPages.filter(
              item => item.node.title === action.payload
            ).length ?
              props.innerPages.filter(
                item => item.node.title === action.payload
              ).slice().shift().node
              :
              payload

        }
      )
      :
      handleChangeStep(action.payload, action.payload, action.payload, action.skipStep)
    ;
  }

  const handleInstruction = () =>
    props.instruction &&
    props.instruction.filter(
      item => item.step === step
    ).slice().shift()
  ;

  const handleItemSpan = () =>
    handleFormMenu() ?
      props.grid ?
        props.grid.gridSpan ?
          props.grid.gridSpan
          :
          localConfig.grid.gridSpan
        :
        localConfig.grid.gridSpan
      :
      localConfig.grid.gridSpan
    ;

  const handleItemValue = (item) =>
    (
      props.subject &&
      handleSubject()
    ) ?
      (
        console.log(item),
        props.subject[item] ?
          props.subject[item]
          :
          handleDatatransformation([props.subject]).slice().shift()[item]
      )
      :
      (
        fieldValues &&
        fieldValues[item]
      )


  const handleSwitchChange = () => {

  }

  return (
    <div>
      <Form
        onSubmit={handleSubmit}
      >
        <Row
          justify="safe"
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            {
              hasHeader &&
              <FormHeader
                avatar={handleFormAvatar()}
                handleFormHeaderClick={handleFormHeaderClick}
                hasBadge={hasBadge}
                hasBaseButton={hasBaseButton}
                hasButton={hasButton}
                hasExtraButton={handleFormHeaderExtraButton()}
                innerConfig={localConfig}
                justify={handleFormJustify()}
                justifyAlternate={handleFormJustifyAlternate()}
                text={props.text}
                title={handleFormTitle()}
              />
            }
            {
              handleFormCover() &&
              <FormCover
                carouselContentLevel={carouselContentLevel}
                cover={props.cover}
                defaultFields={props.defaultFields}
                fieldValues={fieldValues}
                handleChangeStep={handleChangeStep}
                handleFormCoverAction={handleFormFooterAction}
                handleHiddenContent={handleHiddenContent}
                innerConfig={localConfig}
                navigate={navigate}
                onChangeStep={onChangeStep}
                openFormDrawer={openFormDrawer}
                step={step}
                subject={handleFormCoverSubject()}
                subjectAlternate={handleSubjectAlternate()}
              />
            }
            <Row
              justify="unsafe"
              type="flex"
            >
              {
                !handleFormCoverProgress() &&
                handleFormMenu() &&
                <Col
                  lg={
                    {
                      push: handleItemSpan(),
                      span: handleFormMenuSpan()
                    }
                  }
                  xs={localConfig.grid.gridSpan}
                >
                  {
                    handlePreview() ?
                      <FormPreview
                        externalStaticData={props.externalStaticData}
                        fieldValues={fieldValues}
                        handleFormMenuClick={handleFormMenuClick}
                        innerConfig={localConfig}
                        preview={props.preview}
                        staticData={props.staticData}
                        step={step}
                        subject={fieldValues}

                      />
                      :
                        handleInstruction() ?
                          <FormInstruction
                            innerConfig={localConfig}
                            instruction={handleInstruction()}
                            subject={fieldValues}
                          />
                          :
                          <FormMenu
                            handleFormMenuClick={handleFormMenuClick}
                            innerConfig={localConfig}
                            menu={props.menu}
                          />
                  }
                </Col>
              }
              <Col
                lg={
                  {
                    pull: handleFormMenuSpan(),
                    span: handleItemSpan()
                  }
                }
                xs={localConfig.grid.gridSpan}
              >
                <ScrollableContainer
                  innerConfig={localConfig}
                >
                  {
                    props.mockup &&
                    <Mockup
                      colorPalette={props.mockup.colorPalette}
                      innerConfig={localConfig}
                      subject={props.subject}
                    />
                  }
                  {
                    props.formFields &&
                    !handleFormCoverProgress() &&
                    Array.isArray(handleFormFields()) &&
                    handleFormFields().map(
                      item =>
                        [
                          <Row
                            justify="inherit"
                            type="flex"
                          >
                            <Col>
                              {item.title}
                            </Col>
                          </Row>
                          ,
                          <Row
                            justify="initial"
                            type="flex"
                          >
                            {
                              !item.hidden &&
                              item.rows.map(
                                (innerItem, innerIndex) =>
                                  <Col
                                    span={localConfig.grid.gridSpan}
                                  >
                                    <Row
                                      justify="start"
                                      type="flex"
                                    >
                                      {
                                        innerItem.addonBefore &&
                                        <Col
                                          span={localConfig.grid.gridAddonBeforeTreshold}
                                        >
                                          <Avatar
                                            src={innerItem.addonBefore.avatar}
                                          />
                                        </Col>
                                      }
                                      {
                                        innerItem.columns.map(
                                          (innerInnerItem, innerInnerIndex) =>
                                            <Col
                                              lg={handleInnerInnerItemSpan(innerItem, innerInnerIndex, innerInnerItem)}
                                              xs={localConfig.grid.gridSpan}
                                            >
                                              {
                                                (
                                                  innerInnerItem.subject ?
                                                    props.subject
                                                    :
                                                    innerInnerItem
                                                ) &&
                                                handleFormFieldItem(innerInnerItem) &&
                                                <FormItem
                                                  hasFeedback
                                                  label={innerInnerItem.label}
                                                >
                                                  {
                                                    getFieldDecorator(innerInnerItem.field,
                                                      {
                                                        initialValue: handleItemValue(innerInnerItem.field),
                                                        rules: [
                                                          // {
                                                          //   required: !innerInnerItem.helper,
                                                          // },
                                                        ]
                                                      }
                                                    )
                                                    (
                                                      innerInnerItem.select ?
                                                        <SelectField
                                                          externalStaticData={props.externalStaticData}
                                                          extraField={innerInnerItem.extraField}
                                                          field={innerInnerItem.field}
                                                          fieldValues={fieldValues}
                                                          innerConfig={localConfig}
                                                          innerExternalStaticData={innerInnerItem.externalStaticData}
                                                          internalStaticData={innerInnerItem.internalStaticData}
                                                          onChangeField={onChangeField}
                                                          options={innerInnerItem.select.options}
                                                          secondaryInternalStaticData={innerInnerItem.secondaryInternalStaticData}
                                                          secondaryStaticData={props.secondaryStaticData}
                                                          staticData={props.staticData}
                                                        />
                                                        :
                                                        innerInnerItem.date ?
                                                          <DatePickerField
                                                            field={innerInnerItem.field}
                                                            innerConfig={localConfig}
                                                            onChangeField={onChangeField}
                                                          />
                                                          :
                                                          innerInnerItem.autocomplete ?
                                                            <AutoCompleteField
                                                              externalStaticData={props.externalStaticData}
                                                              extraField={innerInnerItem.extraField}
                                                              field={innerInnerItem.field}
                                                              fieldValues={fieldValues}
                                                              innerExternalStaticData={innerInnerItem.externalStaticData}
                                                              onChangeField={onChangeField}
                                                              options={innerInnerItem.autocomplete.options}
                                                              placeholder={innerInnerItem.autocomplete.placeholder}
                                                            />
                                                            :
                                                            innerInnerItem.customCompositeField ?
                                                              <CustomCompositeField
                                                                defaultFields={props.defaultFields}
                                                                fieldValues={fieldValues}
                                                                handleDefaultFields={handleDefaultFields}
                                                                handleInnerInnerItemSubmitHandler={handleInnerInnerItemSubmitHandler}
                                                                innerConfig={localConfig}
                                                                onChangeField={onChangeField}
                                                                staticData={props.staticData}
                                                                subject={
                                                                  props.subject ?
                                                                    props.subject
                                                                    :
                                                                    fieldValues
                                                                }
                                                              />
                                                              :
                                                              innerInnerItem.checkbox ?
                                                                innerInnerItem.checkbox.label &&
                                                                <Checkbox>
                                                                  {innerInnerItem.checkbox.label}
                                                                </Checkbox>
                                                                :
                                                                innerInnerItem.helper ?
                                                                  <Helper
                                                                    avatar={innerInnerItem.helper.avatar}
                                                                    grid={innerInnerItem.helper.grid}
                                                                    innerConfig={localConfig}
                                                                    text={innerInnerItem.helper.text}
                                                                    title={innerInnerItem.helper.title}
                                                                  />
                                                                  :
                                                                  innerInnerItem.image ?
                                                                    innerInnerItem.drawer ?
                                                                      (
                                                                        innerInnerItem.hasAvatar
                                                                      ) ?
                                                                        <ImageDraggerField
                                                                          button={innerInnerItem.button}
                                                                          buttonLabel={innerInnerItem.buttonLabel}
                                                                          display={innerItem.display}
                                                                          drawer={innerInnerItem.drawer}
                                                                          extraButtonLabel={innerInnerItem.button.label}
                                                                          field={innerInnerItem.field}
                                                                          fieldValues={fieldValues}
                                                                          formField={handleFormField()}
                                                                          formValues={formValues}
                                                                          handleInnerInnerItemSubmitHandler={handleInnerInnerItemSubmitHandler}
                                                                          handleSubject={handleSubject}
                                                                          hasAvatar={innerInnerItem.hasAvatar}
                                                                          icon={
                                                                            handleItemValue(innerInnerItem.field) ?
                                                                              handleItemValue(innerInnerItem.field)
                                                                              :
                                                                              innerInnerItem.icon
                                                                          }
                                                                          innerConfig={localConfig}
                                                                          justify={innerInnerItem.justify}
                                                                          onChangeField={onChangeField}
                                                                          receiveFormValues={receiveFormValues}
                                                                          staticData={props.staticData}
                                                                          subject={handleImageDraggerFieldSubject()}
                                                                        />
                                                                        :
                                                                        <FormButton
                                                                          action={event => handleInnerInnerItemSubmitHandler(event, innerInnerItem.button.action, innerInnerItem.field)}
                                                                          addonAfter={innerInnerItem.button.addonAfter}
                                                                          addonBefore={innerInnerItem.button.addonBefore}
                                                                          title={innerInnerItem.button.label}
                                                                        >
                                                                        </FormButton>
                                                                      :
                                                                      innerInnerItem.buttonLabel ?
                                                                        <ImageDraggerField
                                                                          buttonLabel={innerInnerItem.buttonLabel}
                                                                          display={innerItem.display}
                                                                          extraButtonLabel={innerInnerItem.extraButtonLabel}
                                                                          field={innerInnerItem.field}
                                                                          formField={handleFormField()}
                                                                          formValues={formValues}
                                                                          icon={
                                                                            handleItemValue(innerInnerItem.field) ?
                                                                              handleItemValue(innerInnerItem.field)
                                                                              :
                                                                              innerInnerItem.icon
                                                                          }
                                                                          innerConfig={localConfig}
                                                                          onChangeField={onChangeField}
                                                                          receiveFormValues={receiveFormValues}
                                                                        />
                                                                        :
                                                                        innerInnerItem.icon ?
                                                                          <ImageDraggerField
                                                                            icon={innerInnerItem.icon}
                                                                            innerConfig={localConfig}
                                                                          />
                                                                          :
                                                                          <ImageDraggerField
                                                                            innerConfig={localConfig}
                                                                          />
                                                                    :
                                                                    innerInnerItem.map ?
                                                                      <MapField
                                                                        innerConfig={localConfig}
                                                                      />
                                                                      :
                                                                        innerInnerItem.phone ?
                                                                          <PhoneNumberInputField
                                                                            extraField={innerInnerItem.extraField}
                                                                            extraFieldKey={innerInnerItem.extraFieldKey}
                                                                            field={innerInnerItem.field}
                                                                            fieldValues={fieldValues}
                                                                            innerConfig={localConfig}
                                                                            onChangeField={onChangeField}
                                                                            phone={innerInnerItem.phone}
                                                                            placeholder={innerInnerItem.defaultValue}
                                                                          />
                                                                        :
                                                                        innerInnerItem.radio ||
                                                                        innerInnerItem.radioButton ?
                                                                          <RadioField
                                                                            field={innerInnerItem.field}
                                                                            innerConfig={localConfig}
                                                                            onChangeField={onChangeField}
                                                                            radio={innerInnerItem.radio}
                                                                            radioButton={innerInnerItem.radioButton}
                                                                          />
                                                                          :
                                                                          innerInnerItem.switch ?
                                                                            <SwitchField
                                                                              field={innerInnerItem.field}
                                                                              innerConfig={localConfig}
                                                                              onChangeField={onChangeField}
                                                                              status={innerInnerItem.status}
                                                                            />
                                                                            :
                                                                            innerInnerItem.tags ?
                                                                              <TagsField
                                                                                button={innerInnerItem.button}
                                                                                field={innerInnerItem.field}
                                                                                formValues={formValues}
                                                                                handleInnerInnerItemSubmitHandler={handleInnerInnerItemSubmitHandler}
                                                                                innerConfig={localConfig}
                                                                                subject={handleSubjectAlternate()}
                                                                                submitHandler={props.submitHandler}
                                                                                tags={innerInnerItem.tags}
                                                                                title={innerInnerItem.title}
                                                                              />
                                                                              :
                                                                              innerInnerItem.textarea ?
                                                                                <TextArea
                                                                                  onChange={event => handleChangeField(event, innerInnerItem)}
                                                                                  placeholder={innerInnerItem.defaultValue}
                                                                                />
                                                                                :
                                                                                innerInnerItem.time ?
                                                                                  <TimePicker />
                                                                                  :
                                                                                  innerInnerItem.tree ?
                                                                                    <FormTree
                                                                                      data={data}
                                                                                      dataField={innerInnerItem.field}
                                                                                      defaultExpandAll={innerInnerItem.tree.defaultExpandAll}
                                                                                      innerConfig={localConfig}
                                                                                      method={props.method}
                                                                                      receiveData={receiveData}
                                                                                      service={props.services[props.service]}
                                                                                      subject={props.subject}
                                                                                    />
                                                                                    :
                                                                                    innerInnerItem.dynamic ?
                                                                                      <FormButton
                                                                                        action={event => handleFormFieldsDynamic(event)}
                                                                                        addonAfter={innerInnerItem.button.addonAfter}
                                                                                        addonBefore={innerInnerItem.button.addonBefore}
                                                                                        title={innerInnerItem.button.label}
                                                                                      >
                                                                                      </FormButton>
                                                                                      :
                                                                                      innerInnerItem.addonBefore ?
                                                                                        <Input
                                                                                          addonBefore={
                                                                                            innerInnerItem.addonBefore.avatar ?
                                                                                              <Avatar
                                                                                                src={innerInnerItem.addonBefore.avatar}
                                                                                              />
                                                                                              :
                                                                                              innerInnerItem.addonBefore
                                                                                          }
                                                                                          onChange={event => handleChangeField(event, innerInnerItem)}
                                                                                          onPressEnter={event => handleChangeStep(event, innerInnerItem.defaultValue)}
                                                                                          placeholder={innerInnerItem.defaultValue}
                                                                                          type={innerInnerItem.type}
                                                                                        />
                                                                                        :
                                                                                        innerInnerItem.addonAfter ?
                                                                                          <Input
                                                                                            addonAfter={innerInnerItem.addonAfter}
                                                                                            onChange={event => handleChangeField(event, innerInnerItem)}
                                                                                            onPressEnter={event => handleChangeStep(event, innerInnerItem.defaultValue)}
                                                                                            placeholder={innerInnerItem.defaultValue}
                                                                                            type={innerInnerItem.type}
                                                                                          />
                                                                                          :
                                                                                          <Input
                                                                                            onChange={event => handleChangeField(event, innerInnerItem)}
                                                                                            onPressEnter={event => handleChangeStep(event, innerInnerItem.defaultValue)}
                                                                                            placeholder={innerInnerItem.defaultValue}
                                                                                            type={innerInnerItem.type}
                                                                                          />
                                                    )
                                                  }
                                                </FormItem>
                                              }
                                              {
                                                (
                                                  innerInnerItem.subject ?
                                                    props.subject
                                                    :
                                                    innerInnerItem
                                                ) &&
                                                innerInnerItem.button &&
                                                innerInnerItem.button.justify &&
                                                <Row
                                                  justify={innerInnerItem.button.justify}
                                                  type="flex"
                                                >
                                                  <Col>
                                                    <FormButton
                                                      action={event => handleInnerInnerItemSubmitHandler(event, innerInnerItem.button.action, innerInnerItem.field)}
                                                      addonAfter={innerInnerItem.button.addonAfter}
                                                      addonBefore={innerInnerItem.button.addonBefore}
                                                      title={innerInnerItem.button.label}
                                                    />
                                                  </Col>
                                                </Row>
                                              }
                                            </Col>
                                        )
                                      }
                                      {
                                        innerItem.addonAfter &&
                                        <Col
                                          lg={handleAddonAfterSpan()}
                                          xs={localConfig.grid.gridSpan}
                                        >
                                          {
                                            innerItem.addonAfter.avatar &&
                                              <Avatar
                                                onMouseDown={event => handleAddonAfterClick(event, innerItem)}
                                                src={innerItem.addonAfter.avatar}
                                              />
                                          }
                                          {
                                            innerItem.addonAfter.button &&
                                              <FormButton
                                                action={event => handleInnerInnerItemSubmitHandler(event, innerItem.addonAfter.button.action)}
                                                addonBefore={innerItem.addonAfter.button.addonBefore}
                                                title={innerItem.addonAfter.button.label}
                                              />
                                          }
                                        </Col>
                                      }
                                    </Row>
                                  </Col>
                              )
                            }
                          </Row>
                        ]
                    )
                  }
                </ScrollableContainer>
              </Col>
            </Row>
            {
              hasAlternateCover &&
              <FormCover
                alternateCover
                cover={props.cover}
                handleChangeStep={handleChangeStep}
                handleFormCoverAction={handleFormFooterAction}
                handleHiddenContent={handleHiddenContent}
                innerConfig={localConfig}
                step={step}
                subject={props.subject}
              />
            }
          </Col>
        </Row>
        {
          hasFooter &&
          <FormFooter
            actions={props.actions}
            fieldValues={fieldValues}
            formField={formField}
            formValues={formValues}
            handleChangeStep={handleChangeStep}
            handleFormFooterAction={handleFormFooterAction}
            handleFormStep={handleFormStep}
            handleFormStepValidate={handleFormStepValidate}
            handleSubject={handleSubject}
            handleSubmit={props.handleSubmit}
            innerConfig={localConfig}
            innerPages={props.innerPages}
            navigate={navigate}
            onChangeField={onChangeField}
            onChangeStep={onChangeStep}
            openFormDrawer={openFormDrawer}
            resetData={resetData}
            staticData={props.staticData}
            step={step}
            subject={handleFormCoverSubject()}
            subjectAlternate={handleSubjectAlternate()}
            submitLabel={props.submitLabel}
            text={props.text}
          />
        }
      </Form>
    </div>
  )
}

const mapDispatchToProps = dispatch => (
  {
    onAction: (action) =>  dispatch(action)
    ,
    onChangeField: (fieldValues) => dispatch (
      {
        payload: fieldValues,
        type: `onChangeField`
      }
    )
    ,
    onChangeStep: (step) => dispatch (
      {
        payload: step,
        type: `onChangeStep`
      }
    )
    ,
    receiveData: (payload) => dispatch (
      {
        payload: payload,
        type: `receiveData`
      }
    )
    ,
    receiveFormValues: (formValues) => dispatch (
      {
        payload: formValues,
        type: `receiveFormValues`
      }
    )
    ,
    resetData: (item) => dispatch (
      {
        payload: item,
        type: `resetData`
      }
    )
    ,
    showAlternateFormItem: () => dispatch (
      {
        type: `showAlternateFormItem`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    alternateFormItemShown:
     state.alternateFormItemShown ?
       state.alternateFormItemShown
       :
       state.vowtchSettings.alternateFormItemShown
    ,
    carouselContentLevel: state.carouselContentLevel,
    data:
     state.data ?
       state.data
       :
       state.vowtchSettings.data
    ,
    fieldValues: state.fieldValues,
    formField: state.formField,
    formValues: state.formValues,
    step: state.step,
  }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )
  (
    Form.create()(GenericForm)
  )
