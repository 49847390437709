/* eslint-disable no-unused-expressions */
import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Col,
  Progress,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import InformationCard from './InformationCard';

import config from '../utils/config';


const GenericCarouselControl = (
  {
    carouselAutoplay,
    carouselContentLevel,
    handleCarouselProgressAvatarImage,
    handleCarouselProgressAvatarPercent,
    handleCarouselProgressPlayer,
    handleCarouselText,
    handleSubject,
    next,
    openDrawerSixthPartyMenu,
    openTimelineDrawer,
    openTimelineDrawerFourthParty,
    openTimelineDrawerThirdParty,
    pause,
    previous,
    staticData,
    subject,
    timelineDrawerOpened,
    timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleTimelineDrawer = () => {
    !timelineDrawerOpened &&
    openTimelineDrawer();
    timelineDrawerOpenedFourthParty &&
    openTimelineDrawerFourthParty();
    !timelineDrawerOpenedThirdParty &&
    openTimelineDrawerThirdParty();
  }

  return (
    handleCarouselProgressPlayer() &&
    // !timelineDrawerOpened &&
    (
      carouselContentLevel ?
        <Row
          className="trigger"
          justify="right right"
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            <Row
              justify="space-between"
              type="flex"
            >
              <Col
              >
                {
                  handleCarouselText()
                }
              </Col>
              <Col
              >
                <Progress
                  format={
                    percent =>
                    <Avatar
                      onMouseDown={event =>  pause(event)}
                      src={handleCarouselProgressAvatarImage()}
                    />
                  }
                  percent={handleCarouselProgressAvatarPercent()}
                  type="circle"
                  width={localConfig.carousel.progressWidth}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        :
        <Row
          className="trigger"
          justify="right right"
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            <Row
              justify="space-between"
              type="flex"
            >
              <Col
              >
                {
                  staticData &&
                  <InformationCard
                    subject={handleSubject()}
                  />
                }
              </Col>
              <Col
              >
                <Avatar
                  onMouseDown={openDrawerSixthPartyMenu}
                />
                {
                  carouselAutoplay ?
                    [
                      <Avatar
                        onMouseDown={event =>  pause(event)}
                      />
                      ,
                      <Avatar
                        onMouseDown={event =>  previous(event)}
                      />
                      ,
                      <Button>
                        <Row
                          justify="left"
                          type="flex"
                        >
                          <Col
                            onMouseDown={handleTimelineDrawer}
                          >
                            {handleCarouselText()}
                          </Col>
                        </Row>
                      </Button>
                      ,
                      <Avatar
                        onMouseDown={event =>  next(event)}
                      />
                    ]
                    :
                    [
                      <Button>
                        <Row
                          justify="left"
                          type="flex"
                        >
                          <Col
                            onMouseDown={handleTimelineDrawer}
                          >
                            {handleCarouselText()}
                          </Col>
                        </Row>
                      </Button>
                      ,
                      <Progress
                        format={
                          percent =>
                          <Avatar
                            onMouseDown={event =>  pause(event)}
                            src={handleCarouselProgressAvatarImage()}
                          />
                        }
                        percent={handleCarouselProgressAvatarPercent()}
                        type="circle"
                        width={localConfig.carousel.progressWidth}
                      />
                    ]
                }
              </Col>
            </Row>
          </Col>
        </Row>
    )
  );
}

export default GenericCarouselControl;
