import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './FormMenu.less';

const { Link } = Anchor;

const FormMenu = (
  props
) => {

  const {
    handleFormMenuClick,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="center"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <QueueAnim
          component={Menu}
          componentProps={{
            defaultOpenKeys: [],
            defaultSelectedKeys: [],
            mode: "inline",
            onClick: handleFormMenuClick
          }}
          delay={localConfig.rcQueueAnim.delay}
          duration={localConfig.rcQueueAnim.duration}
          interval={localConfig.rcQueueAnim.interval}
          leaveReverse
          type={['right', 'left']}
        >
        {
            props.menu.items &&
            props.menu.items.map(
              (item, index) =>
                <Menu.Item>
                  <Anchor>
                    <Link
                      title={item.title}
                    >
                    </Link>
                  </Anchor>
                </Menu.Item>
            )
        }
        </QueueAnim>
      </Col>
    </Row>
  );
}

export default FormMenu;
