import React, { useEffect, useState } from 'react';

import {
  guestStorage
} from '../vowtch-cross-domain/cross-domain';


const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState(
    localStorage.getItem(localStorageKey) || localStorageKey
  );
  useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value]);
  return [value, setValue];
};

export {
  useStateWithLocalStorage
};
