import React from 'react';

import { connect } from "react-redux";

import './ComercialCard.less';

import {
  Card
} from 'antd';

const { Meta } = Card;

const ComercialCard = (
  {
    ...props
  }
) => {
  return (
    <Card
      hoverable
      cover={
        <img
          src={props.subject.avatar}
        />
      }
    >
      <Meta
        description={props.subject.description}
        title={props.subject.title}
      />
    </Card>
  );
}

export default ComercialCard;
