/* eslint-disable no-unused-expressions */

import React from 'react';

import { connect } from "react-redux";

import Drawer from 'react-motion-drawer';

import QueueAnim from 'rc-queue-anim';

import TagMenu from './TagMenu';

import config from '../utils/config';

const TagDrawer = (
  {
    alternateCardInnerWidgetShown,
    data,
    formField,
    formValues,
    onChangeField,
    onChangeSubject,
    onChangeTags,
    onChangeTagDrawer,
    onChangeTagMenu,
    openTagDrawer,
    receiveFormValues,
    resetData,
    secondaryStaticData,
    staticData,
    showAlternateCardInnerWidget,
    showTagMenu,
    subject,
    tagDrawerOpened,
    tagMenuShown,
    thirdPartyStaticData,
    ...props
  }
) => {

  const {
    hasDivider,
    hasDrawer,
    hasFooter,
    hasTitle,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleActionClick = (
    event,
    item,
    innerItem,
    innerInnerItem =
      formField ?
        formField
        :
        localConfig.text.innerWidgetInnerWidgetFormValueKey
  ) => {
    event.stopPropagation();
    event.preventDefault();
    console.log(item);
    console.log(innerItem);
    item &&
    (
      innerItem ?
        (
          onChangeTags(item)
        )
        :
        item.parent &&
        (
          receiveFormValues(
            {
              [innerInnerItem]
              :
              formValues ?
                formValues[innerInnerItem] ?
                  Array.isArray(formValues[innerInnerItem]) ?
                    [...formValues[innerInnerItem], item.title]
                    :
                    [formValues[innerInnerItem], item.title]
                  :
                  [item.title]
                :
                [item.title]
            }
          )
          ,
          onChangeField(
            {
              [innerInnerItem]
              :
              formValues ?
                formValues[innerInnerItem] ?
                  Array.isArray(formValues[innerInnerItem]) ?
                    [...formValues[innerInnerItem], item.title]
                    :
                    [formValues[innerInnerItem], item.title]
                  :
                  [item.title]
                :
                [item.title]
            }
          )
          ,
          data.length &&
          !alternateCardInnerWidgetShown &&
          showAlternateCardInnerWidget()
          ,
          openTagDrawer(),
          resetData(secondaryStaticData),
          resetData(thirdPartyStaticData)
        )
    );
  }

  const handleTagMenuInnerItemData = (item, innerItem, innerInnerItem) =>
    innerInnerItem ?
      innerInnerItem[localConfig.defaultThirdPartySecondaryField] == innerItem[localConfig.defaultThirdPartySecondaryField]
      :
      innerItem ?
        innerItem.replace(
          localConfig.regularExpressions.regularExpressionsTagsField,
          localConfig.text.activityResultSeparatorText
        ).split(
          localConfig.text.activityResultSeparatorText
        ).slice().shift() == item[localConfig.defaultThirdPartySecondaryField].toLowerCase().replace(
          localConfig.text.activityResultSeparatorText,
          localConfig.text.activityResultThirdPartySeparatorText
        )
        :
        localConfig.tagsonomy.basetagsonomy[localConfig.defaultThirdPartyField].slice().shift().items.filter(
          innerInnerInnerItem => innerInnerInnerItem[localConfig.defaultThirdPartySecondaryField] == item[localConfig.defaultThirdPartySecondaryField]
        ).slice().shift()

  const handleThirdPartyStaticData = () =>
    thirdPartyStaticData &&
    thirdPartyStaticData.length ?
      thirdPartyStaticData.slice().shift()[localConfig.defaultThirdPartyField] ?
        [localConfig.tagsonomy.basetagsonomy, ...thirdPartyStaticData].map(
          (item, index) =>
            thirdPartyStaticData.length - index == thirdPartyStaticData.length ?
              thirdPartyStaticData.filter(
                innerItem => handleTagMenuInnerItemData(innerItem)
              ).slice().shift() ?
                Object.assign(
                  {},
                  item,
                  {
                    [localConfig.defaultThirdPartyField]
                    :
                    [
                      Object.assign(
                        {},
                        ...item[localConfig.defaultThirdPartyField].slice().shift()
                        ,
                        {
                          items:
                            item[localConfig.defaultThirdPartyField].slice().shift().items.map(
                              innerItem =>
                                thirdPartyStaticData.filter(
                                  innerInnerItem => handleTagMenuInnerItemData(item, innerItem, innerInnerItem)
                                ).slice().shift() ?
                                  thirdPartyStaticData.filter(
                                    innerInnerItem => handleTagMenuInnerItemData(item, innerItem, innerInnerItem)
                                  ).slice().shift()[
                                    localConfig.defaultThirdPartyField
                                  ].slice().shift()
                                  :
                                  innerItem
                            )
                        }
                      )
                    ]
                  }
                )
                :
                localConfig.tagsonomy.basetagsonomy
              :
              item
        )
        :
        localConfig.tagsonomy.basetagsonomy
      :
      localConfig.tagsonomy.basetagsonomy

  const handleTagMenuInnerItem = (
    event,
    localThirdPartyStaticData = handleThirdPartyStaticData()
  ) =>
    thirdPartyStaticData &&
    thirdPartyStaticData.length ?
      thirdPartyStaticData.slice().shift()[localConfig.defaultThirdPartyField] ?
        localThirdPartyStaticData.filter(
          item =>
            formField ?
              handleTagMenuInnerItemData(item, formField)
              :
              item
        ).length &&
        localThirdPartyStaticData.filter(
          item =>
            formField ?
              handleTagMenuInnerItemData(item, formField)
              :
              item
        ).slice().shift()[
          localConfig.defaultThirdPartyField
        ].slice().shift()
        :
        thirdPartyStaticData
      :
      thirdPartyStaticData

  const handleTagMenuClick = (
    item,
    itemIndex = item.item && item.item.props.index,
    localTags = handleTagMenuInnerItem()
  ) => {
    item &&
    console.log(item);
    console.log(localTags);
    console.log(thirdPartyStaticData);
    console.log(secondaryStaticData);
    item.domEvent &&
    item.domEvent.preventDefault();
    localTags && (
      localTags.items ?
        localTags.items[itemIndex] ?
          localTags.items[itemIndex].items ?
            --itemIndex + localConfig.menu.threshold == localConfig.menu.threshold ?
              localTags.items[itemIndex].items &&
              thirdPartyStaticData &&
              thirdPartyStaticData.thirdPartyStaticData.items &&
              onChangeTags(thirdPartyStaticData.thirdPartyStaticData)
              :
              onChangeTags(
                Object.assign(
                  {},
                  ...localTags.items[++itemIndex]
                  ,
                  {
                    thirdPartyStaticData: localTags
                  }
                )
              )
            :
            onChangeTags(secondaryStaticData)
          :
          secondaryStaticData.items ?
            localTags.items.length == secondaryStaticData.items.length ?
              resetData(thirdPartyStaticData)
              :
              onChangeTags(secondaryStaticData)
            :
            onChangeTags(secondaryStaticData)
        :
        onChangeTags(secondaryStaticData)
    );
  };

  const handleOpenDrawer = (item) =>
    item ?
      item.href ?
        (
          resetData(secondaryStaticData),
          resetData(thirdPartyStaticData),
          navigate(item.href)
        )
        :
        item.tagMenuShown ?
          (
            resetData(secondaryStaticData),
            resetData(thirdPartyStaticData),
            showTagMenu(),
            openTagDrawer()
          )
          :
          (
            resetData(secondaryStaticData),
            resetData(thirdPartyStaticData),
            tagMenuShown && showTagMenu(),
            openTagDrawer()
          )
      :
      (
        resetData(secondaryStaticData),
        resetData(thirdPartyStaticData),
        openTagDrawer()
      )
    ;

  console.log(secondaryStaticData);
  console.log(thirdPartyStaticData);

  return (
    <div>
      {
        typeof window !== 'undefined' &&
        staticData.length &&
        (
          hasDrawer ?
            <Drawer
              onChange={open => onChangeTagDrawer(open)}
              open={tagDrawerOpened}
              right
              width={localConfig.drawerWidth}
            >
              {
                tagMenuShown &&
                <TagMenu
                  alternateCardInnerWidgetShown={alternateCardInnerWidgetShown}
                  formValues={formValues}
                  handleActionClick={handleActionClick}
                  handleTagMenuClick={handleTagMenuClick}
                  hasDrawer={hasDrawer}
                  hasTitle={hasTitle}
                  innerConfig={localConfig}
                  onChangeTags={onChangeTags}
                  openTagDrawer={handleOpenDrawer}
                  receiveFormValues={receiveFormValues}
                  showAlternateCardInnerWidget={showAlternateCardInnerWidget}
                  tags={handleTagMenuInnerItem()}
                >
                </TagMenu>
              }
            </Drawer>
            :
            tagMenuShown &&
            <TagMenu
              alternateCardInnerWidgetShown={alternateCardInnerWidgetShown}
              formValues={formValues}
              handleActionClick={handleActionClick}
              handleTagMenuClick={handleTagMenuClick}
              hasDrawer={hasDrawer}
              hasTitle={hasTitle}
              innerConfig={localConfig}
              onChangeTags={onChangeTags}
              openTagDrawer={handleOpenDrawer}
              receiveFormValues={receiveFormValues}
              showAlternateCardInnerWidget={showAlternateCardInnerWidget}
              tags={handleTagMenuInnerItem()}
            >
            </TagMenu>
        )
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeField: (fieldValues) => dispatch (
      {
        payload: fieldValues,
        type: `onChangeField`
      }
    ),
    onChangeSubject: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubject`
      }
    )
    ,
    onChangeTagDrawer: (open) => dispatch (
      {
        payload: open,
        type: `onChangeTagDrawer`
      }
    )
    ,
    onChangeTagMenu: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTagMenu`
      }
    )
    ,
    onChangeTags: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTags`
      }
    )
    ,
    openTagDrawer: () => dispatch (
      {
        type: `openTagDrawer`
      }
    )
    ,
    receiveFormValues: (formValues) => dispatch (
      {
        payload: formValues,
        type: `receiveFormValues`
      }
    )
    ,
    resetData: (item) => dispatch (
      {
        payload: {
          secondaryStaticData: item
        },
        type: `resetData`
      }
    )
    ,
    showAlternateCardInnerWidget: () => dispatch (
      {
        type: `showAlternateCardInnerWidget`
      }
    )
    ,
    showTagMenu: () => dispatch (
      {
        type: `showTagMenu`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    alternateCardInnerWidgetShown: state.alternateCardInnerWidgetShown,
    data: state.data,
    formField: state.formField,
    formValues: state.formValues,
    secondaryStaticData: state.secondaryStaticData,
    staticData: state.staticData,
    subject: state.subject,
    tagDrawerOpened: state.tagDrawerOpened,
    tagMenuShown: state.tagMenuShown,
    thirdPartyStaticData: state.thirdPartyStaticData
  }
);

const ConnectedTagDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TagDrawer);

export default ConnectedTagDrawer;
