import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

import './CardInnerWidgetInlineInformation.less';

const { Link } = Anchor;

const CardInnerWidgetInlineInformation = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="center"
      type="flex"
    >
      <Col
      >
        <Anchor>
          <Link
            title={props.subject[props.item.field]}
          >
          </Link>
        </Anchor>
      </Col>
      <Col
      >
        {props.item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
      </Col>
    </Row>
  );
}

export default CardInnerWidgetInlineInformation;
