import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import CardHeaderWidget from './CardHeaderWidget';

import config from '../utils/config';

import './CardHeader.less';

const CardHeader = (
  {
    handleCardHeaderWidgetClick,
    ...props
  }
) => {

  const {
    alternateCardHeaderWidgetShown,
    innerConfig,
    navigate,
    onChangeSubject
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleCardWidgetsGrid = (item) =>
    item.grid ?
      item.grid.gridSpan ?
        item.grid
        :
        props.grid ?
          props.grid.gridSpan ?
            props.grid
            :
            localConfig.grid
          :
          localConfig.grid
      :
      props.grid ?
        props.grid.gridSpan ?
          props.grid
          :
          localConfig.grid
        :
        localConfig.grid
    ;

  return (
    props.headerWidgets &&
    <Row
      justify="initial"
      type="flex"
    >
      <Col>
        {
          props.headerWidgets ?
            props.headerWidgets.filter(
              item => item.alternateHeaderWidget === alternateCardHeaderWidgetShown ||
                      item.requiredHeaderWidget
            ).map(
              item =>
                <CardHeaderWidget
                  button={item.button}
                  form={item.form}
                  formProps={props.formProps}
                  formValues={props.formValues}
                  grid={handleCardWidgetsGrid(item)}
                  handleCardHeaderWidgetClick={handleCardHeaderWidgetClick}
                  inlineInformation={item.inlineInformation}
                  innerConfig={localConfig}
                  navigate={navigate}
                  onChangeSubject={onChangeSubject}
                  subject={localConfig.entity.user}
                />
            )
            :
            props.title
        }
      </Col>
    </Row>
  );
}

export default CardHeader;
