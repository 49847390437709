import React from 'react';

import {
  Col,
  Row,
  Tag
} from 'antd';

import { connect } from "react-redux";

import FormButton from './FormButton';

import config from '../utils/config';

import './TagsField.less';

const TagsField = (
  {
    handleInnerInnerItemSubmitHandler,
    ...props
  }
) => {
	const {
    innerConfig
  } = props;

  console.log(props);

	const localConfig = innerConfig ? innerConfig : config;

	return (
		<Row
			justify="left"
			type="flex"
		>
			<Col
				span={localConfig.grid.gridSpan}
			>
				<Row
					justify="left"
					type="flex"
				>
					<Col>
						{props.title}
					</Col>
				</Row>
				<Row
					justify="left"
					type="flex"
				>
					<Col>
						{
              (
                !props.formValues
                ||
                (
                  props.formValues &&
                  !props.formValues[props.field]
                )
              ) &&
		          props.tags
		            .map(
		              (item, index) =>
		                <Tag
		                	closable={!!item.closable}
		                >
		                  {item.name}
		                </Tag>
		            )
		        }
            {
              props.subject &&
              props.subject[props.field] &&
              Array.isArray(props.subject[props.field]) &&
		          props.subject[props.field]
		            .map(
		              (item, index) =>
		                <Tag
		                	closable
		                >
		                  {item}
		                </Tag>
		            )
		        }
            {
              props.formValues &&
              props.formValues[props.field] &&
              Array.isArray(props.formValues[props.field]) &&
		          props.formValues[props.field]
		            .map(
		              (item, index) =>
		                <Tag
		                	closable
		                >
		                  {item}
		                </Tag>
		            )
		        }
					</Col>
				</Row>
				<Row
					justify="left"
					type="flex"
				>
					<Col>
						<FormButton
              action={event => handleInnerInnerItemSubmitHandler(event, props.button.action, props.field)}
							addonAfter={props.button.addonAfter}
			        addonBefore={props.button.addonBefore}
			        title={props.button.label}
			      />
					</Col>
				</Row>
			</Col>
		</Row>
	);
}

export default TagsField;
