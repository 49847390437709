import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import config from '../utils/config';

import './FormInstruction.less';

const FormInstruction = (
  props
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  return (
    <Row
      justify="baseline"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <Row
          justify="baseline"
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            <Row
              justify="end"
              type="flex"
            >
              <Col
                span={localConfig.grid.gridSpan}
              >
                {props.instruction.title}
              </Col>
            </Row>
            {
              props.instruction.text.map(
                item =>
                  <Row
                    justify="end"
                    type="flex"
                  >
                    <Col
                      span={localConfig.grid.gridSpan}
                    >
                      {item.text}
                    </Col>
                  </Row>
              )
            }
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormInstruction;
