import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import config from '../../utils/config';

import './MainThirdPartyMenuFooterWidget.less';

const MainThirdPartyMenuFooterWidget = (
  {
    handleStep,
    handleThirdPartyAlternateMenuClickAlternate,
    ...props
  }
) => {

  const {
    alternateThirdPartyAlternateMenuAlternateShown,
    alternateThirdPartyAlternateMenuShown,
    alternateThirdPartyMenuShown,
    alternateThirdPartyThirdPartyMenuShown,
    innerConfig,
    step
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleContent = () =>
    localConfig.entity.user.communicationFeatures.updates &&
      localConfig.entity.user.communicationFeatures.updates
      // +
      // localConfig.text.activityResultSeparatorText +
      // localConfig.text.activityResultAlternateBadgeText
    ;

  const handleFilter = () =>
    !alternateThirdPartyThirdPartyMenuShown &&
    !props.menuFilter &&
    (
      (
        props.subject[localConfig.defaultFifthPartyField] &&
        (
          props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
          props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
        )
      )
    )
  ;

  const handleFilterCount = () =>
    props.staticData &&
    props.staticData.length &&
    props.staticData.slice().shift()[localConfig.defaultSecondaryField] &&
    props.staticData.slice().shift()[localConfig.defaultSecondaryField].filter(
      innerInnerItem =>
        innerInnerItem[localConfig.defaultFifthPartyField]
    ).length

  return (
    alternateThirdPartyMenuShown ?
      <Row
        justify="end"
        type="flex"
      >
        {
          handleFilter() &&
          [
            <Col>

            </Col>
            ,
            step ?
              <Col
                onMouseDown={event => handleStep(event)}
              >
                <MenuButton
                  title={localConfig.text.footerMainThirdPartyMenuRightButtonSlugTextAlternate}
                />
              </Col>
              :
              <Col>

              </Col>
          ]
        }
      </Row>
      :
      !alternateThirdPartyAlternateMenuShown &&
      <Row
        justify="left"
        type="flex"
      >
        {
          !props.menuFilter &&
            [
              <Col>
                <MenuButton/>
              </Col>
              ,
              <Col
                onMouseDown={event => handleThirdPartyAlternateMenuClickAlternate(event, props.subject)}
              >
                {localConfig.text.bottomRightThirdPartySlugText}
              </Col>
              ,
              <Col>
                <MenuButton
                  title={handleFilterCount()}
                />
              </Col>
            ]
        }
      </Row>
  );
}

export default MainThirdPartyMenuFooterWidget;
