/* eslint-disable no-unused-expressions */
import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Col,
  Input,
  Popconfirm,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import MainMenuTopWidget from './MainMenuTopWidget';

import InformationCard from '../../general/InformationCard';

import config from '../../utils/config';

import './MainAlternateMenuDynamicBody.less';

const MainAlternateMenuDynamicBody = (
  {
    handleAlternateMenuClick,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeClassieContentBadgeText,
    onChangeLoader,
    onChangeMenu,
    onChangeSubject,
    onChangeSubjectThirdParty,
    navigate,
    openDrawer,
    resetData,
    showAlternateMenu,
    ...props
  }
) => {

  const {
    alternateMenuButtonShown,
    hasTitle,
    innerConfig,
    step
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleContentThirdParty = (innerItem) =>
    localConfig.text.centerSlugText +
    localConfig.text.activityResultSeparatorText +
    innerItem[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdPartyAlternate]
  ;

  const handleInnerItemClick = (event, innerItem) => {
    event.stopPropagation();
    console.log(innerItem);
    // innerItem.href && window.location.assign(innerItem.href);
    innerItem.href &&
    (
      innerItem.carouselContent ?
        (
          navigate(innerItem.href),
          onChangeSubject(innerItem)
        )
        :
        window.location.assign(innerItem.href)
    );
    innerItem.classieContentBadgeText &&
    (
      resetData(
        {
          data: {}
        }
      ),
      onChangeCarouselContent(innerItem.carouselContent[localConfig.text.innerWidgetInnerWidgetFormValueKey]),
      onChangeClassieContentBadgeText(innerItem.classieContentBadgeText),
      onChangeLoader(innerItem.title),
      innerItem.menu && onChangeMenu([innerItem.menu]),
      // navigate(localConfig.text.linkText),
      showAlternateMenu(),
      openDrawer()
    );
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryField] &&
    (
      resetData(
        {
          data: {}
        }
      ),
      onChangeCarouselContent(innerItem[localConfig.defaultThirdPartyThirdPartySecondaryField].slice().shift()),
      onChangeCarouselContentLevel([innerItem].length / [innerItem].length + [innerItem].length / [innerItem].length),
      onChangeCarouselInitialContent([innerItem].length - [innerItem].length),
      onChangeLoader(handleContentThirdParty(innerItem)),
      onChangeSubject(handleSubject(innerItem)),
      // onChangeSubjectThirdParty(handleSubject(innerItem)),
      innerItem.menu && onChangeMenu([innerItem.menu]),
      // navigate(localConfig.text.linkText),
      showAlternateMenu(),
      openDrawer()
    );
  }

  const handleSubject = (innerItem) =>
    innerItem[localConfig.defaultSixthPartyField] ?
      Object.assign(
        {},
        ...innerItem,
        {
          [
            localConfig.defaultSixthPartyFieldAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyField]
        },
        {
          [
            localConfig.defaultSixthPartyFieldAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternate]
        },
        {
          [
            localConfig.defaultSixthPartyFieldAlternateAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternateAlternate]
        }
      )
      :
      Object.assign(
        {},
        ...innerItem,
        {
          [
            localConfig.defaultSixthPartyFieldAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternate]
        }
        ,
        {
          [
            localConfig.defaultSixthPartyFieldAlternateAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternateAlternate]
        }
      )

  ;

  return (
    [
      (
        hasTitle &&
        alternateMenuButtonShown
      ) &&
      <MainMenuTopWidget
        handleMenuClick={handleAlternateMenuClick}
        innerConfig={localConfig}
        menu={localConfig.menu.alternateItem}
        subject={props.subject}
      />
      ,
      !step &&
      <Row
        justify="space-between"
        type="flex"
      >
        <Col>
          {props.item.title}
        </Col>
      </Row>
      ,
      step &&
      step == step / step + step / step &&
      <Row
        justify="space-between"
        type="flex"
      >
        <Col>
          {localConfig.text.topCenterSlugThirdPartyFourthdPartyText}
        </Col>
      </Row>
      ,
      step &&
      step == step / step + step / step + step / step &&
      <Row
        justify="space-between"
        type="flex"
      >
        <Col>
          {localConfig.text.topCenterSlugThirdPartyFourthdPartyAlternateText}
        </Col>
      </Row>
      ,
      step &&
      step == step / step + step / step + step / step &&
      <Row
        justify="space-between"
        type="flex"
      >
        <Col>
          {localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyText}
        </Col>
      </Row>
      ,
      props.item.items &&
      props.item.items.map(
        (innerItem, innerIndex) =>
          !innerItem.parent &&
          step ?
            <Popconfirm
              cancelText={localConfig.text.messageLeftText}
              okText={localConfig.text.messageRightText}
              placement="top"
              title={
                [
                  <Row
                    justify="unset"
                    type="flex"
                  >
                    <Col>
                      {localConfig.text.messageText.slice().reverse().shift().title}
                    </Col>
                  </Row>
                  ,
                  <Row
                    justify="unset"
                    type="flex"
                  >
                    <Col>
                      {localConfig.text.messageText.slice().shift().title}
                    </Col>
                  </Row>
                ]
              }
            >
              <Row
                justify="start"
                type="flex"
              >
                <Col
                  onMouseDown={event =>  handleInnerItemClick(event, innerItem)}
                >
                  <InformationCard
                    subject={handleSubject(innerItem)}
                  />
                  {
                    step &&
                    <Avatar
                    />
                  }
                </Col>
              </Row>
            </Popconfirm>
            :
            <Row
              justify="start"
              type="flex"
            >
              <Col
                onMouseDown={event =>  handleInnerItemClick(event, innerItem)}
              >
                <InformationCard
                  subject={handleSubject(innerItem)}
                />
              </Col>
            </Row>
      )
      ,
      step &&
      step == step / step + step / step + step / step &&
      <Row
        justify="space-between"
        type="flex"
      >
        <Col>
          {localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyText}
        </Col>
        <Col>
          <MenuButton
            popoverPlacement="topRight"
            popoverText={localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyAlternateText}
            popoverTitle={localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyText}
          />
        </Col>
      </Row>
    ]
  );
}

export default MainAlternateMenuDynamicBody;
