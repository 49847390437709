// module.exports = {
//   guests: 'http://localhost:8001',
//   hosts: [
//     {
//       origin: 'http://localhost:8000',
//       allowedMethods: ['get', 'set', 'remove']
//     },
//     {
//       origin: 'http://localhost:8001',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'http://localhost:8002',
//       allowedMethods: ['get', 'set']
//     }
//   ]
// };

// module.exports = {
//   guests: 'https://vowtch-alpha-foliography.d1xbpmguxt6ast.amplifyapp.com',
//   hosts: [
//     {
//       origin: 'https://vowtch-alpha.d291gxazrx2ik7.amplifyapp.com',
//       allowedMethods: ['get', 'set', 'remove']
//     },
//     {
//       origin: 'https://vowtch-alpha-foliography.d1xbpmguxt6ast.amplifyapp.com',
//       allowedethods: ['get', 'set']
//     },
//     {
//       origin: 'https://vowtch-alpha-conservative-action.do424gpr4wnoh.amplifyapp.com/',
//       allowedMethods: ['get', 'set', 'remove']
//     },
//     {
//       origin: 'https://vowtch-alpha-bayern.d2n7h4to76va6n.amplifyapp.com',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'https://vowtch-alpha-foliography.dtfu3n7iv01i6.amplifyapp.com',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'https://vowtch-alpha-foliography-directory.dtfu3n7iv01i6.amplifyapp.com',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'https://vowtch-alpha-foliography-studio.dtfu3n7iv01i6.amplifyapp.com',
//       allowedMethods: ['get', 'set']
//     }
//   ]
// };

// module.exports = {
//   guests: 'http://vowtch.go.ro:8001',
//   hosts: [
//     {
//       origin: 'http://vowtch.go.ro:8000',
//       allowedMethods: ['get', 'set', 'remove']
//     },
//     {
//       origin: 'http://vowtch.go.ro:8001',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'http://vowtch.go.ro:8002',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'http://vowtch.go.ro:8003',
//       allowedMethods: ['get', 'set']
//     },
//     {
//       origin: 'http://vowtch.go.ro:8004',
//       allowedMethods: ['get', 'set']
//     }
//   ]
// };

module.exports = {
  guests: 'https://accounts.vowtch.com',
  hosts: [
    {
      origin: 'https://accounts.vowtch.com',
      allowedMethods: ['get', 'set']
    },
    {
      origin: 'https://labs.vowtch.com',
      allowedMethods: ['get', 'set']
    },
    {
      origin: 'https://directory.foliography.com',
      allowedMethods: ['get', 'set']
    },
    {
      origin: 'https://player.foliography.com',
      allowedMethods: ['get', 'set']
    },
    {
      origin: 'https://studio.foliography.com',
      allowedMethods: ['get', 'set']
    }
  ]
};
