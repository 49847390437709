import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Card,
  Carousel,
  Col,
  Row
} from 'antd';

import config from '../utils/config';

import './GenericCarousel.less';

const hammerjs =
  typeof window !== `undefined` ?
    require("hammerjs")
    :
    undefined
;

const { Meta } = Card;

const GenericCarousel = (
  {
    formDrawerOpened,
    onChangeForm,
    openFormDrawer,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const carousel = React.createRef();

  const handleCarouselInnerWidgetFormAction = (form) =>
    Object.assign({}, form, {
      handleSubmit: props.handleDataSubmit
    })

  const handleCarouselInnerWidgetClick = (event, innerItem, innerIndex) => {
    onChangeForm(
      handleCarouselInnerWidgetFormAction(
        props.innerPages.filter(
          item => item.node.title === innerItem.title
        ).shift().node
      )
    );
    openFormDrawer();
    !props.settings.autoplay &&
    carousel.current.slick.slickGoTo(++innerIndex);
  }

  const handleContent = (innerItem) =>
    innerItem.count ?
      innerItem.count +
      localConfig.text.activityResultSeparatorText +
      innerItem.title
      :
      localConfig.text.activityResultSeparatorText +
      innerItem.title
  ;

  const next = (event) => {
    event.stopPropagation();
    carousel.current.slick.slickNext();
    carousel.current && carousel.current.slick.slickNext();
  };

  const previous = (event) => {
    event.stopPropagation();
    carousel.current && carousel.current.slick.slickPrev();
  };

  return (
    <div>
      <Row
        justify="right"
        type="flex"
      >
        <Col
        >
          <Avatar
            onMouseDown={event =>  previous(event)}
          />
          <Avatar
            onMouseDown={event =>  next(event)}
          />
        </Col>
      </Row>
      <Row
        justify="unsafe"
        type="flex"
      >
        <Col
          span={localConfig.grid.gridSpan}
        >
           <Carousel
             autoplaySpeed={props.settings.autoplaySpeed}
             dots={props.settings.dots}
             effect={props.settings.effect}
             pauseOnHover={props.settings.pauseOnHover}
             ref={carousel}
             speed={props.settings.speed}
             swipe={props.settings.swipe}
           >
             {
               props.slides &&
               props.slides.map(
                 (item, index) =>
                   <Card
                     cover=
                     {
                       <img
                         alt=""
                         src={item.image.src}
                       />
                     }
                     hoverable
                  >
                    <Meta
                      avatar=
                      {
                        <Avatar
                          src={item.avatar.src}
                        />
                      }
                      description={
                        <Card
                        >
                          <Meta
                            description={
                              item.subDescription &&
                              item.subDescription.items &&
                              item.subDescription.items.map(
                                (innerItem, innerIndex) =>
                                  <Button
                                    htmlType="submit"
                                    onClick={event => handleCarouselInnerWidgetClick(event, innerItem, innerIndex)}
                                    type="primary"
                                  >
                                    {innerItem.title}
                                  </Button>
                              )
                            }
                            title={
                              <Row
                                justify="safe"
                                type="flex"
                              >
                                {
                                  item.description &&
                                  item.description.items &&
                                  item.description.items.map(
                                    innerItem =>
                                      <Col
                                      >
                                        {handleContent(innerItem)}
                                      </Col>
                                  )
                                }
                              </Row>
                            }
                          />
                        </Card>
                      }
                      title={
                        <Card
                        >
                          <Meta
                            description={
                              item.subTitle.items ?
                                item.subTitle.items.map(
                                  innerItem =>
                                    <Row
                                      justify="safe"
                                      type="flex"
                                    >
                                      <Col
                                      >
                                        {innerItem.title}
                                      </Col>
                                    </Row>
                                )
                                :
                                item.subTitle
                            }
                            title={
                              <Card
                              >
                                <Meta
                                  description={item.title}
                                  title={item.superTitle}
                                />
                              </Card>
                            }
                          />
                        </Card>
                      }
                    />
                  </Card>
              )
             }
           </Carousel>

        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeForm: (item) => dispatch (
      {
        payload: item,
        type: `onChangeForm`
      }
    )
    ,
    openFormDrawer: () => dispatch (
      {
        type: `openFormDrawer`
      }
    )
  }
);

const mapStateToProps = state => (
  {

  }
);

const ConnectedGenericCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericCarousel);

export default ConnectedGenericCarousel;
