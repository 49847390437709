import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

const CardSectionInlineInformation = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props.subject);
  console.log(props.item);

  const handleSectionContent = (item) =>
    item.field ?
      props.subject[item.field] ?
        props.subject[item.field]
        :
        localConfig.entity.user[item.field] ?
          localConfig.entity.user[item.field]
          :
          item.content
      :
      item.content
    ;

  return (
    [
      <Row
        justify="inherit"
        type="flex"
      >
        <Col>
          {props.item.title}
        </Col>
      </Row>
      ,
      <Row
        justify="initial"
        type="flex"
      >
        <Col
        >
          {handleSectionContent(props.item)}
        </Col>
      </Row>
    ]
  );
}

export default CardSectionInlineInformation;
