import React from 'react';

import { connect } from "react-redux";

import {
  Select
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './SelectField.less';

const Option = Select.Option;

const SelectField = (
  {
    fieldValues,
    onChangeField,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  console.log(props);
  console.log(fieldValues);

  const localConfig = innerConfig ? innerConfig : config;

  const handleChange = (
		value,
    extraValue,
		localInnerItem =
      props.externalStaticData &&
      props.externalStaticData[props.field] &&
			props.externalStaticData[props.field].find(
				item => item[props.innerExternalStaticData.valueField] == value
			)
		) =>
      onChangeField(
        props.internalStaticData ?
          {
            [props.field]: value
          }
          :
    			props.extraField ?
    	      {
    					[props.extraField]: localInnerItem[props.innerExternalStaticData.extraField],
    					[props.field]: localInnerItem[props.innerExternalStaticData.titleField],
    				}
    				:
    				{
    					[props.field]: value
    				}
      )
  ;

  console.log(
    props.secondaryInternalStaticData &&
    props.secondaryInternalStaticData.condition &&
    props.secondaryInternalStaticData.condition.split(
      localConfig.text.topLeftSlugSeparatorText
    )
  );

  const handleSecondaryInternalStaticData = (
    secondaryInternalStaticDataSplit =
      props.secondaryInternalStaticData.condition &&
      props.secondaryInternalStaticData.condition.split(
        localConfig.text.topLeftSlugSeparatorText
      )
  ) =>
    props.secondaryStaticData.length &&
    props.secondaryStaticData.slice().shift()[localConfig.defaultThirdPartyField].slice().shift().items.filter(
      element => element.title =
        secondaryInternalStaticDataSplit.slice().shift()
    ).reverse().slice().shift().items.find(
      innerElement =>
        innerElement.title =
          secondaryInternalStaticDataSplit.reverse().slice().shift()
        &&
        innerElement.items
    ).items
  ;

  console.log(
    props.secondaryInternalStaticData &&
    props.secondaryInternalStaticData.condition &&
    handleSecondaryInternalStaticData()
  );

  const handleOptions = () =>
    props.internalStaticData ?
      props.staticData ?
        props.staticData[props.internalStaticData]
        :
        props.externalStaticData ?
          (
            fieldValues &&
            props.innerExternalStaticData &&
            props.innerExternalStaticData.filterField
          ) ?
            props.externalStaticData[props.field].filter(
              item =>
                (
                  item[props.innerExternalStaticData.filterField] == fieldValues[props.innerExternalStaticData.filterField] ||
                  item[props.innerExternalStaticData.filterField] == props.innerExternalStaticData.defaultFilterFieldValue
                )
            )
            :
            props.externalStaticData[props.field]
          :
          props.options
      :
      props.externalStaticData ?
        (
          fieldValues &&
          props.innerExternalStaticData &&
          props.innerExternalStaticData.filterField
        ) ?
          props.externalStaticData[props.field].filter(
            item =>
              (
                item[props.innerExternalStaticData.filterField] == fieldValues[props.innerExternalStaticData.filterField] ||
                item[props.innerExternalStaticData.filterField] == props.innerExternalStaticData.defaultFilterFieldValue
              )
          )
          :
          props.externalStaticData[props.field]
        :
        props.secondaryInternalStaticData ?
          handleSecondaryInternalStaticData()
          :
          props.options
  ;

  const handleDefaultValue = (
    localDefaultValue =
      handleOptions() &&
      Array.isArray(handleOptions()) &&
      handleOptions().slice().shift()
  ) =>
    handleOptions() &&
    Array.isArray(handleOptions()) &&
    props.innerExternalStaticData  ?
      localDefaultValue[props.innerExternalStaticData.valueField]
      :
      props.internalStaticData  ?
        localDefaultValue.title
        :
        props.secondaryInternalStaticData  ?
          localDefaultValue.title
          :
          localDefaultValue.title
  ;

  console.log(handleOptions());

  const handleSearch = () => {
  }

  return (
    <div>
      <Select
        defaultValue={handleDefaultValue()}
        onChange={props.handleChange ? props.handleChange : handleChange}
        onSearch={handleSearch}
        placeholder={props.placeholder}
        showSearch
     >
       {
         handleOptions() &&
         Array.isArray(handleOptions()) &&
         handleOptions().map(
           (item, index) =>
            props.innerExternalStaticData  ?
              <Option
                key={item[props.innerExternalStaticData.valueField]}
                value={item[props.innerExternalStaticData.valueField]}
              >
                {item[props.innerExternalStaticData.titleField]}
              </Option>
              :
              props.internalStaticData  ?
                <Option
                  key={item.value}
                  value={item.value}
                >
                  {item.title}
                </Option>
                :
                props.secondaryInternalStaticData ?
                  <Option
                    key={item.title}
                    value={item.title}
                  >
                    {item.title}
                  </Option>
                  :
                  <Option
                    key={item.value}
                    value={item.value}
                  >
                    {item.title}
                  </Option>
        )
       }
     </Select>
    </div>
  );
}

export default SelectField;
