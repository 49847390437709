import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';

import InformationCard from './InformationCard';

import ScrollableContainer from '../general/ScrollableContainer';

import config from '../utils/config';

import './MainMenuTopWidget.less';

const { Link } = Anchor;

const MainMenuTopWidget = (
  {
    handleAlternateMenuClick,
    handleMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleContent = () =>
    props.menuFilter ?
      localConfig.text.bottomRightThirdPartySlugText
      :
      props.menu &&
      props.menu.title
    ;

  console.log(props);

  return (
    props.subject &&
    props.menu.hasAvatar ?
      <Row
        justify="inherit"
        type="flex"
      >
        <Col>
          {
            props.subject.parent ?
              <ScrollableContainer
                innerConfig={localConfig}
              >
                <Row
                  justify="initial"
                  type="flex"
                >
                  <Col>
                    <MenuButton/>
                  </Col>
                  <Col
                    onMouseDown={event => handleMenuClick(event, props.menu)}
                  >
                    <InformationCard
                      hasExtendedAvatar
                      hasExtendedContent={props.menu.parent}
                      hasExtendedReplacementTitleLink
                      justify={localConfig.grid.menuJustify}
                      replacementDescriptionText={props.menu.title}
                      replacementTitleText={props.menu.description}
                      subject={props.subject}
                      secondaryJustify={localConfig.grid.menuSecondaryJustify}
                    />
                  </Col>
                </Row>
              </ScrollableContainer>
              :
              <Row
                justify="unset"
                type="flex"
              >
                <Col>
                  <MenuButton/>
                </Col>
                <Col>
                  <InformationCard
                    replacementDescriptionText={props.menu.title}
                    subject={props.subject}
                  />
                </Col>
              </Row>
          }
        </Col>
      </Row>
      :
      <Row
        justify="start"
        type="flex"
      >
        {
          props.menu &&
          props.menu.level ?
            <Col>
              <MenuButton
                action={handleMenuClick}
              />
            </Col>
            :
            <Col>
            </Col>
        }
        <Col>
          <Anchor>
            <Link
              title={handleContent()}
            >
            </Link>
          </Anchor>
        </Col>
        {
          props.menu &&
          props.menu.hasAction ?
            <Col>
              {
                !props.menuFilter &&
                !props.menu.hasAvatar &&
                [
                  <MenuButton
                  />,
                  <MenuButton
                    action={handleAlternateMenuClick}
                  />
                ]
              }
            </Col>
            :
            <Col>
            </Col>
        }
      </Row>
  );
}

export default MainMenuTopWidget;
