import React from 'react';

import { connect } from "react-redux";

import {
  Button,
  Col,
  Popover,
  Row,
  Tooltip
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './MenuButton.less';

const MenuButton = (
  props
) => {

  return (
    props.tooltipText ?
      <Tooltip
        overlayClassName={props.justify}
        placement={props.tooltipPlacement}
        title={props.tooltipText}
      >
        <Button
          onClick={props.action}
        >
          {props.title}
        </Button>
      </Tooltip>
      :
        props.popoverText ?
        <Popover
          arrowPointAtCenter
          autoAdjustOverflow={props.popoverAutoAdjustOverflow}
          content={
            <Row
              justify="unset"
              type="flex"
            >
              <Col>
                {
                  props.popoverText.map(
                    (innerItem, innerIndex) =>
                      <Row
                        justify="unset"
                        type="flex"
                      >
                        <Col>
                          {innerItem.title}
                        </Col>
                      </Row>
                  )
                }
              </Col>
            </Row>
          }
          overlayClassName={props.popoverOverlayClassName}
          placement={props.popoverPlacement}
          title={props.popoverTitle}
          trigger={props.trigger}
        >
          <Button
            onClick={props.action}
          >
            {props.title}
          </Button>
        </Popover>
        :
        <Button
          onClick={props.action}
        >
          {props.title}
        </Button>
  );
}

export default MenuButton;
