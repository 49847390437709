import React from 'react';

import { connect } from "react-redux";

import {
  Button,
  Tooltip
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './MenuButton.less';

const MenuButton = (
  props
) => {

  return (
    props.tooltipText ?
      <Tooltip
        placement={props.tooltipPlacement}
        title={props.tooltipText}
      >
        <Button
          className={props.className}
          onClick={props.action}
        >
          {props.title}
        </Button>
      </Tooltip>
      :
      <Button
        onClick={props.action}
      >
        {props.title}
      </Button>
  );
}

export default MenuButton;
