import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Icon,
  Layout,
  Row
} from 'antd';

import config from '../utils/config';

const MainFooter = (
  {
    menu,
    openDrawer,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <div>
      <Row
        justify="space-between"
        type="flex"
      >
        <Col>
        </Col>
        <Col>
        </Col>
      </Row>
    </div>
  )
}

export default MainFooter;
