import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import FormButton from '../form/FormButton';
import InformationCard from '../general/InformationCard';
import MenuButton from '../menu/MenuButton';

import config from '../utils/config';

import './GenericHeader.less';

const { Link } = Anchor;

const GenericHeader = (
  {
    handleCardSwitch,
    onChangeStep,
    ...props
  }
) => {

  const {
    hasButton,
    hasParent,
    isEditable,
    innerConfig,
    navigate,
    onAction
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleDatatransformation = (item) =>
    item.map(
      innerItem =>
        Object.assign(
          {},
          ...props.datatransfomation.map(
            innerinnerItem =>
              Object.assign(
                {},
                Object.assign(
                  {},
                  {
                    [innerinnerItem.field]
                    :
                    innerItem[innerinnerItem.defaultField]
                  }
                )
              )
          )
        )
    )
  ;


  const handleAvatar = (
    datatransformationSubject =
      props.datatransfomation &&
      handleDatatransformation([props.subject]).slice().shift()
  ) =>
    props.header ?
      props.subject ?
        (
          props.datatransfomation &&
          datatransformationSubject[
            props.datatransfomation.find(
              element => element.markerField
            ).field
          ] &&
          Object.keys(datatransformationSubject).filter(
            item => props.subject[item]
          ).length > Object.keys(props.subject).length
        ) ?
          props.subject.avatar
          :
          props.header.avatar ?
            props.header.avatar
            :
            props.avatar
        :
        props.header.avatar ?
          props.header.avatar
          :
          props.avatar
      :
      props.avatar
    ;

  const handleData = () =>
    props.subject ?
      props.subject
      :
      props.staticData &&
      props.staticData.length ?
        props.staticData.slice().shift()
        :
        localConfig.entity.user
    ;

  const handleGenericHeaderAction = () =>
    props.header.action &&
    (
      props.header.button &&
      props.header.button.href
    ) ?
      navigate(props.header.button.href)
      :
      onAction(
        {
          ...props.header.action,
          payload:
            props.header.action.payload &&
            props.innerPages.filter(
              item =>
                item.node.title ===
                  props.header.action.payload.split(
                    localConfig.text.topLeftSlugSeparatorText
                  ).slice().shift()
            ).length ?
              [
                props.innerPages.filter(
                  item =>
                    item.node.title ===
                      props.header.action.payload.split(
                        localConfig.text.topLeftSlugSeparatorText
                      ).slice().shift()
                ).slice().shift().node
                ,
                props.header.action.payload.split(
                  localConfig.text.topLeftSlugSeparatorText
                ).reverse().slice().shift()
              ]
              :
              props.innerPages.filter(
                item => item.node.title === props.header.action.payload
              ).length ?
                props.innerPages.filter(
                  item => item.node.title === props.header.action.payload
                ).slice().shift().node
                :
                props.header.action.payload
        }
      )
  ;

  const handleGenericHeaderActionAlternate = () =>
    props.header.href &&
    (
      onChangeStep &&
      onChangeStep(props.step / props.step),
      navigate(props.header.href)
    )
  ;

  const handleGenericHeaderActionThirdParty = () =>
    props.header.secondaryButton &&
    props.header.secondaryButton.action ?
      onAction(
        {
          ...props.header.secondaryButton.action,
        }
      )
      :
      (
        onChangeStep &&
        onChangeStep(props.step / props.step),
        navigate(props.header.secondaryButton.href)
      )
  ;

  const handleJustify = () =>
    props.header &&
    props.header.justify

  const handleTitle = (
    datatransformationSubject =
      props.datatransfomation &&
      handleDatatransformation([props.subject]).slice().shift()
  ) =>
    props.inheritTitle ?
      props.subject ?
        (
          props.datatransfomation &&
          datatransformationSubject[
            props.datatransfomation.find(
              element => element.markerField
            ).field
          ] &&
          Object.keys(datatransformationSubject).filter(
            item => props.subject[item]
          ).length > Object.keys(props.subject).length
        ) ?
          props.thirdPartyTitle
          :
          datatransformationSubject[
            props.datatransfomation.find(
              element => element.extraMarkerField
            ).field
          ] ?
            props.requiredThirdPartyTitle ?
              props.thirdPartyTitle
              :
              props.subject.title
            :
            props.thirdPartyTitle
        :
        props.inheritTitle
      :
      props.title
  ;

  console.log(
    props.datatransfomation &&
    handleDatatransformation([props.subject]).slice().shift()
  )

  console.log(
    props
  )

  return (
    <div>
      {
        hasParent ?
          <Row
            justify="space-between"
            type="flex"
          >
            <Col>
              {
                handleData().parent ?
                  <Row
                    justify="initial"
                    type="flex"
                  >
                    <Col>
                      <InformationCard
                        informationReverse
                        subject={handleData()}
                      />
                    </Col>
                  </Row>
                  :
                  <Row
                    justify="unset"
                    type="flex"
                  >
                    <Col>
                      <InformationCard
                        informationReverse
                        subject={handleData()}
                      />
                    </Col>
                  </Row>
              }
            </Col>
            <Col>
              {
                isEditable &&
                <Anchor
                  onClick={handleCardSwitch}
                >
                  <Link
                    title={localConfig.text.bottomRightHeaderSlugText}
                  >
                  </Link>
                </Anchor>
              }
            </Col>
          </Row>
          :
          hasButton ?
            <Row
              justify={handleJustify()}
              type="flex"
            >
              <Col>
                <Row
                  justify="inherit"
                  type="flex"
                >
                  <Col>
                    <Avatar
                      src={handleAvatar()}
                    />
                  </Col>
                  <Col>
                    {handleTitle(props.item)}
                  </Col>
                </Row>
              </Col>
              <Col>
                {
                  hasButton &&
                  props.header &&
                  props.header.action &&
                  <Row
                    justify="end"
                    type="flex"
                  >
                    <Col>
                      {
                        props.header.button &&
                        <FormButton
                          action={handleGenericHeaderAction}
                          addonBefore={props.header.button.addonBefore}
                          title={props.header.button.label}
                        />
                      }
                      {
                        props.header.secondaryButton &&
                        <FormButton
                          action={handleGenericHeaderActionThirdParty}
                          addonBefore={props.header.secondaryButton.addonBefore}
                          title={props.header.secondaryButton.label}
                        />
                      }
                    </Col>
                    <Col>
                      <MenuButton
                        action={handleGenericHeaderActionAlternate}
                      />
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
            :
            <Row
              justify="inherit"
              type="flex"
            >
              <Col>
                <Avatar
                  src={props.avatar}
                />
              </Col>
              <Col>
                {handleTitle(props.item)}
              </Col>
            </Row>
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onAction: (action) =>  dispatch(action)
  }
);

const mapStateToProps = state => (
  {

  }
);

const ConnectedGenericHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericHeader);

export default ConnectedGenericHeader;
