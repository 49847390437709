import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import TweenOne from 'rc-tween-one';

import {
  Col,
  Row
} from 'antd';


import InformationCard from '../InformationCard';

import MenuButton from '../MenuButton';

import config from '../../utils/config';

import './ClassieContentPost.less';

const ClassieContentPost = (
  {
    handleDataSubmit,
    handleThirdPartyMouseDown,
    onAnimationComplete,
    onAnimationStart,
    onChangeForm,
    openFormDrawer,
    ...props
  }
) => {
  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const [postContent, setPostContent] = useState();

  const handleCarouselProgressPlayerAnimation = () =>
    localConfig.carousel.animation.map(
      item =>
        Object.assign(
          {},
          item,
          item.onAnimationComplete &&
            {
              onComplete: onAnimationComplete
            }
          ,
          item.onAnimationStart &&
            {
              onStart: onAnimationStart
            }
        )
    )

  console.log(handleCarouselProgressPlayerAnimation());

  const handlePostObject = (item) =>
    Object.keys(item).find(
		  innerItem =>
  			item[innerItem] instanceof Object &&
        !Array.isArray(item[innerItem]) &&
        Object.keys(item[innerItem]).length > localConfig.grid.gridAddonBeforeTreshold

		)
  ;

  const handlePost = (
    item,
    itemKeys = Object.keys(item),
    itemObject = handlePostObject(item),
    itemObjectIndex = itemKeys.indexOf(handlePostObject(item)),
    itemItemObjectIndex = itemObjectIndex - itemObjectIndex / itemObjectIndex - itemObjectIndex / itemObjectIndex - itemObjectIndex / itemObjectIndex,
    itemItemObjectIndexAlternate = itemObjectIndex - itemObjectIndex / itemObjectIndex
  ) =>
    Object.assign(
      {},
      item,
      ...{
        [itemKeys.slice().shift()]
        :
        !postContent && item[itemKeys.slice().shift()]
      },
      ...{
        [itemKeys[itemItemObjectIndex]]
        :
        postContent ?
          item[itemKeys[itemItemObjectIndex]]
          :
          item[itemKeys[itemItemObjectIndex]].split(
            localConfig.text.topLeftSlugSeparatorTextAlternate
          ).slice().shift()
      },
      ...{
        [handlePostObject(item)]
        :
        !postContent && item[itemKeys[itemItemObjectIndexAlternate]]
        // .map(
        //   item => localConfig.text.alternateActivityResultThirdPartySeparatorText + item
        // )
        // .join(localConfig.text.activityResultSecondarySeparatorText)
      }
      ,
      ...{
        [itemKeys[itemItemObjectIndexAlternate]]
        :
        localConfig.text.activityResultSeparatorText
      }
    )
  ;

  const handleInnerWidgetAlternateSlugText = (
    item,
    itemKeys = Object.keys(item),
    itemObject = handlePostObject(item),
    itemObjectIndex = itemKeys.indexOf(handlePostObject(item)),
    itemItemObjectIndex = itemObjectIndex - itemObjectIndex / itemObjectIndex - itemObjectIndex / itemObjectIndex - itemObjectIndex / itemObjectIndex,
    itemItemObjectIndexAlternate = itemObjectIndex - itemObjectIndex / itemObjectIndex
  ) =>
    item[itemKeys[itemItemObjectIndex]].split(
      localConfig.text.topLeftSlugSeparatorTextAlternate
    ).length > itemObjectIndex / itemObjectIndex &&
    (
      postContent ?
        localConfig.text.innerWidgetSlugText
        :
        localConfig.text.innerWidgetAlternateSlugText
    )
  ;

  const handleMouseDown = (event, item) => {
    event.preventDefault();
    console.log(handlePostObject(props.item));
    setPostContent(
      postContent ?
        !item
        :
        item
    )
  }

  const handleThirdPartyJustify = () =>
    postContent && localConfig.grid.menuJustify
  ;

  console.log(handlePostObject(props.item));
  console.log(props.item);
  console.log(Object.keys(props.item));
  console.log(Object.keys(props.item).indexOf(handlePostObject(props.item)));
  console.log(Object.keys(props.item)[Object.keys(props.item).indexOf(handlePostObject(props.item)) - Object.keys(props.item).indexOf(handlePostObject(props.item)) / Object.keys(props.item).indexOf(handlePostObject(props.item)) ]);

  console.log(props.animationMoment);

  return (
    <div>
      <TweenOne
        animation={handleCarouselProgressPlayerAnimation()}
        moment={props.animationMoment}
        paused={props.animationPaused}
        repeat={props.repeat}
      >
        <Row
          justify="start"
          type="flex"
        >
          <Col
          >
            <Row
              justify="space-between"
              type="flex"
            >
              <TweenOne
                animation={localConfig.carousel.animationSecondary}
                moment={props.animationMoment}
                paused={props.animationPaused}
                repeat={props.repeat}
              >
                <Col
                >
                  <InformationCard
                    animationMoment={props.animationMoment}
                    handleThirdPartyMouseDown={handleThirdPartyMouseDown}
                    hasAnimation
                    innerConfig={localConfig}
                    subject={props.item.subTitle}
                  />
                </Col>
              </TweenOne>
            </Row>
            <Row
              justify="space-between"
              type="flex"
            >
              <Col
              >
                <InformationCard
                  handleMouseDown={handleMouseDown}
                  handleThirdPartyMouseDown={handleThirdPartyMouseDown}
                  hasExtendedAvatar
                  hasExtendedContent
                  informationReverse
                  innerWidgetAlternateSlugText={handleInnerWidgetAlternateSlugText(props.item)}
                  justify={localConfig.grid.menuJustify}
                  secondaryJustify={localConfig.grid.menuJustify}
                  subject={handlePost(props.item)}
                  subjectAlternate={props.itemAlternate}
                  thirdPartyJustify={handleThirdPartyJustify()}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              type="flex"
            >
              <Col
              >
                <MenuButton
                  title={localConfig.text.widgetText}
                />
              </Col>
              <Col
              >
                <MenuButton
                  title={localConfig.text.widgetTextAlternate}
                />
                <MenuButton
                  title={localConfig.text.classieContentThirdPartySlugTextAlternate}
                />
                <MenuButton
                  title={localConfig.text.widgetTextThirdParty}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </TweenOne>
    </div>
  )
}

export default ClassieContentPost;
