import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Input,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import SelectField from '../../form/SelectField';

import config from '../../utils/config';

import './MenuSelectItem.less';

const MenuSelectItem = (
  {
    handleMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleChange = (value) => {
    handleMenuClick(new Event(localConfig.events.change), value);
  }

  const handleDefaultValue = () =>
    props.defaultValue ?
      props.defaultValue
      :
      localConfig.text.topMainThirdPartyMenuSelectFieldPlaceholder
  ;

  return (
    <Row
      justify="normal"
      type="flex"
    >
      <Col>
        <MenuButton />
      </Col>
      <Col
      >
        <SelectField
          handleChange={handleChange}
          innerConfig={localConfig}
          options={props.options}
          placeholder={handleDefaultValue()}
        />
      </Col>
    </Row>
  );
}

export default MenuSelectItem;
