import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';
import MenuSelectItem from './MenuSelectItem';

import CircularProgress from '../../general/CircularProgress';
import InformationCard from '../../general/InformationCard';
import ScrollableContainer from '../../general/ScrollableContainer';

import config from '../../utils/config';

import './MainMenuDynamicBody.less';

const { Link } = Anchor;

const MainMenuDynamicBody = (
  {
    handleActionClick,
    handleMenuClick,
    ...props
  }
) => {

  const {
    formField,
    innerConfig,
  } = props;

  console.log(formField)

  const localConfig = innerConfig ? innerConfig : config;

  const handleFilter = (innerItem) =>
    props.menuFilter ?
      innerItem.parent &&
      innerItem[localConfig.defaultFifthPartyField] &&
      (
        props.menuFilter.constructor === Object ?
          innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
          innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
          :
          innerItem[localConfig.defaultFifthPartyField][props.menuFilter]
      )
      :
      innerItem.parent &&
      !innerItem[localConfig.defaultFifthPartyField] ||
      innerItem[localConfig.defaultFifthPartyField] &&
      (
        !innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] &&
        !innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
      )
    ;

  const handleItem = (innerItem) =>
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ||
    (
      innerItem.parent &&
      innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField]
    )

  console.log(props)

  return (
    props.menu.dynamicItems ?
      [
        props.menuFilter &&
        <MenuSelectItem
          handleMenuClick={handleMenuClick}
          innerConfig={localConfig}
          options={props.menu.options}
        />
        ,
        <ScrollableContainer
          innerConfig={localConfig}
        >
          <Row
            justify="safe"
            type="flex"
          >
            <Col>
              <QueueAnim
                component={Menu}
                componentProps={{
                  defaultOpenKeys: [],
                  defaultSelectedKeys: [],
                  mode: "inline",
                }}
                delay={localConfig.rcQueueAnim.delay}
                duration={localConfig.rcQueueAnim.duration}
                interval={localConfig.rcQueueAnim.interval}
                leaveReverse
                type={['right', 'left']}
              >
                {
                  props.staticData &&
                  props.staticData.length ?
                    props.staticData.map(
                      (item, index) =>
                        item.parent &&
                        item[localConfig.defaultSecondaryField] ?
                          item[localConfig.defaultSecondaryField].filter(
                            innerItem =>
                              props.menu.hasParent ?
                                innerItem.parent &&
                                handleFilter(innerItem)
                                :
                                innerItem
                          )
                          .map(
                            (innerItem, innerIndex) =>
                              <Menu.Item>
                                {
                                  handleItem(innerItem) ?
                                    props.menu.isOrdered ?
                                      <Row
                                        justify="inherit"
                                        type="flex"
                                      >
                                        <Col
                                          onMouseDown={
                                            event =>
                                              formField ?
                                                handleActionClick(event, innerItem)
                                                :
                                                handleMenuClick(event, innerItem)
                                          }
                                        >
                                          <Row
                                            justify="initial"
                                            type="flex"
                                          >
                                            <Col>
                                              <MenuButton
                                                title={innerItem.titleInitial}
                                              />
                                            </Col>
                                            <Col>
                                              <InformationCard
                                                subject={innerItem}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      :
                                      <Row
                                        justify="inherit"
                                        type="flex"
                                      >
                                        <Col
                                          onMouseDown={
                                            event =>
                                              formField ?
                                                handleActionClick(event, innerItem)
                                                :
                                                handleMenuClick(event, innerItem)
                                          }
                                        >
                                          <InformationCard
                                            subject={innerItem}
                                          />
                                        </Col>
                                      </Row>
                                    :
                                    props.menu.isOrdered ?
                                      <Row
                                        justify="initial"
                                        type="flex"
                                      >
                                        <Col
                                          onMouseDown={
                                            event =>
                                              formField ?
                                                handleActionClick(event, innerItem)
                                                :
                                                handleMenuClick(event, innerItem)
                                          }
                                        >
                                          <Row
                                            justify="initial"
                                            type="flex"
                                          >
                                            <Col>
                                              <MenuButton
                                                title={innerItem.titleInitial}
                                              />
                                            </Col>
                                            <Col>
                                              <InformationCard
                                                hasPartialContent
                                                subject={innerItem}
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      :
                                      <Row
                                        justify="initial"
                                        type="flex"
                                      >
                                        <Col
                                          onMouseDown={
                                            event =>
                                              formField ?
                                                handleActionClick(event, innerItem)
                                                :
                                                handleMenuClick(event, innerItem)
                                          }
                                        >
                                          <InformationCard
                                            hasPartialContent
                                            subject={innerItem}
                                          />
                                        </Col>
                                      </Row>
                                }
                              </Menu.Item>
                          )
                          :
                          <Menu.Item>
                            <CircularProgress/>
                          </Menu.Item>
                    )
                    :
                    <Menu.Item>
                      <CircularProgress/>
                    </Menu.Item>
                }
              </QueueAnim>
            </Col>
          </Row>
        </ScrollableContainer>
      ]
      :
      <Menu.Item>
        <Anchor>
          <Link
            href={localConfig.menu.items[localConfig.menu.items.length - localConfig.menu.items.length / localConfig.menu.items.length].href}
            title={localConfig.menu.items[localConfig.menu.items.length - localConfig.menu.items.length / localConfig.menu.items.length].title}
          >
          </Link>
        </Anchor>
      </Menu.Item>
  );
}

export default MainMenuDynamicBody;
