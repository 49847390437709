import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Card,
  Col,
  Row
} from 'antd';

import config from '../../utils/config';

import './ClassieContentTitle.less';

const { Meta } = Card;

const ClassieContentTitle = (
  {
    ...props
  }
) => {

  const {
    carouselContent,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleContent = (innerItem) =>
    innerItem.count ?
      innerItem.count +
      localConfig.text.activityResultSeparatorText +
      innerItem.title
      :
      localConfig.text.activityResultSeparatorText +
      innerItem.title
  ;

  const handleJustify = () =>
    carouselContent ?
      localConfig.grid.classieContentTitleJustify +
      localConfig.text.activityResultSeparatorText +
      carouselContent
      :
      localConfig.grid.classieContentTitleJustify
    ;

  const handleMouseDown = (event, item) => {
    event.stopPropagation();
    console.log(item)
    item.href &&
    navigate(item.href);
  }

  console.log(props)

  return (
    <Row
      justify={handleJustify()}
      type="flex"
    >
      <Col
      >
        <Card
          hoverable
        >
         <Meta
           avatar=
           {
             <Avatar
               src={
                 props.item.avatar.src
               }
             />
           }
           description={
             <Card
             >
               <Meta
                 description={
                   props.item.subDescription &&
                   props.item.subDescription.items &&
                   props.item.subDescription.items.map(
                     innerItem =>
                       innerItem.title &&
                       [
                         <button
                           className="trigger"
                           onClick={event =>  handleMouseDown(event, innerItem)}
                         >
                           <Avatar
                             src={
                               innerItem.avatar
                             }
                           />
                           {innerItem.title}
                         </button>
                       ]
                   )
                 }
                 title={
                   <Row
                     justify="safe"
                     type="flex"
                   >
                     {
                       props.item.description &&
                       props.item.description.items &&
                       props.item.description.items.map(
                         innerItem =>
                           <Col
                           >
                             {handleContent(innerItem)}
                           </Col>
                       )
                     }
                   </Row>
                 }
               />
             </Card>
           }
           title={
             <Card
             >
               <Meta
                 description={props.item.subTitle}
                 title={
                   <Card
                   >
                     <Meta
                       description={props.item.title}
                       title={props.item.superTitle}
                     />
                   </Card>
                 }
               />
             </Card>
           }
         />
        </Card>
      </Col>
    </Row>
  );
}

export default ClassieContentTitle;
