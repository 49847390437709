import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericTable from '../../table/GenericTable';

import config from '../../utils/config';

const CardInnerWidgetTable = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleDataSource = () =>
    props.subject[props.tableProps.field]
  ;

  return (
    <Row
      justify="unsafe"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <GenericTable
          columns={props.tableProps.columns}
          dataSource={handleDataSource()}
          innerConfig={localConfig}
        />
      </Col>
    </Row>
  );
}

export default CardInnerWidgetTable;
