import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuBadge from './MenuBadge';
import MenuButton from './MenuButton';

import config from '../utils/config';

import './MenuHeader.less';

const MenuHeader = (
  {
    openDrawer,
    ...props
  }
) => {

  const {
    innerConfig,
    isAlternateMenu
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <div>
      {
        !isAlternateMenu ?
          <Row
            justify="space-between"
            type="flex"
          >
            <Col>
              <Row
                justify="space-between"
                type="flex"
              >
                <Col>
                  <MenuButton/>
                </Col>
                <Col>
                  <MenuBadge
                    rightText={props.rightBadgeText}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <MenuButton
                action={openDrawer}
              />
            </Col>
          </Row>
          :
          <Row
            justify="space-between"
            type="flex"
          >
            <Col>
              <Row
                justify="center"
                type="flex"
              >
                <Col>
                  <MenuButton/>
                </Col>
                <Col>
                  <MenuBadge
                    rightText={localConfig.text.topRightSlugText}
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <MenuButton
                action={openDrawer}
              />
            </Col>
          </Row>
      }
    </div>
  );
}

export default MenuHeader;
