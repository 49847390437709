import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericForm from '../../form/GenericForm';

import config from '../../utils/config';

import './CardInnerWidgetForm.less';

const CardInnerWidgetForm = (
  {
    handleCardInnerWidgetClick,
    handleDataSubmit,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleFormHeaderButton = () =>
    props.formProps.hasButton ?
      props.formProps.hasButton
      :
      !props.formProps.hasButton
  ;

  console.log(props)

  return (
    <Row
      justify="unsafe"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <GenericForm
          actions={props.formProps.actions}
          avatar={props.formProps.avatar}
          cover={props.formProps.cover}
          datatransfomation={props.datatransfomation}
          defaultFields={props.formProps.defaultFields}
          externalStaticData={props.externalStaticData}
          formFields={props.formProps.formFields}
          formFieldsReverse={props.formProps.formFieldsReverse}
          grid={props.formProps.grid}
          handleSubmit={
            handleDataSubmit ?
              handleDataSubmit
              :
              handleCardInnerWidgetClick
          }
          hasBaseButton={props.formProps.hasBaseButton}
          hasButton
          hasCover={props.formProps.hasCover}
          hasFooter
          hasHeader={props.formProps.hasHeader}
          header={props.formProps.header}
          innerConfig={localConfig}
          innerPages={props.innerPages}
          instruction={props.formProps.instruction}
          justify={props.formProps.justify}
          menu={props.formProps.menu}
          navigate={navigate}
          navigation={props.navigation}
          preview={props.formProps.preview}
          replacementTitle={props.formProps.replacementTitle}
          secondaryStaticData={props.secondaryStaticData}
          staticData={props.staticData}
          subject={props.subject}
          submitHandler={
            props.subject ?
              (
                props.formProps.submitHandlerThirdParty &&
                props.step == props.formProps.submitHandlerThirdParty.submitStep
              ) ?
                props.formProps.submitHandlerThirdParty
                :
                props.formProps.submitHandlerAlternate
              :
              props.formProps.submitHandler
          }
          submitLabel={props.formProps.submitLabel}
          text={props.text}
          title={props.formProps.title}
        />
      </Col>
    </Row>
  );
}

export default CardInnerWidgetForm;
