import config from '../utils/config';

import {
  getRequest,
  postRequest
} from '../aws/aws_rest_client';

const { api } = config;
const { methods } = api;

const createInvitation = async (parameters) =>
  postRequest(
    methods.invitations.create,
    {
      body: parameters
    }
  );

const invitationInfo = async (parameters) =>
  getRequest(
    methods.invitations.info,
    {
      queryStringParameters: parameters
    }
  );

const listInvitations = async (
  parameters,
  reducer,
  reducerParameters
) =>
  getRequest(
    methods.invitations.list,
    {
      queryStringParameters: parameters
    },
    reducer,
    reducerParameters
  );

const updateInvitation = async (parameters) =>
  postRequest(
    methods.invitations.update,
    {
      body: parameters
    }
  );

export {
  createInvitation,
  invitationInfo,
  listInvitations,
  updateInvitation
};
