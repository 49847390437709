/* eslint-disable no-unused-expressions */
import React, { createRef, useEffect, useRef } from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import classie from 'foliography-content-library/library/classie.js';

import config from '../utils/config';

import './MenuBadge.less';

const MenuBadge = (
  {
    ...props
  }
) => {

  const {
    carouselContent,
    carouselContentLevel,
    classieContentBadgeText,
    hasClassieContentBadge,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const animationDelay = 2500;

  const hiddenCssClassSelector = 'is-hidden';
  const visibleCssClassSelector = 'is-visible';

  const handleClassieContentBadgeText = () =>
    (
      classieContentBadgeText &&
      classieContentBadgeText.length
    ) ?
      classieContentBadgeText
      :
      localConfig.text.classieContentBadgeText
    ;

  let elementArray = useRef(
    Array.apply(null, Array(handleClassieContentBadgeText().length)).map(
      item => createRef()
    )
  );

  console.log(props);

  const handleJustify = () =>
    carouselContent && carouselContent !== localConfig.defaultThirdPartyParametersAlternate[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
      classieContentBadgeText && classieContentBadgeText.length ?
        localConfig.grid.menuBadgeJustify +
        localConfig.text.activityResultSeparatorText +
        carouselContent
        :
        localConfig.grid.menuBadgeJustify
      :
      localConfig.grid.menuBadgeJustify
    ;

  const handleNextClassieContentTextItem = (
    index,
    localClassieContentBadgeText = handleClassieContentBadgeText()
  ) =>
    ++index == localClassieContentBadgeText.length ?
      index - index
      :
      index
    ;

  const handleSwitchClassieContentTextItem = (
    oldIndex,
    newIndex,
    localElementArray = elementArray.current
  ) => {
    localElementArray[oldIndex] &&
    localElementArray[oldIndex].current && (
      classie.remove(localElementArray[oldIndex].current, visibleCssClassSelector),
      classie.add(localElementArray[oldIndex].current, hiddenCssClassSelector)
    );
    localElementArray[newIndex] &&
    localElementArray[newIndex].current && (
      classie.remove(localElementArray[newIndex].current, hiddenCssClassSelector),
      classie.add(localElementArray[newIndex].current, visibleCssClassSelector)
    );
  }

  const handleClassieContentTextItem = (
    index,
    nextIndex = handleNextClassieContentTextItem(index)
  ) => {
    handleSwitchClassieContentTextItem(index, nextIndex);
    setTimeout(
      handleClassieContentTextItem,
      animationDelay,
      nextIndex
    );
  }

  const handleRightText = () =>
    handleClassieContentBadgeText().length ?
      props.rightTextThirdParty ?
        props.rightTextThirdParty
        :
        props.rightText
      :
      props.rightText

  console.log(elementArray)

  useEffect(
    (
      localClassieContentBadgeText = handleClassieContentBadgeText(),
      localElementArray = elementArray.current.map(
        item => item ?
          item
          :
          createRef()
      ),
      localIndex = localClassieContentBadgeText.length - localClassieContentBadgeText.length
    ) => {
      console.log(elementArray);
      localElementArray[localIndex] &&
      localElementArray[localIndex].current &&
      setTimeout(
        handleClassieContentTextItem,
        animationDelay,
        localIndex
      );
    }
    ,
    [elementArray]
  )
  ;

  return (
    <Row
      justify={handleJustify()}
      type="flex"
    >
      <Col>
      </Col>
      <Col>
        {props.leftText}
      </Col>
      <Col>
        {handleRightText()}
      </Col>
        {
          hasClassieContentBadge ?
            handleClassieContentBadgeText().length ?
              [
                <Col>
                  {localConfig.text.classieContentBadgeSeparatorText}
                </Col>
                ,
                <Col>
                  {
                    handleClassieContentBadgeText().map(
                      (item, index) =>
                        elementArray.current[index] &&
                        <span
                          ref={elementArray.current[index]}
                        >
                          {item && item.text}
                        </span>
                    )
                  }
                </Col>
              ]
              :
              props.rightTextThirdParty ?                
                <Col>
                  {props.rightTextThirdParty}
                </Col>
                :
                <Col>
                  {props.rightTextAlternate}
                </Col>
            :
            <Col>
              {props.rightTextAlternate}
            </Col>
        }
    </Row>
  );
}

export default MenuBadge;
