import React from 'react';

import {
  Anchor,
  Button,
  Col,
  Row
} from 'antd';

import config from '../utils/config';

const { Link } = Anchor;

const GenericPanel = (
  {
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleJustify = () =>
    props.justify ?
      props.justify
      :
      localConfig.grid.justify

  const handleInnerJustify = () =>
    props.form ?
      localConfig.grid.formJustify
      :
      localConfig.grid.panelJustify

  const handleSpan = () =>
    props.edit ?
      localConfig.grid.mediumGridSpan
      :
      localConfig.grid.gridSpan

  return (
    <Row
      justify={handleJustify()}
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <Row
          justify={handleInnerJustify()}
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            <Row
              justify="inherit"
              type="flex"
            >
              <Col
                span={handleSpan()}
              >
                {props.showTitle && props.title}
              </Col>
              {
                props.edit &&
                <Col
                  span={localConfig.grid.mediumGridSpan}
                >
                  <Button
                    onClick={() => props.showAlternateContent(props.alternateContent)}
                    type="link"
                  >
                    {localConfig.text.edit}
                  </Button>
                </Col>
              }
            </Row>
            <Row
              justify="initial"
              type="flex"
            >
              <Col
                span={localConfig.grid.gridSpan}
              >
                {props.children}
              </Col>
            </Row>
            {
              props.showLink &&
              <Row
                justify="end"
                type="flex"
              >
                <Col
                >
                  <Anchor affix={false}>
                    <Link
                      title={props.linkText}
                    >
                    </Link>
                  </Anchor>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default GenericPanel;
