import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Badge,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import CircularProgress from '../../general/CircularProgress';
import InformationCard from '../../general/InformationCard';
import ScrollableContainer from '../../general/ScrollableContainer';

import config from '../../utils/config';

import './MainThirdPartyMenuInnerWidget.less';

const MainThirdPartyMenuInnerWidget = (
  {
    handleThirdPartyAlternateMenuClick,
    handleThirdPartyMenuMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleFilter = (innerItem) =>
    (!
      (
        innerItem[localConfig.defaultFifthPartyField] &&
        (
          innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
          innerItem[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
        )
      )
    )
    ;

  const handleThirdPartyMenuInnerItem = (innerItem) =>
    innerItem[localConfig.defaultFifthPartyField] ?
      innerItem[localConfig.defaultFifthPartyField].title
      :
      localConfig.text.inlineInformationSlugText
    ;

  console.log(props.staticData)

  return (
    <Row
      justify="unset"
      type="flex"
    >
      <Col>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col
            onMouseDown={event => handleThirdPartyAlternateMenuClick(event)}
          >
            {props.item.alternateTitle}
          </Col>
          <Col>
            <MenuButton
              action={handleThirdPartyAlternateMenuClick}
            />
          </Col>
        </Row>
        <ScrollableContainer
          innerConfig={localConfig}
        >
          {
            (
              props.item.parent &&
              props.item.items &&
              props.item.items[props.item.items.length - props.item.items.length / props.item.items.length] &&
              props.item.items[props.item.items.length - props.item.items.length / props.item.items.length].parent
            )
            &&
            (
              props.staticData &&
              props.staticData.length ?
                props.staticData.map(
                  (item, index) =>
                    item[localConfig.defaultSecondaryField] ?
                      item[localConfig.defaultSecondaryField].map(
                        (innerItem, innerIndex) =>
                          innerItem.parent &&
                          // handleFilter(innerItem) &&
                          <Row
                            justify="start"
                            type="flex"
                          >
                            <Col
                            >
                              {
                                innerItem.hasActivity &&
                                innerItem.avatar &&
                                <Badge
                                  count={innerItem.activityCount}
                                >
                                  <Avatar
                                    src={innerItem.avatar}
                                  />
                                </Badge>
                              }
                            </Col>
                            <Col
                              onMouseDown={event => handleThirdPartyMenuMenuClick(event, innerItem)}
                            >
                              <InformationCard
                                hasInnerContent={innerItem.hasActivity}
                                innerConfig={localConfig}
                                replacementDescriptionText={handleThirdPartyMenuInnerItem(innerItem)}
                                replacementTitleText={localConfig.text.inlineInformationAlternateSlugText}
                                subject={innerItem}
                              />
                            </Col>
                          </Row>
                      )
                      :
                      <CircularProgress/>
                )
                :
                <CircularProgress/>
            )
          }
        </ScrollableContainer>
      </Col>
    </Row>
  );
}

export default MainThirdPartyMenuInnerWidget;
