import React from 'react';

import {
	AutoComplete,
	Col,
	Row
} from 'antd';

import config from '../utils/config';

import './AutoCompleteField.less';

const { Option } = AutoComplete;

const AutoCompleteField = (
  {
		fieldValues,
    onChangeField,
    ...props
  }
) => {
	const {
    innerConfig
  } = props;

	const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  const handleChange = (
		value,
		localInnerItem =
			props.externalStaticData[props.field].find(
				item => item[props.innerExternalStaticData.valueField] == value
			)
		) =>
    onChangeField(
			props.extraField ?
	      {
					[props.extraField]: localInnerItem[props.innerExternalStaticData.extraField],
					[props.field]: localInnerItem[props.innerExternalStaticData.titleField],
				}
				:
				{
					[props.field]: value
				}
    )
	;

	const handleDefaultValue = () =>
    fieldValues &&
    fieldValues[props.field]
	;

  const handleOptions = () =>
    props.externalStaticData ?
      props.externalStaticData[props.field]
      :
      props.options

	return (
    <AutoComplete
			defaultValue={handleDefaultValue()}
      onChange={handleChange}
      options={handleOptions()}
      placeholder={props.placeholder}
      filterOption={
        (inputValue, option) =>
            props.externalStaticData  ?
              Math.abs(option.props.children.toUpperCase().indexOf(inputValue.toUpperCase())) == option.props.children.toUpperCase().indexOf(inputValue.toUpperCase())
              :
              Math.abs(option.props.value.toUpperCase().indexOf(inputValue.toUpperCase())) == option.props.value.toUpperCase().indexOf(inputValue.toUpperCase())

      }
    >
      {
        handleOptions() &&
        handleOptions().map(
          item =>
            props.externalStaticData  ?
              <Option
                key={item[props.innerExternalStaticData.valueField]}
                value={item[props.innerExternalStaticData.valueField]}
              >
                {item[props.innerExternalStaticData.titleField]}
              </Option>
              :
              <Option
                key={item.value}
                value={item.value}
              >
                {item.value}
              </Option>
        )
      }
    </AutoComplete>
  )
}

export default AutoCompleteField;
