import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericForm from '../../form/GenericForm';

import config from '../../utils/config';

const CardHeaderWidgetForm = (
  {
    handleCardHeaderWidgetClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="unsafe"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <GenericForm
          formFields={props.formProps.formFields}
          grid={props.grid}
          handleSubmit={props.actionHandler}
          innerConfig={localConfig}
          secondaryStaticData={props.secondaryStaticData}
          submitHandler={props.form.submitHandler}
          submitLabel={props.formProps.submitLabel}
          title={props.formProps.title}
        />
      </Col>
    </Row>
  );
}

export default CardHeaderWidgetForm;
