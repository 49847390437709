import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row,
  Switch
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

const SwitchField = (
  {
    fieldValues,
    onChangeField,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  console.log(props);
  console.log(fieldValues);

  const handleChange = (value) =>
  {
    onChangeField(
      {
        [props.field]: value
      }
    )
  }

  return (
    <Row
      justify="start"
      type="flex"
    >
      <Col>
        <Switch
          onChange={handleChange}
        />
        {props.status}
      </Col>
    </Row>
  );
}

export default SwitchField;
