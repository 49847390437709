import {
  guestStorageGetItem,
  guestStorageSetItem
} from './libs/vowtch-cross-domain/cross-domain';

import {
  useStateWithLocalStorage
} from './libs/hooks/localstorage';

import {
  createCreativecontent,
  creativecontentInfo,
  deleteCreativecontent,
  listCreativecontent,
  updateCreativecontent
} from './libs/services/creativecontent';

import {
  createGroup,
  groupInfo,
  listGroups,
  updateGroup
} from './libs/services/groups';

import {
  createInvitation,
  invitationInfo,
  listInvitations,
  updateInvitation
} from './libs/services/invitations';


import {
  createUser,
  listUsers,
  loginUser,
  updateUser
} from './libs/services/users';

import CardContainer from './components/general/CardContainer';
import CircularProgress from './components/general/CircularProgress';
import ConnectedGenericCarousel from './components/general/GenericCarousel';
import ConnectedFormDrawer from './components/form/FormDrawer';
import ConnectedMenuDrawer from './components/menu/MenuDrawer';
import GenericCard from './components/card/GenericCard';
import GenericForm from './components/form/GenericForm';
import GenericHeader from './components/general/GenericHeader';
import GenericPanelCollection from './components/panel/GenericPanelCollection';
import InformationCard from './components/general/InformationCard';
import MainFooter from './components/menu/MainFooter';
import MenuBadge from './components/menu/MenuBadge';

const Components = {
  CardContainer,
  CircularProgress,
  ConnectedGenericCarousel,
  ConnectedFormDrawer,
  ConnectedMenuDrawer,
  GenericCard,
  GenericForm,
  GenericHeader,
  GenericPanelCollection,
  InformationCard,
  MainFooter,
  MenuBadge
};

const Hooks = {
  guestStorageGetItem,
  guestStorageSetItem,
  useStateWithLocalStorage
};

const creativecontentService = {
  createCreativecontent,
  creativecontentInfo,
  deleteCreativecontent,
  listCreativecontent,
  updateCreativecontent
};

const groupService = {
  createGroup,
  groupInfo,
  listGroups,
  updateGroup
};

const invitationService = {
  createInvitation,
  invitationInfo,
  listInvitations,
  updateInvitation
};


const userService = {
  createUser,
  listUsers,
  loginUser,
  updateUser
};

const Services = {
  creativecontentService,
  groupService,
  invitationService,
  userService
};

const Vowtch = {
  Components,
  Hooks,
  Services
};


export default Vowtch;
