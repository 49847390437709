import React from 'react';

const Footer = ({ data }) => {
  return (
    <div>
    </div>
  );
}


export default Footer
