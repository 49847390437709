import React from 'react';

import { connect } from "react-redux";

import ReactHtmlParser from 'react-html-parser';

import Texty from 'rc-texty';

import TweenOne from 'rc-tween-one';

import {
  Avatar,
  Card,
  Col,
  Icon,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './InformationCard.less';

const { Meta } = Card;

const InformationCard = (
  {
    ...props
  }
) => {

  const {
    handleMouseDown,
    handleThirdPartyMouseDown,
    hasAnimation,
    hasExtendedAvatar,
    hasExtendedContent,
    hasHiddenContent,
    hasInnerContent,
    hasMediumAvatar,
    hasPartialContent,
    informationReverse,
    innerInformationReverse,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleAvatar = () =>
    hasMediumAvatar ?
      props.subject.avatarMedium
      :
      props.subject.avatar

  const  handleExtendedAvatar = () =>
    props.subject.extendedAvatar

  const handleInnerContent = () =>
    localConfig.text.activitySeparatorText +
    props.subject.activityCount


    const  handleExtendedAvatarReplacementDescription = (item, secondaryItem, thirdPartyItem) =>
      secondaryItem ?
        <Row
          justify={props.secondaryJustify}
          type="flex"
        >
          <Col
          >
            {
              item &&
              <Avatar
                src={handleExtendedAvatar()}
              />
            }
          </Col>
          <Col
            onClick={event => handleInnerInnerItemActionHandler(event)}
          >
            {!(item instanceof Object) && item}
          </Col>
          <Col
          >
            {!(secondaryItem instanceof Object) && secondaryItem}
          </Col>
          <Col
            onClick={event => handleMouseDown(event, props.subject)}
          >
            {!(thirdPartyItem instanceof Object) && thirdPartyItem}
          </Col>
        </Row>
        :
        <Row
          onClick={event => handleMouseDown(event, props.subject)}
          justify={props.justify}
          type="flex"
        >
          <Col
          >
            <Avatar
              src={handleExtendedAvatar()}
            />
          </Col>
          <Col
          >
            {!(item instanceof Object) && item}
          </Col>
        </Row>

    const  handleExtendedAvatarReplacementTitle = (item, secondaryItem) =>
      <Row
        onClick={event => handleMouseDown(event, props.subject)}
        justify={props.secondaryJustify}
        type="flex"
      >
        <Col
        >
          <Avatar
            src={handleExtendedAvatar()}
          />
        </Col>
        <Col
        >
          {!(item instanceof Object) && item}
        </Col>
        <Col
        >
          {!(secondaryItem instanceof Object) && secondaryItem}
        </Col>
      </Row>


  const handleReplacementDescriptionText = (hasExtendedContent) =>
    hasExtendedContent ?
      !hasHiddenContent &&
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementDescription(
          props.subject.subDescription
        )
        :
        props.subject.subDescription
      :
      hasPartialContent ?
        props.subject.subDescription
        :
        props.replacementDescriptionText ?
          props.replacementDescriptionText
          :
          hasExtendedAvatar ?
            handleExtendedAvatarReplacementDescription(
              props.subject.description,
              props.subject.subDescription,
              props.innerWidgetAlternateSlugText
            )
            :
            props.subject.description ?
              props.subject.description
              :
              localConfig.text.activityResultSeparatorText
    ;

  const handleReplacementTitleText = (hasExtendedContent) =>
    hasExtendedContent ?
      !hasHiddenContent &&
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementTitle(
          props.subject.subTitle
        )
        :
        props.subject.subTitle
      :
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementDescription(
          props.subject.subTitle,
          props.subject.title
        )
        :
        props.replacementTitleText ?
          props.replacementTitleText
          :
          props.subject.title ?
            props.subject.title
            :
            localConfig.text.activityResultSeparatorText

    ;


    const handleInnerInnerItemActionHandler = (event) => {
      event.stopPropagation();
      console.log(localConfig)
      window.open(
        props.subjectAlternate[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty],
        localConfig.text.linkTarget
      )
    }

  console.log(props)

  return (
    <div>
      <Card
      >
        {
          props.subject &&
          hasExtendedContent ?
            <Meta
              avatar={
                <Avatar
                  src={handleAvatar()}
                />
              }
              className={props.thirdPartyJustify}
              description={
                <Card
                >
                  {
                    informationReverse ?
                      <Meta
                        description={handleReplacementDescriptionText()}
                        title={handleReplacementTitleText()}
                      />
                      :
                      <Meta
                        description={handleReplacementTitleText()}
                        title={handleReplacementDescriptionText()}
                      />
                  }
                </Card>
              }
              title={
                <Card
                >
                  {
                    informationReverse ?
                      <Meta
                        description={handleReplacementDescriptionText()}
                        title={handleReplacementTitleText()}
                      />
                      :
                      <Meta
                        description={handleReplacementTitleText()}
                        title={handleReplacementDescriptionText()}
                      />
                  }
                </Card>
              }
            />
            :
            informationReverse ?
              <Meta
                avatar={
                  <Avatar
                    onClick={handleThirdPartyMouseDown && (event => handleThirdPartyMouseDown(event, props.subject))}
                    src={!hasInnerContent && handleAvatar()}
                  >
                    {
                      hasInnerContent &&
                      handleInnerContent()
                    }
                  </Avatar>
                }
                description={handleReplacementDescriptionText()}
                title={handleReplacementTitleText()}
              />
              :
              <Meta
                avatar={
                  <TweenOne
                    animation={localConfig.carousel && localConfig.carousel.animationFourthParty}
                    moment={props.animationMoment}
                    paused={localConfig.carousel && !localConfig.carousel.animationFourthParty}
                    repeat={props.repeat}
                  >
                    <Avatar
                      onClick={handleThirdPartyMouseDown && (event => handleThirdPartyMouseDown(event, props.subject))}
                      src={!hasInnerContent && handleAvatar()}
                    >
                      {
                        hasInnerContent &&
                        handleInnerContent()
                      }
                    </Avatar>
                  </TweenOne>
                }
                description={
                  hasAnimation ?
                    <Texty
                      delay={localConfig.carousel && localConfig.carousel.textyAnimation.delay}
                      key={Math.random()}
                      mode={localConfig.carousel && localConfig.carousel.textyAnimation.mode}
                      type={localConfig.carousel && localConfig.carousel.textyAnimation.type}
                    >
                      {handleReplacementTitleText()}
                    </Texty>
                    :
                    handleReplacementTitleText()
                }
                title={handleReplacementDescriptionText()}
              />
        }
      </Card>
    </div>
  );
}

export default InformationCard;
