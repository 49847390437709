import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import config from '../../utils/config';

import './MainThirdPartyMenuInnerWidgetInlineInformation.less';

const { Link } = Anchor;

const MainThirdPartyMenuInnerWidgetInlineInformation = (
  {
    handleThirdPartyThirdPartyAlternateMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInlineInformation = () =>
    props[localConfig.text.innerWidgetInnerWidgetFormValueKey] ?
      localConfig.entity.user[props[localConfig.text.innerWidgetInnerWidgetFormValueKey]]
      :
      localConfig.entity.user.communicationFeatures
    ;

  const handleInnerItemClick = (event, innerItem) => {
    handleThirdPartyThirdPartyAlternateMenuClick();
  }

  console.log(handleInlineInformation())

  return (
    [
      <Row
        justify="baseline"
        type="flex"
      >
        <Col>
          {localConfig.text.mainThirdPartyMenuThirdPartySlugText}
        </Col>
      </Row>
      ,
      handleInlineInformation().items.map(
        (innerItem, innerItemIndex) =>
          !innerItem.handleHiddenContent &&
          <Row
            justify="baseline"
            type="flex"
          >
            <Col>
              <Row
                justify="space-between"
                type="flex"
              >
                <Col
                  onMouseDown={event => handleInnerItemClick(event, innerItem)}
                >
                  {
                    innerItem.addonBefore && innerItem.addonBefore.avatar &&
                    <Avatar
                      src={innerItem.addonBefore.avatar}
                    />
                  }
                  <Anchor>
                    <Link
                      title={innerItem.title}
                    >
                    </Link>
                  </Anchor>
                </Col>
                <Col
                >
                  {
                    handleInlineInformation().addonAfter && handleInlineInformation().addonAfter.avatar &&
                    <Avatar
                      src={handleInlineInformation().addonAfter.avatar}
                    />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
      )
    ]
  );
}

export default MainThirdPartyMenuInnerWidgetInlineInformation;
