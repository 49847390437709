import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Col,
  Icon,
  Row,
  Spin
} from 'antd';

import config from '../utils/config';

import './CircularProgressAlternate.less';

const CircularProgressAlternate = (
  props
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="start center"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <Row
          justify="start start"
          type="flex"
        >
          <Col
          >
            <Avatar
              src={props.avatar}
            />
          </Col>
        </Row>
        <Row
          justify="start start"
          type="flex"
        >
          <Col
          >
            {props.text}
          </Col>
        </Row>
        <Row
          justify="start start"
          type="flex"
        >
          <Col
          >
            <Avatar
            />
          </Col>
          <Col
          >
            <Avatar
            />
            <Avatar
            />
            <Avatar
            />
          </Col>
        </Row>
      </Col>
    </Row>

  );
}

export default CircularProgressAlternate;
