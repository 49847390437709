import ConnectedTagDrawer from './components/tags/TagDrawer';
import TagMenu from './components/tags/TagMenu';

import {
    createTagsonomy,
    listTagsonomies,
    tagsonomyInfo,
    updateTagsonomy
} from './library/services/tag';

const Components = {
  ConnectedTagDrawer
};

const tagsonomyService = {
  createTagsonomy,
  listTagsonomies,
  tagsonomyInfo,
  updateTagsonomy
};

const Services = {
  tagsonomyService
};

const Tagsonomy = {
  Components,
  Services
};

export default Tagsonomy;
