import React from 'react';

import {
	Anchor,
	Avatar,
	Checkbox,
	Col,
	Row
} from 'antd';

import ColorPicker from 'rc-color-picker';

import MenuBadge from '../menu/MenuBadge';

import config from '../utils/config';

import 'rc-color-picker/assets/index.css';

import './Mockup.less';


const { Link } = Anchor;

const Mockup = props => {
	const {
    innerConfig
  } = props;

	const localConfig = innerConfig ? innerConfig : config;

	return (
		<Row
			justify="left"
			type="flex"
		>
			<Col
				span={localConfig.grid.gridSpan}
			>
				{
					props.colorPalette ?
						<Row
							justify="normal"
		          type="flex"
						>
							<Col
								span={localConfig.grid.gridSpan}
							>
								<Row
									justify="start"
									type="flex"
								>
									<Col
										span={localConfig.grid.mediumGridSpan}
									>
										Color Palette
									</Col>
								</Row>
								<Row
									justify="start"
									type="flex"
								>
									<Col
										span={localConfig.grid.gridSpan}
									>
										<Row
											justify="start"
											type="flex"
										>
											{
												props.subject.mockup.colors.map(
													item =>
														<Col
															span={localConfig.grid.gridSpan}
														>
															<ColorPicker
													      color={item.color}
													    />
													    {item.title} ({item.color})
														</Col>
												)
											}
										</Row>
									</Col>
								</Row>
								<Row
									justify="left"
									type="flex"
								>
									<Col>
										<Anchor
											affix={localConfig.grid.anchorAffix}
										>
											<Link
												title={localConfig.text.bottomSlugText}
											/>
										</Anchor>
									</Col>
								</Row>
							</Col>
						</Row>
						:
						<Row
							justify="space-between"
		          type="flex"
						>
							<Col>
								<MenuBadge
									rightText={props.subject.title}
								/>
							</Col>
							<Col>
								<Row
									justify="start"
									type="flex"
								>
									<Col
										span={localConfig.grid.gridSpan}
									>
										<Avatar
											src={props.subject.avatar}
										/>
									</Col>
									{
										localConfig.mock.menu.map(
											item =>
												<Col
													span={localConfig.grid.gridSpan}
												>
													<Anchor affix={false}>
														<Link
															title={item.title}
														/>
													</Anchor>
												</Col>
										)
									}
								</Row>
							</Col>
						</Row>
				}
			</Col>
		</Row>
	)
}

export default Mockup;
