import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import InformationCard from '../general/InformationCard';

import config from '../utils/config';

import './FormPreview.less';

const FormPreview = (
  {
    fieldValues,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleDatatransformationExternalStaticDataField = (
    innerInnerItem,
    innerInnerItemField,
    innerInnerItemSubject =
      props.preview.subject ?
        (
          fieldValues &&
          props.staticData[props.preview.subject].find(
            item => item[props.preview.field] == fieldValues[props.preview.subject]
          )
        ) ?
          props.staticData[props.preview.subject].find(
            item => item[props.preview.field] == fieldValues[props.preview.subject]
          )
          :
          Array.isArray(props.staticData[props.preview.subject]) ?
            props.staticData[props.preview.subject].slice().shift()
            :
            props.subject
        :
        props.subject
    ,
    localInnerItem =
      innerInnerItemSubject &&
      props.externalStaticData[innerInnerItemField].find(
        item => item[innerInnerItem.externalStaticDataField] == innerInnerItemSubject[innerInnerItemField]
      )
  ) =>
    localInnerItem &&
    localInnerItem[innerInnerItem.replacementExternalStaticDataField] ?
      localInnerItem[innerInnerItem.replacementExternalStaticDataField]
      :
      localConfig.text.activityResultSeparatorText
  ;

  const handleDatatransformation = (
    item,
    innerinnerItemReverse
  ) =>
    item.map(
      innerItem =>
        Object.assign(
          {},
          ...props.preview.datatransfomation.filter(
              innerinnerItem => innerinnerItem.step == props.step
            ).map(
              innerinnerItem =>
                (
                  (
                    (
                      (
                        innerinnerItem.alternateField &&
                        innerItem[innerinnerItem.alternateField.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                      )
                      ||
                      innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()] ||
                      (
                        innerinnerItem.thirdPartyField &&
                        innerItem[innerinnerItem.thirdPartyField.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                      )
                      ||
                      (
                        innerItem[innerinnerItem.field] &&
                        Array.from(innerItem[innerinnerItem.field]).slice().shift() !== localConfig.text.activityResultSeparatorText
                      )
                    ) &&
                    props.preview.datatransfomation.filter(
                      innerinnerInnerItem => innerinnerInnerItem.defaultField == innerinnerItem.defaultField
                    ).length > props.preview.datatransfomation.length / props.preview.datatransfomation.length
                  )
                  ||
                  (
                    props.preview.datatransfomation.filter(
                      innerinnerInnerItem => innerinnerInnerItem.defaultField == innerinnerItem.defaultField
                    ).length == props.preview.datatransfomation.length / props.preview.datatransfomation.length
                  )
                )  ?
                  Object.assign(
                    {},
                    Object.assign(
                      {},
                      innerinnerItemReverse ?
                        {
                          [innerinnerItem.defaultField]
                          :
                          Math.abs(innerinnerItem.field.indexOf(localConfig.datatransfomation.separator)) == innerinnerItem.field.indexOf(localConfig.datatransfomation.separator) ?
                            (
                              innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()] ?
                                props.externalStaticData[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()] ?
                                  handleDatatransformationExternalStaticDataField(
                                    innerinnerItem,
                                    innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()
                                  )
                                  :
                                  innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()]
                                :
                                innerItem[innerinnerItem.alternateField.split(localConfig.datatransfomation.separator).slice().shift()] ?
                                  innerItem[innerinnerItem.alternateField.split(localConfig.datatransfomation.separator).slice().shift()]
                                  :
                                  innerItem[innerinnerItem.thirdPartyField.split(localConfig.datatransfomation.separator).slice().shift()] ?
                                    innerItem[innerinnerItem.thirdPartyField.split(localConfig.datatransfomation.separator).slice().shift()]
                                    :
                                    localConfig.text.activityResultSeparatorText
                            ) +
                            innerinnerItem.separatorText +
                            (
                              innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()] ?
                                innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                                :
                                innerItem[innerinnerItem.thirdPartyField.split(localConfig.datatransfomation.separator).reverse().slice().shift()] ?
                                  innerItem[innerinnerItem.thirdPartyField.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                                  :
                                  innerItem[innerinnerItem.alternateField.split(localConfig.datatransfomation.separator).reverse().slice().shift()] ?
                                    handleDatatransformationExternalStaticDataField(
                                      innerinnerItem,
                                      innerinnerItem.alternateField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                    )
                                    :
                                    localConfig.text.activityResultSeparatorText
                            )
                            :
                            innerinnerItem.alternateExternalStaticDataField ?
                              handleDatatransformationExternalStaticDataField(
                                innerinnerItem,
                                innerinnerItem.alternateExternalStaticDataField
                              )
                              :
                              innerItem[innerinnerItem.field]
                      }
                      :
                      {
                        [innerinnerItem.field]
                        :
                        innerItem[innerinnerItem.defaultField]
                      }
                    )
                  )
                  :
                  Object.assign(
                    {}
                  )
            )
        )
    )
  ;

  console.log(props)
  console.log(fieldValues)

  const handleSubject = (
    innerItem = Object.keys(localConfig.entity.user).slice().shift()
  ) =>
    props.preview.subject ?
      (
        fieldValues &&
        props.staticData[props.preview.subject] &&
        props.staticData[props.preview.subject].find(
          item => item[props.preview.field] == fieldValues[props.preview.subject]
        )
      ) ?
        props.preview.externalSubjectField ?
          Object.assign(
            {},
            ...props.staticData[props.preview.subject].find(
              item => item[props.preview.field] == fieldValues[props.preview.subject]
            ),
            {
              [props.preview.externalSubjectField]
              :
              fieldValues[props.preview.externalSubjectField]
            }
            // ,
            // {
            //   [innerItem]
            //   :
            //   fieldValues[innerItem] ?
            //     fieldValues[innerItem]
            //     :
            //     props.staticData[innerItem]
            // }
          )
          :
          props.staticData[props.preview.subject].find(
            item => item[props.preview.field] == fieldValues[props.preview.subject]
          )
        :
        Array.isArray(props.staticData[props.preview.subject]) ?
          props.staticData[props.preview.subject].slice().shift()
          :
          props.subject ?
            props.subject
            :
            localConfig.entity.user
      :
      props.subject ?
        props.subject
        :
        localConfig.entity.user
  ;

  const handleDatatransformationSubjectItem = (item) =>
    props.preview.datatransfomation.find(
      innerItem => innerItem.defaultField == item
    )

  const handleDatatransformationSubject = (subject) =>
    Object.assign(
      {},
      ...Object.keys(localConfig.entity.user).map(
        item =>
          Object.assign(
            {},
            Object.assign(
              {},
              subject[item] ?
                Array.from(subject[item]).slice().shift() !== localConfig.text.activityResultSeparatorText ?
                  {
                    [item]
                    :
                    subject[item]
                  }
                  :
                  (
                    Math.abs(subject[item].indexOf(
                        handleDatatransformationSubjectItem(item).separatorText
                      )
                    ) == subject[item].indexOf(
                        handleDatatransformationSubjectItem(item).separatorText
                      )
                    &&
                    handleDatatransformationSubjectItem(item).separatorText !== localConfig.text.activityResultSeparatorText
                  ) ?
                    {
                      [item]
                      :
                      (
                        props.externalStaticData[
                          handleDatatransformationSubjectItem(item).field.split(localConfig.datatransfomation.separator).slice().shift()
                        ] &&
                        props.externalStaticData[
                          handleDatatransformationSubjectItem(item).field.split(localConfig.datatransfomation.separator).slice().shift()
                        ].find(
                          innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                            subject[item].split(
                              handleDatatransformationSubjectItem(item).separatorText
                            ).slice().shift()
                          ) ?
                            props.externalStaticData[
                              handleDatatransformationSubjectItem(item).field.split(localConfig.datatransfomation.separator).slice().shift()
                            ].find(
                              innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                subject[item].split(
                                  handleDatatransformationSubjectItem(item).separatorText
                                ).slice().shift()
                              )[handleDatatransformationSubjectItem(item).secondarExternalStaticDataField]
                              :
                              props.externalStaticData[
                                handleDatatransformationSubjectItem(item).field.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                              ] &&
                              props.externalStaticData[
                                handleDatatransformationSubjectItem(item).field.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                              ].find(
                                innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                  subject[item].split(
                                    handleDatatransformationSubjectItem(item).separatorText
                                  ).reverse().slice().shift()
                                ) ?
                                  props.externalStaticData[
                                    handleDatatransformationSubjectItem(item).field.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                  ].find(
                                    innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                      subject[item].split(
                                        handleDatatransformationSubjectItem(item).separatorText
                                      ).reverse().slice().shift()
                                    )[handleDatatransformationSubjectItem(item).secondarExternalStaticDataField]
                                  :
                                  props.externalStaticData[
                                    handleDatatransformationSubjectItem(item).alternateField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                  ] &&
                                  props.externalStaticData[
                                    handleDatatransformationSubjectItem(item).alternateField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                  ].find(
                                    innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                      subject[item].split(
                                        handleDatatransformationSubjectItem(item).separatorText
                                      ).reverse().slice().shift()
                                    ) ?
                                    props.externalStaticData[
                                      handleDatatransformationSubjectItem(item).alternateField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                    ].find(
                                      innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                        subject[item].split(
                                          handleDatatransformationSubjectItem(item).separatorText
                                        ).reverse().slice().shift()
                                      )[handleDatatransformationSubjectItem(item).secondarExternalStaticDataField]
                                    :
                                    props.externalStaticData[
                                      handleDatatransformationSubjectItem(item).thirdPartyField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                    ] &&
                                    props.externalStaticData[
                                      handleDatatransformationSubjectItem(item).thirdPartyField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                    ].find(
                                      innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                        subject[item].split(
                                          handleDatatransformationSubjectItem(item).separatorText
                                        ).reverse().slice().shift()
                                      ) ?
                                      props.externalStaticData[
                                        handleDatatransformationSubjectItem(item).thirdPartyField.split(localConfig.datatransfomation.separator).reverse().slice().shift()
                                      ].find(
                                        innerItem => innerItem[handleDatatransformationSubjectItem(item).replacementExternalStaticDataField] ==
                                          subject[item].split(
                                            handleDatatransformationSubjectItem(item).separatorText
                                          ).reverse().slice().shift()
                                        )[handleDatatransformationSubjectItem(item).secondarExternalStaticDataField]
                                        :
                                        localConfig.entity.user[item]
                      )
                    }
                    :
                    {
                      [item]
                      :
                      localConfig.entity.user[item]
                    }
                  :
                  {
                    [item]
                    :
                    localConfig.entity.user[item]
                  }
            )
          )
      )
    )

  console.log(handleSubject())
  console.log(handleDatatransformation([handleSubject()], handleSubject()).slice().shift())
  console.log(handleDatatransformationSubject(handleDatatransformation([handleSubject()], handleSubject()).slice().shift()));

  const handleExtendedContent = (
    localSwitchField =
      props.preview.datatransfomation &&
      props.preview.datatransfomation.find(
        element => element.switchField
      )
    ,
    switchField =
      localSwitchField &&
      localSwitchField.switchField
  ) =>
    props.preview.hasExtendedContent &&
    switchField &&
    fieldValues &&
    fieldValues[switchField]
  ;

  console.log(
    fieldValues
  )


  return (
    <Row
      justify="center"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <Row
          justify="left"
          type="flex"
        >
          <Col
            span={localConfig.grid.gridSpan}
          >
            {props.preview.title}
          </Col>
        </Row>
      </Col>
      <Col
        span={localConfig.grid.gridSpan}
      >
        <InformationCard
          hasExtendedContent={handleExtendedContent()}
          informationReverse
          subject={handleDatatransformationSubject(handleDatatransformation([handleSubject()], handleSubject()).slice().shift())}
        />
      </Col>
    </Row>
  );
}

export default FormPreview;
