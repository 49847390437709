import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import config from '../utils/config';

import './CardContainer.less';

const CardContainer = (
  {
    alternateCardShown,
    ...props

  }
) => {

  const {
    isMixinContent,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    isMixinContent ?
      <Row
        justify="safe"
        type="flex"
      >
        <Col
          span={localConfig.grid.gridSpan}
        >
          {props.card}
        </Col>
      </Row>
      :
      <Row
        justify="unsafe"
        type="flex"
      >
        <Col
          span={localConfig.grid.gridSpan}
        >
          {props.card}
        </Col>
      </Row>

  );
}

export default CardContainer;
