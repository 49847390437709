import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

import MainFifthPartyMenuTopWidget from './auxiliary/MainFifthPartyMenuTopWidget';
import MainMenuDynamicBody from './auxiliary/MainMenuDynamicBody';
import MenuInputItem from './auxiliary/MenuInputItem';
import MenuSelectItem from './auxiliary/MenuSelectItem';
import MenuStaticItem from './auxiliary/MenuStaticItem';
import MainMenuTopWidget from './auxiliary/MainMenuTopWidget';

import config from '../utils/config';

import './MainSixthPartyMenu.less';

const MainSixthPartyMenu = (
  {
    handleActionClick,
    handleMenuClick,
    menu,
    openDrawer,
    ...props
  }
) => {

  const {
    formField,
    hasFooter,
    hasTitle,
    innerConfig,
    navigate,
    sixthPartyMenuShown
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleSubject = (innerItem) =>
    innerItem[localConfig.defaultSixthPartyField] ?
      Object.assign(
        {},
        ...innerItem,
        {
          [
            localConfig.defaultSixthPartyFieldAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyField]
        },
        {
          [
            localConfig.defaultSixthPartyFieldAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternate]
        },
        {
          [
            localConfig.defaultSixthPartyFieldAlternateAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternateAlternate]
        }
      )
      :
      Object.assign(
        {},
        ...innerItem,
        {
          [
            localConfig.defaultSixthPartyFieldAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternate]
        }
        ,
        {
          [
            localConfig.defaultSixthPartyFieldAlternateAlternate
          ]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternateAlternate]
        }
      )
    ;

  const handleStaticData = ()  =>
    localConfig.defaultThirdPartyThirdPartyThirdPartyFieldAlternate ?
      props.staticData &&
      props.staticData.length &&
      [
        Object.assign({},
          {
            [localConfig.defaultSecondaryField]
            :
            props.staticData[props.staticData.length / props.staticData.length][localConfig.defaultThirdPartyThirdPartyThirdPartyField][localConfig.defaultThirdPartyThirdPartyThirdPartyFieldAlternate].slice().shift().items.map(
              item => handleSubject(item)
            )
          },
          {
            parent
            :
            {
            }
          }
        )
      ]
      :
      [localConfig.menu.sixthPartyMenuItem.user]
    ;
  console.log(props);

  return (
    <div>
      <Row
        justify="stretch stretch space-between"
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline"
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={['right', 'left']}
          >
            <Menu.Item>
              <MenuHeader
                innerConfig={localConfig}
                isSixthPartyMenu
                openDrawer={openDrawer}
                rightBadgeText={localConfig.text.topLeftSlugSixthPartyText}
              />
            </Menu.Item>
            <MainMenuDynamicBody
              formField={formField}
              handleActionClick={handleActionClick}
              handleMenuClick={handleMenuClick}
              innerConfig={localConfig}
              menu={menu}
              staticData={handleStaticData()}
            />
            <Menu.Item>

            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
            <Menu.Item>

            </Menu.Item>
          </QueueAnim>
        </Col>
      </Row>
    </div>
  )
}

export default MainSixthPartyMenu;
