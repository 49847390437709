import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import MenuInputItem from './MenuInputItem';

import config from '../../utils/config';

import './MainThirdPartyMenuTopWidget.less';

const MainFifthPartyMenuTopWidget = (
  {
    handleMenuClick,
    menu,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleMenuButtonClick = (event) => {
    event.stopPropagation();
    console.log(menu);
    navigate(
      menu.fifthPartyItem.href ?
        menu.fifthPartyItem.href
        :
        localConfig.menu.fifthPartyItem.href
    );
  }

  const handleMenuButtonTitle = () =>
    menu.extraButtonTitle ?
      menu.extraButtonTitle
      :
      localConfig.menu.fifthPartyItem.extraButtonTitle
  ;

  return (
    <Row
      justify="inherit"
      type="flex"
    >
      <Col>
        <MenuInputItem
          defaultValue={localConfig.text.topMainFifthPartyMenuDynamicBodySlugText}
          handleMenuClick={handleMenuClick}
          innerConfig={localConfig}
          menu={localConfig.menu.fifthPartyItem}
        />
      </Col>
      <Col>
        <MenuButton
          action={event => handleMenuButtonClick(event)}
          title={handleMenuButtonTitle()}
        />
      </Col>
    </Row>
  );
}

export default MainFifthPartyMenuTopWidget;
