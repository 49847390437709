import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Col,
  Row
} from 'antd';

import ScrollableContainer from '../general/ScrollableContainer';

import config from '../utils/config';

import './CustomCompositeField.less';

const CustomCompositeField = (
  {
    handleDefaultFields,
    handleInnerInnerItemSubmitHandler,
    onChangeField,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const [composite, setComposite] = useState();

  const handleAction = (item, index) =>
    Object.assign(
      {},
      item,
      {
        action: Object.assign(
          {},
          item.action,
          {
            payload: item.action.payload + localConfig.text.alternateActivityResultThirdPartySeparatorText + index
          }
        )
      }
    )

  const handleAvatarSource = (
    item,
    index,
    localDefaultFields = handleDefaultFields()
  ) =>
    props.fieldValues ?
      props.fieldValues[item.title + index] ?
        props.fieldValues[item.title + index]
        :
        props.subject ?
          props.subject[localConfig.defaultThirdPartyThirdPartyField] ?
            props.subject[localConfig.defaultThirdPartyThirdPartyField][item.title + index] ?
              props.subject[localConfig.defaultThirdPartyThirdPartyField][item.title + index][localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateThirdParty]
              :
              localDefaultFields[item.title + index] ?
                localDefaultFields[item.title + index]
                :
                props.staticData[localConfig.defaultFormField]
            :
            localDefaultFields[item.title + index] ?
              localDefaultFields[item.title + index]
              :
              props.staticData[localConfig.defaultFormField]
          :
          localDefaultFields[item.title + index] ?
            localDefaultFields[item.title + index]
            :
            props.staticData[localConfig.defaultFormField]
      :
      props.subject ?
        props.subject[localConfig.defaultThirdPartyThirdPartyField] ?
          props.subject[localConfig.defaultThirdPartyThirdPartyField][item.title + index] ?
            props.subject[localConfig.defaultThirdPartyThirdPartyField][item.title + index][localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateThirdParty]
            :
            localDefaultFields[item.title + index] ?
              localDefaultFields[item.title + index]
              :
              props.staticData[localConfig.defaultFormField]
          :
          localDefaultFields[item.title + index] ?
            localDefaultFields[item.title + index]
            :
            props.staticData[localConfig.defaultFormField]
        :
        localDefaultFields[item.title + index] ?
          localDefaultFields[item.title + index]
          :
          props.staticData[localConfig.defaultFormField]
  ;

  const handleComposite = (
    localComposite = composite
  ) =>
    // localComposite ?
    //   localComposite
    //   :
      props.fieldValues &&
      (
        [
          ...Object.keys(
            (
              props.subject &&
              props.subject[localConfig.defaultThirdPartyThirdPartyField]
            ) ?
            props.subject[localConfig.defaultThirdPartyThirdPartyField]
            :
            handleDefaultFields()
          ),
          ...Object.keys(props.fieldValues)
        ]
        .map(
          (item, index)  =>
            (
              Array.isArray(localComposite) ?
                Math.abs(item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty.title)) ==
                  item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty.title) ?
                    [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty]
                    :
                    Math.abs(item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary.title)) ==
                      item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary.title) ?
                        [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary]
                        :
                        Math.abs(item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentType.title)) ==
                          item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentType.title) ?
                            [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentType]
                            :
                            [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentType]
                :
                Math.abs(item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty.title)) ==
                  item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty.title) ?
                    localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty
                    :
                    Math.abs(item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary.title)) ==
                      item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary.title) ?
                        localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary
                        :
                        Math.abs(item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentType.title)) ==
                          item.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentType.title) ?
                            localConfig.entity.user.publishingContentAlternateTypes.contentType
                            :
                            localConfig.entity.user.publishingContentAlternateTypes.contentType
            )
        )
      )

    ;

  console.log(handleDefaultFields())
  console.log(handleComposite())
  console.log(props)
  console.log(
    handleComposite()
  )
  // console.log([
  //   ...Object.keys(handleDefaultFields()),
  //   ...Object.keys(props.fieldValues)
  // ])

  const handleCompositeChange = (
    event,
    contentType,
    contentTypeSecondary,
    contentTypeThirdParty,
    localComposite = handleComposite()
  ) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(localComposite);
    setComposite(
      Array.isArray(localComposite) ?
        contentTypeThirdParty ?
          [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty]
          :
          contentTypeSecondary ?
            [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary]
            :
            contentType ?
              [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentType]
              :
              [...localComposite, localConfig.entity.user.publishingContentAlternateTypes.contentType]
        :
        contentTypeThirdParty ?
          [localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdParty]
          :
          contentTypeSecondary ?
            [localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary]
            :
            contentType ?
              [localConfig.entity.user.publishingContentAlternateTypes.contentType]
              :
              [localConfig.entity.user.publishingContentAlternateTypes.contentType]
    );
  }

  const handleCompositeChangeAlternate = (event, item, index) => {
    event.preventDefault();
    event.stopPropagation();
    console.log(index)
    console.log(composite)
    console.log(props.fieldValues)

    onChangeField(
      {
        [item.title + index]
        :
        !props.fieldValues[item.title + index]
      }
    )

    // setComposite(
    //   Array.isArray(composite) ?
    //     composite.map(
    //       (innerItem, innerIndex) => (
    //         (
    //           innerIndex == index
    //         ) ?
    //         !innerItem
    //         :
    //         innerItem
    //       )
    //     )
    //     :
    //     [localConfig.progress.progressPercent]
    // );
  }

  return (
    <div>
      <Row
        justify="end inherit"
        type="flex"
      >
        <Col
        >
          <Button
            onClick={
              event => handleInnerInnerItemSubmitHandler(
                event,
                handleAction(
                  localConfig.entity.user.publishingContentAlternateTypes.contentTypeThirdPartyAlternate,
                  handleComposite() ?
                    handleComposite().length
                    :
                    localConfig.progress.progressPercent - localConfig.progress.progressPercent
                ).action
              )
            }
          />
        </Col>
      </Row>
      <Row
  			justify="left inherit"
  			type="flex"
  		>
  			<Col
  				span={localConfig.grid.gridSpan}
  			>
          <ScrollableContainer
            innerConfig={localConfig}
          >
            {
              handleComposite() &&
              Array.isArray(handleComposite()) &&
              handleComposite().map(
                (item, index) =>
                  item &&
                  handleAvatarSource(item, index) != props.staticData[localConfig.defaultFormField] &&
          				<Row
          					justify="left"
          					type="flex"
          				>
          					<Col>
                      <Row
                        justify="left initial"
                        type="flex"
                      >
                        <Col>
                          <Avatar
                            src={item.avatar}
                          />
          					    </Col>
                        <Col>
                          {
                            Math.abs(item.title.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary.title)) !==
                              item.title.indexOf(localConfig.entity.user.publishingContentAlternateTypes.contentTypeSecondary.title) &&
                            <Avatar
                              src={handleAvatarSource(item, index)}
                            />
                          }
              						{props.title}
              					</Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row
                        justify="end initial"
                        type="flex"
                      >
                        <Col>
                          <Button
                            onClick={event => handleInnerInnerItemSubmitHandler(event, handleAction(item, index).action)}
                          >
                            {localConfig.text.bottomRightHeaderSlugTextAlternate}
                          </Button>
                          <Button
                            onClick={event => handleCompositeChangeAlternate(event, item, index)}
                          />
          					    </Col>
                      </Row>
                    </Col>
          				</Row>
              )
            }
          </ScrollableContainer>
        </Col>
      </Row>
    </div>
  );
}

export default CustomCompositeField;
