import React from 'react';

import {
  Col,
  Row
} from 'antd';

import {
  Bar,
  BarChart,
  Line,
  ResponsiveContainer,
  Tooltip
} from "recharts";

import config from '../utils/config';

const GenerecicChart = (
  {
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    props.barChart ?
      <ResponsiveContainer
        height={props.barChart.height}
      >
        <BarChart
          data={props.data}
        >
          <Tooltip />
          <Bar
            dataKey={props.barChart.dataKey}
            fill={props.barChart.fill}
          />
        </BarChart>
      </ResponsiveContainer>
      :
      props.textChart ?
        <Row
          justify="center"
          type="flex"
        >
          <Col>
          </Col>
        </Row>
        :
        props.textLineChart ?
          [
            <Row
              justify="start"
              type="flex"
            >
              <Col
              >
                {props.data[props.textLineChart.dataKey]}
              </Col>
              <Col
              >
                <i className={props.textLineChart.icon} />
              </Col>
            </Row>,
            <Row
              justify="start"
              type="flex"
            >
              <Col
                span={localConfig.grid.gridSpan}
              >
                {props.textLineChart.title}
              </Col>
            </Row>
          ]
          :
          <Row
            justify="start"
            type="flex"
          >
            <Col
              span={localConfig.grid.gridSpan}
            >
            </Col>
          </Row>
  )
}

export default GenerecicChart;
