import config from '../utils/config';

import {
  getRequest,
  postRequest
} from '../aws/aws_rest_client';

const { api } = config;
const { methods } = api;

const createTagsonomy = async (parameters) =>
  postRequest(
    methods.tagsonomies.create,
    {
      body: parameters
    }
  );

const tagsonomyInfo = async (parameters) =>
  getRequest(
    methods.tagsonomies.info,
    {
      queryStringParameters: parameters
    }
  );

const listTagsonomies = async (
  parameters,
  reducer
) =>
  getRequest(
    methods.tagsonomies.list,
    {
      queryStringParameters: parameters
    },
    reducer
  );

const updateTagsonomy = async (parameters) =>
  postRequest(
    methods.tagsonomies.update,
    {
      body: parameters
    }
  );

export {
  createTagsonomy,
  listTagsonomies,
  tagsonomyInfo,
  updateTagsonomy
};
