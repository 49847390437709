/* eslint-disable no-unused-expressions */

import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Carousel,
  Col,
  Row
} from 'antd';

import InformationCard from './InformationCard';

import config from '../utils/config';

const TimelineCarousel = (
  {
    carouselAutoplay,
    carouselContentLevel,
    carouselInitialContent,
    data,
    handleCarouselContent,
    handleDatatransformation,
    onChangeCarouselAutoplay,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeTimelineCarousel,
    onChangeLoader,
    onChangeSubject,
    onChangeSubjectAlternate,
    openTimelineDrawer,
    openTimelineDrawerAlternate,
    openTimelineDrawerFourthParty,
    openTimelineDrawerThirdParty,
    resetData,
    setCarouselIndex,
    subject,
    timelineDrawerOpened,
    timelineDrawerOpenedAlternate,
    timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty,
    ...props
  }
) => {

  const {
    innerConfig,
    timelineCarousel
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleCarouselAafterChange = (index) => {
    setCarouselIndex(index);
  }

  const handleCarouselInitialContent = (event, item, itemIndex) => {
    onChangeLoader(handleContentThirdParty(handleDatatransformation([item]).slice().shift()));
    onChangeCarouselInitialContent(
      item[localConfig.defaultSixthPartyFieldThirdParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate ?
        itemIndex
        :
        props.pages.length - props.pages.length
    );
    console.log(handleDatatransformation([item], !item, !item, item).slice().shift());
    item[localConfig.defaultSixthPartyFieldThirdParty] != localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
    item[localConfig.defaultSixthPartyFieldThirdParty] != localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary &&
    (
      onChangeCarouselContentLevel(props.pages.length / props.pages.length),
      onChangeSubjectAlternate(subject),
      onChangeSubject(handleSubject(
          handleDatatransformation([item], !item, !item, item).slice().shift()
      ))
    );
    // onChangeCarouselAutoplay();
    openTimelineDrawerAlternate();
    timelineDrawerOpenedThirdParty &&
    openTimelineDrawerThirdParty();
    timelineDrawerOpenedFourthParty &&
    openTimelineDrawerFourthParty();

    item[localConfig.defaultSixthPartyFieldThirdParty] != localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary &&
    resetData(
      {
        data: {}
      }
    );
    !carouselContentLevel &&
    item[localConfig.defaultSixthPartyFieldThirdParty] != localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
    item[localConfig.defaultSixthPartyFieldThirdParty] != localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary &&
    onChangeCarouselContent(item[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateSecondary]);
  }

  const handleSubject = (item) =>
    Object.assign(
      {},
      ...Object.keys(item).map(
        innerItem =>
          Object.assign(
            {},
            {

              [innerItem]
              :
              item[innerItem] ?
                item[innerItem].constructor === Object ?
                  item[innerItem][Object.keys(item[innerItem]).slice().shift()].constructor === Object ?
                    item[innerItem][Object.keys(item[innerItem]).slice().shift()]
                    :
                    Array.isArray(item[innerItem][Object.keys(item[innerItem]).slice().shift()]) ?
                      item[innerItem][Object.keys(item[innerItem]).slice().shift()].slice().shift().constructor === Object ?
                        item[innerItem][Object.keys(item[innerItem]).slice().shift()].slice().shift()[Object.keys(item[innerItem][Object.keys(item[innerItem]).slice().shift()]).slice().shift()]
                        :
                        item[innerItem][Object.keys(item[innerItem]).slice().shift()]
                      :
                      item[innerItem][Object.keys(item[innerItem]).slice().shift()]
                  :
                  item[innerItem]
                :
                item[innerItem]
          }
        )
      )
    )
  ;

  const handleContentThirdParty = (innerItem) =>
    localConfig.text.centerSlugText +
    localConfig.text.activityResultSeparatorText +
    innerItem[localConfig.defaultSixthPartyFieldThirdParty]
  ;

  console.log(data);
  console.log(handleDatatransformation(data, data));
  console.log(props.pages[props.pages.length - props.pages.length / props.pages.length].node.datatransfomation);
  console.log(data[props.pages.length - props.pages.length]);
  console.log(handleDatatransformation([data[props.pages.length - props.pages.length]], data[props.pages.length - props.pages.length]));

  const handleCustomPaging = (
    index,
    localData = handleDatatransformation(data, data)
  ) =>
    (
      handleCarouselContent() ?
        <Row
          justify="start left"
          type="flex"
        >
          <Col
          >
          </Col>
        </Row>
        :
        (index != index - index) &&
        (
          Math.abs(localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(index - index, localConfig.timelineCarousel.data.itemPrefixLength).indexOf(localData[index - index / index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(index - index, localConfig.timelineCarousel.data.itemPrefixLength))) !=
            localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(index - index, localConfig.timelineCarousel.data.itemPrefixLength).indexOf(localData[index - index / index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(index - index, localConfig.timelineCarousel.data.itemPrefixLength))
        ) ?
          (
            Math.abs(localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength).indexOf(localData[index - index / index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[index - index / index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength))) !=
              localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength).indexOf(localData[index - index / index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[index - index / index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength))
          ) ?
            <Row
              justify="left start baseline"
              type="flex"
            >
              <Col
              >
              </Col>
              <Col
              >
                <Row
                  justify="left baseline"
                  type="flex"
                >
                  <Col
                  >
                  </Col>
                  <Col
                  >
                  </Col>
                </Row>
                <Row
                  justify="left baseline"
                  type="flex"
                >
                  <Col
                  >
                    {localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].length - localConfig.timelineCarousel.data.itemSuffixLength)}
                  </Col>
                </Row>
              </Col>
              <Col
              >
                <Row
                  justify="left baseline"
                  type="flex"
                >
                  <Col
                  >
                  </Col>
                  <Col
                  >
                  </Col>
                </Row>
                <Row
                  justify="left baseline"
                  type="flex"
                >
                  <Col
                  >
                    {localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(index - index, localConfig.timelineCarousel.data.itemPrefixLength).toUpperCase()}
                  </Col>
                </Row>
              </Col>
            </Row>
            :
            <Row
              justify="left start"
              type="flex"
            >
              <Col
              >
              </Col>
              <Col
              >
                <Row
                  justify="left baseline"
                  type="flex"
                >
                  <Col
                  >
                  </Col>
                  <Col
                  >
                  </Col>
                </Row>
                <Row
                  justify="left baseline"
                  type="flex"
                >
                  <Col
                  >
                    {localData[index][localConfig.defaultSixthPartyFieldThirdPartyAlternate].substring(index - index, localConfig.timelineCarousel.data.itemPrefixLength).toUpperCase()}
                  </Col>
                </Row>
              </Col>
            </Row>
          :
          <Row
            justify="start left"
            type="flex"
          >
            <Col
            >
            </Col>
          </Row>
    );


  return (
    <div>
      <Carousel
        afterChange={index => handleCarouselAafterChange(index)}
        centerMode={localConfig.timelineCarousel.centerMode}
        customPaging={handleCustomPaging}
        dots={carouselAutoplay || !handleCarouselContent() && localConfig.timelineCarousel.dots}
        draggable={localConfig.timelineCarousel.draggable}
        initialSlide={localConfig.timelineCarousel.slidesToShow}
        ref={localCarousel => onChangeTimelineCarousel(localCarousel)}
        slidesToShow={localConfig.timelineCarousel.slidesToShow}
      >
        {
          // [...Array(localConfig.timelineCarousel.slidesToFill)].fill(localConfig.grid.gridSpan).map(
          data.map(
            (item, itemIndex) =>
              <div>
                <Row
                  justify="start"
                  type="flex"
                >
                  <Col
                    onMouseDown={event =>  handleCarouselInitialContent(event, item, itemIndex)}
                  >
                    <InformationCard
                      hasExtendedAvatar
                      hasExtendedContent
                      informationReverse={item[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate}
                      subject={
                        item[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate ?
                          handleDatatransformation([item], item).slice().shift()
                          :
                          handleDatatransformation([item]).slice().shift()
                      }
                    />
                  </Col>
                </Row>
              </div>
          )
      }
      </Carousel>
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeCarouselInitialContent: (content) => dispatch (
      {
        payload: content,
        type: `onChangeCarouselInitialContent`
      }
    ),
    onChangeSubjectAlternate: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubjectAlternate`
      }
    ),
    resetData: (item) => dispatch (
      {
        payload: item,
        type: `resetData`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    carouselAutoplay: state.carouselAutoplay,
    carouselInitialContent: state.carouselInitialContent,
    data: state.data,
    subject: state.subject
  }
);

const ConnectedTimelineCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineCarousel);

export default ConnectedTimelineCarousel;
