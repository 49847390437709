import React from 'react';

import {
  Col,
  Row,
  Timeline
} from 'antd';

import config from '../utils/config';

const TimeLinePlot = (
  {
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (

    <Row
      justify="start"
      type="flex"
    >
      <Col>
        <Timeline>
          {
            props.data &&
            props.data.length &&
            props.data.map(
              item =>
                <Timeline.Item>
                  {item.description}
                </Timeline.Item>
            )
          }
        </Timeline>
      </Col>
    </Row>
  )
}

export default TimeLinePlot;
