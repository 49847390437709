import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Badge,
  Col,
  Icon,
  Input,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';
import MenuFooter from './MenuFooter';
import MenuHeader from './MenuHeader';

import MainAlternateMenuDynamicBody from './auxiliary/MainAlternateMenuDynamicBody';
import MainMenuTopWidget from './auxiliary/MainMenuTopWidget';

import ComercialCard from '../general/ComercialCard';
import InformationCard from '../general/InformationCard';
import ScrollableContainer from '../general/ScrollableContainer';

import config from '../utils/config';

import './MainAlternateMenu.less';

const { Link } = Anchor;

const MainAlternateMenu = (
  {
    handleAlternateMenuClick,
    handleAlternateMenuMenuClick,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeClassieContentBadgeText,
    onChangeLoader,
    onChangeMenu,
    onChangeSubject,
    onChangeSubjectThirdParty,
    openDrawer,
    resetData,
    showAlternateMenu,
    ...props
  }
) => {

  const {
    alternateMenuButtonShown,
    hasDivider,
    hasFooter,
    hasTitle,
    innerConfig,
    menu,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  return (
    <div>
      <Row
        justify="initial"
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline",
              onClick: handleAlternateMenuMenuClick
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={['right', 'left']}
          >
            <Menu.Item>
              <MenuHeader
                innerConfig={localConfig}
                isAlternateMenu
                openDrawer={openDrawer}
              />
            </Menu.Item>

            {
              localConfig.entity.group.types ?
                localConfig.entity.group.types.map(
                  (item, index) =>
                    item.current &&
                    <Menu.Item
                      key={index}
                    >
                      <MainMenuTopWidget
                        handleMenuClick={handleAlternateMenuClick}
                        innerConfig={localConfig}
                        menu={localConfig.menu.alternateItem}
                        subject={props.subject}
                      />
                    </Menu.Item>
                )
                :
                <Menu.Item>

                </Menu.Item>
            }
            <ScrollableContainer
              innerConfig={localConfig}
            >
              <Row
                justify="safe"
                type="flex"
              >
                <Col>
                  <QueueAnim
                    component={Menu}
                    componentProps={{
                      defaultOpenKeys: [],
                      defaultSelectedKeys: [],
                      mode: "inline",
                    }}
                    delay={localConfig.rcQueueAnim.delay}
                    duration={localConfig.rcQueueAnim.duration}
                    interval={localConfig.rcQueueAnim.interval}
                    leaveReverse
                    type={['right', 'left']}
                  >
                    {
                      props.staticData &&
                      props.staticData.length ?
                        [props.staticData.slice().shift()].map(
                          (item, index) =>
                            item.parent &&
                            item[localConfig.defaultThirdPartyThirdPartyThirdPartyField] ?
                              item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types ?
                                [
                                  item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types.map(
                                    (innerItem, innerIndex) =>
                                      !innerItem.current &&
                                      !innerItem.parent &&
                                      <Menu.Item
                                        key={innerIndex}
                                      >
                                        <MainAlternateMenuDynamicBody
                                          alternateMenuButtonShown={alternateMenuButtonShown}
                                          handleAlternateMenuClick={handleAlternateMenuClick}
                                          handleAlternateMenuMenuClick={handleAlternateMenuMenuClick}
                                          hasTitle
                                          innerConfig={localConfig}
                                          item={innerItem}
                                          menu={menu}
                                          navigate={navigate}
                                          onChangeCarouselContent={onChangeCarouselContent}
                                          onChangeCarouselContentLevel={onChangeCarouselContentLevel}
                                          onChangeCarouselInitialContent={onChangeCarouselInitialContent}
                                          onChangeClassieContentBadgeText={onChangeClassieContentBadgeText}
                                          onChangeLoader={onChangeLoader}
                                          onChangeMenu={onChangeMenu}
                                          onChangeSubject={onChangeSubject}
                                          onChangeSubjectThirdParty={onChangeSubjectThirdParty}
                                          openDrawer={openDrawer}
                                          resetData={resetData}
                                          showAlternateMenu={showAlternateMenu}
                                          subject={props.subject}
                                        />
                                      </Menu.Item>
                                  )
                                  ,
                                  item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types.length ==
                                    item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types.length / item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types.length &&
                                    <Menu.Item>
                                      <Row
                                        justify="safe"
                                        type="flex"
                                      >
                                        <Col>
                                          <ComercialCard
                                            subject={localConfig.defaulCcomercial}
                                          />
                                        </Col>
                                      </Row>
                                    </Menu.Item>
                                ]
                                :
                                <Menu.Item>

                                </Menu.Item>
                            :
                            <Menu.Item>

                            </Menu.Item>
                        )
                        :
                        <Menu.Item>

                        </Menu.Item>
                    }
                  </QueueAnim>
                </Col>
              </Row>
            </ScrollableContainer>
            {
              hasFooter &&
              <Menu.Item>
                <MenuFooter
                  innerConfig={localConfig}
                  isAlternateMenu
                  onChangeCarouselContent={onChangeCarouselContent}
                  onChangeCarouselContentLevel={onChangeCarouselContentLevel}
                  onChangeClassieContentBadgeText={onChangeClassieContentBadgeText}
                  onChangeLoader={onChangeLoader}
                  onChangeSubject={onChangeSubject}
                  onChangeSubjectThirdParty={onChangeSubjectThirdParty}
                  resetData={resetData}
                />
              </Menu.Item>
            }
          </QueueAnim>
        </Col>
      </Row>
    </div>
  );
}

export default MainAlternateMenu;
