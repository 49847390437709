import * as filestack from 'filestack-js';

import config from './filestack_config';

const { apikey } = config;

const client = filestack.init(apikey);
const token = {};

const onProgress = (event) => {

}

const createFilestackFile = (
  callback,
  parameters,
  secondaryCallback,
  secondaryParameters
) => {
  console.log(parameters);
  console.log(secondaryParameters);
  client.upload(
    parameters.file.originFileObj,
    { onProgress },
    {},
    token
  ).then(
      response =>
        callback(response)
    )
    .then(
      response =>
        secondaryCallback &&
        secondaryCallback(
          {[secondaryParameters]: response.payload.url}
        )
    )
    .catch(
      error => {
        console.log(error)
      }
    );
}

export {
  createFilestackFile
};
