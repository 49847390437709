/* eslint-disable no-unused-expressions */

import React from 'react';

import { connect } from "react-redux";

import TweenOne from 'rc-tween-one';

import classie from '../../library/classie.js';

import ClassieContentArticle from "./auxiliary/ClassieContentArticle";
import ClassieContentPost from "./auxiliary/ClassieContentPost";
import ClassieContentTitle from "./auxiliary/ClassieContentTitle";
import ClassieContentTitleAlternate from "./auxiliary/ClassieContentTitleAlternate";
import ClassieContentVideo from "./auxiliary/ClassieContentVideo";
import MenuButton from "./MenuButton";

import config from '../utils/config';

import './ClassieContent.less';

// const classie =
//   typeof window !== `undefined` ?
//     require("../../library/classie.js").default
//     :
//     undefined
// ;



const ClassieContent = (
  {
    carouselContent,
    carouselContentLevel,
    classieContentBadgeText,
    onAnimationComplete,
    onAnimationStart,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeForm,
    onChangeLoader,
    onChangeSubject,
    onChangeSubjectAlternate,
    openFormDrawer,
    resetData,
    subject,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const container = React.createRef();

  const handleDatatransformation = (item, itemItem, itemItemItem) =>
    item &&
    Array.isArray(item) &&
    item.length &&
    props.datatransfomation &&
    item.map(
      innerItem =>
        Object.assign(
          {},
          ...props.datatransfomation.filter(
              innerinnerItem =>
                itemItemItem ?
                  innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate
                  :
                  itemItem ?
                    innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary
                    :
                    innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate &&
                    innerinnerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] !== localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary
            ).map(
            innerinnerItem =>
              Object.assign(
                {},
                Object.assign(
                  {},
                  (
                    innerItem[
                      props.datatransfomation.find(
                        element => element.markerField
                      ).field
                    ]
                    &&
                    innerinnerItem.extraMarkerField
                  ) ?
                    {
                      [innerinnerItem.defaultField]
                      :
                      props.text.extraMarkerFieldSlugText
                    }
                    :
                    {
                      [innerinnerItem.defaultField]
                      :
                      Math.abs(innerinnerItem.field.indexOf(localConfig.datatransfomation.separator)) == innerinnerItem.field.indexOf(localConfig.datatransfomation.separator) ?
                        innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()] ?
                          innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()][innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                          :
                          innerItem[innerinnerItem.field]
                        :
                        innerItem[innerinnerItem.field]
                    }
                )
              )
          )
        )
    );

  const handleInnerWidgetInnerClickInnerItem = (
    innerItem,
    localItem = innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].split(localConfig.text.activityResultThirdPartySeparatorText).slice().shift()
  ) =>
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].replace(
      localItem,
      localItem.toUpperCase()
    ) +
    localConfig.text.activityResultSeparatorText +
    localConfig.text.innerWidgetInnerWidgetFormAlternateTitle
  ;

  const handleInnerWidgetInnerWidgetFormAction = (form) =>
    Object.assign({}, form, {
      handleSubmit: handleThirdPartyMouseDown
    })
  ;

  const handleThirdPartyMouseDown = (event, innerItem) => {
    onChangeSubject(innerItem);
    props.innerPages &&
      onChangeForm(
        handleInnerWidgetInnerWidgetFormAction(
          props.innerPages.filter(
            item => item.node.title ===
              (
                innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ?
                  innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
                    handleInnerWidgetInnerClickInnerItem(innerItem)
                    :
                    localConfig.text.alternateInnerWidgetInnerWidgetFormTitle
                  :
                  localConfig.text.innerWidgetInnerWidgetFormTitle
              )
          ).shift().node
        )
      );
      openFormDrawer();
  }


  console.log(props);
  console.log(props.item);
  console.log(handleDatatransformation([props.item]));
  console.log(handleDatatransformation([props.item], props.item));
  console.log(handleDatatransformation([props.item], props.item, props.item));


  console.log(props);

  return (
    <div
      className="container intro-effect-sidefixed"
      ref={container}
    >
      <header
        className="header"
      >
        <div
          className="bg-img"
        >
          {
            props.item.video && Object.getOwnPropertyNames(props.item.video).length ?
              <ClassieContentVideo
                innerConfig={localConfig}
                item={props.item}
              />
              :
              props.item.image &&
              <img src={props.item.image.src}/>
          }
        </div>
      </header>
      {
        !props.isStatic &&
        [
          <div
            className="trigger"
          >
            {
              props.item.post && Object.getOwnPropertyNames(props.item.post).length ?
                <ClassieContentPost
                  animationMoment={props.animationMoment}
                  animationPaused={props.animationPaused}
                  handleDataSubmit={handleThirdPartyMouseDown}
                  handleThirdPartyMouseDown={handleThirdPartyMouseDown}
                  innerConfig={localConfig}
                  item={handleDatatransformation([props.item], props.item).slice().shift()}
                  itemAlternate={props.item}
                  navigate={navigate}
                  onAnimationComplete={onAnimationComplete}
                  onAnimationStart={onAnimationStart}
                  onChangeForm={onChangeForm}
                  openFormDrawer={openFormDrawer}
                />
                // :
                // (
                //   classieContentBadgeText &&
                //   classieContentBadgeText.length
                // ) ?
                //   <ClassieContentTitleAlternate
                //     carouselContent={carouselContent}
                //     innerConfig={localConfig}
                //     item={handleDatatransformation([props.item]).slice().shift()}
                //     navigate={navigate}
                //   />
                :
                carouselContentLevel != carouselContentLevel / carouselContentLevel &&
                <ClassieContentTitle
                  animationMoment={props.animationMoment}
                  animationPaused={props.animationPaused}
                  carouselContent={carouselContent}
                  carouselContentLevel={carouselContentLevel}
                  classieContentBadgeText={classieContentBadgeText}
                  innerConfig={localConfig}
                  innerPages={props.innerPages}
                  item={handleDatatransformation([props.item]).slice().shift()}
                  itemExtended={props.item}
                  items={props.items}
                  onAnimationComplete={onAnimationComplete}
                  onAnimationStart={onAnimationStart}
                  onChangeCarouselContent={onChangeCarouselContent}
                  onChangeCarouselContentLevel={onChangeCarouselContentLevel}
                  onChangeCarouselInitialContent={onChangeCarouselInitialContent}
                  onChangeForm={onChangeForm}
                  onChangeLoader={onChangeLoader}
                  onChangeSubject={onChangeSubject}
                  onChangeSubjectAlternate={onChangeSubjectAlternate}
                  resetData={resetData}
                  subject={subject}
                />
            }
          </div>
          ,
          !(
            props.item.post &&
            Object.getOwnPropertyNames(props.item.post).length
          )  &&
          <TweenOne
            animation={localConfig.carousel.animationFifthParty}
            moment={props.animationMoment}
            paused={!localConfig.carousel.animationFifthParty}
            repeat={props.repeat}
          >
            <article
              className="content"
            >
              {
                props.item.title &&
                Object.getOwnPropertyNames(props.item.title).length &&
                props.item[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary &&
                <ClassieContentArticle
                  animationMoment={props.animationMoment}
                  innerConfig={localConfig}
                  item={handleDatatransformation([props.item], props.item, props.item).slice().shift()}
                />
              }
            </article>
          </TweenOne>
        ]
      }
      </div>
  )
}

export default ClassieContent;
