import React from 'react';

import { connect } from "react-redux";

import {
  Badge,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

import './CardInnerWidgetInlineInformationActivity.less';

const CardInnerWidgetInlineInformationActivity = (
  {
    handleCardInnerWidgetClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInnerItemActivityResult = () =>
    Array.isArray(props.subject[props.item.field]) ?
      props.subject[props.item.field]
      :
      props.subject[props.item.field][props.carouselContent] ?
        props.subject[props.item.field][props.carouselContent]
        :
        props.subject[props.item.field][props.subject[localConfig.defaultThirdPartyThirdPartySecondaryField]]
    ;

  const handleInnerItemActivityResultBadgeContent = (innerItem) =>
    innerItem.activityCount +
    localConfig.text.activityResultSeparatorText +
    localConfig.text.activityResultBadgeText
    ;

  const handleInnerItemActivityResultContent = (innerItem) =>
    innerItem.count +
    localConfig.text.activityResultSeparatorText +
    (
      innerItem.hasActivity ?
      localConfig.text.activityResultAlternateText
      :
      localConfig.text.activityResultText
    )
    ;

  console.log(handleInnerItemActivityResult())

  return (
    [
      <Row
        justify="start"
        type="flex"
      >
        <Col
        >
          {props.item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
        </Col>
      </Row>
      ,
      handleInnerItemActivityResult().map(
        innerItem =>
          <Row
            justify="start"
            onClick={event => handleCardInnerWidgetClick(event, innerItem)}
            type="flex"
          >
            <Col
            >
              {innerItem.title}
              {
                innerItem.hasActivity &&
                <Badge
                  count={handleInnerItemActivityResultBadgeContent(innerItem)}
                />
              }
            </Col>
            <Col
            >
              {handleInnerItemActivityResultContent(innerItem)}
            </Col>
          </Row>
      )
    ]
  );
}

export default CardInnerWidgetInlineInformationActivity;
