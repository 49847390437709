import React from "react";
import PropTypes from 'prop-types';

import {
  Col,
  Menu,
  Row
} from "antd";

import QueueAnim from "rc-queue-anim";

import MenuStaticItem from "../general/MenuStaticItem";

import ScrollableContainer from '../general/ScrollableContainer';

import config from "../utils/config";

import "./TagMenuDynamicBody.less";

const TagMenuDynamicBody = (
  {
    handleActionClick,
    handleTagMenuClick,
    ...props
  }
) => {
  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <ScrollableContainer
      innerConfig={localConfig}
    >
      <Row
        justify="space-around"
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline",
              onClick: handleTagMenuClick
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={["right", "left"]}
          >
            {
              props.tags.items.map(
                item =>
                  <Menu.Item>
                    <MenuStaticItem
                      handleActionClick={handleActionClick}
                      handleMenuClick={handleTagMenuClick}
                      innerConfig={localConfig}
                      item={item}
                    />
                  </Menu.Item>
              )
            }
          </QueueAnim>
        </Col>
      </Row>
    </ScrollableContainer>
  );
}

export default TagMenuDynamicBody;
