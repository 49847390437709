import React, { useState } from 'react';

import {
	Col,
	DatePicker,
	Row
} from 'antd';

import config from '../utils/config';

import './DatePickerField.less';

const DatePickerField = (
  {
    onChangeField,
    ...props
  }
) => {
	const {
    innerConfig
  } = props;

	const localConfig = innerConfig ? innerConfig : config;

  const [mode, setMode] = useState(localConfig.date.dateMode);
  const [currentValue, setCurrentValue] = useState();

	const handleChange = (value, extravalue) =>
    onChangeField(
      {[props.field]: extravalue}
    )	
  ;

  const handleOpenChange = (status) => {
  }

  const handlePanelChange = (value, extravalue) => {
    setMode(
      mode ?
        currentValue == value ?
          localConfig.date.dateFlow[mode].previous
          :
          localConfig.date.dateFlow[mode].next
        :
        localConfig.date.dateFormat
    )
    setCurrentValue(value);
  }

  return (
    <DatePicker
      format={localConfig.date.dateFormat}
      mode={
        mode ?
          mode
          :
          localConfig.date.dateMode
      }
			onChange={(value, extravalue) => handleChange(value, extravalue)}
      onOpenChange={status => handleOpenChange(status)}
      onPanelChange={(value, extravalue) => handlePanelChange(value, extravalue)}
    />
  );
}


export default DatePickerField;
