import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import {
  Carousel,
  Col,
  Dropdown,
  Menu,
  Row
} from 'antd';

import CircularProgress from './CircularProgress';
import CircularProgressAlternate from './CircularProgressAlternate';
import ClassieContent from "./ClassieContent";
import GenericCarouselControl from "./GenericCarouselControl";
import Masonry from "./Masonry";
import MenuButton from "./MenuButton";

import config from '../utils/config';

import './GenericCarousel.less';

const hammerjs =
  typeof window !== `undefined` ?
    require("hammerjs")
    :
    undefined
;

const GenericCarousel = (
  {
    animationCarouselProgress,
    carousel,
    carouselAutoplay,
    carouselContent,
    carouselContentLevel,
    carouselInitialContent,
    carouselProgress,
    classieContainer,
    classieContentBadgeText,
    loader,
    menu,
    onChangeCarousel,
    onChangeCarouselAutoplay,
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeCarouselProgress,
    onChangeForm,
    onChangeLoader,
    onChangeSubject,
    onChangeSubjectAlternate,
    openDrawerSixthPartyMenu,
    openFormDrawer,
    openTimelineDrawer,
    openTimelineDrawerFourthParty,
    openTimelineDrawerThirdParty,
    resetData,
    setAnimationCarouselProgress,
    staticData,
    subject,
    timelineDrawerOpened,
    timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  // const carousel = React.createRef();
  const container = React.createRef();

  const [animationMoment, setAnimationMoment] = useState(localConfig.carousel.progressMaximumPercent - localConfig.carousel.progressMaximumPercent);
  const [animationPaused, setAnimationPaused] = useState(!localConfig.carousel.progressMaximumPercent);
  const [animationProgress, setAnimationProgress] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState();
  // const [animationCarouselProgress, setAnimationCarouselProgress] = useState(localConfig.carousel.progressMaximumPercent - localConfig.carousel.progressMaximumPercent);

  var interval;

  console.log(props);
  console.log(carouselInitialContent);

  const handleCarouselAafterChange = (index) => {
    onChangeCarouselInitialContent(index);
    setAnimationCarouselProgress(index);
    setCarouselIndex(index);
    clearInterval(interval);
    console.log(animationMoment);
  }

  const handleCarouselBeforeChange = (oldIndex, newIndex) => {
    interval = setInterval(
      () => {
        carouselProgress =
          carouselProgress == localConfig.carousel.progressMaximumPercent ?
            carouselProgress - localConfig.carousel.progressMaximumPercent
            :
            carouselProgress
        carouselProgress += localConfig.carousel.progressPercent
        onChangeCarouselProgress(carouselProgress);
        setAnimationMoment(localConfig.carousel.progressMaximumPercent - localConfig.carousel.progressMaximumPercent);
        setCarouselIndex(newIndex);
      }, localConfig.carousel.progressInterval)
    ;
    console.log(animationMoment);
    setAnimationMoment();
  }

  const handleCarouselContent = () =>
    !carouselContent ||
    (
      localConfig.menu.items.find(
        element => element.timelineMenuShown
      ) &&
      carouselContent != localConfig.menu.items.find(
        element => element.timelineMenuShown
      ).carouselContent
    )
  ;

  const handleCarouselJustify = () =>
    props.settings.static ?
      localConfig.grid.carouselJustify
      :
      localConfig.grid.carouselJustifyAlternate
  ;

  const handleCarouselJustifyAlternate = () =>
    carouselAutoplay ?
      localConfig.grid.carouselJustifyThirdParty
      :
      localConfig.grid.carouselJustifyThirdPartyAlternate
  ;

  const handleCarouselMenuClick = (item) =>
    item.item &&
    item.item.props &&
    item.item.props.index == item.item.props.index / item.item.props.index ?
      (
        console.log(item),
        console.log(staticData),
        resetData(
          {
            data: {}
          }
        ),
        onChangeLoader(item.item.props.title),
        onChangeCarouselContentLevel(),
        onChangeCarouselContent(item.item.props[localConfig.text.innerWidgetInnerWidgetFormValueKey]),
        onChangeCarouselInitialContent(props.slides.length / props.slides.length)
      )
      :
      (
        console.log(item),
        console.log(localConfig.text.innerWidgetInnerWidgetFormValueKey),
        resetData(
          {
            data: {}
          }
        ),
        onChangeLoader(item.item.props.title),
        onChangeCarouselContentLevel(),
        onChangeCarouselContent(item.item.props[localConfig.text.innerWidgetInnerWidgetFormValueKey]),
        onChangeCarouselInitialContent(props.slides.length / props.slides.length)
      )
    ;

  const handleCarouselMenu = (
    <Menu
      onClick={handleCarouselMenuClick}
    >
      {
        localConfig.carousel.menu.items.map(
          item =>
            <Menu.Item
              {...item}
            >
              {item.title}
            </Menu.Item>
        )
      }
    </Menu>
  );

  const handleCarouselProgressAvatarImage = () =>
    carouselAutoplay ?
      props.settings.image.play
      :
      props.settings.image.pause
    ;

  const handleCarouselProgressAvatarPercent = () =>
    !carouselAutoplay && carouselProgress

  const handleCarouselProgressPlayer = () =>
    props.settings.static ?
      !props.settings.static
      :
      !(
        classieContentBadgeText &&
        props.slides &&
        props.slides.length &&
        props.slides.length == props.slides.length / props.slides.length
      )
    ;

  const handleCarouselProgressPlayerAnimation = () => {
    animationProgress[animationCarouselProgress] = !localConfig.carousel.progressMaximumPercent;
    setAnimationProgress(animationProgress);
  }

  const handleCarouselProgressPlayerAnimationAlternate = () => {
    animationProgress[animationCarouselProgress] = localConfig.carousel.progressMaximumPercent;
    setAnimationProgress(animationProgress);
  }

  const handleCarouselSpeed = () =>
    carouselAutoplay ?
      localConfig.carousel.speed.play
      :
      localConfig.carousel.speed.pause
    ;

  const handleCarouselText = () =>
    carouselIndex &&
    props.slides[carouselIndex] &&
    (
      carouselContentLevel ?
        props.slides[carouselIndex].title
        :
        localConfig.text.activityResultSeparatorText
    ) +
    localConfig.text.activityResultSeparatorText +
    (
      carouselIndex ?
        carouselIndex + carouselIndex / carouselIndex
        :
        props.slides.length / props.slides.length
    ) +
    localConfig.text.activityResultSeparatorText +
    localConfig.text.urlSeparatorText +
    localConfig.text.activityResultSeparatorText +
    props.slides.length
  ;

  console.log(animationProgress);

  const handleSubject = () =>
    // subject ?
    //   subject
    //   :
      localConfig.defaultFifthPartyParameters
  ;

  const next = (event) => {
    event.stopPropagation();
    console.log(animationProgress);
    // setAnimationMoment(localConfig.carousel.progressMaximumPercent - localConfig.carousel.progressMaximumPercent);
    !animationProgress[animationCarouselProgress] && carousel.slick && carousel.slick.slickNext();
    setAnimationPaused(!localConfig.carousel.progressMaximumPercent);
  };

  const pause = (event) => {
    event.stopPropagation();
    carousel.slick &&
    carouselAutoplay ?
      carousel.slick.slickPause()
      :
      carousel.slick.slickPlay()
    ;
    onChangeCarouselAutoplay();
    setAnimationPaused(!animationPaused);
  };

  const previous = (event) => {
    event.stopPropagation();
    // setAnimationMoment(localConfig.carousel.progressMaximumPercent - localConfig.carousel.progressMaximumPercent);
    !animationProgress[animationCarouselProgress] && carousel.slick && carousel.slick.slickPrev();
    setAnimationPaused(!localConfig.carousel.progressMaximumPercent);
  };

  return (
    <div
      className="container"
      ref={container}
    >
      <GenericCarouselControl
        carouselAutoplay={carouselAutoplay}
        carouselContentLevel={carouselContentLevel}
        handleCarouselText={handleCarouselText}
        handleCarouselProgressAvatarImage={handleCarouselProgressAvatarImage}
        handleCarouselProgressAvatarPercent={handleCarouselProgressAvatarPercent}
        handleCarouselProgressPlayer={handleCarouselProgressPlayer}
        handleSubject={handleSubject}
        innerConfig={localConfig}
        next={next}
        openDrawerSixthPartyMenu={openDrawerSixthPartyMenu}
        openTimelineDrawer={openTimelineDrawer}
        openTimelineDrawerFourthParty={openTimelineDrawerFourthParty}
        openTimelineDrawerThirdParty={openTimelineDrawerThirdParty}
        pause={pause}
        previous={previous}
        staticData={staticData}
        subject={subject}
        timelineDrawerOpened={timelineDrawerOpened}
        timelineDrawerOpenedFourthParty={timelineDrawerOpenedFourthParty}
        timelineDrawerOpenedThirdParty={timelineDrawerOpenedThirdParty}
      />
      {
        handleCarouselContent() &&
        <Row
          justify={handleCarouselJustifyAlternate()}
          type="flex"
        >
          <Col
            onMouseDown={event =>  pause(event)}
            span={localConfig.grid.gridSpan}
          >
            <MenuButton
            />
          </Col>
        </Row>
      }
      <Row
        justify={handleCarouselJustify()}
        type="flex"
      >
        <Col
          span={localConfig.grid.gridSpan}
        >
          {
            (
                props.slides &&
                props.slides.length &&
                (
                   Object.keys(props.slides.slice().shift())
                     .find(
                       element => Object.keys(props.parameters)
                         .find(
                           innerElement => innerElement === element
                         )
                     )
               )
               ||
               props.settings.static
            ) ?
              <Carousel
                afterChange={index => handleCarouselAafterChange(index)}
                autoplay={carouselAutoplay}
                autoplaySpeed={props.settings.autoplaySpeed}
                beforeChange={(oldIndex, newIndex) => handleCarouselBeforeChange(oldIndex, newIndex)}
                dots={props.settings.dots}
                effect={props.settings.effect}
                initialSlide={carouselInitialContent}
                pauseOnHover={!props.settings.dots}
                ref={localCarousel => onChangeCarousel(localCarousel)}
                speed={handleCarouselSpeed()}
                swipe={props.settings.swipe}
              >
                {
                   props.slides &&
                   props.slides.map(
                     (item, index) =>
                       <div>
                        <ClassieContent
                          animationMoment={animationMoment}
                          animationPaused={animationPaused}
                          carousel={carousel}
                          carouselContent={carouselContent}
                          carouselContentLevel={carouselContentLevel}
                          classieContainer={classieContainer}
                          classieContentBadgeText={classieContentBadgeText}
                          container={container}
                          datatransfomation={props.datatransfomation}
                          innerConfig={localConfig}
                          innerPages={props.innerPages}
                          isStatic={props.settings.static}
                          item={item}
                          items ={props.slides}
                          navigate={navigate}
                          onAnimationComplete={handleCarouselProgressPlayerAnimationAlternate}
                          onAnimationStart={handleCarouselProgressPlayerAnimation}
                          onChangeCarouselContent={onChangeCarouselContent}
                          onChangeCarouselContentLevel={onChangeCarouselContentLevel}
                          onChangeCarouselInitialContent={onChangeCarouselInitialContent}
                          onChangeForm={onChangeForm}
                          onChangeLoader={onChangeLoader}
                          onChangeSubject={onChangeSubject}
                          onChangeSubjectAlternate={onChangeSubjectAlternate}
                          openFormDrawer={openFormDrawer}
                          resetData={resetData}
                          subject={subject}
                          text={props.text}
                        />
                      </div>
                  )
                }
              </Carousel>
              :
              <CircularProgressAlternate
                avatar={props.avatar}
                text={loader}
              />
          }
        </Col>
      </Row>
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeCarousel: (item) => dispatch (
      {
        payload: item,
        type: `onChangeCarousel`
      }
    ),
    onChangeCarouselAutoplay: () => dispatch (
      {
        type: `onChangeCarouselAutoplay`
      }
    ),
    onChangeCarouselContent: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselContent`
      }
    ),
    onChangeCarouselContentLevel: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselContentLevel`
      }
    ),
    onChangeCarouselInitialContent: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselInitialContent`
      }
    ),
    onChangeCarouselProgress: (progress) => dispatch (
      {
        payload: progress,
        type: `onChangeCarouselProgress`
      }
    ),
    onChangeForm: (item) => dispatch (
      {
        payload: item,
        type: `onChangeForm`
      }
    ),
    onChangeLoader: (item) => dispatch (
      {
        payload: item,
        type: `onChangeLoader`
      }
    ),
    onChangeSubject: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubject`
      }
    ),
    onChangeSubjectAlternate: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubjectAlternate`
      }
    ),
    openDrawerSixthPartyMenu: () => dispatch (
      {
        type: `openDrawerSixthPartyMenu`
      }
    ),
    openFormDrawer: () => dispatch (
      {
        type: `openFormDrawer`
      }
    ),
    openTimelineDrawer: () => dispatch (
      {
        type: `openTimelineDrawer`
      }
    ),
    openTimelineDrawerFourthParty: () => dispatch (
      {
        type: `openTimelineDrawerFourthParty`
      }
    ),
    openTimelineDrawerThirdParty: () => dispatch (
      {
        type: `openTimelineDrawerThirdParty`
      }
    ),
    resetData: (item) => dispatch (
      {
        payload: item,
        type: `resetData`
      }
    ),
    setAnimationCarouselProgress: (item) => dispatch (
      {
        payload: item,
        type: `setAnimationCarouselProgress`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    animationCarouselProgress: state.animationCarouselProgress,
    carousel: state.carousel,
    carouselAutoplay: state.carouselAutoplay,
    carouselContentLevel: state.carouselContentLevel,
    carouselInitialContent: state.carouselInitialContent,
    carouselProgress: state.carouselProgress,
    classieContainer: state.classieContainer,
    classieContentBadgeText: state.classieContentBadgeText,
    loader: state.loader,
    menu: state.menu,
    subject: state.subject,
    timelineDrawerOpened: state.timelineDrawerOpened,
    timelineDrawerOpenedFourthParty: state.timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty: state.timelineDrawerOpenedThirdParty

  }
);

const ConnectedGenericCarousel = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericCarousel);

export default ConnectedGenericCarousel;
