import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import InformationCard from '../general/InformationCard';

import config from '../utils/config';

import './TableCellImage.less';

const TableCellImage = (
  {
    ...props
  }
) => {

  return (
    <InformationCard
      subject={props.item}
    />
  );
}

export default TableCellImage;
