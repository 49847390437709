import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row,
  Table
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import TableCellImage from './TableCellImage';

import config from '../utils/config';

import './GenericTable.less';

const GenericTable = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const onChange = () => {

  }

  const handleTextSort = (first, second, item) =>
    first[item.dataIndex].title.length - second[item.dataIndex].title.length

  const handleColumns = () =>
    props.columns.map(
      (item, index) =>
        item.image ?
          Object.assign({}, item, {
            render: (text) =>
              <TableCellImage
                item={text}
              />
            ,
            sorter: (first, second) =>
              item.textSorter &&
              handleTextSort(first, second, item)
          })
          :
          item
    )
  ;

  return (
    <Row
      justify="unsafe"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <Table
          columns={handleColumns()}
          dataSource={props.dataSource}
          onChange={onChange}
          pagination={localConfig.table.pagination}
          size={localConfig.table.size}
        />
      </Col>
    </Row>
  );
}

export default GenericTable;
