module.exports = {
  api: {
    methods: {
      tagsonomies: {
        create: {

        },
        info: {

        },
        list: {
          apiName: "VowtchTagsAPI",
          path: "/tagsonomies-info",
        },
        update: ``
      },
    }
  }
}
