import React from 'react';

import {GoogleMap, withGoogleMap} from "react-google-maps";

import config from '../utils/config';

// const google = window.google;

const google =
  typeof window !== `undefined` ?
    window.google
    :
    undefined
;

const SimpleMapExampleGoogleMap = withGoogleMap(props => (
  <GoogleMap
    defaultZoom={props.localConfig.map.zoom}
    defaultCenter={{lat: props.localConfig.map.center.latitude, lng: props.localConfig.map.center.longitude}}
  />
));

const MapField = (
  {
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  console.log({
    props: props,
    localConfig: localConfig,
  });

  const localConfig = innerConfig ? innerConfig : config;

  let styleName = props.styleName;
  if (!styleName) {
    styleName = localConfig.map.styleName
  }
  return (
    <div>
      <SimpleMapExampleGoogleMap
        containerElement={<div style={{height: localConfig.map.container.height}}/>}
        loadingElement={<div style={{height: localConfig.map.loading.height}}/>}
        localConfig={localConfig}
        mapElement={<div style={{height: localConfig.map.map.height}}/>}
      />
    </div>
  );
}

export default MapField;
