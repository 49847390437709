import React from 'react';

import { connect } from "react-redux";

import {
  Card,
  Col,
  Row,
  Skeleton
} from 'antd';

import CardHeader from './CardHeader';
import CardSideWidget from './CardSideWidget';
import CardInnerWidget from './CardInnerWidget';

import CardSectionInlineInformation from './auxiliary/CardSectionInlineInformation';
import CardSectionInlineInformationAlternate from './auxiliary/CardSectionInlineInformationAlternate';

import CircularProgress from '../general/CircularProgress';
import ScrollableContainer from '../general/ScrollableContainer';

import config from '../utils/config';

import './GenericCard.less';

const GenericCard = (
  {
    alternateCardInnerWidgetInnerWidgetShown,
    alternateCardInnerWidgetShown,
    handleDataSubmit,
    handleStaticData,
    onAction,
    onChangeForm,
    onChangeMenu,
    onChangeSubject,
    onChangeSubjectThirdParty,
    openDrawer,
    openFormDrawer,
    resetData,
    receiveFormValues,
    showAlternateCardInnerWidget,
    showAlternateCardInnerWidgetInnerWidget,
    step,
    subject,
    ...props
  }
) => {

  const {
    hasHeader,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);
  console.log(subject);

  const handleCardGenericHeaderAction = (item) =>
    item.action &&
    (
      console.log(item),
      onAction(
        {
          ...item.action,
          payload:
            item.action.payload &&
            props.innerPages.filter(
              innerItem =>
                innerItem.node.title ===
                  item.action.payload.split(
                    localConfig.text.topLeftSlugSeparatorText
                  ).slice().shift()
            ).length ?
              [
                props.innerPages.filter(
                  innerItem =>
                    innerItem.node.title ===
                      item.action.payload.split(
                        localConfig.text.topLeftSlugSeparatorText
                      ).slice().shift()
                ).slice().shift().node
                ,
                item.action.payload.split(
                  localConfig.text.topLeftSlugSeparatorText
                ).reverse().slice().shift()
              ]
              :
              props.innerPages.filter(
                innerItem => innerItem.node.title === item.action.payload
              ).length ?
                props.innerPages.filter(
                  innerItem => innerItem.node.title === item.action.payload
                ).slice().shift().node
                :
                item.action.payload
        }
      )
    )

  const handleCardHeaderWidgetClick = (innerItem) => {
    resetData();
    showAlternateCardInnerWidget();
  }

  const handleCardInnerWidgetClick = (event, innerItem, innerInnerItem) => {
    event.preventDefault &&
    event.preventDefault();
    console.log(innerItem);
    receiveFormValues(
      {[localConfig.text.innerWidgetInnerWidgetFormValueKey]: innerItem.title}
    );
    showAlternateCardInnerWidget();
  }

  const handleCardInnerWidgetInnerClickInnerItem = (
    innerItem,
    localItem = innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].split(localConfig.text.activityResultThirdPartySeparatorText).slice().shift()
  ) =>
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].replace(
      localItem,
      localItem.toUpperCase()
    ) +
    localConfig.text.activityResultSeparatorText +
    localConfig.text.innerWidgetInnerWidgetFormAlternateTitle
  ;

  const handleCardInnerWidgetInnerClick  = (event, innerItem) => {
    console.log(innerItem);
    console.log(props.navigation);
    onChangeSubject(innerItem);
    onChangeSubjectThirdParty(innerItem);
    props.navigation &&
    (
      props.navigation.hrefInnerWidgetInnerClickThirdParty ?
        (
          handleCardGenericHeaderAction(props.navigation.hrefInnerWidgetInnerClickThirdParty)
        )
        :
        props.navigation.hrefInnerWidgetInnerClick &&
        (
          innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate ?
            navigate(props.navigation.hrefInnerWidgetInnerClick)
            :
            (
              innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateFourthParty
            ) ?

              props.navigation.hrefInnerWidgetInnerClickAlternate &&
              navigate(props.navigation.hrefInnerWidgetInnerClickAlternate)
              :
              (
                innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldFifthParty] == localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateFifthParty
              ) ?
                props.navigation.hrefInnerWidgetInnerClickFifthParty &&
                navigate(props.navigation.hrefInnerWidgetInnerClickFifthParty)
                :
                props.navigation.hrefInnerWidgetInnerClickFourthParty &&
                navigate(props.navigation.hrefInnerWidgetInnerClickFourthParty)
        )
    )
    ;
    props.innerPages &&
    onChangeForm(
      handleCardInnerWidgetInnerWidgetFormAction(
        props.innerPages.filter(
          item => item.node.title ===
            (
              innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ||
              innerItem[localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty] ?
                innerItem.parent &&
                innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
                  handleCardInnerWidgetInnerClickInnerItem(innerItem)
                  :
                  localConfig.text.alternateInnerWidgetInnerWidgetFormTitle
                :
                localConfig.text.innerWidgetInnerWidgetFormTitle
            )
        ).shift().node
      )
    );
    openFormDrawer();
  }

  const handleCardInnerWidgetInnerWidgetFormAction = (form) =>
    Object.assign({}, form, {
      handleSubmit: handleDataSubmit
    })

  const handleCardInnerWidgetTopClick  = () => {
    showAlternateCardInnerWidgetInnerWidget();
  }


  const handleCardSideWidgetsSpan = (extendInnerWidgetsSpan) =>
    extendInnerWidgetsSpan ?
      localConfig.grid.gridSpan -
      (
        props.grid ?
          props.grid.gridSpan ?
            props.sideWidgets ?
              props.grid.gridSpan
              :
              props.grid.gridSpan + extendInnerWidgetsSpan
            :
            localConfig.grid.gridSpan
          :
          localConfig.grid.gridSpan
      )
      :
      localConfig.grid.gridSpan -
      (
        props.grid ?
          props.grid.gridSpan ?
            props.grid.gridSpan
            :
            localConfig.grid.gridSpan
          :
          localConfig.grid.gridSpan
      );

  const handleCardSideWidgetClick = (event, innerItem) => {
    event.preventDefault();
    receiveFormValues(
      innerItem.title ?
        props.formValues ?
          Array.isArray(props.formValues) ?
            [
              ...props.formValues,
              {
                [localConfig.text.innerWidgetInnerWidgetFormValueKey]
                :
                innerItem.title
              }
            ]
            :
            [
              props.formValues,
              {
                [localConfig.text.innerWidgetInnerWidgetFormValueKey]
                :
                innerItem.title
              }
            ]
          :
          {
            [localConfig.text.innerWidgetInnerWidgetFormValueKey]
            :
            innerItem.title
          }
        :
        Array.isArray(props.formValues) ?
          props.formValues.filter(
            item =>
              item &&
              item[localConfig.text.innerWidgetInnerWidgetFormValueKey] == innerItem.title
          )
          :
          [props.formValues].filter(
            item =>
              item &&
              item[localConfig.text.innerWidgetInnerWidgetFormValueKey] == innerItem.title
          )
    );
    showAlternateCardInnerWidget();
  };

  const handleCardWidgetsGrid = (item) =>
    item.grid ?
      item.grid.gridSpan ?
        item.grid
        :
        props.grid ?
          props.grid.gridSpan ?
            props.grid
            :
            localConfig.grid
          :
          localConfig.grid
      :
      props.grid ?
        props.grid.gridSpan ?
          props.grid
          :
          localConfig.grid
        :
        localConfig.grid
    ;

  const handleItemSpan = (extendSideWidgetsSpan) =>
    props.grid ?
      props.grid.gridSpan ?
        props.grid.gridSpanTreshold ?
          props.grid.gridSpan - props.grid.gridSpanTreshold
          :
          extendSideWidgetsSpan ?
            props.grid.gridSpan - extendSideWidgetsSpan
            :
            props.grid.gridSpan
        :
        localConfig.grid.gridSpan
      :
      localConfig.grid.gridSpan
    ;

    const handleSubjectItemfield = (
      item,
      staticData = props.staticData.slice().shift()
    ) =>
      item.field &&
      Math.abs(item.field.indexOf(localConfig.text.topLeftSlugSeparatorText)) ==
        item.field.indexOf(localConfig.text.topLeftSlugSeparatorText) ?
          staticData[
            item.field.split(
              localConfig.text.topLeftSlugSeparatorText
            ).slice().shift()
          ][
            item.field.split(
              localConfig.text.topLeftSlugSeparatorText
            ).reverse().slice().shift()
          ]
          :
          staticData[item.field]
    ;

  const handleSubject = (
    item,
    innerItem =
      item ?
        item.inlineInformation ?
          Array.isArray(item.inlineInformation) ?
            item.inlineInformation.slice().shift()
            :
            item.inlineInformation
          :
          item
        :
        item
    ,
    staticData = props.staticData.slice().shift()
  ) =>
    props.hasParent ?
      innerItem ?
        handleSubjectItemfield(innerItem) ?
          staticData
          :
          localConfig.entity.user
        :
        staticData
      :
      item ?
        innerItem.form ?
          subject
          :
          handleSubjectItemfield(innerItem) ?
            staticData
            :
            localConfig.entity.user
        :
        staticData
    ;

  return (
    <Card>
      <Row
        justify="safe"
        type="flex"
      >
        <Col
          span={localConfig.grid.gridSpan}
        >
          {
            hasHeader &&
            <CardHeader
              alternateCardHeaderWidgetShown={alternateCardInnerWidgetShown}
              formProps={props.form}
              formValues={props.formValues}
              grid={props.grid}
              handleCardHeaderWidgetClick={handleCardHeaderWidgetClick}
              headerWidgets={props.headerWidgets}
              innerConfig={localConfig}
              navigate={navigate}
              onChangeSubject={onChangeSubject}
              title={props.title}
            />
          }
          <Row
            justify="unsafe"
            type="flex"
          >
            {
              props.sideWidgets &&
              <Col
                lg={
                  {
                    span: handleCardSideWidgetsSpan(),
                    push: handleItemSpan(props.grid.gridSpanTreshold)
                  }
                }
                xs={localConfig.grid.gridSpan}
              >
                {
                  props.sideWidgets &&
                  props.sideWidgets.filter(
                    item => item.alternateSideWidget === alternateCardInnerWidgetShown ||
                            item.requiredSideWidget
                  ).map(
                    item =>
                      handleSubject() ?
                        <CardSideWidget
                          alternateSideWidget={item.alternateSideWidget}
                          avatar={item.avatar}
                          avatarInformation={item.avatarInformation}
                          carouselContent={props.carouselContent}
                          form={item.form}
                          formProps={props.form}
                          formValues={props.formValues}
                          grid={handleCardWidgetsGrid(item)}
                          handleCardSideWidgetClick={handleCardSideWidgetClick}
                          inlineInformation={item.inlineInformation}
                          innerConfig={localConfig}
                          singleInformation={item.singleInformation}
                          singleLine={item.singleLine}
                          subject={handleSubject(item)}
                          topic={item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
                        />
                        :
                        <CircularProgress/>
                  )
                }
              </Col>
            }
            <Col
              lg={
                {
                  span: handleItemSpan(),
                  pull: handleCardSideWidgetsSpan(localConfig.grid.gridSpanDoubleTreshold),
                  push: !props.sideWidgets &&
                        handleCardSideWidgetsSpan(localConfig.grid.gridSpanDoubleTreshold)
                }
              }
              xs={localConfig.grid.gridSpan}
            >
              <ScrollableContainer
                innerConfig={localConfig}
              >
                {
                  props.sections &&
                  props.sections.map(
                    item =>
                      item.field ?
                        handleSubject() ?
                          item.avatar ?
                            <CardSectionInlineInformationAlternate
                              innerConfig={localConfig}
                              item={item}
                              subject={handleSubject(item)}
                            />
                            :
                            <CardSectionInlineInformation
                              innerConfig={localConfig}
                              item={item}
                              subject={handleSubject(item)}
                            />
                          :
                          <CircularProgress/>
                        :
                        <CardSectionInlineInformationAlternate
                          innerConfig={localConfig}
                          item={item}
                        />
                  )
                }
                {
                  props.innerWidgets &&
                  props.innerWidgets.filter(
                    item => item.alternateInnerWidget === alternateCardInnerWidgetShown ||
                            item.requiredInnerWidget
                  ).map(
                      item =>
                        handleSubject() ?
                          <CardInnerWidget
                            alternateCardInnerWidgetInnerWidgetShown={alternateCardInnerWidgetInnerWidgetShown}
                            alternateInnerWidget={item.alternateInnerWidget}
                            carouselContent={props.carouselContent}
                            data={props.data}
                            datatransfomation={props.datatransfomation}
                            externalStaticData={props.externalStaticData}
                            form={item.form}
                            formProps={props.form}
                            grid={handleCardWidgetsGrid(item)}
                            handleCardInnerWidgetClick={handleCardInnerWidgetClick}
                            handleCardInnerWidgetInnerClick={handleCardInnerWidgetInnerClick}
                            handleCardInnerWidgetTopClick={handleCardInnerWidgetTopClick}
                            handleDataSubmit={handleDataSubmit}
                            handleStaticData={handleStaticData}
                            inlineInformation={item.inlineInformation}
                            innerConfig={localConfig}
                            innerPages={props.innerPages}
                            justify={item.justify}
                            navigate={navigate}
                            navigation={props.navigation}
                            secondaryJustify={item.secondaryJustify}
                            secondaryStaticData={props.secondaryStaticData}
                            staticData={props.staticData}
                            step={step}
                            subject={handleSubject(item)}
                            table={item.table}
                            tableProps={props.table}
                            text={props.text}
                          />
                          :
                          <Row
                            justify="initial"
                            type="flex"
                          >
                            <Col
                              span={localConfig.grid.gridSpan}
                            >
                              <Skeleton
                                active
                                avatar
                                loading={!props.staticData.length}
                              >
                              </Skeleton>
                            </Col>
                          </Row>
                    )
                }
              </ScrollableContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onAction: (action) =>  dispatch(action)
    ,
    onChangeForm: (item) => dispatch (
      {
        payload: item,
        type: `onChangeForm`
      }
    )
    ,
    onChangeMenu: (item) => dispatch (
      {
        payload: item,
        type: `onChangeMenu`
      }
    )
    ,
    onChangeSubject: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubject`
      }
    )
    ,
    onChangeSubjectThirdParty: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubjectThirdParty`
      }
    )
    ,
    openDrawer: () => dispatch (
      {
        type: `openDrawer`
      }
    )
    ,
    openFormDrawer: () => dispatch (
      {
        type: `openFormDrawer`
      }
    )
    ,
    resetData: () => dispatch (
      {
        type: `resetData`
      }
    )
    ,
    receiveFormValues: (formValues) => dispatch (
      {
        payload: formValues,
        type: `receiveFormValues`
      }
    )
    ,
    showAlternateCardInnerWidget: () => dispatch (
      {
        type: `showAlternateCardInnerWidget`
      }
    )
    ,
    showAlternateCardInnerWidgetInnerWidget: () => dispatch (
      {
        type: `showAlternateCardInnerWidgetInnerWidget`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    alternateCardInnerWidgetInnerWidgetShown: state.alternateCardInnerWidgetInnerWidgetShown,
    alternateCardInnerWidgetShown: state.alternateCardInnerWidgetShown,
    step: state.step,
    subject: state.subject
  }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )
  (
    GenericCard
  );
