module.exports = {
  api: {
    methods: {
      creativecontent: {
        create: {
          apiName: "VowtchNetworkAPI",
          path: "/create-creative-content",
        },
        delete: {
          apiName: "VowtchNetworkAPI",
          path: "/delete-creative-content",
        },
        info: {

        },
        list: {
          apiName: "VowtchNetworkAPI",
          path: "/creative-content-info",
        },
        update: {
          apiName: "VowtchNetworkAPI",
          path: "/update-creative-content",
        }
      },
      groups: {
        create: {
          apiName: "VowtchNetworkAPI",
          path: "/create-network",
        },
        info: {

        },
        list: {
          apiName: "VowtchNetworkAPI",
          path: "/network-info",
        },
        update: ``
      },
      invitations: {
        create: {
          apiName: "VowtchRegistration-API",
          path: "/create-invitation",
        },
        info: {

        },
        list: {
          apiName: "VowtchRegistration-API",
          path: "/invitation-info",
        },
        update: ``
      },
      users: {
        create: {
          apiName: "VowtchRegistration-API",
          path: "/register",
        },
        info: {

        },
        list: {
          apiName: "VowtchRegistration-API",
          path: "/info",
        },
        login: {
          apiName: "VowtchRegistration-API",
          path: "/login",
        },
        update: {
          apiName: "VowtchRegistration-API",
          path: "/update",
        },
      }
    }
  }
}
