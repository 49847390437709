import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Badge,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

const { Link } = Anchor;

const MenuStaticItem = (
  {
    ...props
  }
) => {

  return (
    props.item.hasActivity ?
      <Row
        justify="start"
        type="flex"
      >
        <Col>
          <Anchor>
            <Link
              title={props.item.title}
            >
            </Link>
          </Anchor>
        </Col>
        <Col>
          <Badge
            count={props.item.activityCount}
          />
        </Col>
      </Row>
      :
      <Anchor>
        <Link
          title={props.item.title}
        >
        </Link>
      </Anchor>
  );
}

export default MenuStaticItem;
