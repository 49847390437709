import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuBadge from '../menu/MenuBadge';
import MenuButton from '../menu/MenuButton';

import config from '../utils/config';

import './FormHeader.less';

const FormHeader = (
  {
    handleFormHeaderClick,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleJustify = () =>
    props.justify
  ;

  const handleJustifyAlternate = () =>
    localConfig.grid.mainHeaderJustify + localConfig.text.activityResultSeparatorText + props.justifyAlternate
  ;

  return (
    <Row
      justify="initial"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        {
          props.hasBadge ?
            <Row
              justify="space-evenly"
              type="flex"
            >
              <Col>
                <Row
                  justify="space-between"
                  type="flex"
                >
                  <Col>
                    <MenuBadge
                      rightText={localConfig.text.topLeftSlugInnerWidgetInnerWidgetForm}
                      rightTextAlternate={localConfig.text.topLeftSlugSeparatorText}
                    >
                    </MenuBadge>
                  </Col>
                  <Col>
                    <MenuButton
                      action={handleFormHeaderClick}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            :
            props.hasButton ?
              <Row
                justify={handleJustify()}
                type="flex"
              >
                <Col
                  span={localConfig.grid.gridSpan}
                >
                  <Row
                    justify="space-between"
                    type="flex"
                  >
                    <Col>
                      {
                        props.avatar ?
                          <Row
                            justify={handleJustifyAlternate()}
                            type="flex"
                          >
                            <Col>
                              <Avatar
                                src={props.avatar}
                              />
                            </Col>
                            <Col>
                              {props.title}
                            </Col>
                          </Row>
                          :
                          props.title
                      }
                    </Col>
                    <Col>

                      {
                        props.hasBaseButton &&
                        <MenuButton
                          action={handleFormHeaderClick}
                        />
                      }
                    </Col>
                  </Row>
                </Col>
              </Row>
              :
              props.title
        }
      </Col>
    </Row>
  );
}

export default FormHeader;
