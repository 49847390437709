/* eslint-disable no-unused-expressions */

import React from 'react';

import { connect } from "react-redux";

import Drawer from 'react-motion-drawer';

// import {
//   Drawer
// } from 'antd';

import QueueAnim from 'rc-queue-anim';

import MainAlternateMenu from './MainAlternateMenu';
import MainFifthPartyMenu from './MainFifthPartyMenu';
import MainFourthPartyMenu from './MainFourthPartyMenu';
import MainHeader from './MainHeader';
import MainMenu from './MainMenu';
import MainSixthPartyMenu from './MainSixthPartyMenu';
import MainThirdPartyMenu from './MainThirdPartyMenu';

import FormDrawer from '../form/FormDrawer';

import config from '../utils/config';

const MenuDrawer = (
  {
    alternateCardInnerWidgetInnerWidgetShown,
    alternateCardInnerWidgetShown,
    alternateMenuShown,
    alternateMenuButtonShown,
    alternateThirdPartyAlternateMenuAlternateShown,
    alternateThirdPartyAlternateMenuFilter,
    alternateThirdPartyAlternateMenuShown,
    alternateThirdPartyMenuShown,
    alternateThirdPartyThirdPartyMenuShown,
    carouselAutoplay,
    carouselContent,
    carouselContentAlternate,
    carouselContentLevel,
    classieContainer,
    classieContentBadgeText,
    drawerOpened,
    filterAlternateThirdPartyAlternateMenu,
    formField,
    formMenuShown,
    formValues,
    fifthPartyMenuShown,
    fourthPartyMenuShown,
    menu,
    menuModified,
    menuShown,
    onAction,
    onChangeCarouselAutoplay,
    onChangeCarouselContent,
    onChangeCarouselContentAlternate,
    onChangeCarouselContentLevel,
    onChangeCarouselInitialContent,
    onChangeClassieContentBadgeText,
    onChangeDrawer,
    onChangeField,
    onChangeForm,
    onChangeLoader,
    onChangeMenu,
    onChangeSubject,
    onChangeSubjectThirdParty,
    onChangeTimelineDrawer,
    openDrawer,
    openDrawerSixthPartyMenu,
    openFormDrawer,
    openTimelineDrawer,
    openTimelineDrawerAlternate,
    openTimelineDrawerFourthParty,
    openTimelineDrawerThirdParty,
    receiveFormValues,
    resetData,
    secondaryMenu,
    showAlternateCardInnerWidget,
    showAlternateCardInnerWidgetInnerWidget,
    showAlternateMenu,
    showAlternateMenuButton,
    showAlternateThirdPartyAlternateMenu,
    showAlternateThirdPartyAlternateMenuAlternate,
    showAlternateThirdPartyMenu,
    showAlternateThirdPartyThirdPartyMenu,
    showFormMenu,
    showFifthPartyMenu,
    showFourthPartyMenu,
    showMenu,
    showSixthPartyMenu,
    showThirdPartyMenu,
    sixthPartyMenuShown,
    staticData,
    subject,
    subjectAlternate,
    subjectThirdParty,
    thirdPartyMenuShown,
    timelineDrawerOpened,
    timelineDrawerOpenedAlternate,
    timelineDrawerOpenedFourthParty,
    timelineDrawerOpenedThirdParty,
    ...props
  }
) => {

  const {
    hasClassieContentBadge,
    hasDivider,
    hasFooter,
    hasTitle,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(menu)
  console.log(showMenu)
  console.log(secondaryMenu)

  const handleActionClick = (
    event,
    innerItem,
    innerInnerItem =
      formField ?
        formField
        :
        localConfig.text.innerWidgetInnerWidgetFormValueKey
  ) => {
    console.log(innerInnerItem)
    receiveFormValues(
      {
        [innerInnerItem]
        :
        formValues ?
          formValues[innerInnerItem] ?
            Array.isArray(formValues[innerInnerItem]) ?
              [...formValues[innerInnerItem], innerItem.title]
              :
              [formValues[innerInnerItem], innerItem.title]
            :
            [innerItem.title]
          :
          [innerItem.title]
      }
    );

    onChangeField(
      {
        [innerInnerItem]
        :
        formValues ?
          formValues[innerInnerItem] ?
            Array.isArray(formValues[innerInnerItem]) ?
              [...formValues[innerInnerItem], innerItem.title]
              :
              [formValues[innerInnerItem], innerItem.title]
            :
            [innerItem.title]
          :
          [innerItem.title]
      }
    );

    console.log({
      [innerInnerItem]
      :
      formValues ?
        formValues[innerInnerItem] ?
          Array.isArray(formValues[innerInnerItem]) ?
            [...formValues[innerInnerItem], innerItem.title]
            :
            [formValues[innerInnerItem], innerItem.title]
          :
          [innerItem.title]
        :
        [innerItem.title]
    })
  }

  const handleCarouselContent = () =>
    !carouselContent ||
    carouselContent != localConfig.menu.items.find(
      element => element.timelineMenuShown
    ).carouselContent
  ;

  const handleData = () =>
    staticData &&
    staticData.length ?
      staticData.slice().shift()
      :
      localConfig.entity.user
  ;

  console.log(subject)
  console.log(handleData())

  const handleDrawerplacement = (event) =>
    sixthPartyMenuShown ?
      localConfig.text.menuDrawerplacementAlternate
      :
      localConfig.text.menuDrawerplacement
  ;

  const handleFormMenuClick = () => {
    showFormMenu()
  }

  const handleGenericHeaderAction = (item) =>
    item.action &&
    onAction(
      {
        ...item.action,
        payload:
          item.action.payload &&
          props.pages.filter(
            innerItem =>
              innerItem.node.title ===
                item.action.payload.split(
                  localConfig.text.topLeftSlugSeparatorText
                ).slice().shift()
          ).length ?
            [
              props.pages.filter(
                innerItem =>
                  innerItem.node.title ===
                    item.action.payload.split(
                      localConfig.text.topLeftSlugSeparatorText
                    ).slice().shift()
              ).slice().shift().node
              ,
              item.action.payload.split(
                localConfig.text.topLeftSlugSeparatorText
              ).reverse().slice().shift()
            ]
            :
            props.pages.filter(
              innerItem => innerItem.node.title === item.action.payload
            ).length ?
              props.pages.filter(
                innerItem => innerItem.node.title === item.action.payload
              ).slice().shift().node
              :
              item.action.payload
      }
    )

  const handleMenu = () =>
    secondaryMenu ?
      secondaryMenu
      :
      localConfig.menu

  const handleMenuClickInnerItem = (
    innerItem,
    localItem = innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].split(localConfig.text.activityResultThirdPartySeparatorText).slice().shift()
  ) =>
    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty].replace(
      localItem,
      localItem.toUpperCase()
    ) +
    localConfig.text.activityResultSeparatorText +
    localConfig.text.innerWidgetInnerWidgetFormAlternateTitle
  ;

  const handleMenuClick = (
    item,
    innerItem,
    // itemIndex = item.item && item.item.props.index - localConfig.menu.threshold
    itemIndex = item.item && item.item.props.children.props.itemIndex
  ) => {
    console.log(menu);
    console.log(props);
    console.log(menuModified);
    console.log(item);
    // console.log(menu.items[itemIndex])
    console.log(itemIndex);
    console.log(innerItem);
    console.log(sixthPartyMenuShown);
    !handleCarouselContent() &&
    carouselAutoplay &&
    onChangeCarouselAutoplay();
    (
      (
        // itemIndex ||
        (
          innerItem &&
          sixthPartyMenuShown
        )
      ) || (
        menu.items &&
        menu.items[itemIndex] &&
        !menu.items[itemIndex].dynamicItems &&
        !menu.items[itemIndex].items
      )
    ) && (
      timelineDrawerOpened &&
      openTimelineDrawer(),
      timelineDrawerOpenedAlternate &&
      openTimelineDrawerAlternate(),
      timelineDrawerOpenedThirdParty &&
      openTimelineDrawerThirdParty(),
      !timelineDrawerOpenedFourthParty &&
      openTimelineDrawerFourthParty()
    );
    innerItem ?
      innerItem.carouselContent ?
        (
          resetData(
            {
              data: {}
            }
          ),
          console.log(carouselAutoplay),
          console.log(innerItem),
          onChangeSubject(innerItem),
          console.log(subject),
          onChangeCarouselContentLevel(),
          onChangeLoader(innerItem.title),
          onChangeCarouselContentAlternate(innerItem.carouselContent),
          onChangeCarouselContent(innerItem.carouselContent),
          onChangeCarouselInitialContent(props.pages.length / props.pages.length),
          handleThirdPartyThirdPartyMenuClick(innerItem),
          // carouselAutoplay &&
          onChangeCarouselAutoplay(),
          openTimelineDrawer(),
          openTimelineDrawerFourthParty(),
          openTimelineDrawerAlternate()
          // openTimelineDrawerThirdParty()
        )
        :
        (
          onChangeSubjectThirdParty(innerItem),
          console.log(innerItem),
          onChangeForm(
            handleMenuFormAction(
              props.pages.filter(
                item => item.node.title ===
                  (
                    innerItem[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ?
                      innerItem.parent[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
                        handleMenuClickInnerItem(innerItem)
                        :
                        localConfig.text.alternateInnerWidgetInnerWidgetFormTitle
                      :
                      localConfig.text.innerWidgetInnerWidgetFormTitle
                  )
              ).shift().node
            )
          )
        )
      :
      menu.hasAvatar && onChangeSubjectThirdParty(handleData())
    ;
    menu.form && innerItem ?
      showFormMenu()
      :
      menu.alternateItem && !menu.items && innerItem ?
        !innerItem && onChangeMenu(menu.alternateItem)
        :
        menu.items ?
          menu.items[itemIndex] ?
            menu.items[itemIndex].carouselContent ?
              (
                resetData(
                  {
                    data: {}
                  }
                ),
                // innerItem &&
                // innerItem.carouselContent &&
                // onChangeSubject(innerItem),
                onChangeCarouselContentLevel(),
                onChangeLoader(menu.items[itemIndex].title),
                onChangeCarouselContentAlternate(menu.items[itemIndex].carouselContent),
                onChangeCarouselContent(menu.items[itemIndex].carouselContent),
                onChangeCarouselInitialContent(props.pages.length - props.pages.length),
                handleThirdPartyThirdPartyMenuClick(menu.items[itemIndex]),
                // timelineDrawerOpenedFourthParty &&

                menu.items[itemIndex].timelineMenuShown  ?
                  (
                    carouselAutoplay &&
                    onChangeCarouselAutoplay(),
                    openTimelineDrawer(),
                    openTimelineDrawerAlternate(),
                    openTimelineDrawerFourthParty(),
                    openTimelineDrawerThirdParty(),
                    console.log(timelineDrawerOpenedAlternate),
                    console.log(timelineDrawerOpenedFourthParty),
                    console.log(timelineDrawerOpenedThirdParty)
                  )
                  :
                  (
                    !timelineDrawerOpened &&
                    openTimelineDrawer(),
                    // !timelineDrawerOpenedAlternate &&
                    openTimelineDrawerAlternate(),
                    // timelineDrawerOpenedFourthParty &&
                    openTimelineDrawerFourthParty()
                    // timelineDrawerOpenedThirdParty &&
                    // openTimelineDrawerThirdParty()
                  )
              )
              :
              menu.items[itemIndex].href ?
                (
                  console.log(!subject),
                  onChangeSubject(!menu.items[itemIndex]),
                  handleThirdPartyThirdPartyMenuClick(menu.items[itemIndex])
                )
                :
                menu.items[itemIndex].hrefAlternate ?
                  (
                    console.log(!subject),
                    // window.location.assign(menu.items[itemIndex].hrefAlternate)
                    window.open(menu.items[itemIndex].hrefAlternate, localConfig.text.linkTarget)
                  )
                  :
                  menu.items[itemIndex].alternateMenuShown ?
                    showAlternateMenu()
                    :
                    menu.items[itemIndex].timelineMenuShown ?
                      (
                        openDrawer(),
                        console.log(menu.items[itemIndex]),
                        openTimelineDrawer(),
                        openTimelineDrawerAlternate(),
                        openTimelineDrawerThirdParty()
                      )
                      :
                      menu.items[itemIndex].items ?
                        onChangeMenu(menu.items[itemIndex])
                        :
                        menu.items[itemIndex].dynamicItems ?
                          onChangeMenu(menu.items[itemIndex])
                          :
                          onChangeMenu(menu)
            :
            onChangeMenu(handleMenu())
          :
          onChangeMenu(handleMenu())
      ;
  };

  const handleAlternateMenuClick = (event, href) => {
    event.stopPropagation();
    console.log(href);
    href ?
      // window.location.assign(href)
      navigate(href)
      :
      showAlternateMenu()
  }

  const handleAlternateMenuMenuClick = () => {

  }

  const handleMenuFormAction = (form) =>
    Object.assign({}, form, {
      handleSubmit: props.handleDataSubmit
    })

  const handleThirdPartyAlternateMenuClick = (event, item) => {
    event.stopPropagation();
    console.log(alternateThirdPartyAlternateMenuFilter);
    handleThirdPartyAlternateMenuFilter(item);
    alternateThirdPartyAlternateMenuAlternateShown &&
    showAlternateThirdPartyAlternateMenuAlternate();
    showAlternateThirdPartyAlternateMenu();
  }

  const handleThirdPartyAlternateMenuClickAlternate = (event, item) => {
    !alternateThirdPartyAlternateMenuAlternateShown &&
    showAlternateThirdPartyAlternateMenuAlternate();
    showAlternateThirdPartyAlternateMenu();
  }

  const handleThirdPartyAlternateMenuClickThirdParty = (event, item) => {
    event.stopPropagation();
    console.log(item);
    alternateThirdPartyAlternateMenuAlternateShown &&
    showAlternateThirdPartyAlternateMenuAlternate();
    handleThirdPartyAlternateMenuFilter(item);
  }

  const handleThirdPartyAlternateMenuFilter = (item) => {
    alternateThirdPartyAlternateMenuFilter && filterAlternateThirdPartyAlternateMenu();
    filterAlternateThirdPartyAlternateMenu(item);
  }

  const handleThirdPartyAlternateMenuMenuClick = (event, item) => {
    console.log(subject);
    event.stopPropagation();
    item &&
      item.constructor === Object ?
        (
          !alternateThirdPartyMenuShown &&
          onChangeSubjectThirdParty(item),
          showAlternateThirdPartyMenu(item)
        )
        :
        handleThirdPartyAlternateMenuFilter(item)
      ;
  }

  const handleThirdPartyMenuClick = (item) => {
    showAlternateThirdPartyMenu(item);
    onChangeSubjectThirdParty(item);
  }

  const handleThirdPartyMenuMenuClick = (event, item) => {
    event.stopPropagation();
    alternateThirdPartyThirdPartyMenuShown &&
    (
      alternateThirdPartyAlternateMenuAlternateShown &&
      showAlternateThirdPartyAlternateMenuAlternate()
      ,
      item ?
        showAlternateThirdPartyThirdPartyMenu(item)
        :
        subjectThirdParty && showAlternateThirdPartyThirdPartyMenu(subjectThirdParty)
    )
    ;
    item ?
      item.constructor === Object ?
        (
          onChangeSubjectThirdParty(item),
          showAlternateThirdPartyMenu(item)
        )
        :
        handleThirdPartyAlternateMenuFilter(item)
      :
      subjectThirdParty && showAlternateThirdPartyMenu(subjectThirdParty)
    ;
  }

  const handleThirdPartyThirdPartyMenuClick = (item) => {
    item.hasParent ?
      showThirdPartyMenu()
      :
      (
        !alternateCardInnerWidgetInnerWidgetShown &&
        showAlternateCardInnerWidgetInnerWidget(),
        alternateCardInnerWidgetShown &&
        showAlternateCardInnerWidget(),
        openDrawer(),
        item.href &&
        navigate(item.href),
        !handleCarouselContent() &&
        openTimelineDrawer()
      )
    ;
    console.log(subject);
    !item.href && !item.parent && (
      showAlternateThirdPartyMenu(subjectThirdParty),
      showAlternateThirdPartyThirdPartyMenu(subjectThirdParty)
    )
  }

  const handleThirdPartyThirdPartyAlternateMenuClick = () => {
    showAlternateThirdPartyThirdPartyMenu(subjectThirdParty);
  }


  const handleOpenDrawer = (item) =>
    item ?
      item.action ?
        handleGenericHeaderAction(item)
        :
        item.href ?
          (
            resetData(),
            !alternateCardInnerWidgetInnerWidgetShown &&
            showAlternateCardInnerWidgetInnerWidget(),
            alternateCardInnerWidgetShown &&
            showAlternateCardInnerWidget(),
            navigate(item.href)
          )
          :
          item.alternateMenuShown ?
            (
              openDrawer(),
              fifthPartyMenuShown && showFifthPartyMenu(),
              fourthPartyMenuShown && showFourthPartyMenu(),
              menuShown && showMenu(),
              sixthPartyMenuShown && showSixthPartyMenu(),
              thirdPartyMenuShown && showThirdPartyMenu(),
              !alternateMenuShown && showAlternateMenu()
            )
            :
            item.thirdPartyMenuShown ?
              (
                alternateMenuShown && showAlternateMenu(),
                fifthPartyMenuShown && showFifthPartyMenu(),
                fourthPartyMenuShown && showFourthPartyMenu(),
                menuShown && showMenu(),
                sixthPartyMenuShown && showSixthPartyMenu(),
                !thirdPartyMenuShown && showThirdPartyMenu(),
                openDrawer()
              )
              :
              item.fourthPartyMenuShown ?
                (
                  alternateMenuShown && showAlternateMenu(),
                  fifthPartyMenuShown && showFifthPartyMenu(),
                  menuShown && showMenu(),
                  sixthPartyMenuShown && showSixthPartyMenu(),
                  thirdPartyMenuShown && showThirdPartyMenu(),
                  onChangeMenu(
                    (
                      !fourthPartyMenuShown &&
                      menu.fourthPartyItem
                    ) ?
                      menu.fourthPartyItem
                      :
                      menu
                    ),
                  !fourthPartyMenuShown && showFourthPartyMenu(),
                  openDrawer()
                )
              :
              item.fifthPartyMenuShown ?
                (
                  alternateMenuShown && showAlternateMenu(),
                  fourthPartyMenuShown && showFourthPartyMenu(),
                  menuShown && showMenu(),
                  sixthPartyMenuShown && showSixthPartyMenu(),
                  thirdPartyMenuShown && showThirdPartyMenu(),
                  onChangeMenu(
                    (
                      !fifthPartyMenuShown &&
                      menu.fifthPartyItem
                    ) ?
                      menu.fifthPartyItem
                      :
                      menu
                    ),
                  !fifthPartyMenuShown && showFifthPartyMenu(),
                  openDrawer()
                )
                :
                item.menuShown ?
                  (
                    carouselContentLevel &&
                    carouselContentLevel == carouselContentLevel / carouselContentLevel + carouselContentLevel / carouselContentLevel
                  ) ?
                    (
                      alternateMenuShown && showAlternateMenu(),
                      fifthPartyMenuShown && showFifthPartyMenu(),
                      fourthPartyMenuShown && showFourthPartyMenu(),
                      fourthPartyMenuShown && showFourthPartyMenu(),
                      sixthPartyMenuShown && showSixthPartyMenu(),
                      thirdPartyMenuShown && showThirdPartyMenu(),
                      menuShown && showMenu(),
                      handleOpenFormDrawer()
                    )
                    :
                    (
                      alternateMenuShown && showAlternateMenu(),
                      fifthPartyMenuShown && showFifthPartyMenu(),
                      fourthPartyMenuShown && showFourthPartyMenu(),
                      sixthPartyMenuShown && showSixthPartyMenu(),
                      thirdPartyMenuShown && showThirdPartyMenu(),
                      !menuShown && showMenu(),
                      onChangeMenu(
                        classieContentBadgeText.length ?
                          menu.items.length ?
                            menu
                            :
                            menuModified
                          :
                          localConfig.menu
                      ),
                      openDrawer()

                    )
                  :
                  item.sixthPartyMenuShown ?
                    (
                      alternateMenuShown && showAlternateMenu(),
                      fifthPartyMenuShown && showFifthPartyMenu(),
                      fourthPartyMenuShown && showFourthPartyMenu(),
                      menuShown && showMenu(),
                      thirdPartyMenuShown && showThirdPartyMenu(),
                      !sixthPartyMenuShown && showSixthPartyMenu(),
                      onChangeMenu(
                        classieContentBadgeText.length ?
                          menu.items.length ?
                            menu
                            :
                            menuModified
                          :
                          localConfig.menu
                      ),
                      openDrawer()

                    )
                    :
                    (
                      alternateMenuShown && showAlternateMenu(),
                      fifthPartyMenuShown && showFifthPartyMenu(),
                      fourthPartyMenuShown && showFourthPartyMenu(),
                      fourthPartyMenuShown && showFourthPartyMenu(),
                      sixthPartyMenuShown && showSixthPartyMenu(),
                      thirdPartyMenuShown && showThirdPartyMenu(),
                      menuShown && showMenu(),
                      onChangeMenu(menu),
                      openDrawer()
                    )
      :
      openDrawer()
    ;

  const handleOpenFormDrawer = (event) => {
    // !subjectThirdParty && onChangeSubjectThirdParty(localConfig.entity.user);
    // !subjectThirdParty && onChangeSubjectThirdParty(subject);
    onChangeSubjectThirdParty(subject);
    // console.log(subject)

    console.log(subjectThirdParty.parent);
    onChangeForm(
      handleMenuFormAction(
        props.pages.filter(
          item => item.node.title ===
            (
              subjectThirdParty[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ?
                subjectThirdParty.parent && subjectThirdParty.parent[localConfig.defaultThirdPartyThirdPartySecondaryField] ?
                  handleMenuClickInnerItem(subjectThirdParty)
                  :
                  localConfig.text.alternateInnerWidgetInnerWidgetFormTitle
                :
                subjectThirdParty.parent ?
                  localConfig.text.innerWidgetInnerWidgetFormTitle
                  :
                  localConfig.text.innerWidgetInnerWidgetFormThirdPartyTitle
            )
        ).shift().node
      )
    );

    openFormDrawer();
  }

  const handleOpenFormDrawerAlternate = (event) => {
    resetData(
      {
        data: {}
      }
    );
    console.log(localConfig);
    console.log(menu.sixthPartyMenuItem[localConfig.defaultSecondaryFieldAlternate]);
    onChangeCarouselContentLevel();
    onChangeSubject();
    menu.items.find(
      element => element.carouselContent == carouselContentAlternate
    ) ?
      onChangeLoader(menu.items.find(
        element => element.carouselContent == carouselContentAlternate
      ).title)
      :
      menu.sixthPartyMenuItem[localConfig.defaultSecondaryFieldAlternate][localConfig.defaultSecondaryField].find(
        element => element.carouselContent == carouselContentAlternate
      ) ?
        onChangeLoader(menu.sixthPartyMenuItem[localConfig.defaultSecondaryFieldAlternate][localConfig.defaultSecondaryField].find(
          element => element.carouselContent == carouselContentAlternate
        ).title)
        :
        onChangeLoader(menu.items.slice().shift().title);
    ;
    onChangeSubject(subjectAlternate);
    carouselContentAlternate ?
      onChangeCarouselContent(carouselContentAlternate)
      :
      onChangeCarouselContent(menu.items.slice().shift().carouselContent)
    ;

  }

  return (
    <div>
      <MainHeader
        carouselContent={carouselContent}
        carouselContentLevel={carouselContentLevel}
        classieContainer={classieContainer}
        classieContentBadgeText={classieContentBadgeText}
        handleOpenFormDrawer={handleOpenFormDrawer}
        handleOpenFormDrawerAlternate={handleOpenFormDrawerAlternate}
        hasClassieContentBadge={hasClassieContentBadge}
        innerConfig={localConfig}
        menu={handleMenu()}
        onChangeCarouselContentLevel={onChangeCarouselContentLevel}
        openDrawer={handleOpenDrawer}
        openFormDrawer={openFormDrawer}
        staticData={staticData}
        subject={subject}
      >
      </MainHeader>
      {
        typeof window !== 'undefined' &&
        <Drawer
          onChange={open => onChangeDrawer(open)}
          open={drawerOpened}
          right
          width={localConfig.drawerWidth}
        >
          {
            alternateMenuShown || localConfig.menu.alternateMenuShown ?
              <MainAlternateMenu
                alternateMenuButtonShown={alternateMenuButtonShown}
                handleAlternateMenuClick={handleAlternateMenuClick}
                handleAlternateMenuMenuClick={handleAlternateMenuMenuClick}
                hasFooter={hasFooter}
                hasTitle={hasTitle}
                innerConfig={localConfig}
                menu={menu}
                navigate={navigate}
                onChangeCarouselContent={onChangeCarouselContent}
                onChangeCarouselContentLevel={onChangeCarouselContentLevel}
                onChangeCarouselInitialContent={onChangeCarouselInitialContent}
                onChangeClassieContentBadgeText={onChangeClassieContentBadgeText}
                onChangeLoader={onChangeLoader}
                onChangeMenu={onChangeMenu}
                onChangeSubject={onChangeSubject}
                onChangeSubjectThirdParty={onChangeSubjectThirdParty}
                openDrawer={handleOpenDrawer}
                resetData={resetData}
                showAlternateMenu={showAlternateMenu}
                staticData={staticData}
                subject={subject}
              >
              </MainAlternateMenu>
              :
              thirdPartyMenuShown ?
                <MainThirdPartyMenu
                  alternateThirdPartyAlternateMenuAlternateShown={alternateThirdPartyAlternateMenuAlternateShown}
                  alternateThirdPartyAlternateMenuFilter={alternateThirdPartyAlternateMenuFilter}
                  alternateThirdPartyAlternateMenuShown={alternateThirdPartyAlternateMenuShown}
                  alternateThirdPartyMenuShown={alternateThirdPartyMenuShown}
                  alternateThirdPartyThirdPartyMenuShown={alternateThirdPartyThirdPartyMenuShown}
                  handleThirdPartyAlternateMenuClick={handleThirdPartyAlternateMenuClick}
                  handleThirdPartyAlternateMenuClickAlternate={handleThirdPartyAlternateMenuClickAlternate}
                  handleThirdPartyAlternateMenuClickThirdParty={handleThirdPartyAlternateMenuClickThirdParty}
                  handleThirdPartyAlternateMenuMenuClick={handleThirdPartyAlternateMenuMenuClick}
                  handleThirdPartyMenuClick={handleThirdPartyMenuClick}
                  handleThirdPartyMenuMenuClick={handleThirdPartyMenuMenuClick}
                  handleThirdPartyThirdPartyAlternateMenuClick={handleThirdPartyThirdPartyAlternateMenuClick}
                  hasFooter={hasFooter}
                  hasTitle={hasTitle}
                  innerConfig={localConfig}
                  openDrawer={handleOpenDrawer}
                  staticData={staticData}
                  subject={subjectThirdParty}
                  thirdPartyMenuShown={thirdPartyMenuShown}
                >
                </MainThirdPartyMenu>
                :
                fifthPartyMenuShown ?
                  <MainFifthPartyMenu
                    fifthPartyMenuShown={fifthPartyMenuShown}
                    handleMenuClick={handleMenuClick}
                    hasFooter={hasFooter}
                    hasTitle={hasTitle}
                    innerConfig={localConfig}
                    menu={menu}
                    navigate={navigate}
                    openDrawer={handleOpenDrawer}
                  >
                  </MainFifthPartyMenu>
                  :
                  fourthPartyMenuShown ?
                    <MainFourthPartyMenu
                      fourthPartyMenuShown={fourthPartyMenuShown}
                      handleMenuClick={handleMenuClick}
                      hasFooter={hasFooter}
                      hasTitle={hasTitle}
                      innerConfig={localConfig}
                      openDrawer={handleOpenDrawer}
                    >
                    </MainFourthPartyMenu>
                    :
                    sixthPartyMenuShown ?
                      <MainSixthPartyMenu
                        formField={formField}
                        handleActionClick={handleActionClick}
                        handleMenuClick={handleMenuClick}
                        hasFooter={hasFooter}
                        hasTitle={hasTitle}
                        innerConfig={localConfig}
                        menu={localConfig.menu.sixthPartyMenuItem}
                        openDrawer={handleOpenDrawer}
                        navigate={navigate}
                        sixthPartyMenuShown={sixthPartyMenuShown}
                        staticData={staticData}
                      >
                      </MainSixthPartyMenu>
                      :
                      formMenuShown ?
                        <FormDrawer
                          carouselContent={carouselContent}
                          handleFormMenuClick={handleFormMenuClick}
                          hasBadge
                          hasDrawer
                          innerConfig={localConfig}
                          menu={menu}
                          navigate={navigate}
                        >
                        </FormDrawer>
                        :
                        menuShown &&
                        <MainMenu
                          carouselContent={carouselContent}
                          classieContentBadgeText={classieContentBadgeText}
                          handleAlternateMenuClick={handleAlternateMenuClick}
                          handleAlternateMenuMenuClick={handleAlternateMenuClick}
                          handleMenuClick={handleMenuClick}
                          hasFooter={hasFooter}
                          hasTitle={hasTitle}
                          innerConfig={localConfig}
                          menu={menu}
                          menuShown={menuShown}
                          openDrawer={handleOpenDrawer}
                          staticData={staticData}
                          subject={subject}
                        >
                        </MainMenu>
          }
        </Drawer>
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    filterAlternateThirdPartyAlternateMenu: (item) => dispatch (
      {
        payload: item,
        type: `filterAlternateThirdPartyAlternateMenu`
      }
    )
    ,
    onAction: (action) =>  dispatch(action)
    ,
    onChangeCarouselAutoplay: () => dispatch (
      {
        type: `onChangeCarouselAutoplay`
      }
    )
    ,
    onChangeCarouselContent: (item) => dispatch (
      {
        payload: item,
        type: `onChangeCarouselContent`
      }
    ),
    onChangeCarouselContentAlternate: (item) => dispatch (
      {
        payload: item,
        type: `onChangeCarouselContentAlternate`
      }
    ),
    onChangeCarouselContentLevel: (item) => dispatch (
      {
        payload: item,
        type: `onChangeCarouselContentLevel`
      }
    )
    ,
    onChangeClassieContentBadgeText: (item) => dispatch (
      {
        payload: item,
        type: `onChangeClassieContentBadgeText`
      }
    )
    ,
    onChangeCarouselInitialContent: (ccontent) => dispatch (
      {
        payload: ccontent,
        type: `onChangeCarouselInitialContent`
      }
    )
    ,
    onChangeDrawer: (open) => dispatch (
      {
        payload: open,
        type: `onChangeDrawer`
      }
    )
    ,
    onChangeField: (item) => dispatch (
      {
        payload: item,
        type: `onChangeField`
      }
    )
    ,
    onChangeForm: (item) => dispatch (
      {
        payload: item,
        type: `onChangeForm`
      }
    )
    ,
    onChangeLoader: (item) => dispatch (
      {
        payload: item,
        type: `onChangeLoader`
      }
    )
    ,
    onChangeMenu: (item) => dispatch (
      {
        payload: item,
        type: `onChangeMenu`
      }
    )
    ,
    onChangeSubject: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubject`
      }
    )
    ,
    onChangeSubjectThirdParty: (item) => dispatch (
      {
        payload: item,
        type: `onChangeSubjectThirdParty`
      }
    )
    ,
    onChangeTimelineDrawer: (open) => dispatch (
      {
        payload: open,
        type: `onChangeTimelineDrawer`
      }
    )
    ,
    openDrawer: () => dispatch (
      {
        type: `openDrawer`
      }
    )
    ,
    openDrawerSixthPartyMenu: (item) => dispatch (
      {
        payload: item,
        type: `openDrawerSixthPartyMenu`
      }
    )
    ,
    openFormDrawer: () => dispatch (
      {
        type: `openFormDrawer`
      }
    )
    ,
    openTimelineDrawer: () => dispatch (
      {
        type: `openTimelineDrawer`
      }
    )
    ,
    openTimelineDrawerAlternate: (open) => dispatch (
      {
        type: `openTimelineDrawerAlternate`
      }
    )
    ,
    openTimelineDrawerFourthParty: (open) => dispatch (
      {
        type: `openTimelineDrawerFourthParty`
      }
    )
    ,
    openTimelineDrawerThirdParty: (open) => dispatch (
      {
        type: `openTimelineDrawerThirdParty`
      }
    )
    ,
    receiveFormValues: (formValues) => dispatch (
      {
        payload: formValues,
        type: `receiveFormValues`
      }
    )
    ,
    resetData: (item) => dispatch (
      {
        payload: item,
        type: `resetData`
      }
    )
    ,
    showAlternateCardInnerWidget: () => dispatch (
      {
        type: `showAlternateCardInnerWidget`
      }
    )
    ,
    showAlternateCardInnerWidgetInnerWidget: () => dispatch (
      {
        type: `showAlternateCardInnerWidgetInnerWidget`
      }
    )
    ,
    showAlternateMenu: () => dispatch (
      {
        type: `showAlternateMenu`
      }
    )
    ,
    showAlternateMenuButton: () => dispatch (
      {
        type: `showAlternateMenuButton`
      }
    )
    ,
    showAlternateThirdPartyAlternateMenu: () => dispatch (
      {
        type: `showAlternateThirdPartyAlternateMenu`
      }
    )
    ,
    showAlternateThirdPartyAlternateMenuAlternate: () => dispatch (
      {
        type: `showAlternateThirdPartyAlternateMenuAlternate`
      }
    )
    ,
    showAlternateThirdPartyMenu: (item) => dispatch (
      {
        payload: item,
        type: `showAlternateThirdPartyMenu`
      }
    )
    ,
    showAlternateThirdPartyThirdPartyMenu: (item) => dispatch (
      {
        payload: item,
        type: `showAlternateThirdPartyThirdPartyMenu`
      }
    )
    ,
    showFormMenu: () => dispatch (
      {
        type: `showFormMenu`
      }
    )
    ,
    showFifthPartyMenu: () => dispatch (
      {
        type: `showFifthPartyMenu`
      }
    )
    ,
    showFourthPartyMenu: () => dispatch (
      {
        type: `showFourthPartyMenu`
      }
    )
    ,
    showMenu: () => dispatch (
      {
        type: `showMenu`
      }
    )
    ,
    showSixthPartyMenu: () => dispatch (
      {
        type: `showSixthPartyMenu`
      }
    )
    ,
    showThirdPartyMenu: () => dispatch (
      {
        type: `showThirdPartyMenu`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    alternateCardInnerWidgetInnerWidgetShown: state.alternateCardInnerWidgetInnerWidgetShown,
    alternateCardInnerWidgetShown: state.alternateCardInnerWidgetShown,
    alternateMenuButtonShown: state.alternateMenuButtonShown,
    alternateMenuShown: state.alternateMenuShown,
    alternateThirdPartyAlternateMenuAlternateShown: state.alternateThirdPartyAlternateMenuAlternateShown,
    alternateThirdPartyAlternateMenuFilter: state.alternateThirdPartyAlternateMenuFilter,
    alternateThirdPartyAlternateMenuShown: state.alternateThirdPartyAlternateMenuShown,
    alternateThirdPartyMenuShown: state.alternateThirdPartyMenuShown,
    alternateThirdPartyThirdPartyMenuShown: state.alternateThirdPartyThirdPartyMenuShown,
    carouselAutoplay: state.carouselAutoplay,
    carouselContent: state.carouselContent,
    carouselContentAlternate: state.carouselContentAlternate,
    carouselContentLevel: state.carouselContentLevel,
    classieContainer: state.classieContainer,
    classieContentBadgeText: state.classieContentBadgeText,
    drawerOpened: state.drawerOpened,
    formField: state.formField,
    formMenuShown: state.formMenuShown,
    formValues: state.formValues,
    fifthPartyMenuShown: state.fifthPartyMenuShown,
    fourthPartyMenuShown: state.fourthPartyMenuShown,
    menu: state.menu,
    menuModified: state.menuModified,
    menuShown: state.menuShown,
    secondaryMenu: state.secondaryMenu,
    sixthPartyMenuShown: state.sixthPartyMenuShown,
    staticData: state.staticData,
    subject: state.subject,
    subjectAlternate: state.subjectAlternate,
    subjectThirdParty: state.subjectThirdParty,
    thirdPartyMenuShown: state.thirdPartyMenuShown,
    timelineDrawerOpened: state.timelineDrawerOpened,
    timelineDrawerOpenedAlternate: state.timelineDrawerOpenedAlternate,
    timelineDrawerOpenedThirdParty: state.timelineDrawerOpenedThirdParty
  }
);

const ConnectedMenuDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuDrawer);

export default ConnectedMenuDrawer;
