import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import InformationCard from '../InformationCard';
import MenuButton from '../MenuButton';
import ScrollableContainer from '../ScrollableContainer';

import config from '../../utils/config';

import './ClassieContentArticle.less';

const ClassieContentArticle = (
  {
    ...props
  }
) => {
  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  return (
    <Row      
    >
      <Col
      >
        <Row
          justify="space-between"
          type="flex"
        >
          <Col
          >
            {
              props.item.title &&
              <InformationCard
                subject={props.item.title}
              />
            }
          </Col>
          <Col
          >
            <MenuButton/>
          </Col>
        </Row>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col
          >
            <Row
              justify="start"
              type="flex"
            >
              <Col
              >
                <MenuButton/>
              </Col>
              <Col
              >
                {props.item.subSubTitle}
              </Col>
              <Col
              >
                <MenuButton/>
              </Col>
              <Col
              >
                {props.item.subSubDescription}
              </Col>
            </Row>
          </Col>
          <Col
          >
            <Row
              justify="start"
              type="flex"
            >
              <Col
              >
                {props.item.superTitle}
              </Col>
              <Col
              >
                <MenuButton/>
              </Col>
            </Row>
          </Col>
        </Row>
        <ScrollableContainer
          innerConfig={localConfig}
        >
          <p>
            {props.item.subSubSubDescription}
            {
              props.item.extendedAvatar &&
              <img src={props.item.extendedAvatar.src}/>
            }
            {props.item.subSubSubDescription}
          </p>
          <p>
            {props.item.subSubSubDescription}
          </p>
          <Row
            justify="space-between"
            type="flex"
          >
            <Col
            >
              <Row
                justify="start"
                type="flex"
              >
                <Col
                >
                  <MenuButton/>
                  <MenuButton/>
                  <MenuButton/>
                  <MenuButton/>
                </Col>
                <Col
                >
                </Col>
              </Row>
            </Col>
          </Row>
        </ScrollableContainer>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col
          >
            <Row
              justify="start"
              type="flex"
            >
              <Col
              >
                {
                  props.item.subTitle &&
                  <InformationCard
                    informationReverse
                    subject={props.item.subTitle}
                  />
                }
              </Col>
              <Col
              >
                <MenuButton/>
                <MenuButton/>
              </Col>
            </Row>
          </Col>
          <Col
          >
            <Row
              justify="start"
              type="flex"
            >
              <Col
              >
                <Row
                  justify="start"
                  type="flex"
                >
                  <Col
                  >
                    {localConfig.text.classieContentThirdPartySlugText}
                  </Col>
                </Row>
                <Row
                  justify="start"
                  type="flex"
                >
                  <Col
                  >
                    <MenuButton/>
                  </Col>
                  <Col
                  >
                    {props.item.superDescription}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>

  );
}

export default ClassieContentArticle;
