import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import CardSideWidgetAvatarInformation from './auxiliary/CardSideWidgetAvatarInformation';
import CardSideWidgetForm from './auxiliary/CardSideWidgetForm';
import CardSideWidgetInlineInformation from './auxiliary/CardSideWidgetInlineInformation';
import CardSideWidgetSingleInformation from './auxiliary/CardSideWidgetSingleInformation';

import config from '../utils/config';

import './CardSideWidget.less';

const CardSideWidget = (
  {
    handleCardSideWidgetClick,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (

    props.form ?
      <CardSideWidgetForm
        formProps={props.formProps}
        innerConfig={localConfig}
      />
      :
      <Row
        justify="start"
        type="flex"
      >
        {
          props.singleInformation ?
            <CardSideWidgetSingleInformation
              avatar={props.avatar}
              innerConfig={localConfig}
              singleLine={props.singleLine}
              subject={props.subject}
              topic={props.topic}
            />
            :
            <Col
              span={localConfig.grid.gridSpan}
            >
              <Row
                justify="center"
                type="flex"
              >
                <Col
                  span={props.grid.gridSpan}
                >
                  {
                    props.inlineInformation &&
                    Array.isArray(props.inlineInformation) &&
                    <CardSideWidgetInlineInformation
                      alternateSideWidget={props.alternateSideWidget}
                      carouselContent={props.carouselContent}
                      formValues={props.formValues}
                      handleCardSideWidgetClick={handleCardSideWidgetClick}
                      inlineInformation={props.inlineInformation}
                      innerConfig={localConfig}
                      subject={props.subject}
                    />
                  }
                  {
                    props.avatarInformation &&
                    <CardSideWidgetAvatarInformation
                      avatarInformation={props.avatarInformation}
                      innerConfig={localConfig}
                    />
                  }
                </Col>
              </Row>
            </Col>
        }
      </Row>
  );
}

export default CardSideWidget;
