import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

// import CustomScroll from 'react-customscroll';

import config from '../utils/config';

import './ScrollableContainer.less';

const CustomScroll =
  typeof window !== `undefined` ?
    require("react-customscroll").default
    :
    undefined
;


// import CustomScroll from 'react-customscroll';

console.log(CustomScroll)

// let CustomScroll;
//
// import("react-customscroll")
//   .then(
//     component =>
//       CustomScroll = component.default
//   )
//   .catch(error => {
//
//   })

const ScrollableContainer = (
  props
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="unsafe"
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        {
          typeof window !== 'undefined' &&
          <CustomScroll
          >
              {props.children}
          </CustomScroll>
        }
      </Col>
    </Row>
  );
}

export default ScrollableContainer;
