import React from 'react';

import { connect } from "react-redux";

import Drawer from 'react-motion-drawer';

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericForm from './GenericForm';

import config from '../utils/config';

import './FormDrawer.less';

const FormDrawer = (
  {
    fieldValues,
    form,
    formDrawerOpened,
    handleFormMenuClick,
    menu,
    onChangeFormDrawer,
    openFormDrawer,
    staticData,
    subject,
    subjectThirdParty,
    ...props
  }
) => {

  const {
    hasDrawer,
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleBadge = () =>
    subject ?
      subject[localConfig.defaultThirdPartyThirdPartySecondaryFieldThirdParty] ?
        !props.hasBadge
        :
        !props.hasBadge
      :
      !props.hasBadge
    ;

  const handleFormFields = (
    item
  ) =>
    // console.log(item)
    form &&
    form.form.formFields.find(
      element => element.rows && element.rows.find(
        innerElement => innerElement.columns.find(
          innerInnerElement =>
            innerInnerElement[item]
        )
      )
    )

  console.log(form)
  console.log(subjectThirdParty)
  // console.log(handleFormFields(localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty));

  const handleJustify = (
    localConfig = innerConfig ? innerConfig : config
  ) =>
    subject ?
      subject[localConfig.defaultThirdPartySecondaryField] ?
        localConfig.grid.alternateFormCoverJustify +
        localConfig.text.activityResultSeparatorText +
        subject[localConfig.defaultThirdPartySecondaryField].split(
          localConfig.text.activityResultSeparatorText
        ).join(
          localConfig.text.alternateActivityResultAlternateSeparatorText
        ).toLowerCase()
        :
        form &&
        form.header &&
        form.header.justify ?
          localConfig.grid.alternateFormCoverJustify +
          localConfig.text.activityResultSeparatorText +
          form.header.justify
          :
          handleFormFields(localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField) ?
            localConfig.grid.alternateFormCoverJustify +
            localConfig.text.activityResultSeparatorText +
            localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField
            :
            handleFormFields(localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty) ?
              localConfig.grid.alternateFormCoverJustify +
              localConfig.text.activityResultSeparatorText +
              localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty
              :
              JSON.stringify(menu) !== JSON.stringify(localConfig.menu) ?
                props.carouselContent ?
                  localConfig.grid.alternateFormCoverJustify +
                  localConfig.text.activityResultSeparatorText +
                  props.carouselContent
                  :
                  localConfig.grid.alternateFormCoverJustify
                :
                localConfig.grid.alternateFormCoverJustify
      :
      handleFormFields(localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField) ?
        localConfig.grid.alternateFormCoverJustify +
        localConfig.text.activityResultSeparatorText +
        localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryField
        :
        handleFormFields(localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty) ?
          localConfig.grid.alternateFormCoverJustify +
          localConfig.text.activityResultSeparatorText +
          localConfig.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdPartyAlternateThirdParty
          :
          localConfig.grid.alternateFormCoverJustify
    ;

  return (
    <div>
      {
        typeof window !== 'undefined' &&
        (
          !hasDrawer ?
            <Drawer
              onChange={open => onChangeFormDrawer(open)}
              open={formDrawerOpened}
              right
              width={localConfig.drawerWidth}
            >
              <Row
                justify={handleJustify()}
                type="flex"
              >
                {
                  form &&
                  <Col
                    span={localConfig.grid.gridSpan}
                  >
                    <GenericForm
                      actions={form.form.actions}
                      avatar={form.form.avatar}
                      cover={form.form.cover}
                      datatransfomation={form.datatransfomation}
                      formFields={form.form.formFields}
                      grid={form.form.grid}
                      handleSubmit={form.handleSubmit}
                      hasAlternateCover={form.form.hasAlternateCover}
                      hasBadge={handleBadge()}
                      hasBaseButton
                      hasButton
                      hasCover={form.form.hasCover}
                      hasFooter
                      hasHeader
                      innerConfig={localConfig}
                      innerPages={props.innerPages}
                      navigate={navigate}
                      openFormDrawer={openFormDrawer}
                      staticData={staticData}
                      subject={subjectThirdParty}
                      submitHandler={form.form.submitHandler}
                      submitLabel={form.form.submitLabel}
                      text={form.form.text}
                      title={form.form.title}
                    />
                  </Col>
                }
              </Row>
            </Drawer>
            :
            <Row
              justify={handleJustify()}
              type="flex"
            >
              {
                form &&
                <Col
                  span={localConfig.grid.gridSpan}
                >
                  <GenericForm
                    actions={form.form.actions}
                    avatar={form.form.avatar}
                    cover={form.form.cover}
                    datatransfomation={form.datatransfomation}
                    formFields={form.form.formFields}
                    grid={form.form.grid}
                    handleSubmit={form.handleSubmit}
                    hasAlternateCover={form.form.hasAlternateCover}
                    hasBadge={handleBadge()}
                    hasButton
                    hasCover={form.form.hasCover}
                    hasFooter
                    hasHeader
                    innerConfig={localConfig}
                    innerPages={props.innerPages}
                    navigate={navigate}
                    openFormDrawer={handleFormMenuClick}
                    subject={subjectThirdParty}
                    submitHandler={form.form.submitHandler}
                    submitLabel={form.form.submitLabel}
                    text={form.form.text}
                    title={form.form.title}
                  />
                </Col>
              }
            </Row>
        )
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => (
  {
    onChangeFormDrawer: (open) => dispatch (
      {
        payload: open,
        type: `onChangeFormDrawer`
      }
    )
    ,
    openFormDrawer: () => dispatch (
      {
        type: `openFormDrawer`
      }
    )
  }
);

const mapStateToProps = state => (
  {
    fieldValues: state.fieldValues,
    form: state.form,
    formDrawerOpened: state.formDrawerOpened,
    staticData: state.staticData,
    subject: state.subject,
    subjectThirdParty: state.subjectThirdParty
  }
);

const ConnectedFormDrawer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormDrawer);

export default ConnectedFormDrawer;
