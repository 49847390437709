import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

import './CardInnerWidgetInlineCard.less';

const { Link } = Anchor;

const CardInnerWidgetInlineCard = (
  {
    handleStaticData,
    handleSubjectItemfield,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInnerItemClick = (event, innerItem) => {
    event.stopPropagation();
    console.log(handleStaticData)
    handleStaticData &&
    handleStaticData();
    navigate(
      props.item.href ?
        props.item.href
        :
        innerItem.href
    )
    ;
  }

  const handleItem = (innerItem) =>
    Object.assign(
      {},
      ...innerItem,
      {
        [
          localConfig.defaultSixthPartyFieldAlternate
        ]
        :
        innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternate] ?
          innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternate]
          :
          innerItem[localConfig.defaultSixthPartyFieldAlternate]
      },
      {
        [
          localConfig.defaultSixthPartyFieldAlternateAlternate
        ]
        :
        innerItem[localConfig.defaultSixthPartyFieldAlternate][localConfig.defaultSixthPartyFieldAlternateAlternate]
      }
    )
  ;

  console.log(handleSubjectItemfield(props.item))

  return (
    [
      <Row
        justify="unset"
        type="flex"
      >
        <Col
          span={localConfig.grid.gridSpan}
        >
          {props.item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
        </Col>
      </Row>
      ,
      handleSubjectItemfield(props.item).items &&
      handleSubjectItemfield(props.item).items.map(
        innerItem =>
          <Row
            justify="unset"
            type="flex"
          >
            <Col
              span={localConfig.grid.gridSpan}
            >
              <Row
                justify="space-between"
                type="flex"
              >
                <Col
                  onMouseDown={event => handleInnerItemClick(event, innerItem)}
                >
                  {
                    innerItem.addonBefore && innerItem.addonBefore.avatar &&
                    <Avatar
                      src={innerItem.addonBefore.avatar}
                    />
                  }
                  {
                    props.item.addonBefore && props.item.addonBefore.avatar &&
                    <Avatar
                      src={props.item.addonBefore.avatar}
                    />
                  }
                  <Row
                    justify="space-between"
                    type="flex"
                  >
                    <Col
                      span={localConfig.grid.gridSpan}
                    >
                      <Anchor>
                        <Link
                          title={innerItem.description}
                        >
                        </Link>
                      </Anchor>
                    </Col>
                    <Col
                      span={localConfig.grid.gridSpan}
                    >
                      <Anchor>
                        <Link
                          title={handleItem(innerItem).title}
                        >
                        </Link>
                      </Anchor>
                    </Col>
                  </Row>
                </Col>
                <Col
                >
                  {
                    props.item.addonAfter && props.item.addonAfter.avatar &&
                    <Avatar
                      src={props.item.addonAfter.avatar}
                    />
                  }
                </Col>
              </Row>
            </Col>
          </Row>
      )
    ]
  );
}

export default CardInnerWidgetInlineCard;
