import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Card,
  Col,
  Icon,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './InformationCard.less';

const { Link } = Anchor;

const { Meta } = Card;

const InformationCard = (
  {
    ...props
  }
) => {

  const {
    hasExtendedAvatar,
    hasExtendedContent,
    hasHiddenContent,
    hasInnerContent,
    hasMediumAvatar,
    hasPartialContent,
    informationReverse,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleAvatar = () =>
    hasMediumAvatar ?
      props.subject.avatarMedium
      :
      props.subject.avatar

  const  handleExtendedAvatar = () =>
    props.subject.extendedAvatar

  const  handleExtendedAvatarReplacementDescription = (item, secondaryItem) =>
    secondaryItem ?
      <Row
        justify={props.secondaryJustify}
        type="flex"
      >
        <Col
        >
          <Avatar
            src={handleExtendedAvatar()}
          />
          {item}
        </Col>
        <Col
        >
          {secondaryItem}
        </Col>
      </Row>
      :
      <Row
        justify={props.justify}
        type="flex"
      >
        <Col
        >
          <Avatar
            src={handleExtendedAvatar()}
          />
        </Col>
        <Col
        >
          {item}
        </Col>
      </Row>

  const  handleExtendedAvatarReplacementTitle = (item, secondaryItem) =>
    <Row
      justify={props.secondaryJustify}
      type="flex"
    >
      <Col
        onClick={() => { window.open(props.subject.subSubDescription, localConfig.text.linkTarget)}}
      >
        {
          props.hasExtendedReplacementTitleLink ?
            <Anchor>
              <Link                
                target={localConfig.text.linkTarget}
                title={item}
              >
              </Link>
            </Anchor>
            :
            item
        }
        <Avatar
          src={handleExtendedAvatar()}
        />
      </Col>
      <Col
      >
        {secondaryItem}
        <Avatar
          src={handleExtendedAvatar()}
        />
      </Col>
    </Row>

  const handleInnerContent = () =>
    localConfig.text.activitySeparatorText +
    props.subject.activityCount

  const handleReplacementDescriptionText = (hasExtendedContent) =>
    hasExtendedContent ?
      !hasHiddenContent &&
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementDescription(props.subject.subDescription)
        :
        props.subject.subDescription
      :
      hasPartialContent ?
        props.subject.subDescription
        :
        props.replacementDescriptionText ?
          props.replacementDescriptionText
          :
          hasExtendedAvatar ?
            handleExtendedAvatarReplacementDescription(props.subject.description, props.subject.subSubDescription)
            :
            props.subject.description
    ;

  const handleReplacementTitleText = (hasExtendedContent) =>
    hasExtendedContent ?
      !hasHiddenContent &&
      hasExtendedAvatar ?
        handleExtendedAvatarReplacementTitle(props.subject.subTitle)
        :
        props.subject.subTitle
      :
      props.replacementTitleText ?
        props.replacementTitleText
        :
        props.subject.title
    ;

  return (
    <div>
      <Card
      >
        {
          hasExtendedContent ?
            <Meta
              avatar={
                <Avatar
                  src={handleAvatar()}
                />
              }
              description={
                <Card
                >
                  {
                    informationReverse ?
                      <Meta
                        description={handleReplacementDescriptionText(hasExtendedContent)}
                        title={handleReplacementTitleText(hasExtendedContent)}
                      />
                      :
                      <Meta
                        description={handleReplacementTitleText(hasExtendedContent)}
                        title={handleReplacementDescriptionText(hasExtendedContent)}
                      />
                  }
                </Card>
              }
              title={
                <Card
                >
                  {
                    informationReverse ?
                      <Meta
                        description={handleReplacementDescriptionText()}
                        title={handleReplacementTitleText()}
                      />
                      :
                      <Meta
                        description={handleReplacementTitleText()}
                        title={handleReplacementDescriptionText()}
                      />
                  }
                </Card>
              }
            />
            :
            informationReverse ?
              <Meta
                avatar={
                  <Avatar
                    src={!hasInnerContent && handleAvatar()}
                  >
                    {
                      hasInnerContent &&
                      handleInnerContent()
                    }
                  </Avatar>
                }
                description={handleReplacementDescriptionText()}
                title={handleReplacementTitleText()}
              />
              :
              <Meta
                avatar={
                  <Avatar
                    src={!hasInnerContent && handleAvatar()}
                  >
                    {
                      hasInnerContent &&
                      handleInnerContent()
                    }
                  </Avatar>
                }
                description={handleReplacementTitleText()}
                title={handleReplacementDescriptionText()}
              />
        }
      </Card>
    </div>
  );
}

export default InformationCard;
