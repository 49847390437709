module.exports = {
  drawerWidth: 405,
  grid: {
    menuBadgeJustify: "start"
  },
  text: {
    classieContentBadgeSeparatorText: "for",
    classieContentBadgeText: [
      {
        text: "friends"
      },
      {
        text: "sports"
      },
      {
        text: "fans"
      }
    ]
  }
}
