import config from  './config';

const createGuest = require('cross-domain-storage/guest');

const createHost =
  typeof window !== `undefined` ?
    require('cross-domain-storage/host')
    :
    undefined
;

console.log(config.hosts);

const storageHost =
  createHost &&
  createHost(
    config.hosts
  );

const guestStorageGetItem = (
  callback,
  callbackParameters,
  callbackSecondary,
  localStorageKey
) => {
  const guestStorage =
    typeof document !== `undefined` &&
    createGuest(
      config.guests
    );
  guestStorage &&
  guestStorage.get(
    localStorageKey,
    (error, localStorageValue) => {
      console.log(error);
      console.log(localStorageValue);
      guestStorage.close();
      callback &&
      callback(
        Object.assign(
          {},
          callbackParameters,
          {
            [localStorageKey]
            :
            localStorageValue
          }
        ),
        callbackSecondary
      )
    }
  )
}

const guestStorageSetItem = (
  callbackParameters,
  localStorageKey,
  localStorageValue
) => {

  const guestStorage =
    typeof document !== `undefined` &&
    createGuest(
      config.guests
    );
  guestStorage &&
  guestStorage.set(
    localStorageKey,
    localStorageValue,
    (error, localStorageValue) => {
      console.log(callbackParameters);
      console.log(error);
      console.log(localStorageValue);
      guestStorage.close();
      callbackParameters.href &&
      window.location.assign(callbackParameters.href);
    }
  );
}

export {
  guestStorageGetItem,
  guestStorageSetItem
};
