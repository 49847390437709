import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './MenuBadge.less';

const MenuBadge = (
  props
) => {

  return (
    <Row
      justify="start"
      type="flex"
    >
      <Col>
      </Col>
      <Col>
        {props.leftText}
      </Col>
      <Col>
        {props.rightText}
      </Col>
      <Col>
        {props.rightTextAlternate}
      </Col>
    </Row>
  );
}

export default MenuBadge;
