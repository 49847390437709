import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericForm from '../form/GenericForm';

import CardInnerWidgetBlockCard from './auxiliary/CardInnerWidgetBlockCard';
import CardInnerWidgetForm from './auxiliary/CardInnerWidgetForm';
import CardInnerWidgetInlineCard from './auxiliary/CardInnerWidgetInlineCard';
import CardInnerWidgetInlineInformation from './auxiliary/CardInnerWidgetInlineInformation';
import CardInnerWidgetInlineInformationActivity from './auxiliary/CardInnerWidgetInlineInformationActivity';
import CardInnerWidgetTable from './auxiliary/CardInnerWidgetTable';

import InformationCard from '../general/InformationCard';

import config from '../utils/config';

import './CardInnerWidget.less';

const CardInnerWidget = (
  {
    handleCardInnerWidgetClick,
    handleCardInnerWidgetInnerClick,
    handleCardInnerWidgetTopClick,
    handleDataSubmit,
    handleStaticData,
    ...props
  }
) => {

  const {
    innerConfig,
    navigate
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleDatatransformation = (item) =>
    Array.isArray(item) &&
    item.length &&
    item.map(
      innerItem =>
        Object.assign(
          {},
          ...props.datatransfomation.map(
            innerinnerItem =>
              Object.assign(
                {},
                Object.assign(
                  {},
                  (
                    innerItem[
                      props.datatransfomation.find(
                        element => element.markerField
                      ).field
                    ]
                    &&
                    innerinnerItem.extraMarkerField
                  ) ?
                    {
                      [innerinnerItem.defaultField]
                      :
                      props.text.extraMarkerFieldSlugText
                    }
                    :
                    {
                      [innerinnerItem.defaultField]
                      :
                      Math.abs(innerinnerItem.field.indexOf(localConfig.datatransfomation.separator)) == innerinnerItem.field.indexOf(localConfig.datatransfomation.separator) ?
                        innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()] &&
                        innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()][innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                        :
                        innerItem[innerinnerItem.field]
                    }
                )
              )
          )
        )
    )

  const handleData = (
    staticData = props.staticData.slice().shift()
  ) =>
    Array.isArray(props.data) ?
      props.data
      :
      staticData[localConfig.defaultThirdPartyThirdPartyField] ?
        // staticData[localConfig.defaultThirdPartyThirdPartyField]
        props.inlineInformation.slice().shift().filterField ?
          handleDatatransformation(staticData[localConfig.defaultThirdPartyThirdPartyField].filter(
            element =>
              (
                localConfig.text.activityResultThirdPartySeparatorTextAlternate &&
                props.inlineInformation.slice().shift().filterValue.split(localConfig.text.activityResultThirdPartySeparatorTextAlternate).length > props.staticData.length / props.staticData.length
              ) ?
                element[props.inlineInformation.slice().shift().filterField] != props.inlineInformation.slice().shift().filterValue.split(localConfig.text.activityResultThirdPartySeparatorTextAlternate).reverse().slice().shift()
                :
                element[props.inlineInformation.slice().shift().filterField] == props.inlineInformation.slice().shift().filterValue
          ))
          :
          handleDatatransformation(staticData[localConfig.defaultThirdPartyThirdPartyField])
        :
        staticData
  ;

  const handleInlineInformationData = (item) =>
    Array.isArray(props.subject[item.field]) ||
    Object.keys(props.subject[item.field]).find(
      element => element == props.subject[localConfig.defaultThirdPartyThirdPartySecondaryField]
    )
  ;

  const handleSubjectItemfield = (item) =>
    Math.abs(item.field.indexOf(localConfig.text.topLeftSlugSeparatorText)) ==
      item.field.indexOf(localConfig.text.topLeftSlugSeparatorText) ?
        props.subject[
          item.field.split(
            localConfig.text.topLeftSlugSeparatorText
          ).slice().shift()
        ][
          item.field.split(
            localConfig.text.topLeftSlugSeparatorText
          ).reverse().slice().shift()
        ].slice().shift()
        :
        props.subject[item.field]


  console.log(props)


  return (
    <Row
      justify="center"
      type="flex"
    >
      <Col
        span={props.grid.gridSpan}
      >
        {
          props.table ?
            <CardInnerWidgetTable
              handleCardInnerWidgetClick={handleCardInnerWidgetClick}
              innerConfig={localConfig}
              subject={props.subject}
              tableProps={props.tableProps}
            />
            :
            props.form ?
              <CardInnerWidgetForm
                datatransfomation={props.datatransfomation}
                externalStaticData={props.externalStaticData}
                formProps={props.formProps}
                handleCardInnerWidgetClick={handleCardInnerWidgetClick}
                handleDataSubmit={handleDataSubmit}
                innerConfig={localConfig}
                innerPages={props.innerPages}
                navigate={navigate}
                navigation={props.navigation}
                secondaryStaticData={props.secondaryStaticData}
                staticData={props.staticData.slice().shift()}
                step={props.step}
                subject={props.subject}
                text={props.text}
              />
              :
              props.inlineInformation &&
              Array.isArray(props.inlineInformation) &&
              props.inlineInformation.map(
                item =>
                  item.card ?
                    handleSubjectItemfield(item).items ?
                      <CardInnerWidgetInlineCard
                        handleStaticData={handleStaticData}
                        handleSubjectItemfield={handleSubjectItemfield}
                        innerConfig={localConfig}
                        item={item}
                        navigate={navigate}
                        subject={props.subject}
                      />
                      :
                      <CardInnerWidgetBlockCard
                        alternateCardInnerWidgetInnerWidgetShown={props.alternateCardInnerWidgetInnerWidgetShown}
                        alternateInnerWidget={props.alternateInnerWidget}
                        carouselContent={props.carouselContent}
                        data={
                          handleData() &&
                          item.limit ?
                            handleData().slice(item.limit - item.limit, item.limit)
                            :
                            handleData()
                        }
                        datatransfomation={props.datatransfomation}
                        grid={props.grid}
                        handleCardInnerWidgetInnerClick={handleCardInnerWidgetInnerClick}
                        handleCardInnerWidgetTopClick={handleCardInnerWidgetTopClick}
                        handleDatatransformation={handleDatatransformation}
                        innerConfig={localConfig}
                        item={item}
                        justify={props.justify}
                        secondaryJustify={props.secondaryJustify}
                        staticData={props.staticData.slice().shift()}
                        subject={props.subject}
                        text={props.text}
                      />
                    :
                    handleInlineInformationData(item) ?
                      <CardInnerWidgetInlineInformationActivity
                        carouselContent={props.carouselContent}
                        handleCardInnerWidgetClick={handleCardInnerWidgetClick}
                        innerConfig={localConfig}
                        item={item}
                        subject={props.subject}
                      />
                      :
                      Array.isArray(props.subject[item.field]) &&
                      <CardInnerWidgetInlineInformation
                        handleCardInnerWidgetClick={handleCardInnerWidgetClick}
                        innerConfig={localConfig}
                        item={item}
                        subject={props.subject}
                      />

          )
        }
      </Col>
    </Row>
  );
}

export default CardInnerWidget;
