import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Input,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../menu/MenuButton';

import InformationCard from './InformationCard';
import ScrollableContainer from './ScrollableContainer';

import config from '../utils/config';

import './InstantMessenger.less';

const InstantMessenger = (
  props
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleThirdPartyMenuInnerItem = () =>
    props.subject.conversation ?
      props.subject.conversation.title
      :
      localConfig.text.inlineInformationSlugText

  const handleThirdPartyMenuInnerItemTopic = () =>
    props.subject.conversation ?
      props.subject.conversation.incomingRequest ?
        props.subject.conversation.incomingRequest
        :
        props.subject.conversation.outgoingRequest ?
          props.subject.conversation.outgoingRequest
          :
          props.topic
      :
      props.topic


  console.log(props.subject)

  return (
    <Row
      justify="initial"
      type="flex"
    >
      <Col>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col>
            <InformationCard
              replacementDescriptionText={handleThirdPartyMenuInnerItem()}
              subject={localConfig.entity.user}
            />
          </Col>
          <Col>
            <MenuButton />
          </Col>
        </Row>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col>
            <ScrollableContainer
              innerConfig={localConfig}
            >
              {
                handleThirdPartyMenuInnerItemTopic().map(
                  (item, index) =>
                    item.parent ?
                      <Row
                        justify="space-between"
                        type="flex"
                      >
                        <Col>
                          {
                            item.date &&
                            <Row
                              justify="center"
                              type="flex"
                            >
                              <Col>
                                {item.date}
                              </Col>
                            </Row>
                          }
                          <Row
                            justify="right"
                            type="flex"
                          >
                            <Col>
                              <InformationCard
                                replacementDescriptionText={item.time}
                                subject={props.subject}
                              />
                            </Col>
                          </Row>
                          {
                            item.items.map(
                              (innerItem, innerIndex) =>
                                <Row
                                  justify="space-between"
                                  type="flex"
                                >
                                  <Col>
                                  </Col>
                                  <Col>
                                    {innerItem.text}
                                  </Col>
                                </Row>
                            )
                          }
                        </Col>
                      </Row>
                      :
                      <Row
                        justify="space-between"
                        type="flex"
                      >
                        <Col>
                          {
                            item.date &&
                            <Row
                              justify="center"
                              type="flex"
                            >
                              <Col>
                                {item.date}
                              </Col>
                            </Row>
                          }
                          <Row
                            justify="left"
                            type="flex"
                          >
                            <Col>
                              <InformationCard
                                replacementDescriptionText={item.time}
                                subject={props.subject}
                              />
                            </Col>
                          </Row>
                          {
                            item.items.map(
                              (innerItem, innerIndex) =>
                                <Row
                                  justify="space-between"
                                  type="flex"
                                >
                                  <Col>
                                    {innerItem.text}
                                  </Col>
                                  <Col>
                                  </Col>
                                </Row>
                            )
                          }
                        </Col>
                      </Row>
                )
              }
            </ScrollableContainer>
          </Col>
        </Row>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col>
            <Input
              defaultValue={localConfig.text.bottomLeftThirdPartySlugText}
            />
          </Col>
          <Col>
            <MenuButton />
            <MenuButton />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default InstantMessenger;
