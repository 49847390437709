import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Col,
  Icon,
  Row,
  Spin
} from 'antd';

import config from '../utils/config';

import './CircularProgress.less';

const CircularProgress = (
  props
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Spin
      indicator={
        props.avatar ?
          <Avatar
            src={props.avatar}
          />
          :
          <Icon
            spin
            type="loading"
          />
      }
      tip={props.text}
    />
  );
}

export default CircularProgress;
