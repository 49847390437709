import React from "react";
import PropTypes from 'prop-types';

import {
  Col,
  Menu,
  Row
} from "antd";

import QueueAnim from "rc-queue-anim";

import TagMenuDynamicBody from "./TagMenuDynamicBody";

import MainMenuTopWidget from "../general/MainMenuTopWidget";
import MainThirdPartyMenuTopWidget from "../general/MainThirdPartyMenuTopWidget";
import MenuHeader from "../general/MenuHeader";
import MenuInputItem from "../general/MenuInputItem";

import CircularProgress from '../general/CircularProgress';

import config from "../utils/config";

import "../general/MainMenu.less";

import "./TagMenu.less";

const TagMenu = (
  {
    alternateCardInnerWidgetShown,
    formValues,
    handleActionClick,
    handleTagMenuClick,
    onChangeTags,
    openTagDrawer,
    receiveFormValues,
    showAlternateCardInnerWidget,
    ...props
  }
) => {
  const {
    hasInputItem,
    hasDrawer,
    hasTitle,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  console.log(localConfig);

  const handleDatatransfomation = (item) =>
    item.map(
      innerItem =>
        innerItem &&
        Object.assign(
          {},
          ...localConfig.tagsonomy.tagDatatransfomation.map(
            innerinnerItem =>
              Object.assign(
                {},
                Object.assign(
                  {},
                  (
                    innerItem[
                      localConfig.tagsonomy.tagDatatransfomation.find(
                        element => element.markerField
                      ).field
                    ]
                    &&
                    innerinnerItem.extraMarkerField
                  ) ?
                    {
                      [innerinnerItem.defaultField]
                      :
                      localConfig.tagsonomy.text.extraMarkerFieldSlugText
                    }
                    :
                    {
                      [innerinnerItem.defaultField]
                      :
                      Math.abs(innerinnerItem.field.indexOf(localConfig.datatransfomation.separator)) == innerinnerItem.field.indexOf(localConfig.datatransfomation.separator) ?
                          innerItem[innerinnerItem.field.split(localConfig.datatransfomation.separator).slice().shift()][innerinnerItem.field.split(localConfig.datatransfomation.separator).reverse().slice().shift()]
                          :
                          innerItem[innerinnerItem.field]
                    }
                )
              )
          )
        )
    ).slice().shift();

  props.tags &&
  !props.tags.items &&
  console.log(handleDatatransfomation([props.tags]));

  return (
    <div>
      <Row
        justify="space-around"
        type="flex"
      >
        <Col>
          {
            hasDrawer ?
              <QueueAnim
                component={Menu}
                componentProps={{
                  defaultOpenKeys: [],
                  defaultSelectedKeys: [],
                  mode: "inline",
                  onClick: handleTagMenuClick
                }}
                delay={localConfig.rcQueueAnim.delay}
                duration={localConfig.rcQueueAnim.duration}
                interval={localConfig.rcQueueAnim.interval}
                leaveReverse
                type={["right", "left"]}
              >
                <Menu.Item>
                  <MenuHeader
                    innerConfig={localConfig}
                    menu={props.tags}
                    openDrawer={openTagDrawer}
                    rightBadgeText={localConfig.text.topLeftSlugThirdPartyThirdPartyText}
                  />
                </Menu.Item>
                {
                  hasTitle &&
                  (
                    props.tags &&
                    props.tags.items
                  ) ?
                    hasInputItem ?
                      <Menu.Item>
                        <MenuInputItem
                          handleMenuClick={handleTagMenuClick}
                          innerConfig={localConfig}
                          menu={props.tags}
                        />
                      </Menu.Item>
                      :
                      <Menu.Item
                      >
                        <MainMenuTopWidget
                          handleMenuClick={handleTagMenuClick}
                          innerConfig={localConfig}
                          menu={props.tags}
                          subject={props.subject}
                        />
                      </Menu.Item>
                    :
                    [
                      Array.isArray(props.tags) && !props.tags.length ?
                        <Menu.Item>
                          <CircularProgress/>
                        </Menu.Item>
                        :
                        <Menu.Item>
                          <MainThirdPartyMenuTopWidget
                            handleActionClick={handleActionClick}
                            handleThirdPartyMenuClick={handleTagMenuClick}
                            innerConfig={localConfig}
                            subject={handleDatatransfomation([props.tags])}
                          />
                        </Menu.Item>
                      ,
                      <Menu.Item
                        disabled
                      >
                        <MainMenuTopWidget
                          handleMenuClick={handleActionClick}
                          innerConfig={localConfig}
                          menu={handleDatatransfomation([props.tags])}
                          subject={handleDatatransfomation([props.tags])}
                        />
                      </Menu.Item>
                    ]
                }
                {
                  props.tags &&
                  props.tags.items ?
                    <TagMenuDynamicBody
                      handleActionClick={handleActionClick}
                      handleTagMenuClick={handleTagMenuClick}
                      innerConfig={localConfig}
                      tags={props.tags}
                    />
                    :
                    <Menu.Item>

                    </Menu.Item>
                }
                <Menu.Item>

                </Menu.Item>
              </QueueAnim>
              :
              props.tags &&
              props.tags.items ?
                <TagMenuDynamicBody
                  handleActionClick={handleActionClick}
                  handleTagMenuClick={handleTagMenuClick}
                  innerConfig={localConfig}
                  tags={props.tags}
                />
                :
                <Menu.Item>

                </Menu.Item>
          }
        </Col>
      </Row>
    </div>
  );
};

TagMenu.propTypes = {
  innerConfig: PropTypes.object,
  onChangeTags: PropTypes.func,
  openTagDrawer: PropTypes.func,
  tags: PropTypes.object
};

export default TagMenu;
