import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Menu,
  Row
} from 'antd';

import MainAlternateMenuDynamicBody from './MainAlternateMenuDynamicBody';

import MenuButton from '../MenuButton';

import Tagsonomy from 'tagsonomy-library';

import config from '../../utils/config';

import './MainThirdPartyMenuInnerWidgetInnerWidget.less';

const {
  ConnectedTagDrawer
} = Tagsonomy.Components;

const MainThirdPartyMenuInnerItemInnerItemWidget = (
  {
    handleMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
    step
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    step == step / step ?
      [
        <Menu.Item>

        </Menu.Item>
        ,
        <Menu.Item>
          <Row
            justify="space-between around"
            type="flex"
          >
            <Col>
              {localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyThirdPartyText}
            </Col>
            <Col>
              <MenuButton
                popoverAutoAdjustOverflow={step !== step / step}
                popoverOverlayClassName="space-between"
                popoverPlacement="topRight"
                popoverText={localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyThirdPartyAlternateText}
                popoverTitle={localConfig.text.topCenterSlugThirdPartyFourthdPartyThirdPartyThirdPartyThirdPartyThirdPartyText}
              />
            </Col>
          </Row>
          <ConnectedTagDrawer
            innerConfig={localConfig}
          />
        </Menu.Item>
      ]
      :
      props.staticData &&
      props.staticData.length ?
        props.staticData.map(
          (item, index) =>
            item.parent &&
            item[localConfig.defaultThirdPartyThirdPartyThirdPartyField] ?
              item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types ?
                item[localConfig.defaultThirdPartyThirdPartyThirdPartyField].types.map(
                  (innerItem, innerIndex) =>
                    !innerItem.current &&
                    !innerItem.parent &&
                    <Menu.Item
                    >
                      <MainAlternateMenuDynamicBody
                        innerConfig={localConfig}
                        item={innerItem}
                        step={step}
                      />
                    </Menu.Item>
                )
                :
                <Menu.Item>

                </Menu.Item>
              :
              <Menu.Item>

              </Menu.Item>
      )
      :
      <Menu.Item>

      </Menu.Item>
  );
}

export default MainThirdPartyMenuInnerItemInnerItemWidget;
