import ClassieContent from './components/general/ClassieContent';
import ConnectedGenericCarousel from './components/general/GenericCarousel';
import ConnectedGenericSpin from './components/general/GenericSpin';
import ConnectedTimelineDrawer from './components/general/TimelineDrawer';

const Foliography = {
  ConnectedGenericCarousel,
  ConnectedGenericSpin,
  ConnectedTimelineDrawer
};

export default Foliography;
