import Amplify, { API } from 'aws-amplify';

Amplify.configure(
  {
    Auth: {
        identityPoolId: 'us-east-2:e9a77db4-56a3-45d9-a75b-27d7e2473e91',
        region: 'us-east-2',
        userPoolId: 'us-east-2_sJi8e8tHH',
        userPoolWebClientId: '5qb5k39c7ea5vf1caj7rp51nhj'
    },
    API: {
      endpoints: [
        {
          endpoint: "https://gn8hfd9dn2.execute-api.us-east-2.amazonaws.com/Development",
          name: "VowtchTagsAPI",
          region: 'us-east-2'
        }
      ]
    }
  }
);
