import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import GenericForm from '../../form/GenericForm';

import config from '../../utils/config';

import './CardSideWidgetForm.less';

const CardSideWidgetForm = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="unsafe "
      type="flex"
    >
      <Col
        span={localConfig.grid.gridSpan}
      >
        <GenericForm
          formFields={props.formProps.formFields}
          hasFooter
          hasHeader
          innerConfig={localConfig}
          submitLabel={props.formProps.submitLabel}
          title={props.formProps.title}
        />
      </Col>
    </Row>
  );
}

export default CardSideWidgetForm;
