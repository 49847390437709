import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';

import InformationCard from './InformationCard';

import config from '../utils/config';

import './MainThirdPartyMenuTopWidget.less';

const MainThirdPartyMenuTopWidget = (
  {
    handleThirdPartyMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleContent = () =>
    props.subject ?
      props.subject.conversation ?
        localConfig.text.topCenterSlugThirdPartyAlternateText
        :
        localConfig.text.topCenterSlugThirdPartyThirdPartyText
      :
      localConfig.text.topCenterSlugThirdPartyThirdPartyText
    ;

  const handleFilter = () =>
    props.subject &&
    props.subject[localConfig.defaultFifthPartyField] &&
    (
      props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
      props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
    )
  ;

  return (
    <Row
      justify="inherit"
      type="flex"
    >
      <Col>
        <Row
          justify="space-between"
          type="flex"
        >
          <Col>
            <MenuButton
              action={handleThirdPartyMenuClick}
            />
          </Col>
          <Col>
            {handleContent()}
          </Col>
          <Col>
            <MenuButton
              action={handleThirdPartyMenuClick}
            />
          </Col>
        </Row>
        <Row
          justify="initial"
          type="flex"
        >
          <Col>

          </Col>
          <Col>
            {
              props.subject &&
              <InformationCard
                informationReverse
                subject={props.subject}
              />
            }
          </Col>
        </Row>
        {
          handleFilter() &&
          <Row
            justify="center"
            type="flex"
          >
            <Col>
              <MenuButton
                title={localConfig.text.topMainThirdPartyMenuLeftButtonSlugText}
              />
              <MenuButton
                title={localConfig.text.topMainThirdPartyMenuRightButtonSlugText}
              />
            </Col>
          </Row>
        }
      </Col>
    </Row>
  );
}

export default MainThirdPartyMenuTopWidget;
