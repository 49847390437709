import config from '../utils/config';

import {
  getRequest,
  postRequest
} from '../aws/aws_rest_client';

const { api } = config;
const { methods } = api;

const createUser = async (
  parameters,
  reducer
) =>
  postRequest(
    methods.users.create,
    {
      body: parameters
    },
    reducer
  );

const loginUser = async (
  parameters,
  reducer
) =>
  postRequest(
    methods.users.login,
    {
      body: parameters
    },
    reducer
  );

const listUsers = async (
  parameters,
  reducer,
  reducerParameters
) =>
  getRequest(
    methods.users.list,
    {
      queryStringParameters: parameters
    },
    reducer,
    reducerParameters
  );

const userInfo = async (parameters) =>
  getRequest(
    methods.users.info,
    {
      queryStringParameters: parameters
    }
  );

const updateUser = async (parameters) =>
  postRequest(
    methods.users.update,
    {
      body: parameters
    }
  );

export {
  createUser,
  listUsers,
  loginUser,
  updateUser,
  userInfo
};
