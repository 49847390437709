/* eslint-disable no-unused-expressions */

import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import InformationCard from '../../general/InformationCard';

import config from '../../utils/config';

import './MainAlternateMenuFooterWidget.less';

const MainAlternateMenuFooterWidget = (
  {
    onChangeCarouselContent,
    onChangeCarouselContentLevel,
    onChangeClassieContentBadgeText,
    onChangeLoader,
    onChangeSubject,
    onChangeSubjectThirdParty,
    resetData,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInnerItemClick = (event, href, hrefAlternate) => {
    event.stopPropagation();
    console.log(localConfig.menu.items.slice().shift().carouselContent);
    hrefAlternate &&
    (
      onChangeCarouselContent(localConfig.menu.items.slice().shift().carouselContent),
      onChangeCarouselContentLevel(),
      onChangeLoader(localConfig.menu.items.slice().shift().title),
      onChangeSubject(),
      onChangeSubjectThirdParty(),
      resetData(
        {
           data: {}
        }
      )
    );
    href && !hrefAlternate && window.location.assign(href);
    // onChangeCarouselContent(localConfig.menu.alternateItem.carouselContent);
    // onChangeClassieContentBadgeText(localConfig.text.classieContentBadgeText);
  }

  return (
    localConfig.parent &&
    localConfig.parent.parent ?
      <Row
        justify="left"
        type="flex"
      >
        <Col
          onMouseDown={event =>  handleInnerItemClick(event, localConfig.parent.href)}
        >
          <InformationCard
            replacementDescriptionText={localConfig.text.bottomLeftAlternateSlugAlternateText}
            subject={localConfig.parent}
          />
        </Col>
        <Col
          onMouseDown={event =>  handleInnerItemClick(event, localConfig.parent.href)}
        >
          <MenuButton/>
        </Col>
      </Row>
      :
      <Row
        justify="space-between"
        type="flex"
      >
        <Col
          onMouseDown={event =>  handleInnerItemClick(event, localConfig.href, localConfig.href)}
        >
          <Row
            justify="space-between"
            type="flex"
          >
            <Col>
              <MenuButton/>
            </Col>
            <Col>
              {localConfig.text.bottomLeftAlternateSlugText}
            </Col>
          </Row>
        </Col>
        <Col
          onMouseDown={event =>  handleInnerItemClick(event, localConfig.href)}
        >
          <MenuButton/>
        </Col>
      </Row>
  );
}

export default MainAlternateMenuFooterWidget;
