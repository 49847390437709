import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

import './CardSideWidgetInlineInformation.less';

const { Link } = Anchor;

const CardSideWidgetInlineInformation = (
  {
    handleCardSideWidgetClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleInlineInformation = (item) =>
    props.alternateSideWidget && props.formValues ?
      Array.isArray(props.formValues) ?
        props.formValues
        :
        [props.formValues]
      :
      Array.isArray(props.subject[item.field]) ?
        props.subject[item.field]
        :
        props.subject[item.field] ?
          Object.keys(props.subject)[item.field]  ?
            props.subject[item.field][props.carouselContent] ?
              props.subject[item.field][props.carouselContent]
              :
              props.subject[item.field][props.subject[localConfig.defaultThirdPartyThirdPartySecondaryField]]
            :
            localConfig.entity.user[item.field]
          :
          localConfig.entity.user[item.field]
          // props.subject[item.field]
    ;

  const handleInnerItem = (item) =>
    item &&
    item.replace(
      localConfig.text.alternateActivityResultThirdPartySeparatorText,
      localConfig.text.activityResultThirdPartySeparatorText
    );

  const handleItemMenu = (item) =>
    item.menu.map(
      innerItem => innerItem.title
    ).join(
      localConfig.text.activityResultSeparatorText +
      localConfig.text.menuSeparatorText +
      localConfig.text.activityResultSeparatorText
    );

  console.log(props)

  return (
    props.inlineInformation.map(
      item =>
        Array.isArray(handleInlineInformation(item)) ?
          [
            <Row
              justify="end"
              type="flex"
            >
              <Col
              >
                {
                  item.addonBefore && item.addonBefore.avatar &&
                  <Avatar
                    src={item.addonBefore.avatar}
                  />
                }
                {item[localConfig.text.innerWidgetInnerWidgetFormValueKey]}
              </Col>
            </Row>
            ,
            handleInlineInformation(item).map(
              innerItem =>
                props.alternateSideWidget ?
                  Array.isArray(innerItem[localConfig.text.innerWidgetInnerWidgetFormValueKey]) ?
                    innerItem[localConfig.text.inneWidgetInnerWidgetFormValueKey].map(
                      innerInnerItem =>
                        <Row
                          justify="space-between"
                          type="flex"
                        >
                          <Col
                          >
                          </Col>
                          <Col
                            onClick={event => handleCardSideWidgetClick(event, innerInnerItem)}
                          >
                            {
                              handleInnerItem(innerInnerItem)
                            }
                            {
                              item.addonAfter && item.addonAfter.avatar &&
                              <Avatar
                                src={item.addonAfter.avatar}
                              />
                            }
                          </Col>
                        </Row>
                    )
                    :
                    <Row
                      justify="space-between"
                      type="flex"
                    >
                      <Col
                      >
                      </Col>
                      <Col
                        onClick={event => handleCardSideWidgetClick(event, innerItem)}
                      >
                        {
                          innerItem[localConfig.text.innerWidgetInnerWidgetFormValueKey] ?
                            handleInnerItem(innerItem[localConfig.text.innerWidgetInnerWidgetFormValueKey])
                            :
                            handleInnerItem(innerItem.title)
                        }
                        {
                          item.addonAfter && item.addonAfter.avatar &&
                          <Avatar
                            src={item.addonAfter.avatar}
                          />
                        }
                      </Col>
                    </Row>
                  :
                  <Row
                    justify="end"
                    type="flex"
                  >
                    <Col
                      onClick={event => handleCardSideWidgetClick(event, innerItem)}
                    >
                      {innerItem.title}
                    </Col>
                  </Row>
            )
            ,
            item.menu &&
            <Row
              justify="end"
              type="flex"
            >
              <Col
              >
                {handleItemMenu(item)}
              </Col>
            </Row>
          ]
          :
          <Row
            justify="center"
            type="flex"
          >
            <Col
            >
              {
                handleInlineInformation(item) &&
                <Anchor>
                  <Link
                    title={props.subject[item.field]}
                  >
                  </Link>
                </Anchor>
              }
            </Col>
            <Col
            >
              {
                handleInlineInformation(item) &&
                item[localConfig.text.innerWidgetInnerWidgetFormValueKey]
              }
            </Col>
          </Row>
    )
  );
}

export default CardSideWidgetInlineInformation;
