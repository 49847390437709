import React from 'react';

import { connect } from 'react-redux';

import {
  Col,
  Row,
  Tabs
} from 'antd';

import config from '../utils/config';

import GenericPanelCollectionContent from './GenericPanelCollectionContent';

const TabPane = Tabs.TabPane;

const GenericPanelCollection = (
  {
    activeTab,
    alternateContent,
    showAlternateContent,
    ...props
  }
) => {

  const {
    innerConfig
  } = props;

  const content = alternateContent || props.content;

  const localConfig = innerConfig ? innerConfig : config;

  const handleJustify = (item) =>
    item.justify ?
      item.justify
      :
      localConfig.grid.justify

  const handleSpan = (item, innerItem) =>
    innerItem.grid ?
      innerItem.grid.gridSpan
      :
      localConfig.grid.gridSpan / item.columns.length


  return (

    content.rows.map(
      item =>
        item.hidden ?
          <Row
            gutter={localConfig.grid.gutter}
            justify="center"
            type="flex"
          >
          </Row>
          :
          <Row
            gutter={localConfig.grid.gutter}
            justify={handleJustify(item)}
            type="flex"
          >
            {
              item.columns.map(
                innerItem =>
                  innerItem.rows ?
                    <Col
                      lg={handleSpan(item, innerItem)}
                      xs={localConfig.grid.gridSpan}
                    >
                      {
                        innerItem.rows.map(
                          innerInnerItem =>
                            <Row
                              justify="left"
                              type="flex"
                            >
                              {
                                innerInnerItem.columns.map(
                                  innerInnerInnerItem =>
                                  <Col
                                    lg={handleSpan(innerInnerItem, innerInnerInnerItem)}
                                    xs={localConfig.grid.gridSpan}
                                  >
                                    <GenericPanelCollectionContent
                                      forms={props.forms}
                                      innerConfig={localConfig}
                                      innerItem={innerInnerInnerItem}
                                      justify={props.justify}
                                      linkText={innerInnerInnerItem.linkText}
                                      services={props.services}
                                      showAlternateContent={showAlternateContent}
                                      showLink={innerInnerInnerItem.showLink}
                                      subject={props.subject}
                                    />
                                  </Col>
                                )
                              }
                            </Row>
                        )
                      }
                    </Col>
                  :
                  <Col
                    lg={handleSpan(item, innerItem)}
                    xs={localConfig.grid.gridSpan}
                  >
                    {
                      innerItem.tabs ?
                        <Tabs
                          activeKey={activeTab || innerItem.tabs.slice().shift().title}
                          onTabClick={key => showAlternateContent(false, key)}
                        >
                          {
                            innerItem.tabs.map(
                              innerInnerItem =>
                                <TabPane
                                  key={innerInnerItem.title}
                                  tab={innerInnerItem.title}
                                >
                                  <GenericPanelCollectionContent
                                    forms={props.forms}
                                    innerConfig={localConfig}
                                    innerItem={innerInnerItem}
                                    justify={props.justify}
                                    linkText={innerInnerItem.linkText}
                                    services={props.services}
                                    showAlternateContent={showAlternateContent}
                                    showLink={innerInnerItem.showLink}
                                    subject={props.subject}
                                  />
                                </TabPane>
                            )
                          }
                        </Tabs>
                        :
                        <GenericPanelCollectionContent
                          forms={props.forms}
                          innerConfig={localConfig}
                          innerItem={innerItem}
                          justify={props.justify}
                          linkText={innerItem.linkText}
                          services={props.services}
                          showAlternateContent={showAlternateContent}
                          showLink={innerItem.showLink}
                          subject={props.subject}
                        />

                    }
                  </Col>
              )
            }
          </Row>
    )
  )
}

const mapDispatchToProps = dispatch => (
  {
    showAlternateContent: (alternateContent, activeTab) => dispatch (
      {
        payload: {
           alternateContent: alternateContent,
           activeTab: activeTab
        },
        type: `showAlternateContent`
      }
    )
  }
)

const mapStateToProps = state => {
  return {
    activeTab: state.vowtchSettings.activeTab,
    alternateContent: state.vowtchSettings.alternateContent
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  )
  (
    GenericPanelCollection
  );
