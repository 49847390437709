import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Input,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';

import config from '../utils/config';

import './MenuInputItem.less';

const MenuInputItem = (
  {
    handleMenuClick,
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="start"
      type="flex"
    >
      {
        props.menu.level ?
          <Col>
            <MenuButton
              action={handleMenuClick}
            />
          </Col>
          :
          <Col>
          </Col>
      }
      <Col>
        <Input
          addonBefore={
            <MenuButton />
          }
          defaultValue={localConfig.text.topMainAlternateMenuDynamicBodySlugText}
        />
      </Col>
    </Row>
  );
}

export default MenuInputItem;
