import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../../utils/config';

import './CardSideWidgetSingleInformation.less';

const { Link } = Anchor;

const CardSideWidgetSingleInformation = (
  {
    ...props
  }
) => {

  const {
    innerConfig,
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Col
      span={localConfig.grid.gridSpan}
    >
      <Row
        justify="center"
        type="flex"
      >
        <Col
        >
          <Avatar
            src={props.avatar}
          />
        </Col>
      </Row>
      {
        props.singleLine ?
          <Row
            justify="center"
            type="flex"
          >
            <Col
            >
              {props.topic}
              <Anchor>
                <Link
                  title={props.subject.title}
                >
                </Link>
              </Anchor>
            </Col>
          </Row>
          :
          [
            <Row
              justify="center"
              type="flex"
            >
              <Col
              >
                {props.topic}
              </Col>
            </Row>
            ,
            <Row
              justify="center"
              type="flex"
            >
              <Col
              >
                <Anchor>
                  <Link
                    title={props.subject.title}
                  >
                  </Link>
                </Anchor>
              </Col>
            </Row>
          ]
      }
    </Col>
  );
}

export default CardSideWidgetSingleInformation;
