/* eslint-disable no-unused-expressions */
import React from 'react';

import { connect } from "react-redux";

import PropTypes from 'prop-types';

import { navigate } from "gatsby";

import Foliography from 'foliography-content-library';

import Tagsonomy from 'tagsonomy-library';

import Vowtch from 'vowtch-library-branch';

import config from '../../utils/config';

const {
  ConnectedGenericCarousel
} = Foliography;

const {
  tagsonomyService
} = Tagsonomy.Services;

const {
  guestStorageGetItem,
  guestStorageSetItem
} = Vowtch.Hooks;

const {
  creativecontentService,
  userService
} = Vowtch.Services;

const DashboardBaseView = (
  {
    carouselContent,
    carouselContentLevel,
    data,
    onChangeTags,
    receiveData,
    receiveStaticData,
    secondaryStaticData,
    staticData,
    ...props
  }
) => {

  const  {
    hasCardContainer,
    pages,
    title
  } = props;

  const handleData = (item) =>
    item.carousel.settings.static ?
      item.carousel.slides
      :
      (
        data.length
        &&
        Object.keys(data.slice().shift())
          .find(
            element => element === config.defaultThirdPartyThirdPartyThirdPartySecondaryField
          )
      ) ?
        data
        :
        item.carousel.slides
  ;

  const handleDefaultSecondaryParameters = () =>
    carouselContent ?
      Object.assign(
        {},
        ...config.defaultSecondaryParameters,
        {
          [config.defaultThirdPartyThirdPartySecondaryFieldAlternate]
          :
          carouselContent
        }
      )
      :
      config.defaultSecondaryParameters
  ;

  const handleDefaultThirdPartyParameters = (item) =>
    carouselContent ?
      Object.assign(
        {},
        ...config.defaultThirdPartyParameters,
        {
          [config.defaultThirdPartyThirdPartySecondaryField]
          :
          carouselContent
        }
        // ,
        // {
        //   [config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty]
        //   :
        //   item
        // }
      )
      :
      config.defaultThirdPartyParameters
  ;

  const handleDefaultThirdPartyParametersAlternate = (item) =>
    carouselContent ?
      Object.assign(
        {},
        ...config.defaultThirdPartyParametersAlternate,
        {
          [config.defaultThirdPartyThirdPartySecondaryFieldAlternate]
          :
          carouselContent
        }
        // ,
        // {
        //   [config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldThirdParty]
        //   :
        //   item
        // }
      )
      :
      config.defaultThirdPartyParametersAlternate
  ;

  data.length &&
  console.log(carouselContent);
  console.log(carouselContentLevel);
  console.log(config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField]);
  console.log(config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] != carouselContent);

  (
    !staticData.length
    ||
    (
      carouselContent &&
      config.defaultSecondaryParameters[config.defaultThirdPartyThirdPartySecondaryFieldAlternate] != carouselContent
    )
  ) &&
  guestStorageGetItem(
    userService.listUsers,
    config.defaultParameters,
    receiveStaticData,
    config.entity.user.useStateWithLocalStorageKey
  );
  // userService.listUsers(handleDefaultSecondaryParameters(), receiveStaticData);

  carouselContent &&
  config.defaultSecondaryParameters[config.defaultThirdPartyThirdPartySecondaryFieldAlternate] != carouselContent &&
  (
    config.defaultSecondaryParameters = handleDefaultSecondaryParameters()
  );

  // !staticData.length &&
  //   userService.listUsers(config.defaultSecondaryParameters, receiveStaticData);

  carouselContentLevel ?
    (
      !data.length
      ||
      !Object.keys(data.slice().shift())
        .find(
          element => element === config.defaultThirdPartyThirdPartyThirdPartySecondaryField
        )
      ||
      (
        carouselContent &&
        config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] != carouselContent
      )
    ) &&
    creativecontentService.listCreativecontent(
      (
        carouselContent &&
        config.defaultThirdPartyParametersAlternate[config.defaultThirdPartyThirdPartySecondaryFieldAlternate] != carouselContent
      ) ?
        handleDefaultThirdPartyParametersAlternate(config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate)
        :
        handleDefaultThirdPartyParametersAlternate(config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldSecondary)
      ,
      receiveData
    )
    :
    (
      !data.length
      ||
      !Object.keys(data.slice().shift())
        .find(
          element => element === config.defaultThirdPartyThirdPartyThirdPartySecondaryField
        )
      ||
      (
        carouselContent &&
        config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] != carouselContent
      )
    ) &&
    creativecontentService.listCreativecontent(
      (
        carouselContent &&
        config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] != carouselContent
      ) ?
        handleDefaultThirdPartyParameters(config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate)
        :
        handleDefaultThirdPartyParameters(config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary)
      ,
      receiveData
    );

  carouselContent &&
  config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] != carouselContent &&
  (
    config.defaultThirdPartyParameters =
      (
        carouselContent &&
        config.defaultThirdPartyParameters[config.defaultThirdPartyThirdPartySecondaryField] != carouselContent
      ) ?
        handleDefaultThirdPartyParameters(config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternate)
        :
        handleDefaultThirdPartyParameters(config.defaultThirdPartyThirdPartyThirdPartySecondaryFieldAlternateSecondary)
  );

  Array.isArray(secondaryStaticData) && !secondaryStaticData.length &&
    tagsonomyService.listTagsonomies(config.defaultThirdPartySecondaryParameters, onChangeTags);

  return (
    <div>
      {
        pages.filter(
          item => item.node.title === title
        )
          .map(
            ({ node: item, index }) =>
              <div>
                <ConnectedGenericCarousel
                  carouselContent={carouselContent}
                  datatransfomation={item.datatransfomation}
                  innerConfig={config}
                  innerPages={pages}
                  navigate={navigate}
                  parameters={config.defaultThirdPartyParameters}
                  settings={item.carousel.settings}
                  slides={handleData(item)}
                  staticData={staticData.slice().shift()}
                  text={item.text}
                />
              </div>
          )
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => (
  {
    onChangeTags: (item) => dispatch (
      {
        payload: item,
        type: `onChangeTags`
      }
    )
    ,
    receiveData: (payload) => dispatch (
      {
        payload,
        type: `receiveData`
      }
    )
    ,
    receiveStaticData: (payload) => dispatch (
      {
        payload,
        type: `receiveStaticData`
      }
    )
  }
);

const mapStateToProps = state => {
  return {
    carouselContent: state.carouselContent,
    carouselContentLevel: state.carouselContentLevel,
    data: state.data,
    secondaryStaticData: state.secondaryStaticData,
    staticData: state.staticData
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  )
  (
    DashboardBaseView
  );
