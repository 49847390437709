const theme = {
  colors: {
    base: '#121212',
    highlight: '#5b8bf7',
    secondary: '#e9e9e9',
    tertiary: '#f3f3f3',
  },
  responsive: {
    large: '70em',
    medium: '50em',
    small: '35em',
  },
  sizes: {
    maxWidth: '1200px',
    maxWidthCentered: '650px',
  },
}

export default theme
