import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuBadge from './MenuBadge';
import MenuButton from './MenuButton';

import config from '../utils/config';

import './MenuHeader.less';

const MenuHeader = (
  {
    openDrawer,
    ...props
  }
) => {

  const {
    innerConfig,
    isAlternateMenu
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  console.log(props);

  const handleJustify = () =>
  props.menu ?
    props.menu[localConfig.defaultThirdPartySecondaryField] ?
      localConfig.grid.menuHeaderJustify
      :
      localConfig.grid.menuHeaderSecondaryJustify
    :
    localConfig.grid.menuHeaderSecondaryJustify
  ;

  const handleRightSlugText = (isAlternateMenu) =>
    props.menu ?
      props.menu[localConfig.defaultThirdPartySecondaryField] ?
        props.menu[localConfig.defaultThirdPartySecondaryField]
        :
        isAlternateMenu ?
          localConfig.text.topRightSlugText
          :
          props.rightBadgeText
      :
      isAlternateMenu ?
        localConfig.text.topRightSlugText
        :
        props.rightBadgeText
    ;

  return (
    <div>
      <Row
        justify={handleJustify()}
        type="flex"
      >
        <Col>
          {
            !isAlternateMenu ?
              <Row
                justify="space-between"
                type="flex"
              >
                <Col>
                  <Row
                    justify="space-between"
                    type="flex"
                  >
                    <Col>
                      <MenuButton/>
                    </Col>
                    <Col>
                      <MenuBadge
                        rightText={handleRightSlugText()}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <MenuButton
                    action={openDrawer}
                  />
                </Col>
              </Row>
              :
              <Row
                justify="space-between"
                type="flex"
              >
                <Col>
                  <Row
                    justify="center"
                    type="flex"
                  >
                    <Col>
                      <MenuButton/>
                    </Col>
                    <Col>
                      <MenuBadge
                        rightText={handleRightSlugText()}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <MenuButton
                    action={openDrawer}
                  />
                </Col>
              </Row>
          }
        </Col>
      </Row>
    </div>
  );
}

export default MenuHeader;
