import React from 'react';

import {
	Avatar,
	Col,
	Row
} from 'antd';

import config from '../utils/config';

import './Helper.less';

const Helper = props => {
	const {
    innerConfig
  } = props;

	const localConfig = innerConfig ? innerConfig : config;

	const handleSpan = () =>
		props.grid ?
			props.grid.gridSpan ?
				props.grid.griSpan
				:
				localConfig.grid.gridSpan
			:
			localConfig.grid.gridSpan

	return (
		<Row
	    justify="start"
	    type="flex"
	  >
	    <Col>
	      {
	        props.avatar &&
	        <Avatar
	          shape="square"
	          src={props.avatar}
	        />
	      }
	    </Col>
	    <Col>
	      <Row
	        justify="left"
	        type="flex"
	      >
	       <Col
	         span={localConfig.grid.gridSpan}
	       >
	         {props.title}
	       </Col>
	       <Col>
	         <Row
	           justify="left"
	           type="flex"
	         >
	           {
	             props.text &&
	             props.text.map(
	              item =>
	              <Col
	                span={handleSpan()}
	              >
	                {item.text}
	              </Col>
	             )
	           }
	         </Row>
	       </Col>
	      </Row>
	    </Col>
	  </Row>
	)
}

export default Helper;
