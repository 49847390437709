import React from 'react';

import { connect } from "react-redux";

import {
  Col,
  Icon,
  Progress,
  Row,
  Spin
}
  from 'antd';

import config from '../utils/config';

const GenericSpin = (
  {
    ...props
  }
) => {
  const {
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  return (
    <Row
      justify="space-between"
      type="flex"
    >
      <Col
      >
        <Spin
          indicator={
            <Icon
              spin
              type="loading"
            />
          }
        />
      </Col>
    </Row>
  )
}

const mapDispatchToProps = dispatch => (
  {

  }
);

const mapStateToProps = state => (
  {

  }
);

const ConnectedGenericSpin = connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericSpin);

export default ConnectedGenericSpin;
