import React from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button,
  Col,
  Layout,
  Popconfirm,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MainHeaderDropDown from './auxiliary/MainHeaderDropDown';

import MenuBadge from './MenuBadge';
import MenuButton from './MenuButton';

import InformationCard from '../general/InformationCard';

import config from '../utils/config';

import './MainHeader.less';

const { Header } = Layout;

const MainHeader = (
  {
    handleOpenFormDrawer,
    handleOpenFormDrawerAlternate,
    menu,
    onChangeCarouselContentLevel,
    openDrawer,
    openFormDrawer,
    ...props
  }
) => {

  const {
    carouselContent,
    carouselContentLevel,
    classieContainer,
    classieContentBadgeText,
    hasClassieContentBadge,
    innerConfig,
    subject
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleJustify = () =>
    carouselContentLevel ?
      carouselContentLevel == carouselContentLevel / carouselContentLevel ?
        localConfig.grid.mainHeaderJustify +
        localConfig.text.activityResultSeparatorText +
        carouselContent
        :
        localConfig.grid.mainHeaderJustify
      :
      localConfig.grid.mainHeaderJustify
    ;

  console.log(subject);

  return (
    [
      <div
        className="container"
        ref={classieContainer}
      >
        <Header>
          <Row
            justify={handleJustify()}
            type="flex"
          >
            <Col>
              {
                carouselContentLevel ?
                  <Row
                    justify="space-between"
                    type="flex"
                  >
                    <Col>
                      {
                        carouselContentLevel - carouselContentLevel / carouselContentLevel == carouselContentLevel / carouselContentLevel + carouselContentLevel / carouselContentLevel &&
                        <Popconfirm
                          placement="bottom"
                          title={
                            [
                              <Row
                                justify="unset"
                                type="flex"
                              >
                                <Col>
                                  {localConfig.text.messageMainHeaderText.slice().shift().title}
                                </Col>
                              </Row>
                              ,
                              <Row
                                justify="unset"
                                type="flex"
                              >
                                <Col
                                  onMouseDown={event =>  handleOpenFormDrawerAlternate(event)}
                                >
                                  {localConfig.text.messageMainHeaderTextAlternate.slice().reverse().shift().title}
                                </Col>
                              </Row>
                              ,
                              <Row
                                justify="unset"
                                type="flex"
                              >
                                <Col
                                  onMouseDown={event =>  handleOpenFormDrawerAlternate(event)}
                                >
                                  {localConfig.text.messageMainHeaderTextAlternate.slice().shift().title}
                                </Col>
                              </Row>
                            ]
                          }
                        >
                          <Button

                          >
                          </Button>
                        </Popconfirm>
                      }
                    </Col>
                    <Col>
                      <InformationCard
                        hasHiddenAvatar={carouselContentLevel == carouselContentLevel / carouselContentLevel}
                        hasPartialContentAlternate
                        subject={subject}
                      />
                    </Col>
                  </Row>
                  :
                  <MenuBadge
                    carouselContent={carouselContent}
                    carouselContentLevel={carouselContentLevel}
                    classieContentBadgeText={classieContentBadgeText}
                    hasClassieContentBadge={hasClassieContentBadge}
                    innerConfig={localConfig}
                    rightText={localConfig.text.topLeftSlugText}
                    rightTextAlternate={localConfig.text.topLeftSlugSeparatorText}
                    rightTextThirdParty={localConfig.text.topLeftRightSlugText}
                  >
                  </MenuBadge>
              }
            </Col>
            <Col>
            </Col>
            <Col>
            </Col>
            <Col
              className="trigger"
            >
              {
                menu.header.mode ?
                  menu.header.items.map(
                    (item, index) =>
                      <MenuButton
                        action={
                          () => openDrawer(item)
                        }
                        tooltipPlacement="leftTop"
                        tooltipText={item.title}
                      >
                      </MenuButton>
                  )
                  :
                  (
                    carouselContentLevel &&
                    carouselContentLevel !== carouselContentLevel / carouselContentLevel + carouselContentLevel / carouselContentLevel
                  ) ?
                    [
                      <MenuButton
                        action={
                          event => handleOpenFormDrawer(event)
                        }
                        tooltipPlacement="leftTop"
                      >
                      </MenuButton>
                      ,
                      <MenuButton
                        action={
                          event => handleOpenFormDrawerAlternate(event)
                        }
                        tooltipPlacement="leftTop"
                      >
                      </MenuButton>
                    ]
                    :
                    <MainHeaderDropDown
                      carouselContentLevel={carouselContentLevel}
                      innerConfig={localConfig}
                      menu={menu}
                      openDrawer={openDrawer}
                      staticData={props.staticData}
                    >
                    </MainHeaderDropDown>
              }
            </Col>
          </Row>
        </Header>
      </div>
    ]
  );
}

export default MainHeader;
