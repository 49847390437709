import Amplify, { API } from 'aws-amplify';

Amplify.configure(
  {
    Auth: {
        identityPoolId: 'us-east-2:e9a77db4-56a3-45d9-a75b-27d7e2473e91',
        region: 'us-east-2',
        userPoolId: 'us-east-2_sJi8e8tHH',
        userPoolWebClientId: '5qb5k39c7ea5vf1caj7rp51nhj'
    },
    API: {
      endpoints: [
        {
          endpoint: "https://q02o6vwjc4.execute-api.us-east-2.amazonaws.com/Development",
          name: "VowtchNetworkAPI",
          region: 'us-east-2'
        },
        {
          endpoint: "https://y4lzluxm8k.execute-api.us-east-2.amazonaws.com/development",
          name: "VowtchRegistration-API",
          region: 'us-east-2'
        }
      ]
    }
  }
);
