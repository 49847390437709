import React from 'react';

import { connect } from "react-redux";

import {
  Anchor,
  Avatar,
  Col,
  Dropdown,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from '../MenuButton';

import config from '../../utils/config';

import './MainHeaderDropDown.less';

const MainHeaderDropDown = (
  {
    menu,
    openDrawer,
    ...props
  }
) => {
  const {
    carouselContentLevel,
    innerConfig
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const handleData = () =>
    props.staticData &&
    props.staticData.length ?
      props.staticData.slice().shift()
      :
      localConfig.entity.user
    ;

  console.log(menu);

  const handleDropdownMenu = (
    <Menu
    >
      <Menu.Item>
        <Avatar
          src={handleData().avatar}
        />
      </Menu.Item>
      {
        (
          (
            carouselContentLevel &&
            carouselContentLevel !== carouselContentLevel / carouselContentLevel + carouselContentLevel / carouselContentLevel
          ) ?
            menu.headerAlternate
            :
            (
              props.staticData &&
              props.staticData.length
            ) ?
              menu.header
              :
              menu.headerThirdParty ?
                menu.headerThirdParty
                :
                menu.headerAlternate
        ).map(
          (item, index) =>
            <Menu.Item>
              <MenuButton
                action={() => openDrawer(item)}
              >
              </MenuButton>
            </Menu.Item>
        )
      }
    </Menu>
  );

  return (
    <Dropdown
      overlay={handleDropdownMenu}
      placement={menu.headerDropdownPlacement}
    >
      <Avatar
        src={handleData().avatar}
      />
    </Dropdown>
  );
}

export default MainHeaderDropDown;
