import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import {
  Col,
  Menu,
  Row
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import MenuButton from './MenuButton';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

import MainMenuDynamicBody from './auxiliary/MainMenuDynamicBody';
import MenuInputItem from './auxiliary/MenuInputItem';
import MainMenuTopWidget from './auxiliary/MainMenuTopWidget';
import MainThirdPartyMenuDynamicBody from './auxiliary/MainThirdPartyMenuDynamicBody';
import MainThirdPartyMenuFooterWidget from './auxiliary/MainThirdPartyMenuFooterWidget';
import MainThirdPartyMenuInnerWidget from './auxiliary/MainThirdPartyMenuInnerWidget';
import MainThirdPartyMenuInnerWidgetInlineInformation from './auxiliary/MainThirdPartyMenuInnerWidgetInlineInformation';
import MainThirdPartyMenuInnerWidgetInnerWidget from './auxiliary/MainThirdPartyMenuInnerWidgetInnerWidget';

import MainThirdPartyMenuTopWidget from './auxiliary/MainThirdPartyMenuTopWidget';

import InformationCard from '../general/InformationCard';
import InstantMessenger from '../general/InstantMessenger';
import ScrollableContainer from '../general/ScrollableContainer';

import config from '../utils/config';

import './MainThirdPartyMenu.less';

const MainThirdPartyMenu = (
  {
    handleThirdPartyAlternateMenuClick,
    handleThirdPartyAlternateMenuClickAlternate,
    handleThirdPartyAlternateMenuClickThirdParty,
    handleThirdPartyAlternateMenuMenuClick,
    handleThirdPartyMenuClick,
    handleThirdPartyMenuMenuClick,
    handleThirdPartyThirdPartyAlternateMenuClick,
    openDrawer,
    ...props
  }
) => {

  const {
    alternateThirdPartyAlternateMenuAlternateShown,
    alternateThirdPartyAlternateMenuFilter,
    alternateThirdPartyAlternateMenuShown,
    alternateThirdPartyMenuShown,
    alternateThirdPartyThirdPartyMenuShown,
    hasFooter,
    hasTitle,
    innerConfig,
    thirdPartyMenuShown
  } = props;

  const localConfig = innerConfig ? innerConfig : config;

  const [step, setStep] = useState(localConfig.progress.progressPercent - localConfig.progress.progressPercent);

  const handleStep = (event, localStep) =>
    localStep ?
      setStep(localConfig.progress.progressPercent / localConfig.progress.progressPercent)
      :
      setStep(step + step / step)
  ;

  const handleFilter = () =>
    props.subject[localConfig.defaultFifthPartyField] &&
    (
      props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldAlternate] ||
      props.subject[localConfig.defaultFifthPartyField][localConfig.defaultFifthPartyFieldThirdParty]
    )
  ;

  console.log(props)

  console.log(handleFilter())
  console.log(step)

  return (
    <div>
      <Row
        justify="unset"
        type="flex"
      >
        <Col>
          <QueueAnim
            component={Menu}
            componentProps={{
              defaultOpenKeys: [],
              defaultSelectedKeys: [],
              mode: "inline"
            }}
            delay={localConfig.rcQueueAnim.delay}
            duration={localConfig.rcQueueAnim.duration}
            interval={localConfig.rcQueueAnim.interval}
            leaveReverse
            type={['right', 'left']}
          >
            <Menu.Item>
              <MenuHeader
                innerConfig={localConfig}
                isThirdPartyMenu
                openDrawer={openDrawer}
                rightBadgeText={localConfig.text.topLeftSlugThirdPartyText}
              />
            </Menu.Item>
            {
              alternateThirdPartyMenuShown ?
                [
                  <Menu.Item>
                    <MainThirdPartyMenuTopWidget
                      handleStep={handleStep}
                      handleThirdPartyMenuClick={handleThirdPartyMenuClick}
                      innerConfig={localConfig}
                      step={step}
                      subject={props.subject}
                    />
                  </Menu.Item>
                  ,
                  alternateThirdPartyThirdPartyMenuShown ?
                    <Menu.Item>
                      {
                        handleFilter() ?
                          step == step - step ?
                            <InstantMessenger
                              innerConfig={localConfig}
                              subject={props.subject}
                              topic={localConfig.entity.user[localConfig.defaultFifthPartyField]}
                            />
                            :
                            <MainThirdPartyMenuInnerWidgetInnerWidget
                              innerConfig={localConfig}
                              staticData={props.staticData}
                              step={step}
                            />
                          :
                          <InstantMessenger
                            innerConfig={localConfig}
                            subject={props.subject}
                            topic={localConfig.entity.user[localConfig.defaultFifthPartyField]}
                          />
                     }
                    </Menu.Item>
                    :
                    <Menu.Item>
                      {
                        handleFilter() ?
                          step == step - step ?
                            <InstantMessenger
                              innerConfig={localConfig}
                              subject={props.subject}
                              topic={localConfig.entity.user[localConfig.defaultFifthPartyField]}
                            />
                            :
                            <MainThirdPartyMenuInnerWidgetInnerWidget
                              innerConfig={localConfig}
                              staticData={props.staticData}
                              step={step}
                            />
                          :
                          <InstantMessenger
                            innerConfig={localConfig}
                            subject={props.subject}
                            topic={localConfig.entity.user[localConfig.defaultFifthPartyField]}
                          />
                     }
                    </Menu.Item>
                ]
                :
                alternateThirdPartyAlternateMenuShown ?
                  [
                    hasTitle &&
                    localConfig.menu.thirdPartyItem.hasInputItem ?
                      <Menu.Item>
                        <MenuInputItem
                          handleMenuClick={handleThirdPartyAlternateMenuClick}
                          innerConfig={localConfig}
                          menu={localConfig.menu.thirdPartyItem}
                        />
                      </Menu.Item>
                      :
                      <Menu.Item
                      >
                        <MainMenuTopWidget
                          alternateThirdPartyAlternateMenuAlternateShown={alternateThirdPartyAlternateMenuAlternateShown}
                          handleAlternateMenuClick={handleThirdPartyMenuMenuClick}
                          handleMenuClick={handleThirdPartyAlternateMenuClick}
                          innerConfig={localConfig}
                          menu={localConfig.menu.thirdPartyItem}
                          menuFilter={alternateThirdPartyAlternateMenuFilter}
                          step={step}
                          subject={props.subject}
                        />
                      </Menu.Item>
                    ,
                    (
                      alternateThirdPartyAlternateMenuAlternateShown &&
                      !props.menuFilter
                    ) ?
                      <Menu.Item>
                        <MainThirdPartyMenuDynamicBody
                          handleMenuClick={
                            alternateThirdPartyAlternateMenuFilter ?
                              handleThirdPartyAlternateMenuClick
                              :
                              handleThirdPartyAlternateMenuClickThirdParty
                          }
                          innerConfig={localConfig}
                          menu={localConfig.menu.thirdPartyItem}
                          menuFilter={alternateThirdPartyAlternateMenuFilter}
                          staticData={props.staticData}
                        />
                      </Menu.Item>
                      :
                      <Menu.Item>
                        <MainMenuDynamicBody
                          handleMenuClick={
                            alternateThirdPartyAlternateMenuFilter ?
                              handleThirdPartyMenuMenuClick
                              :
                              handleThirdPartyAlternateMenuMenuClick
                          }
                          innerConfig={localConfig}
                          menu={localConfig.menu.thirdPartyItem}
                          menuFilter={alternateThirdPartyAlternateMenuFilter}
                          staticData={props.staticData}
                        />
                      </Menu.Item>
                  ]
                  :
                  localConfig.entity.group.types ?
                    localConfig.entity.group.types.map(
                      (item, index) =>
                        (
                          (
                            item.parent &&
                            item.items &&
                            item.items[item.items.length - item.items.length / item.items.length] &&
                            item.items[item.items.length - item.items.length / item.items.length].parent
                          )
                          ||
                          (
                            item.parent &&
                            item.items &&
                            !item.items[item.items.length - item.items.length / item.items.length]
                          )
                        )
                        &&
                        <Menu.Item>
                          <MainThirdPartyMenuInnerWidget
                            handleThirdPartyAlternateMenuClick={handleThirdPartyAlternateMenuClick}
                            handleThirdPartyAlternateMenuClickAlternate={handleThirdPartyAlternateMenuClickAlternate}
                            handleThirdPartyMenuClick={handleThirdPartyMenuClick}
                            handleThirdPartyMenuMenuClick={handleThirdPartyMenuMenuClick}
                            innerConfig={localConfig}
                            item={item}
                            staticData={props.staticData}
                          />
                        </Menu.Item>
                    )
                    :
                    <Menu.Item>

                    </Menu.Item>
            }
            {
              hasFooter &&
              <Menu.Item>
                <MainThirdPartyMenuFooterWidget
                  alternateThirdPartyAlternateMenuAlternateShown={alternateThirdPartyAlternateMenuAlternateShown}
                  alternateThirdPartyAlternateMenuShown={alternateThirdPartyAlternateMenuShown}
                  alternateThirdPartyMenuShown={alternateThirdPartyMenuShown}
                  alternateThirdPartyThirdPartyMenuShown={alternateThirdPartyThirdPartyMenuShown}
                  handleStep={handleStep}
                  handleThirdPartyAlternateMenuClickAlternate={handleThirdPartyAlternateMenuClickAlternate}
                  innerConfig={localConfig}
                  menuFilter={alternateThirdPartyAlternateMenuFilter}
                  staticData={props.staticData}
                  step={step}
                  subject={props.subject}
                />
              </Menu.Item>
            }
          </QueueAnim>
        </Col>
      </Row>
    </div>
  )
}

export default MainThirdPartyMenu;
