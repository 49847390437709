import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import {
  Avatar,
  Button
} from 'antd';

import QueueAnim from 'rc-queue-anim';

import config from '../utils/config';

import './FormButton.less';

const FormButton = (
  {
    handleFormStep,
    handleFormStepValidate,
    ...props
  }
) => {

  console.log(props)

  const handleLoading = (
    localStep = props.step
  ) =>
    handleFormStep && localStep != localStep / localStep && localStep == handleFormStep()

  return (
    <Button
      onClick={props.action}
      loading={handleLoading()}
    >
      {
        props.addonBefore &&
        <Avatar
          src={props.addonBefore.avatar}
        />
      }
      {props.title}
      {
        props.addonAfter &&
        <Avatar
          src={props.addonAfter.avatar}
        />
      }
    </Button>
  );
}

export default FormButton;
